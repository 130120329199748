/**
 * @author Harsh Raj <harsh.raj@314ecorp.com>
 * @description Page Not Found Result
 */


import { Button, Result, ResultProps } from 'antd';
import { useNavigate } from 'react-router-dom';

const PageNotFoundResult: React.FC<ResultProps> = (props) => {
	const navigate = useNavigate();
	const extra = <Button onClick={() => navigate(-1)}>Go Back</Button>;
	return <Result extra={extra} title='Page Not Found' {...props} />;
};

export default PageNotFoundResult;
