/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Get Client Roles
 */

import _ from 'lodash';
import Keycloak from 'keycloak-js';

const getClientRoles = (keycloak: Keycloak, client?: string): string[] => {
	const clientId = client ?? keycloak?.clientId;

	if (clientId) {
		return _.get(keycloak, ['resourceAccess', clientId, 'roles'], []);
	}
	return [];
};

export default getClientRoles;
