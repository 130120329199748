/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Settings page
 */

import React, { useMemo } from 'react';
import _ from 'lodash';
import { Button, Layout, Menu } from 'antd';
import { Left } from '@icon-park/react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import ErrorBoundary from 'components/content/ErrorBoundary';
import LoadablePage from 'components/content/LoadablePage';
import ResourceAccess from 'configs/ResourceAccess';
import SETTINGS from 'configs/settings-url.config';
import { EXTRA_PATHS, SETTINGS_CONFIG } from 'configs/settings.config';
import { ROOT_URL } from 'constants/index';
import { useUserValues } from 'store/user';

const Settings: React.FC = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { user } = useUserValues();

	const { openKeys, config, menuItems, extra } = useMemo(() => {
		const ra = new ResourceAccess();
		const settingConfig = ra.filterMenuConfig(SETTINGS_CONFIG, user?.roles);
		return {
			openKeys: _.map(settingConfig, (item) => item.key),
			config: settingConfig,
			menuItems: ra.getMenuItems(settingConfig),
			extra: ra.filterExtraItems(EXTRA_PATHS, user?.roles),
		};
	}, [user?.roles]);

	const routes = useMemo(
		() =>
			_.map(_.concat(menuItems, extra), (item) => (
				<Route
					key={item.key}
					path={item.url}
					element={
						<ErrorBoundary>
							<LoadablePage item={item} />
						</ErrorBoundary>
					}
				/>
			)),
		[menuItems, extra],
	);

	const selectedKey = useMemo(() => _.first(_.split(_.get(params, '*'), '/')), [params]);

	const items = _.map(config, (item) => ({
		key: item.key,
		label: item.title,
		children: _.map(_.get(item, 'children'), (child: MenuItem) => ({
			key: child.key,
			icon: child.icon,
			label: _.isString(child.title) ? child.title : (child.title[0].text ?? 'Dexit'),
			onClick: () => navigate(child.routeUrl ? child.routeUrl : ''),
		})),
	}));

	return (
		<Layout hasSider className='main-layout settings-layout'>
			<Layout.Sider className='main-layout-sider' width={246}>
				<Button
					type='link'
					icon={<Left size='20' fill='#595959' />}
					className='setting-back-button grey-color'
					onClick={() => navigate(ROOT_URL)}
				>
					Settings
				</Button>
				<Menu
					mode='inline'
					expandIcon={<></>}
					selectedKeys={[selectedKey ?? SETTINGS.default.key]}
					openKeys={openKeys}
					items={items}
				/>
			</Layout.Sider>
			<Layout.Content className='main-layout-content'>
				<Routes>
					{routes}
					<Route path='*' element={<Navigate to={SETTINGS.default.url} replace />} />
				</Routes>
			</Layout.Content>
		</Layout>
	);
};

export default Settings;
