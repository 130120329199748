/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { StampRequestModel } from '../Models';
// @ts-ignore
import { StampResponseModel } from '../Models';
/**
 * StampApi - axios parameter creator
 * @export
 */
export const StampApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StampRequestModel} stampRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp: async (stampRequestModel: StampRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stampRequestModel' is not null or undefined
            assertParamExists('createStamp', 'stampRequestModel', stampRequestModel)
            const localVarPath = `/api/v1/stamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stampRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStampById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStampById', 'id', id)
            const localVarPath = `/api/v1/stamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStampById: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StampApi - functional programming interface
 * @export
 */
export const StampApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StampApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {StampRequestModel} stampRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStamp(stampRequestModel: StampRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStamp(stampRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.createStamp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStampById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStampById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.deleteStampById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStampById(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StampResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStampById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StampApi.getStampById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StampApi - factory interface
 * @export
 */
export const StampApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StampApiFp(configuration)
    return {
        /**
         * 
         * @param {StampRequestModel} stampRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(stampRequestModel: StampRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createStamp(stampRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStampById(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteStampById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStampById(id?: string, options?: any): AxiosPromise<Array<StampResponseModel>> {
            return localVarFp.getStampById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StampApi - interface
 * @export
 * @interface StampApi
 */
export interface StampApiInterface {
    /**
     * 
     * @param {StampRequestModel} stampRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApiInterface
     */
    createStamp(stampRequestModel: StampRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApiInterface
     */
    deleteStampById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApiInterface
     */
    getStampById(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StampResponseModel>>;

}

/**
 * StampApi - object-oriented interface
 * @export
 * @class StampApi
 * @extends {BaseAPI}
 */
export class StampApi extends BaseAPI implements StampApiInterface {
    /**
     * 
     * @param {StampRequestModel} stampRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public createStamp(stampRequestModel: StampRequestModel, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).createStamp(stampRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public deleteStampById(id: string, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).deleteStampById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public getStampById(id?: string, options?: RawAxiosRequestConfig) {
        return StampApiFp(this.configuration).getStampById(id, options).then((request) => request(this.axios, this.basePath));
    }
}

