/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description File Processing
 */

import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import api from 'client/index';
import { ResponseModel } from '@dexit/common/openapi';
import { Status } from 'store/upload';

const QUERY_UPLOAD_FILE = 'query-uploadfile';

interface FileProcess {
	batchId: string;
	source: 'Upload' | 'Scan';
	changeUploadStatus: (batchId: string, status: Status) => void;
}

const useFileProcessing = (): UseMutationResult<AxiosResponse<ResponseModel, any>, unknown, FileProcess, string> => {
	return useMutation({
		mutationKey: [QUERY_UPLOAD_FILE],
		mutationFn: async ({ batchId }) => {
			return await api.BatchScanningApi.fileProcessing(batchId, 'create');
		},
		onSuccess: (response, { changeUploadStatus }) => {
			if (response.data.id) {
				changeUploadStatus(response.data.id, Status.inProgress);
			}
		},
		onError: (__, { batchId, changeUploadStatus }) => {
			changeUploadStatus(batchId, Status.error);
		},
	});
};

export default useFileProcessing;
