/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ChannelEnum } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { SubscriptionRequestModel } from '../Models';
// @ts-ignore
import { SubscriptionResponseModel } from '../Models';
// @ts-ignore
import { WorkflowChannelPreference } from '../Models';
/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SubscriptionRequestModel} subscriptionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (subscriptionRequestModel: SubscriptionRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionRequestModel' is not null or undefined
            assertParamExists('createSubscription', 'subscriptionRequestModel', subscriptionRequestModel)
            const localVarPath = `/api/v1/subscription/createSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription: async (subscriptionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('deleteSubscription', 'subscriptionId', subscriptionId)
            const localVarPath = `/api/v1/subscription/deleteSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscriptionForUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscription/getAllSubscriptionForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberChannelPreference: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscription/getSubscriberChannelPreference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChannelEnum} channel 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriberPreference: async (channel: ChannelEnum, enabled?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channel' is not null or undefined
            assertParamExists('updateSubscriberPreference', 'channel', channel)
            const localVarPath = `/api/v1/subscription/updateSubscriberPreference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SubscriptionRequestModel} subscriptionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(subscriptionRequestModel: SubscriptionRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(subscriptionRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.createSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscription(subscriptionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubscription(subscriptionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.deleteSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSubscriptionForUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSubscriptionForUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.getAllSubscriptionForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberChannelPreference(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowChannelPreference>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberChannelPreference(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.getSubscriberChannelPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ChannelEnum} channel 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriberPreference(channel: ChannelEnum, enabled?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowChannelPreference>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriberPreference(channel, enabled, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionApi.updateSubscriberPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {SubscriptionRequestModel} subscriptionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(subscriptionRequestModel: SubscriptionRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createSubscription(subscriptionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(subscriptionId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteSubscription(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscriptionForUser(options?: any): AxiosPromise<Array<SubscriptionResponseModel>> {
            return localVarFp.getAllSubscriptionForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberChannelPreference(options?: any): AxiosPromise<Array<WorkflowChannelPreference>> {
            return localVarFp.getSubscriberChannelPreference(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChannelEnum} channel 
         * @param {boolean} [enabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriberPreference(channel: ChannelEnum, enabled?: boolean, options?: any): AxiosPromise<Array<WorkflowChannelPreference>> {
            return localVarFp.updateSubscriberPreference(channel, enabled, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - interface
 * @export
 * @interface SubscriptionApi
 */
export interface SubscriptionApiInterface {
    /**
     * 
     * @param {SubscriptionRequestModel} subscriptionRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    createSubscription(subscriptionRequestModel: SubscriptionRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    deleteSubscription(subscriptionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getAllSubscriptionForUser(options?: RawAxiosRequestConfig): AxiosPromise<Array<SubscriptionResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getSubscriberChannelPreference(options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkflowChannelPreference>>;

    /**
     * 
     * @param {ChannelEnum} channel 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    updateSubscriberPreference(channel: ChannelEnum, enabled?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkflowChannelPreference>>;

}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI implements SubscriptionApiInterface {
    /**
     * 
     * @param {SubscriptionRequestModel} subscriptionRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public createSubscription(subscriptionRequestModel: SubscriptionRequestModel, options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).createSubscription(subscriptionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public deleteSubscription(subscriptionId: string, options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).deleteSubscription(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getAllSubscriptionForUser(options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getAllSubscriptionForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriberChannelPreference(options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getSubscriberChannelPreference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChannelEnum} channel 
     * @param {boolean} [enabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public updateSubscriberPreference(channel: ChannelEnum, enabled?: boolean, options?: RawAxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).updateSubscriberPreference(channel, enabled, options).then((request) => request(this.axios, this.basePath));
    }
}

