/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description keycloak isAuthorized
 */

import _ from 'lodash';

enum operators {
	AND,
	OR,
}

const isAuthorized = (collection?: string[], target?: string | string[], operator?: operators): boolean => {
	if (_.isArray(target)) {
		switch (operator) {
			case operators.OR:
				return _.some(target, (role) => _.includes(collection, role));
			case operators.AND:
			default:
				return _.size(_.intersection(collection, target)) === _.size(target);
		}
	}

	if (_.isString(target)) {
		return _.includes(collection, target);
	}

	return false;
};

export default isAuthorized;
export { operators };
