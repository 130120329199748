/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description useBatch
 */
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import api from 'client/index';
import { GetBatchesResponseModel } from '@dexit/common/openapi';

export const QUERY_BATCHES_KEY = 'query-batches';

const useBatches = (
	batchId?: string[],
	queueId?: string,
	options?: UseQueryOptions<
		Promise<GetBatchesResponseModel[]>,
		unknown,
		GetBatchesResponseModel[],
		(string | undefined)[]
	>,
): UseQueryResult<GetBatchesResponseModel[], unknown> => {
	return useQuery({
		queryKey: [QUERY_BATCHES_KEY, JSON.stringify(batchId), queueId],
		queryFn: async () => {
			const { data } = await api.BatchScanningApi.getBatches(batchId, queueId);
			return data;
		},
		...options,
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useBatches;
