/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Upload Helper
 */


import { RemoteScanObject } from 'dwt/dist/types/RemoteScan';
import { WebTwain } from 'dwt/dist/types/WebTwain';

import EventBus, { Events } from '@dexit/common/utils/EventBus';
import config from 'configs/config.integration';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const isWebTwain = (object: WebTwain | RemoteScanObject): object is WebTwain => 'GetSourceNames' in object;

const publishError: (error: string) => void = (error: string) => EventBus.publish(Events.M_Error, error);

const itemExpandIcon: (isActive?: boolean) => React.JSX.Element = (isActive) =>
	isActive ? <DownOutlined /> : <RightOutlined />;

const chunkSize = config.dwt.chunkSize;
const getPageStartScan: (imageIndex: number) => number = (imageIndex: number) =>
	Math.max(0, imageIndex - chunkSize + 1) + 1;

export { isWebTwain, publishError, getPageStartScan, itemExpandIcon };
