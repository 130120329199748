/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description QueuesOutlined Icon
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
}

const QueuesOutlined: React.FC<IProps> = (props) => {
	const { className, style, ...restProps } = props;
	const cn = classnames('anticon anticon-queuesoutlined', className);
	return (
		<span role='img' aria-label='Queuesoutlined' className={cn} style={style} {...restProps}>
			<svg
				aria-label='Queuesoutlined'
				width={style?.fontSize ?? 14}
				height={style?.fontSize ?? 14}
				viewBox='0 0 17 17'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M14.8835 16.9998C15.0399 17.0024 15.1953 16.9735 15.3403 16.9149C15.4853 16.8562 15.6171 16.769 15.7277 16.6583C15.8384 16.5477 15.9256 16.4159 15.9843 16.2709C16.0429 16.1259 16.0718 15.9705 16.0692 15.8141L16.0692 8.81476C16.0692 8.50537 15.9463 8.20865 15.7276 7.98988C15.5088 7.77111 15.2121 7.64821 14.9027 7.64821L7.90817 7.64821C7.59878 7.64821 7.30206 7.77111 7.08329 7.98988C6.86452 8.20865 6.74161 8.50537 6.74161 8.81476L6.74161 15.8141C6.73906 15.9689 6.76736 16.1226 6.82484 16.2664C6.88232 16.4102 6.96785 16.541 7.07643 16.6514C7.185 16.7618 7.31447 16.8494 7.45727 16.9092C7.60007 16.9691 7.75334 16.9999 7.90817 16.9998L14.8835 16.9998Z'
					fill={style?.color ?? '#9098A7'}
				/>
				<path
					d='M7.75734 6.62942C7.24626 6.63697 6.7586 6.84499 6.39942 7.20866C6.04024 7.57233 5.83829 8.06254 5.83709 8.57368L5.83709 13.6287L4.62253 13.6287C4.46569 13.6314 4.30993 13.6023 4.16457 13.5434C4.01921 13.4844 3.88723 13.3967 3.77653 13.2856C3.66584 13.1744 3.57869 13.0421 3.52032 12.8965C3.46194 12.7509 3.43352 12.595 3.43677 12.4382L3.43677 5.46287C3.43418 5.30644 3.46307 5.15108 3.52174 5.00604C3.58041 4.86101 3.66766 4.72925 3.77829 4.61863C3.88892 4.508 4.02067 4.42075 4.16571 4.36208C4.31074 4.30341 4.4661 4.27451 4.62253 4.27711L11.6219 4.27711C11.9312 4.27711 12.228 4.40002 12.4467 4.61879C12.6655 4.83756 12.7884 5.13427 12.7884 5.44366L12.7884 6.62942L7.75734 6.62942Z'
					fill={style?.color ?? '#9098A7'}
				/>
				<path
					d='M4.32082 3.25247C3.80974 3.26002 3.32207 3.46804 2.96289 3.83171C2.60371 4.19538 2.40177 4.68559 2.40057 5.19672L2.40057 10.2518L1.18601 10.2518C1.02916 10.2544 0.873403 10.2254 0.728043 10.1664C0.582682 10.1074 0.450707 10.0198 0.340011 9.90861C0.229315 9.79747 0.142171 9.66514 0.0837931 9.51954C0.0254148 9.37394 -0.00299924 9.21807 0.000250237 9.06123L0.000249627 2.08591C-0.0023473 1.92948 0.0265491 1.77413 0.0852194 1.62909C0.14389 1.48405 0.231136 1.3523 0.341765 1.24167C0.452394 1.13104 0.584146 1.0438 0.729182 0.985128C0.874218 0.926457 1.02957 0.897561 1.18601 0.900158L8.18533 0.900157C8.49472 0.900157 8.79143 1.02306 9.01021 1.24183C9.22898 1.4606 9.35188 1.75732 9.35188 2.06671L9.35188 3.25247L4.32082 3.25247Z'
					fill={style?.color ?? '#9098A7'}
				/>
			</svg>
		</span>
	);
};

export default QueuesOutlined;
