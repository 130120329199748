/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description TiffViewer
 */

import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import { Button, InputNumber, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { Down, Up, ZoomIn, ZoomOut } from '@icon-park/react';

interface IProps {
	url: string;
	canvasKey?: React.Key;
}

const TiffViewer: React.FC<IProps> = (props) => {
	const { url, canvasKey } = props;

	const [numPages, setNumPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [loading, setLoading] = useState(true);
	const [scale, setScale] = useState(1);
	const initialArrayBufferRef = useRef<ArrayBuffer | null>(null);
	const ifdsRef = useRef<any[]>([]);

	const displayPage = (page: number) => {
		// @ts-ignore
		const utif = UTIF;
		utif.decodeImage(initialArrayBufferRef.current!, ifdsRef.current[page]);

		const rgba: Uint8Array = utif.toRGBA8(ifdsRef.current[page]);
		const canvas: HTMLCanvasElement = document.getElementById(`canvas-${canvasKey ?? 'tiff'}`) as HTMLCanvasElement;
		canvas.width = ifdsRef.current[page].width;
		canvas.height = ifdsRef.current[page].height;
		const ctx = canvas.getContext('2d');
		if (ctx) {
			const img = ctx.createImageData(ifdsRef.current[page].width ?? 1, ifdsRef.current[page].height ?? 1);
			_.forEach(rgba, (val, i) => {
				img.data[i] = val;
			});

			ctx.putImageData(img, 0, 0);
			setLoading(false);
			setCurrentPage(page);
		} else {
			log.warn('Canvas context not created');
		}
	};

	const imgLoaded = (e: any) => {
		// @ts-ignore
		const utif = UTIF;
		ifdsRef.current = utif.decode(e.target.response);
		initialArrayBufferRef.current = e.target.response;
		setNumPages(_.size(ifdsRef.current));
		setCurrentPage(0);
		displayPage(0);
	};

	useEffect(() => {
		if (url) {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', url);
			xhr.responseType = 'arraybuffer';
			xhr.onload = imgLoaded;
			xhr.send();
		}
	}, [url]);

	const zoomIn = () => {
		if (scale >= 2) {
			return;
		}
		setScale(scale + 0.25);
		displayPage(currentPage);
	};

	const zoomOut = () => {
		if (scale <= 0.25) {
			return;
		}
		setScale(scale - 0.25);
		displayPage(currentPage);
	};

	return (
		<div className='full-width-height flex-column'>
			<Row justify='space-between' style={{ backgroundColor: '#F9F9FA' }}>
				<Space
					className='flex-space-between full-width'
					style={{
						padding: 5,
						backgroundColor: '#F0F3F5',
						borderBottom: '1px solid #E8EFEF',
					}}
				>
					<Space size={8}>
						<Tooltip title='Previous Page' placement='bottom'>
							<Button
								className='grey-color'
								shape='circle'
								type='text'
								icon={<Up size='16' />}
								disabled={currentPage === 0}
								onClick={() => displayPage(currentPage - 1)}
							/>
						</Tooltip>
						<Tooltip title='Next Page' placement='bottom'>
							<Button
								className='grey-color'
								shape='circle'
								type='text'
								icon={<Down size='16' />}
								disabled={currentPage === numPages - 1}
								onClick={() => displayPage(currentPage + 1)}
							/>
						</Tooltip>
						<div>
							<InputNumber
								style={{ marginRight: 8, width: 36, backgroundColor: '#E1E4E5' }}
								onPressEnter={(event: any) => {
									const value = _.parseInt(event.target.value);
									displayPage(value - 1);
								}}
								value={currentPage + 1}
								controls={false}
							/>
							<Typography.Text type='secondary'>/ {numPages}</Typography.Text>
						</div>
					</Space>
					<Space size={8}>
						<Typography.Text>{`${scale * 100}%`}</Typography.Text>
						<Button
							className='grey-color'
							shape='circle'
							type='text'
							icon={<ZoomIn size='16' />}
							onClick={zoomIn}
						/>
						<Button
							className='grey-color'
							shape='circle'
							type='text'
							icon={<ZoomOut size='16' />}
							onClick={zoomOut}
						/>
					</Space>
				</Space>
			</Row>
			<div style={{ flex: 1, overflow: 'auto', backgroundColor: '#EBEEF0', alignContent: 'center' }}>
				<canvas
					id={`canvas-${canvasKey ?? 'tiff'}`}
					style={{
						width: loading ? 0 : `calc(100% * ${scale})`,
						position: 'relative',
						left: scale >= 1 ? undefined : '50%',
						transform: scale >= 1 ? undefined : 'translateX(-50%)',
					}}
				/>
				{loading && <Spin style={{ position: 'relative', left: '50%', transform: 'translateX(-50%)' }} />}
			</div>
		</div>
	);
};

export default TiffViewer;
