/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { EventSubscriptionResponseModel } from '../Models';
// @ts-ignore
import { EventsubscriptionRequestModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
/**
 * EventSubscriptionApi - axios parameter creator
 * @export
 */
export const EventSubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EventsubscriptionRequestModel} eventsubscriptionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventSubscription: async (eventsubscriptionRequestModel: EventsubscriptionRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventsubscriptionRequestModel' is not null or undefined
            assertParamExists('createEventSubscription', 'eventsubscriptionRequestModel', eventsubscriptionRequestModel)
            const localVarPath = `/api/v1/eventSubscription/createEventSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventsubscriptionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSubscription: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/eventSubscription/getEventSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventSubscriptionApi - functional programming interface
 * @export
 */
export const EventSubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventSubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EventsubscriptionRequestModel} eventsubscriptionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventSubscription(eventsubscriptionRequestModel: EventsubscriptionRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventSubscription(eventsubscriptionRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventSubscriptionApi.createEventSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventSubscription(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventSubscriptionResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventSubscription(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventSubscriptionApi.getEventSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventSubscriptionApi - factory interface
 * @export
 */
export const EventSubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventSubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {EventsubscriptionRequestModel} eventsubscriptionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventSubscription(eventsubscriptionRequestModel: EventsubscriptionRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createEventSubscription(eventsubscriptionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSubscription(id?: string, options?: any): AxiosPromise<Array<EventSubscriptionResponseModel>> {
            return localVarFp.getEventSubscription(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventSubscriptionApi - interface
 * @export
 * @interface EventSubscriptionApi
 */
export interface EventSubscriptionApiInterface {
    /**
     * 
     * @param {EventsubscriptionRequestModel} eventsubscriptionRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventSubscriptionApiInterface
     */
    createEventSubscription(eventsubscriptionRequestModel: EventsubscriptionRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventSubscriptionApiInterface
     */
    getEventSubscription(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EventSubscriptionResponseModel>>;

}

/**
 * EventSubscriptionApi - object-oriented interface
 * @export
 * @class EventSubscriptionApi
 * @extends {BaseAPI}
 */
export class EventSubscriptionApi extends BaseAPI implements EventSubscriptionApiInterface {
    /**
     * 
     * @param {EventsubscriptionRequestModel} eventsubscriptionRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventSubscriptionApi
     */
    public createEventSubscription(eventsubscriptionRequestModel: EventsubscriptionRequestModel, options?: RawAxiosRequestConfig) {
        return EventSubscriptionApiFp(this.configuration).createEventSubscription(eventsubscriptionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventSubscriptionApi
     */
    public getEventSubscription(id?: string, options?: RawAxiosRequestConfig) {
        return EventSubscriptionApiFp(this.configuration).getEventSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }
}

