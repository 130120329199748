/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DocumentReviewRequestModel } from '../Models';
// @ts-ignore
import { DocumentReviewResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
/**
 * DocumentReviewApi - axios parameter creator
 * @export
 */
export const DocumentReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DocumentReviewRequestModel} documentReviewRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentReview: async (documentReviewRequestModel: DocumentReviewRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentReviewRequestModel' is not null or undefined
            assertParamExists('createDocumentReview', 'documentReviewRequestModel', documentReviewRequestModel)
            const localVarPath = `/api/v1/documentReview/createDocumentReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentReviewRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentReview: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocumentReview', 'id', id)
            const localVarPath = `/api/v1/documentReview/deleteDocumentReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isResolved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentReview: async (id?: string, isResolved?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documentReview/reviewDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (isResolved !== undefined) {
                localVarQueryParameter['is_resolved'] = isResolved;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentReviewApi - functional programming interface
 * @export
 */
export const DocumentReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DocumentReviewRequestModel} documentReviewRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentReview(documentReviewRequestModel: DocumentReviewRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentReview(documentReviewRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentReviewApi.createDocumentReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentReview(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentReview(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentReviewApi.deleteDocumentReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isResolved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentReview(id?: string, isResolved?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentReviewResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentReview(id, isResolved, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentReviewApi.getDocumentReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentReviewApi - factory interface
 * @export
 */
export const DocumentReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentReviewApiFp(configuration)
    return {
        /**
         * 
         * @param {DocumentReviewRequestModel} documentReviewRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentReview(documentReviewRequestModel: DocumentReviewRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createDocumentReview(documentReviewRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentReview(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDocumentReview(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isResolved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentReview(id?: string, isResolved?: boolean, options?: any): AxiosPromise<Array<DocumentReviewResponseModel>> {
            return localVarFp.getDocumentReview(id, isResolved, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentReviewApi - interface
 * @export
 * @interface DocumentReviewApi
 */
export interface DocumentReviewApiInterface {
    /**
     * 
     * @param {DocumentReviewRequestModel} documentReviewRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApiInterface
     */
    createDocumentReview(documentReviewRequestModel: DocumentReviewRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApiInterface
     */
    deleteDocumentReview(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isResolved] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApiInterface
     */
    getDocumentReview(id?: string, isResolved?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentReviewResponseModel>>;

}

/**
 * DocumentReviewApi - object-oriented interface
 * @export
 * @class DocumentReviewApi
 * @extends {BaseAPI}
 */
export class DocumentReviewApi extends BaseAPI implements DocumentReviewApiInterface {
    /**
     * 
     * @param {DocumentReviewRequestModel} documentReviewRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public createDocumentReview(documentReviewRequestModel: DocumentReviewRequestModel, options?: RawAxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).createDocumentReview(documentReviewRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public deleteDocumentReview(id: string, options?: RawAxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).deleteDocumentReview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isResolved] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentReviewApi
     */
    public getDocumentReview(id?: string, isResolved?: boolean, options?: RawAxiosRequestConfig) {
        return DocumentReviewApiFp(this.configuration).getDocumentReview(id, isResolved, options).then((request) => request(this.axios, this.basePath));
    }
}

