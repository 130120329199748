/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description upload store
 */

import _ from 'lodash';
import { UploadFile } from 'antd';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface Uploads {
	files: { file: UploadFile<any>; progress: number }[];
	batchId: string;
	status: Status;
}
export enum Status {
	inProgress = 'in progress',
	done = 'done',
	error = 'error',
	uploading = 'uploading',
	scanning = 'scanning',
}

type State = {
	uploads: Uploads[];
};

type Actions = {
	setUploads: (uploads: Uploads[]) => { uploads: Uploads[] };
	addToUploads: (upload: Uploads) => { upload: Uploads };
	setProgress: (
		progress: number,
		index: number,
		batchId: string,
	) => { progress: number; index: number; batchId: string };
	changeUploadStatus: (batchId: string, status: Status) => { batchId: string; status: Status };
	addFileToBatch: (file: Uploads['files'][0], batchId: string) => { file: Uploads['files'][0]; batchId: string };
	changeFileName: (name: string, batchId: string) => { name: string; batchId: string };
};

const initialState: State = {
	uploads: [],
};

const actions = (set: any): Actions => ({
	setUploads: (uploads) => set({ uploads }),
	addToUploads: (upload) => set((state: State) => ({ uploads: [upload, ...state.uploads] })),
	setProgress: (progress, index, batchId) =>
		set((state: State) => ({
			uploads: _.map(state.uploads, (upload) =>
				upload.batchId === batchId
					? {
							...upload,
							files: _.map(upload.files, (uploadFiles, fileIndex) =>
								fileIndex === index ? { ...uploadFiles, progress } : uploadFiles,
							),
						}
					: upload,
			),
		})),
	changeUploadStatus: (batchId, status) =>
		set((state: State) => ({
			uploads: _.map(state.uploads, (upload) => (upload.batchId === batchId ? { ...upload, status } : upload)),
		})),
	addFileToBatch: (file, batchId) =>
		set((state: State) => ({
			uploads: _.map(state.uploads, (upload) =>
				upload.batchId === batchId
					? {
							...upload,
							files: [...upload.files, file],
						}
					: upload,
			),
		})),
	changeFileName: (name, batchId) =>
		set((state: State) => ({
			uploads: _.map(state.uploads, (upload) =>
				upload.batchId === batchId
					? {
							...upload,
							files: [{ file: { ...upload.files[0].file, name }, progress: 100 }],
						}
					: upload,
			),
		})),
});

const useUploadValues = (): State =>
	useUploadStore((state) => ({
		uploads: state.uploads,
	}));

const useUploadActions = (): Actions =>
	useUploadStore((state) => ({
		setUploads: state.setUploads,
		addToUploads: state.addToUploads,
		setProgress: state.setProgress,
		changeUploadStatus: state.changeUploadStatus,
		addFileToBatch: state.addFileToBatch,
		changeFileName: state.changeFileName,
	}));

const useUploadStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			...actions(set),
		}),
		{ name: 'Upload Store' },
	),
);

export default useUploadStore;
export { useUploadValues, useUploadActions };
