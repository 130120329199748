/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Default Pdf Render
 */

import * as pdfJS from 'pdfjs-dist/legacy/build/pdf.mjs';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import { Spin, Typography } from 'antd';

import useOnScreen from 'components/indexing/useOnScreen';

pdfJS.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.min.mjs', import.meta.url).toString();
const outputScale = globalThis.devicePixelRatio || 1;

interface IProps {
	id: string;
	pageNum: number;
	pdf: pdfJS.PDFDocumentProxy;
	pageToView: { pageNum: number; scroll: boolean };
	pdfRenderScale: number;
	setPageToView: (
		pageNum?: number,
		scroll?: boolean,
	) => {
		pageNum: number;
		scroll: boolean;
	};
}

const RenderPdf: React.FC<IProps> = (props) => {
	const { id, pageNum, pdf, pageToView, pdfRenderScale, setPageToView } = props;
	const [page, setPage] = useState<pdfJS.PDFPageProxy | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const { isIntersecting } = useOnScreen(canvasRef);

	useEffect(() => {
		if (isIntersecting) {
			setPageToView(pageNum, false);
		}
	}, [isIntersecting]);

	useEffect(() => {
		if (canvasRef.current && pageToView.pageNum === pageNum) {
			canvasRef.current.scrollIntoView();
		}
	}, [pageToView]);

	const renderPage = (page: pdfJS.PDFPageProxy) => {
		const viewport = page.getViewport({ scale: pdfRenderScale });
		const canvas: HTMLCanvasElement = canvasRef.current ?? new HTMLCanvasElement();
		const canvasContext = canvas.getContext('2d');
		if (!canvasContext) {
			return;
		}

		canvas.width = _.floor(viewport.width * outputScale);
		canvas.height = _.floor(viewport.height * outputScale);
		canvas.style.width = `${_.floor(viewport.width)}px`;
		canvas.style.height = `${_.floor(viewport.height)}px`;

		const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined;
		const renderContext = { canvasContext, viewport, transform };

		const renderTask = page.render(renderContext);
		renderTask.promise.then(
			() => {
				setLoading(false);
				setError(false);
				log.info('Page rendered');
			},
			(reason: any) => {
				setError(true);
				setLoading(false);
				log.error(reason);
			},
		);
	};

	const loadPage = async () => {
		try {
			const page = await pdf.getPage(pageNum);
			setPage(page);
		} catch (err) {
			setError(true);
			setLoading(false);
			log.error(err);
		}
	};

	useEffect(() => {
		if (page) {
			renderPage(page);
		}
	}, [page, pdfRenderScale]);

	useEffect(() => {
		void loadPage();
	}, [pdf]);

	if (error) {
		return <Typography.Title level={3}> We&apos;re sorry, unable to open file...!</Typography.Title>;
	}

	return (
		<Spin spinning={loading}>
			<canvas id={id} key={id} ref={canvasRef} style={{ height: '65vh' }} />
		</Spin>
	);
};

export default RenderPdf;
