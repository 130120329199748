/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Agent config
 */

import Environment from '@dexit/common/utils/Environment';
import { DEFAULT_SERVER_URL } from '@dexit/common/constants/url';
import { realm } from 'configs/config.integration';
import _ from 'lodash';

export default {
	baseURL: Environment.isLocalhost()
		? DEFAULT_SERVER_URL
		: _.replace('https://{tenant}.api.dexit.tech', '{tenant}', realm),
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true,
};
