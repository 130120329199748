/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Util functions for documents
 */


import _ from 'lodash';
import { AudioOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

import DicomOutlined from 'components/icons/DicomOutlined';
import ExcelOutlined from 'components/icons/ExcelOutlined';
import ImageOutlined from 'components/icons/ImageOutlined';
import ImageViewer from 'components/image/ImageViewer';
import NotesOutlined from 'components/icons/NotesOutlined';
import OHIFViewer from 'components/ohif/OHIFViewer';
import PdfOutlined from 'components/icons/PdfOutlined';
import PowerpointOutlined from 'components/icons/PowerpointOutlined';
import PreviewUnavailable from './PreviewUnavailable';
import TiffViewer from 'components/fax/TiffViewer';
import VideoOutlined from 'components/icons/VideoOutlined';
import VideoPlayer from 'components/play-content/VideoPlayer';
import WordOutlined from 'components/icons/WordOutlined';

enum FileGroups {
	Dicom = 'dicom',
	Excel = 'excel',
	Doc = 'doc',
	Powerpoint = 'powerpoint',
	Image = 'image',
	Video = 'video',
	Audio = 'audio',
	Pdf = 'pdf',
	Tiff = 'tiff',
	Other = 'other',
}

const fileGroups = {
	[FileGroups.Dicom]: ['dcm'],
	[FileGroups.Excel]: ['xls', 'xlsx', 'csv'],
	[FileGroups.Doc]: ['doc', 'docx', 'txt'],
	[FileGroups.Powerpoint]: ['ppt', 'pptx'],
	[FileGroups.Image]: ['jpg', 'jpeg', 'png', 'gif'],
	[FileGroups.Video]: ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm', 'm4v', 'ogg'],
	[FileGroups.Audio]: ['mp3', 'wav', 'aac', 'm4a', 'wma'],
	[FileGroups.Pdf]: ['pdf'],
	[FileGroups.Tiff]: ['tiff', 'tif'],
};

const getFileIcon: (fileName?: string, hideTooltip?: boolean, placement?: TooltipPlacement) => React.JSX.Element = (
	fileName?: string,
	hideTooltip?: boolean,
	placement?: TooltipPlacement,
) => {
	const fileExt = _.toLower(fileName?.split('.').pop());
	switch (true) {
		case _.isEqual(fileName, FileGroups.Excel) || _.includes(fileGroups[FileGroups.Excel], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'EXCEL File'} placement={placement}>
					<ExcelOutlined />
				</Tooltip>
			);

		case _.isEqual(fileName, FileGroups.Dicom) || _.includes(fileGroups[FileGroups.Dicom], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'DICOM File'} placement={placement}>
					<DicomOutlined />
				</Tooltip>
			);

		case _.isEqual(fileName, FileGroups.Video) || _.includes(fileGroups[FileGroups.Video], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'VIDEO File'} placement={placement}>
					<VideoOutlined />
				</Tooltip>
			);

		case _.isEqual(fileName, FileGroups.Audio) || _.includes(fileGroups[FileGroups.Audio], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'AUDIO File'} placement={placement}>
					<AudioOutlined />
				</Tooltip>
			);

		case _.isEqual(fileName, FileGroups.Image) || _.includes(fileGroups[FileGroups.Image], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'IMAGE File'} placement={placement}>
					<ImageOutlined />
				</Tooltip>
			);

		case _.includes([FileGroups.Pdf, FileGroups.Tiff], fileName) ||
			_.includes(_.union(fileGroups[FileGroups.Pdf], fileGroups[FileGroups.Tiff]), fileExt):
			return (
				<Tooltip
					title={
						!hideTooltip &&
						`${_.includes([FileGroups.Pdf], fileName) || _.includes(fileGroups[FileGroups.Pdf], fileExt) ? 'PDF' : 'TIFF'} File`
					}
					placement={placement}
				>
					<PdfOutlined />
				</Tooltip>
			);

		case _.isEqual(fileName, FileGroups.Powerpoint) || _.includes(fileGroups[FileGroups.Powerpoint], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'POWERPOINT File'} placement={placement}>
					{<PowerpointOutlined />}
				</Tooltip>
			);

		case _.isEqual(fileName, FileGroups.Doc) || _.includes(fileGroups[FileGroups.Doc], fileExt):
			return (
				<Tooltip title={!hideTooltip && 'WORD File'} placement={placement}>
					<WordOutlined />
				</Tooltip>
			);

		default:
			return (
				<Tooltip title={!hideTooltip && 'OTHER File'} placement={placement}>
					<NotesOutlined height={15} width={14} />
				</Tooltip>
			);
	}
};

const getViewer: (
	PdfViewer: React.JSX.Element,
	data: string,
	fileType?: FileGroups,
	key?: React.Key,
) => React.JSX.Element | 'Preview not available' = (
	PdfViewer: React.JSX.Element,
	data: string,
	fileType?: FileGroups,
	key?: React.Key,
) => {
	switch (fileType) {
		case FileGroups.Doc:
		case FileGroups.Powerpoint:
		case FileGroups.Excel:
		case FileGroups.Pdf:
			return PdfViewer;

		case FileGroups.Dicom:
			return (
				<OHIFViewer
					StudyInstanceUIDs={data}
					// SeriesInstanceUID={_.get(
					// 	_.find(indexInfo?.customattributes, { field_name: 'SopInstanceUID' }),
					// 	'value',
					// )}
					// initialSOPInstanceUID={_.get(
					// 	_.find(indexInfo?.customattributes, { field_name: 'SeriesInstanceUID' }),
					// 	'value',
					// )}
				/>
			);

		case FileGroups.Video:
			return <VideoPlayer url={data} style={{ background: '#EBEEF0' }} />;

		case FileGroups.Audio:
			return (
				<audio controls src={data} style={{ margin: 10 }} className='full-width'>
					<track kind='captions' />
				</audio>
			);

		case FileGroups.Image:
			return <ImageViewer src={data} />;

		case FileGroups.Tiff:
			return <TiffViewer url={data} canvasKey={key} />;

		case FileGroups.Other:
			return <PreviewUnavailable />;

		default:
			return <Spin />;
	}
};

export { FileGroups, fileGroups, getFileIcon, getViewer };
