/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Resolution of Page Select
 */


import { Form, Select, SelectProps } from 'antd';

const options: SelectProps['options'] = [
	{
		label: 100,
		key: 100,
		value: 100,
	},
	{
		label: 200,
		key: 200,
		value: 200,
	},
	{
		label: 300,
		key: 300,
		value: 300,
	},
];
const Resolution: React.FC = () => {
	return (
		<Form.Item name='resolution' label='Resolution:' initialValue={100} className='inline-item-in-vertical-form'>
			<Select placeholder='Choose a resolution...' options={options} />
		</Form.Item>
	);
};

export default Resolution;
