/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Get index info by document ID
 */

import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { RetrieveResponseData } from '@dexit/common/openapi';

import api from 'client/index';
import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';

const QUERY_INDEX_INFO = 'query-indexinfo';

const useIndexInfo = (
	documentId?: string,
	options?: UseQueryOptions<Promise<RetrieveResponseData>, unknown, RetrieveResponseData, QueryKey>,
): UseQueryResult<RetrieveResponseData, unknown> => {
	return useQuery({
		queryKey: [QUERY_INDEX_INFO, documentId],
		queryFn: async () => {
			if (!documentId) {
				throw new Error('Document ID not found');
			}

			const { data } = await api.DocumentApi.getDocumentIndexInfoById(documentId);
			return data;
		},
		...options,
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useIndexInfo;
export { QUERY_INDEX_INFO };
