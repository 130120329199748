/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description React Query Boundary
 */

import React, { PropsWithChildren } from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import { UseQueryResult } from '@tanstack/react-query';

import { ServerExceptionResult } from 'components/exceptions';

interface IProps {
	loadingMessage?: string;
	isEmpty?: boolean;
	renderEmptyState?: () => React.JSX.Element;
}

const QueryBoundary: React.FC<PropsWithChildren<IProps & Partial<UseQueryResult>>> = (props) => {
	const {
		children,
		isLoading = false,
		isError = false,
		isFetching = false,
		loadingMessage,
		isEmpty,
		renderEmptyState,
	} = props;

	if (isLoading) {
		return <Spin />;
	}

	if (isError) {
		return <ServerExceptionResult />;
	}
	if (isFetching) {
		return (
			<Spin spinning={true} tip={loadingMessage ?? 'Refreshing...'}>
				{children}
			</Spin>
		);
	}
	if (isEmpty && _.isFunction(renderEmptyState)) {
		return renderEmptyState();
	}
	return children;
};

export default QueryBoundary;
