/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Saved Search
 */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, Divider, Input, Modal, Segmented, Space, Tooltip, Tag, Empty } from 'antd';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


import Grid from 'components/Grid/Grid';
import useDeleteSavedSearch from 'hooks/mutations/useDeleteSavedSearch';
import useSavedSearch from 'hooks/useSavedSearch';
import { SavedSearchResponseModel } from '@dexit/common/openapi';
import { useUserValues } from 'store/user';

interface IProps {
	onSelect: (record: SavedSearchResponseModel) => void;
}
enum viewType {
	all = 'all',
	personal = 'personal',
}

const options = [
	{ label: 'All', value: viewType.all },
	{ label: 'Personal', value: viewType.personal },
];

const SavedSearch: React.FC<IProps> = (props) => {
	const { onSelect } = props;
	const [modal, contextHolder] = Modal.useModal();

	const { user } = useUserValues();
	const [view, setView] = useState<viewType>(viewType.personal);
	const [value, setValue] = useState<string>();

	const { data, isFetching } = useSavedSearch();
	const deleteSavedSearch = useDeleteSavedSearch();

	const dataSource = useMemo(() => {
		const results = _.isEqual(view, viewType.personal)
			? _.filter(data, (item) => _.isEqual(item.user?.id, user?.id))
			: data;

		if (_.isEmpty(value)) {
			return results;
		}
		const searchTerm = _.toLower(value);
		return _.filter(results, (item) => _.includes(_.toLower(item?.name), searchTerm));
	}, [data, view, value, user?.id]);

	const handleNameClick = (id?: string) => {
		const record = _.find(data, { id });
		if (record) {
			onSelect(record);
		}
	};

	const handleDelete = (savedSearchId?: string) =>
		modal.confirm({
			title: 'Confirm Delete',
			icon: <ExclamationCircleOutlined />,
			content:
				'Are you sure you want to delete the selected saved search? All information related to it will be lost.',
			okText: 'Delete',
			cancelButtonProps: {
				type: 'text',
			},
			onOk: async () => {
				if (savedSearchId) {
					return await deleteSavedSearch.mutateAsync(savedSearchId);
				}
			},
		});

	const columns: ColDef<SavedSearchResponseModel>[] = [
		{
			headerName: 'Name',
			field: 'name',
			maxWidth: 200,
			cellRenderer: (params: ICellRendererParams<SavedSearchResponseModel, SavedSearchResponseModel['name']>) => (
				<div className='flex-space-between'>
					<Button type='link' className='no-padding' onClick={() => handleNameClick(params.data?.id)}>
						{params.value}
					</Button>
					{params.data?.issystem && <Tag>System</Tag>}
				</div>
			),
		},
		{
			headerName: 'Created By',
			field: 'user',
			sortable: false,
			cellRenderer: (params: ICellRendererParams<SavedSearchResponseModel, SavedSearchResponseModel['user']>) => (
				<Tooltip title={params.value?.email ?? '-'} overlayStyle={{ maxWidth: '1000px' }}>
					{_.isEmpty(params.value?.firstname) && _.isEmpty(params.value?.lastname)
						? '-'
						: _.join(_.compact([params.value?.firstname, params.value?.lastname]), ' ')}
				</Tooltip>
			),
		},
		{
			field: 'creationdate',
			headerName: 'Created Date',
			cellRenderer: (
				params: ICellRendererParams<SavedSearchResponseModel, SavedSearchResponseModel['creationdate']>,
			) => moment(params.value).format(),
		},
		{
			field: 'comments',
			headerName: 'Comments',
			cellRenderer: (
				params: ICellRendererParams<SavedSearchResponseModel, SavedSearchResponseModel['comments']>,
			) => (_.isNil(params.value) ? '-' : params.value),
		},
		{
			headerName: 'Action',
			maxWidth: 100,
			sortable: false,
			cellClass: 'flex-justify-center',
			cellRenderer: (params: ICellRendererParams<SavedSearchResponseModel, null>) => (
				<Button
					danger
					type='text'
					shape='circle'
					title='Delete'
					disabled={!_.isEqual(params.data?.user?.id, user?.id)}
					icon={<DeleteOutlined />}
					onClick={() => void handleDelete(params.data?.id)}
				/>
			),
		},
	];

	return (
		<>
			<Space className='full-width' split={<Divider type='vertical' />} style={{ padding: '10px 0px' }}>
				<Segmented value={view} options={options} onChange={(val) => setView(val as viewType)} />
				<Input.Search placeholder='Search the list' value={value} onChange={(e) => setValue(e.target.value)} />
			</Space>
			{contextHolder}
			{!_.isEmpty(dataSource) ? (
				<Grid
					loading={isFetching}
					containerStyle={{ height: 400 }}
					key='id'
					columnDefs={columns}
					rowData={dataSource}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
		</>
	);
};

export default SavedSearch;
