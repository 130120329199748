/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Page Provider
 */

import React, { PropsWithChildren, useMemo } from 'react';

import Context from './Context';

interface IProps {
	config: MenuItem | null;
}

const Provider: React.FC<PropsWithChildren<IProps>> = (props) => {
	const { children, ...restProps } = props;
	const contextValue = useMemo(
		() => ({
			...restProps,
		}),
		[{ ...restProps }],
	);
	return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default Provider;
