/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description Content
 */

import React, { useMemo } from 'react';
import _ from 'lodash';
import { Layout } from 'antd';
import { Routes, Route, Navigate } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import LoadablePage from './LoadablePage';
import { PageNotFoundResult } from '../exceptions';
import { URL_CONFIG } from 'configs/menu-config';
import { useAppValues } from 'store/app';

const Content: React.FC = () => {
	const { webItems } = useAppValues();

	const routes = useMemo(
		() =>
			_.map(webItems, (item) => <Route path={item.url} key={item.key} element={<LoadablePage item={item} />} />),
		[webItems],
	);

	return (
		<Layout.Content className='main-layout-content'>
			<ErrorBoundary>
				<Routes>
					{routes}
					<Route path={'/'} element={<Navigate to={webItems[0].url ?? URL_CONFIG.queues} replace />} />
					<Route path='*' element={<PageNotFoundResult />} />
				</Routes>
			</ErrorBoundary>
		</Layout.Content>
	);
};

export default Content;
