/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description PDFJS PDFViewer
 */

import * as pdfJS from 'pdfjs-dist/legacy/build/pdf.mjs';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Button, InputNumber, List, Row, Spin } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

import RenderPdf from './RenderPdf';
import { useRetrieveDocActions, useRetrieveDocValues } from 'store/retrieveDoc';

pdfJS.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.min.mjs', import.meta.url).toString();

interface IProps {
	url?: string;
}

const getBlob = async (url: string) => {
	const response = await axios.get(url, { responseType: 'arraybuffer' });
	return response.data;
};

const PDFJSViewer: React.FC<IProps> = (props) => {
	const { url } = props;

	const [pdf, setPdf] = useState<pdfJS.PDFDocumentProxy>();
	const [loading, setLoading] = useState(true);

	const { pageToView, pdfRenderScale } = useRetrieveDocValues();
	const { setPageToView, setPdfRenderScale } = useRetrieveDocActions();

	const loadFile = async (path: string) => {
		setLoading(true);
		const data = await getBlob(path);
		const pdfbytes: pdfJS.PDFDocumentProxy = await pdfJS.getDocument(data).promise;
		setPdf(pdfbytes);
		setLoading(false);
	};

	useEffect(() => {
		if (url) {
			void loadFile(url);
		}
	}, [url]);

	const zoomIn = () => {
		if (pdfRenderScale > 1.75) {
			return;
		}
		setPdfRenderScale(pdfRenderScale + 0.25);
	};

	const zoomOut = () => {
		if (pdfRenderScale < 0.25) {
			return;
		}
		setPdfRenderScale(pdfRenderScale - 0.25);
	};

	if (loading) {
		return <Spin />;
	}
	if (!pdf) {
		return null;
	}
	return (
		<div className='flex-column full-width-height'>
			<Row className='flex-align-center full-width' style={{ height: '40px' }}>
				<div className='flex-center' style={{ flex: '1 1 0%' }}>
					<Button shape='circle' type='text' icon={<ZoomInOutlined />} onClick={zoomIn} />
					<Button shape='circle' type='text' icon={<ZoomOutOutlined />} onClick={zoomOut} />
					<div>
						<InputNumber
							style={{ width: '50px', marginRight: '8px', height: '30px' }}
							onPressEnter={(event: any) => {
								const value = _.parseInt(event.target.value);
								setPageToView(value, false);
							}}
							value={pageToView.pageNum}
							controls={false}
						/>
						/ {pdf.numPages}
					</div>
				</div>
			</Row>
			<Row className='flex-center' style={{ flex: 1, overflowY: 'scroll', background: '#999999' }}>
				{_.map(_.range(pdf?.numPages), (pageNum) => (
					<List.Item
						key={pageNum + 1}
						className='flex-justify-center'
						style={{ border: 'none', backgroundColor: '#999999' }}
					>
						<RenderPdf
							id={(pageNum + 1).toString()}
							pageNum={pageNum + 1}
							pdf={pdf}
							pageToView={pageToView}
							pdfRenderScale={pdfRenderScale}
							setPageToView={setPageToView}
						/>
					</List.Item>
				))}
			</Row>
		</div>
	);
};

export default PDFJSViewer;
