/**
 * @author Harsh Raj <harsh.raj@314ecorp.com>
 * @description Server Down Result
 */


import { Button, Result, ResultProps } from 'antd';
import MaintenanceError from 'components/icons/MaintenanceError';

const ServerDownResult: React.FC<ResultProps> = (props) => {
	const reload = () => {
		location.reload();
	};

	const extra = (
		<Button key='refresh' onClick={reload}>
			Reload
		</Button>
	);

	return (
		<Result
			icon={<MaintenanceError />}
			title='Please wait a Few Minutes Before You Try Again !!'
			extra={extra}
			{...props}
		/>
	);
};

export default ServerDownResult;
