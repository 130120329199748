/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Hook to get all queues
 */

import api from 'client/index';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { QueueResponseModel } from '@dexit/common/openapi';
import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';

const QUERY_QUEUE = 'query-queue';

const useQueue = (
	isActive?: boolean,
	options?: UseQueryOptions<
		Promise<QueueResponseModel[]>,
		unknown,
		QueueResponseModel[],
		(string | undefined | boolean)[]
	>,
): UseQueryResult<QueueResponseModel[], unknown> => {
	return useQuery({
		queryKey: [QUERY_QUEUE, isActive],
		queryFn: async () => {
			const { data } = await api.QueueApi.getQueues(undefined, isActive);
			return data;
		},
		...options,
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useQueue;
export { QUERY_QUEUE };
