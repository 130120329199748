/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ActiveBatchesDetailsRequestModel } from '../Models';
// @ts-ignore
import { ActiveBatchesDetailsResponseModel } from '../Models';
// @ts-ignore
import { AllBatchesPerQueueDetailsRequestModel } from '../Models';
// @ts-ignore
import { AllBatchesPerQueueDetailsResponseModel } from '../Models';
// @ts-ignore
import { DeficiencyAssignedByUserDetailsRequestModel } from '../Models';
// @ts-ignore
import { DeficiencyAssignedByUserDetailsResponseModel } from '../Models';
// @ts-ignore
import { DeficiencyPendingByAssigneeRequestModel } from '../Models';
// @ts-ignore
import { DeficiencyPendingByAssigneeResponseModel } from '../Models';
// @ts-ignore
import { DeficiencyPendingByDocumentRequestModel } from '../Models';
// @ts-ignore
import { DeficiencyPendingByDocumentResponseModel } from '../Models';
// @ts-ignore
import { DocumentReviewResolvedDetailsRequestModel } from '../Models';
// @ts-ignore
import { DocumentReviewResolvedDetailsResponseModel } from '../Models';
// @ts-ignore
import { EntityRequestModel } from '../Models';
// @ts-ignore
import { FaxesPerFaxNumberRequestModel } from '../Models';
// @ts-ignore
import { FaxesPerFaxNumberResponseModel } from '../Models';
// @ts-ignore
import { InboundFaxesPerQueueRequestModel } from '../Models';
// @ts-ignore
import { InboundFaxesPerQueueResponseModel } from '../Models';
// @ts-ignore
import { PagesProcessedByUserRequestModel } from '../Models';
// @ts-ignore
import { PagesProcessedByUserResponseModel } from '../Models';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeBatchesDetails: async (activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeBatchesDetailsRequestModel' is not null or undefined
            assertParamExists('activeBatchesDetails', 'activeBatchesDetailsRequestModel', activeBatchesDetailsRequestModel)
            const localVarPath = `/api/v1/reports/activeBatchesDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeBatchesDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBatchesPerQueueDetails: async (allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allBatchesPerQueueDetailsRequestModel' is not null or undefined
            assertParamExists('allBatchesPerQueueDetails', 'allBatchesPerQueueDetailsRequestModel', allBatchesPerQueueDetailsRequestModel)
            const localVarPath = `/api/v1/reports/allBatchesPerQueueDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allBatchesPerQueueDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficiencyAssignedByUserDetails: async (deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deficiencyAssignedByUserDetailsRequestModel' is not null or undefined
            assertParamExists('deficiencyAssignedByUserDetails', 'deficiencyAssignedByUserDetailsRequestModel', deficiencyAssignedByUserDetailsRequestModel)
            const localVarPath = `/api/v1/reports/deficiencyAssignedByUserDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyAssignedByUserDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficiencyPendingByAssignee: async (deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deficiencyPendingByAssigneeRequestModel' is not null or undefined
            assertParamExists('deficiencyPendingByAssignee', 'deficiencyPendingByAssigneeRequestModel', deficiencyPendingByAssigneeRequestModel)
            const localVarPath = `/api/v1/reports/deficiencyPendingByAssignee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyPendingByAssigneeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficiencyPendingByDocument: async (deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deficiencyPendingByDocumentRequestModel' is not null or undefined
            assertParamExists('deficiencyPendingByDocument', 'deficiencyPendingByDocumentRequestModel', deficiencyPendingByDocumentRequestModel)
            const localVarPath = `/api/v1/reports/deficiencyPendingByDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyPendingByDocumentRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentReviewResolvedDetails: async (documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentReviewResolvedDetailsRequestModel' is not null or undefined
            assertParamExists('documentReviewResolvedDetails', 'documentReviewResolvedDetailsRequestModel', documentReviewResolvedDetailsRequestModel)
            const localVarPath = `/api/v1/reports/documentReviewResolvedDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentReviewResolvedDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EntityRequestModel} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entity: async (entity: EntityRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('entity', 'entity', entity)
            const localVarPath = `/api/v1/reports/entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faxesPerFaxNumber: async (faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faxesPerFaxNumberRequestModel' is not null or undefined
            assertParamExists('faxesPerFaxNumber', 'faxesPerFaxNumberRequestModel', faxesPerFaxNumberRequestModel)
            const localVarPath = `/api/v1/reports/faxesPerFaxNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faxesPerFaxNumberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundFaxesPerQueue: async (inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inboundFaxesPerQueueRequestModel' is not null or undefined
            assertParamExists('inboundFaxesPerQueue', 'inboundFaxesPerQueueRequestModel', inboundFaxesPerQueueRequestModel)
            const localVarPath = `/api/v1/reports/inboundFaxesPerQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboundFaxesPerQueueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesProcessedByUser: async (pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagesProcessedByUserRequestModel' is not null or undefined
            assertParamExists('pagesProcessedByUser', 'pagesProcessedByUserRequestModel', pagesProcessedByUserRequestModel)
            const localVarPath = `/api/v1/reports/pagesProcessedByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagesProcessedByUserRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activeBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveBatchesDetailsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activeBatchesDetails(activeBatchesDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.activeBatchesDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllBatchesPerQueueDetailsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.allBatchesPerQueueDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeficiencyAssignedByUserDetailsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.deficiencyAssignedByUserDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeficiencyPendingByAssigneeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.deficiencyPendingByAssignee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeficiencyPendingByDocumentResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.deficiencyPendingByDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReviewResolvedDetailsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.documentReviewResolvedDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EntityRequestModel} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entity(entity: EntityRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: any; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entity(entity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.entity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaxesPerFaxNumberResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faxesPerFaxNumber(faxesPerFaxNumberRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.faxesPerFaxNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundFaxesPerQueueResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.inboundFaxesPerQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagesProcessedByUserResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pagesProcessedByUser(pagesProcessedByUserRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.pagesProcessedByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: any): AxiosPromise<ActiveBatchesDetailsResponseModel> {
            return localVarFp.activeBatchesDetails(activeBatchesDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: any): AxiosPromise<AllBatchesPerQueueDetailsResponseModel> {
            return localVarFp.allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: any): AxiosPromise<DeficiencyAssignedByUserDetailsResponseModel> {
            return localVarFp.deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: any): AxiosPromise<DeficiencyPendingByAssigneeResponseModel> {
            return localVarFp.deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: any): AxiosPromise<DeficiencyPendingByDocumentResponseModel> {
            return localVarFp.deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: any): AxiosPromise<DocumentReviewResolvedDetailsResponseModel> {
            return localVarFp.documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EntityRequestModel} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entity(entity: EntityRequestModel, options?: any): AxiosPromise<Array<{ [key: string]: any; }>> {
            return localVarFp.entity(entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: any): AxiosPromise<FaxesPerFaxNumberResponseModel> {
            return localVarFp.faxesPerFaxNumber(faxesPerFaxNumberRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: any): AxiosPromise<InboundFaxesPerQueueResponseModel> {
            return localVarFp.inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: any): AxiosPromise<PagesProcessedByUserResponseModel> {
            return localVarFp.pagesProcessedByUser(pagesProcessedByUserRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - interface
 * @export
 * @interface ReportsApi
 */
export interface ReportsApiInterface {
    /**
     * 
     * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    activeBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ActiveBatchesDetailsResponseModel>;

    /**
     * 
     * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<AllBatchesPerQueueDetailsResponseModel>;

    /**
     * 
     * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<DeficiencyAssignedByUserDetailsResponseModel>;

    /**
     * 
     * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<DeficiencyPendingByAssigneeResponseModel>;

    /**
     * 
     * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<DeficiencyPendingByDocumentResponseModel>;

    /**
     * 
     * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<DocumentReviewResolvedDetailsResponseModel>;

    /**
     * 
     * @param {EntityRequestModel} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    entity(entity: EntityRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<{ [key: string]: any; }>>;

    /**
     * 
     * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    faxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<FaxesPerFaxNumberResponseModel>;

    /**
     * 
     * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<InboundFaxesPerQueueResponseModel>;

    /**
     * 
     * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    pagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<PagesProcessedByUserResponseModel>;

}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI implements ReportsApiInterface {
    /**
     * 
     * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public activeBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).activeBatchesDetails(activeBatchesDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).allBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).documentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EntityRequestModel} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public entity(entity: EntityRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).entity(entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public faxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).faxesPerFaxNumber(faxesPerFaxNumberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).inboundFaxesPerQueue(inboundFaxesPerQueueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public pagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).pagesProcessedByUser(pagesProcessedByUserRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

