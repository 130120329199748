/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Stage List Item
 */

import { CSS } from '@dnd-kit/utilities';
import { Color } from 'antd/es/color-picker';
import { List, Space, Button, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';

import Dragger from 'components/icons/Dragger';
import HalfCircleFilled from 'components/icons/HalfCircleFilled';
import { StageList, useManageQueueActions, useManageQueueValues } from 'store/manageQueue';
import { defaultIconColor } from './QueuesIndexingStages';

interface IProps {
	stage: StageList;
	index: number;
	onColorChange: React.Dispatch<React.SetStateAction<string | Color>>;
	onStageNameChange: React.Dispatch<React.SetStateAction<string>>;
}

const StageListItem: React.FC<IProps> = (props) => {
	const { stage, index, onColorChange, onStageNameChange } = props;
	const [modal, contextHolder] = Modal.useModal();

	const { stageList } = useManageQueueValues();
	const { setDisabled, editStage, removeStageAtIndex, addFieldChanged } = useManageQueueActions();

	const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({ id: stage.id });

	const handleEditStage = (indexToEdit: number) => {
		editStage(indexToEdit);
		onColorChange(stageList[indexToEdit].icon_color ?? defaultIconColor);
		onStageNameChange(stageList[indexToEdit].name);
		setDisabled(false);
		addFieldChanged('indexingStages');
	};

	const deleteStage = (index: number) =>
		modal.confirm({
			title: 'Delete stage',
			content: 'Are you sure you want to delete this stage?',
			okText: 'Delete',
			onOk: () => {
				setDisabled(false);
				addFieldChanged('indexingStages');
				removeStageAtIndex(index);
			},
		});

	return (
		<List.Item
			ref={setNodeRef}
			{...attributes}
			className='flex-space-between'
			style={{
				transform: CSS.Transform.toString(transform),
				transition: isSorting ? transition : undefined,
				height: 54,
			}}
		>
			<Space size={16}>
				<Dragger style={{ cursor: 'pointer' }} {...listeners} />
				<HalfCircleFilled style={{ color: stage.icon_color }} />
				{stage.name}
			</Space>
			<Space>
				{contextHolder}
				<Button
					type='text'
					shape='circle'
					className='hover-only'
					icon={<DeleteOutlined className='grey-color' />}
					onClick={() => void deleteStage(index)}
				/>
				<Button
					type='text'
					shape='circle'
					className='hover-only'
					icon={<EditOutlined className='grey-color' />}
					onClick={() => handleEditStage(index)}
				/>
			</Space>
		</List.Item>
	);
};

export default StageListItem;
