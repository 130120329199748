/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description useCreateBatch
 */

import EventBus, { Events } from '@dexit/common/utils/EventBus';
import log from 'loglevel';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import api from 'client/index';
import { BatchRequestModel, ResponseModel } from '@dexit/common/openapi';
import { QUERY_BATCHES_KEY } from 'hooks/useBatches';
import { QUERY_QUEUE } from 'hooks/useQueue';

const useCreateBatch = (): UseMutationResult<ResponseModel, unknown, BatchRequestModel, unknown> => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (payload) => {
			const { data } = await api.BatchScanningApi.createBatch(payload);
			return data;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries([QUERY_BATCHES_KEY]).catch((err) => log.error(err));
			queryClient.invalidateQueries([QUERY_QUEUE]).catch((err) => log.error(err));
			EventBus.publish(Events.M_Success, data.message);
		},
		onError: (error) => {
			EventBus.publish(Events.M_Error, error);
		},
	});
};

export default useCreateBatch;
