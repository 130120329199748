/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { SavedSearchRequestModel } from '../Models';
// @ts-ignore
import { SavedSearchResponseModel } from '../Models';
/**
 * SavedSearchApi - axios parameter creator
 * @export
 */
export const SavedSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SavedSearchRequestModel} savedSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedSearch: async (savedSearchRequestModel: SavedSearchRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savedSearchRequestModel' is not null or undefined
            assertParamExists('createSavedSearch', 'savedSearchRequestModel', savedSearchRequestModel)
            const localVarPath = `/api/v1/savedSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savedSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedSearchById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSavedSearchById', 'id', id)
            const localVarPath = `/api/v1/savedSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedSearchById: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/savedSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavedSearchApi - functional programming interface
 * @export
 */
export const SavedSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavedSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SavedSearchRequestModel} savedSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSavedSearch(savedSearchRequestModel: SavedSearchRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSavedSearch(savedSearchRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SavedSearchApi.createSavedSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedSearchById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedSearchById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SavedSearchApi.deleteSavedSearchById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedSearchById(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedSearchResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedSearchById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SavedSearchApi.getSavedSearchById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SavedSearchApi - factory interface
 * @export
 */
export const SavedSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavedSearchApiFp(configuration)
    return {
        /**
         * 
         * @param {SavedSearchRequestModel} savedSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSavedSearch(savedSearchRequestModel: SavedSearchRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createSavedSearch(savedSearchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedSearchById(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteSavedSearchById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedSearchById(id?: string, options?: any): AxiosPromise<Array<SavedSearchResponseModel>> {
            return localVarFp.getSavedSearchById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SavedSearchApi - interface
 * @export
 * @interface SavedSearchApi
 */
export interface SavedSearchApiInterface {
    /**
     * 
     * @param {SavedSearchRequestModel} savedSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchApiInterface
     */
    createSavedSearch(savedSearchRequestModel: SavedSearchRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchApiInterface
     */
    deleteSavedSearchById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchApiInterface
     */
    getSavedSearchById(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SavedSearchResponseModel>>;

}

/**
 * SavedSearchApi - object-oriented interface
 * @export
 * @class SavedSearchApi
 * @extends {BaseAPI}
 */
export class SavedSearchApi extends BaseAPI implements SavedSearchApiInterface {
    /**
     * 
     * @param {SavedSearchRequestModel} savedSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchApi
     */
    public createSavedSearch(savedSearchRequestModel: SavedSearchRequestModel, options?: RawAxiosRequestConfig) {
        return SavedSearchApiFp(this.configuration).createSavedSearch(savedSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchApi
     */
    public deleteSavedSearchById(id: string, options?: RawAxiosRequestConfig) {
        return SavedSearchApiFp(this.configuration).deleteSavedSearchById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchApi
     */
    public getSavedSearchById(id?: string, options?: RawAxiosRequestConfig) {
        return SavedSearchApiFp(this.configuration).getSavedSearchById(id, options).then((request) => request(this.axios, this.basePath));
    }
}

