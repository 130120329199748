/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description EPIC doc viewer
 */

import _ from 'lodash';

import { Layout } from 'antd';

import OHIFViewer from 'components/ohif/OHIFViewer';
import PDFJSViewer from 'components/pdf/PDFJSViewer';
import Page from 'components/page/Page';
import useIndexInfo from 'hooks/useIndexInfo';
import usePreSignedUrl from 'hooks/usePreSignedUrl';

interface IProps {
	id: string;
}

const EpicDocViewer: React.FC<IProps> = ({ id }) => {
	const { data: indexInfo, isFetching: fetchingIndexInfo } = useIndexInfo(id);
	const { data, isFetching, isError } = usePreSignedUrl(indexInfo?.file_location, {
		enabled: !!indexInfo?.file_location && !_.isEqual(indexInfo?.documenttype.name, 'DICOM'),
	});

	return (
		<Layout className='main-layout'>
			<Layout>
				<Page title={indexInfo?.name} isFetching={isFetching || fetchingIndexInfo} isError={isError}>
					{_.isEqual(indexInfo?.documenttype.name, 'DICOM') ? (
						<OHIFViewer
							StudyInstanceUIDs={_.get(
								_.find(indexInfo?.customattributes, { field: 'study_instance_uid' }),
								'value',
							)}
							// SeriesInstanceUID={_.get(
							// 	_.find(indexInfo?.customattributes, { field_name: 'SopInstanceUID' }),
							// 	'value',
							// )}
							// initialSOPInstanceUID={_.get(
							// 	_.find(indexInfo?.customattributes, { field_name: 'SeriesInstanceUID' }),
							// 	'value',
							// )}
						/>
					) : (
						<PDFJSViewer url={data?.url ?? ''} />
					)}
				</Page>
			</Layout>
		</Layout>
	);
};

export default EpicDocViewer;
