/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description hook to get saved search data
 */

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import api from 'client/index';
import { SavedSearchResponseModel } from '@dexit/common/openapi';

export const QUERY_SAVED_SEARCH = 'query-saved-search';

const useSavedSearch = (
	options?: UseQueryOptions<Promise<SavedSearchResponseModel[]>, unknown, SavedSearchResponseModel[], any>,
): UseQueryResult<SavedSearchResponseModel[], unknown> => {
	return useQuery({
		queryKey: [QUERY_SAVED_SEARCH],
		queryFn: async () => {
			const { data } = await api.SavedSearchApi.getSavedSearchById();
			return data;
		},
		...options,
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useSavedSearch;
