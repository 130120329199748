/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description use is authorized
 */

import { useMemo } from 'react';
import { standaloneHelperInstance } from 'utils/StandaloneHelper';

import getClientRoles from './getClientRoles';
import isAuthorized, { operators } from './isAuthorized';

const useIsAuthorized = (roles: string | string[], operator: operators = operators.AND): boolean => {
	return useMemo(() => {
		const { clientId } = standaloneHelperInstance.keycloakInstance;
		if (!clientId) {
			return false;
		}

		return isAuthorized(getClientRoles(standaloneHelperInstance.keycloakInstance, clientId), roles, operator);
	}, [standaloneHelperInstance.keycloakInstance.realmAccess, roles, operator]);
};

export default useIsAuthorized;
