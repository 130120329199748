/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ActiveBatchesDetailsRequestModel } from '../Models';
// @ts-ignore
import { AllBatchesPerQueueDetailsRequestModel } from '../Models';
// @ts-ignore
import { AuditHistoryRequestModel } from '../Models';
// @ts-ignore
import { DeficiencyAssignedByUserDetailsRequestModel } from '../Models';
// @ts-ignore
import { DeficiencyPendingByAssigneeRequestModel } from '../Models';
// @ts-ignore
import { DeficiencyPendingByDocumentRequestModel } from '../Models';
// @ts-ignore
import { DocumentReviewResolvedDetailsRequestModel } from '../Models';
// @ts-ignore
import { FaxesPerFaxNumberRequestModel } from '../Models';
// @ts-ignore
import { InboundFaxesPerQueueRequestModel } from '../Models';
// @ts-ignore
import { PagesProcessedByUserRequestModel } from '../Models';
// @ts-ignore
import { ReleaseOfInformationSearchModel } from '../Models';
/**
 * ReportsCsvApi - axios parameter creator
 * @export
 */
export const ReportsCsvApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActiveBatchesDetails: async (activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeBatchesDetailsRequestModel' is not null or undefined
            assertParamExists('exportActiveBatchesDetails', 'activeBatchesDetailsRequestModel', activeBatchesDetailsRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportActiveBatchesDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeBatchesDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAuditHistory: async (auditHistoryRequestModel: AuditHistoryRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditHistoryRequestModel' is not null or undefined
            assertParamExists('exportAllAuditHistory', 'auditHistoryRequestModel', auditHistoryRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportAllAuditHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditHistoryRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllBatchesPerQueueDetails: async (allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allBatchesPerQueueDetailsRequestModel' is not null or undefined
            assertParamExists('exportAllBatchesPerQueueDetails', 'allBatchesPerQueueDetailsRequestModel', allBatchesPerQueueDetailsRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportAllBatchesPerQueueDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allBatchesPerQueueDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeficiencyAssignedByUserDetails: async (deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deficiencyAssignedByUserDetailsRequestModel' is not null or undefined
            assertParamExists('exportDeficiencyAssignedByUserDetails', 'deficiencyAssignedByUserDetailsRequestModel', deficiencyAssignedByUserDetailsRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportDeficiencyAssignedByUserDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyAssignedByUserDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeficiencyPendingByAssignee: async (deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deficiencyPendingByAssigneeRequestModel' is not null or undefined
            assertParamExists('exportDeficiencyPendingByAssignee', 'deficiencyPendingByAssigneeRequestModel', deficiencyPendingByAssigneeRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportDeficiencyPendingByAssignee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyPendingByAssigneeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeficiencyPendingByDocument: async (deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deficiencyPendingByDocumentRequestModel' is not null or undefined
            assertParamExists('exportDeficiencyPendingByDocument', 'deficiencyPendingByDocumentRequestModel', deficiencyPendingByDocumentRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportDeficiencyPendingByDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyPendingByDocumentRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDocumentReviewResolvedDetails: async (documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentReviewResolvedDetailsRequestModel' is not null or undefined
            assertParamExists('exportDocumentReviewResolvedDetails', 'documentReviewResolvedDetailsRequestModel', documentReviewResolvedDetailsRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportDocumentReviewResolvedDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentReviewResolvedDetailsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFaxesPerFaxNumber: async (faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faxesPerFaxNumberRequestModel' is not null or undefined
            assertParamExists('exportFaxesPerFaxNumber', 'faxesPerFaxNumberRequestModel', faxesPerFaxNumberRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportFaxesPerFaxNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faxesPerFaxNumberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInboundFaxesPerQueue: async (inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inboundFaxesPerQueueRequestModel' is not null or undefined
            assertParamExists('exportInboundFaxesPerQueue', 'inboundFaxesPerQueueRequestModel', inboundFaxesPerQueueRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportInboundFaxesPerQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboundFaxesPerQueueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPagesProcessedByUser: async (pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagesProcessedByUserRequestModel' is not null or undefined
            assertParamExists('exportPagesProcessedByUser', 'pagesProcessedByUserRequestModel', pagesProcessedByUserRequestModel)
            const localVarPath = `/api/v1/reportsCsv/exportPagesProcessedByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagesProcessedByUserRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReleaseOfInformationSearchModel} releaseOfInformationSearchModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReleaseOfInformation: async (releaseOfInformationSearchModel: ReleaseOfInformationSearchModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseOfInformationSearchModel' is not null or undefined
            assertParamExists('exportReleaseOfInformation', 'releaseOfInformationSearchModel', releaseOfInformationSearchModel)
            const localVarPath = `/api/v1/reportsCsv/exportReleaseOfInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseOfInformationSearchModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsCsvApi - functional programming interface
 * @export
 */
export const ReportsCsvApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsCsvApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportActiveBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportActiveBatchesDetails(activeBatchesDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportActiveBatchesDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAllAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAllAuditHistory(auditHistoryRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportAllAuditHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportAllBatchesPerQueueDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportDeficiencyAssignedByUserDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportDeficiencyPendingByAssignee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportDeficiencyPendingByDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportDocumentReviewResolvedDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportFaxesPerFaxNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportInboundFaxesPerQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportPagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportPagesProcessedByUser(pagesProcessedByUserRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportPagesProcessedByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReleaseOfInformationSearchModel} releaseOfInformationSearchModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportReleaseOfInformation(releaseOfInformationSearchModel: ReleaseOfInformationSearchModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportReleaseOfInformation(releaseOfInformationSearchModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsCsvApi.exportReleaseOfInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsCsvApi - factory interface
 * @export
 */
export const ReportsCsvApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsCsvApiFp(configuration)
    return {
        /**
         * 
         * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActiveBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportActiveBatchesDetails(activeBatchesDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportAllAuditHistory(auditHistoryRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportPagesProcessedByUser(pagesProcessedByUserRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReleaseOfInformationSearchModel} releaseOfInformationSearchModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReleaseOfInformation(releaseOfInformationSearchModel: ReleaseOfInformationSearchModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.exportReleaseOfInformation(releaseOfInformationSearchModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsCsvApi - interface
 * @export
 * @interface ReportsCsvApi
 */
export interface ReportsCsvApiInterface {
    /**
     * 
     * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportActiveBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportAllAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportPagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {ReleaseOfInformationSearchModel} releaseOfInformationSearchModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApiInterface
     */
    exportReleaseOfInformation(releaseOfInformationSearchModel: ReleaseOfInformationSearchModel, options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * ReportsCsvApi - object-oriented interface
 * @export
 * @class ReportsCsvApi
 * @extends {BaseAPI}
 */
export class ReportsCsvApi extends BaseAPI implements ReportsCsvApiInterface {
    /**
     * 
     * @param {ActiveBatchesDetailsRequestModel} activeBatchesDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportActiveBatchesDetails(activeBatchesDetailsRequestModel: ActiveBatchesDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportActiveBatchesDetails(activeBatchesDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportAllAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportAllAuditHistory(auditHistoryRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllBatchesPerQueueDetailsRequestModel} allBatchesPerQueueDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel: AllBatchesPerQueueDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportAllBatchesPerQueueDetails(allBatchesPerQueueDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeficiencyAssignedByUserDetailsRequestModel} deficiencyAssignedByUserDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel: DeficiencyAssignedByUserDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportDeficiencyAssignedByUserDetails(deficiencyAssignedByUserDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeficiencyPendingByAssigneeRequestModel} deficiencyPendingByAssigneeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel: DeficiencyPendingByAssigneeRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportDeficiencyPendingByAssignee(deficiencyPendingByAssigneeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeficiencyPendingByDocumentRequestModel} deficiencyPendingByDocumentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel: DeficiencyPendingByDocumentRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportDeficiencyPendingByDocument(deficiencyPendingByDocumentRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentReviewResolvedDetailsRequestModel} documentReviewResolvedDetailsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel: DocumentReviewResolvedDetailsRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportDocumentReviewResolvedDetails(documentReviewResolvedDetailsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaxesPerFaxNumberRequestModel} faxesPerFaxNumberRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel: FaxesPerFaxNumberRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportFaxesPerFaxNumber(faxesPerFaxNumberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboundFaxesPerQueueRequestModel} inboundFaxesPerQueueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel: InboundFaxesPerQueueRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportInboundFaxesPerQueue(inboundFaxesPerQueueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PagesProcessedByUserRequestModel} pagesProcessedByUserRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportPagesProcessedByUser(pagesProcessedByUserRequestModel: PagesProcessedByUserRequestModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportPagesProcessedByUser(pagesProcessedByUserRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReleaseOfInformationSearchModel} releaseOfInformationSearchModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsCsvApi
     */
    public exportReleaseOfInformation(releaseOfInformationSearchModel: ReleaseOfInformationSearchModel, options?: RawAxiosRequestConfig) {
        return ReportsCsvApiFp(this.configuration).exportReleaseOfInformation(releaseOfInformationSearchModel, options).then((request) => request(this.axios, this.basePath));
    }
}

