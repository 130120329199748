/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Environment helper
 */
import _ from 'lodash';

export const getEnvironment: () => { env: string; domain: string } = () => {
	const hostname = globalThis.location.hostname;
	const splittedHostname = _.split(hostname, '.');
	const domain = _.includes(hostname, 'azure.314ecorp')
		? 'azure.314ecorp'
		: `${splittedHostname[1]}.${splittedHostname[2]}`;

	if (hostname === 'localhost') {
		return {
			env: 'tech',
			domain: 'dexit.314ecorp',
		};
	}

	return {
		env: hostname.endsWith('tech') ? 'tech' : 'com',
		domain,
	};
};
