/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description react-query-config
 */
import { QueryClientConfig } from '@tanstack/react-query';

const config: QueryClientConfig = {
	defaultOptions: {
		queries: {
			networkMode: 'always',
			retry: 1,
			retryDelay: 5000,
			refetchOnWindowFocus: false,
		},
		mutations: {
			networkMode: 'always',
		},
	},
};

export default config;
