/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BodyUserSelection } from '../Models';
// @ts-ignore
import { CreateEmployeesBulkResponseModel } from '../Models';
// @ts-ignore
import { EmployeeRequestModel } from '../Models';
// @ts-ignore
import { GetAllKeycloakUsersResponseModel } from '../Models';
// @ts-ignore
import { GetAllUserResponseModel } from '../Models';
// @ts-ignore
import { GetEmployeeByKeycloakResponseModel } from '../Models';
// @ts-ignore
import { GetEmployeeResponseModel } from '../Models';
// @ts-ignore
import { GetUsersByCriteriaResponseModel } from '../Models';
// @ts-ignore
import { ListAllEmployeeResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { UpdateByKeycloakIdRequestModel } from '../Models';
// @ts-ignore
import { UpdateByKeycloakUsernameRequestModel } from '../Models';
/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inputFilePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBulkload: async (inputFilePath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inputFilePath' is not null or undefined
            assertParamExists('employeeBulkload', 'inputFilePath', inputFilePath)
            const localVarPath = `/api/v1/employee/bulkLoad`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (inputFilePath !== undefined) {
                localVarQueryParameter['input_file_path'] = inputFilePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFind: async (searchString?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['search_string'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFindall: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeGet: async (empId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (empId !== undefined) {
                localVarQueryParameter['emp_id'] = empId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeGetByKeycloak: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('employeeGetByKeycloak', 'userId', userId)
            const localVarPath = `/api/v1/employee/getByKeycloak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeListAllEmployeeDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee/listAllEmployeeDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [firstname] 
         * @param {string} [lastname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSearch: async (firstname?: string, lastname?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (firstname !== undefined) {
                localVarQueryParameter['firstname'] = firstname;
            }

            if (lastname !== undefined) {
                localVarQueryParameter['lastname'] = lastname;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmployeeRequestModel} employeeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeUpdate: async (employeeRequestModel: EmployeeRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeRequestModel' is not null or undefined
            assertParamExists('employeeUpdate', 'employeeRequestModel', employeeRequestModel)
            const localVarPath = `/api/v1/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateByKeycloakIdRequestModel} updateByKeycloakIdRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeUpdateByKeycloakId: async (updateByKeycloakIdRequestModel: UpdateByKeycloakIdRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateByKeycloakIdRequestModel' is not null or undefined
            assertParamExists('employeeUpdateByKeycloakId', 'updateByKeycloakIdRequestModel', updateByKeycloakIdRequestModel)
            const localVarPath = `/api/v1/employee/updateByKeycloakId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateByKeycloakIdRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateByKeycloakUsernameRequestModel} updateByKeycloakUsernameRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeUpdateKeycloakDetails: async (updateByKeycloakUsernameRequestModel: UpdateByKeycloakUsernameRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateByKeycloakUsernameRequestModel' is not null or undefined
            assertParamExists('employeeUpdateKeycloakDetails', 'updateByKeycloakUsernameRequestModel', updateByKeycloakUsernameRequestModel)
            const localVarPath = `/api/v1/employee/updateByKeycloakUsername`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateByKeycloakUsernameRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BodyUserSelection} bodyUserSelection 
         * @param {string} [status] 
         * @param {string} [conditionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSelection: async (bodyUserSelection: BodyUserSelection, status?: string, conditionType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyUserSelection' is not null or undefined
            assertParamExists('userSelection', 'bodyUserSelection', bodyUserSelection)
            const localVarPath = `/api/v1/employee/userSelection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (conditionType !== undefined) {
                localVarQueryParameter['condition_type'] = conditionType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyUserSelection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inputFilePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeBulkload(inputFilePath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateEmployeesBulkResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeBulkload(inputFilePath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeBulkload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeFind(searchString?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllKeycloakUsersResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFind(searchString, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeFind']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeFindall(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllUserResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFindall(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeFindall']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeGet(empId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetEmployeeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeGet(empId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeGetByKeycloak(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmployeeByKeycloakResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeGetByKeycloak(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeGetByKeycloak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeListAllEmployeeDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAllEmployeeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeListAllEmployeeDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeListAllEmployeeDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [firstname] 
         * @param {string} [lastname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeSearch(firstname?: string, lastname?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetEmployeeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeSearch(firstname, lastname, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmployeeRequestModel} employeeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeUpdate(employeeRequestModel: EmployeeRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeUpdate(employeeRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateByKeycloakIdRequestModel} updateByKeycloakIdRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel: UpdateByKeycloakIdRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeUpdateByKeycloakId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateByKeycloakUsernameRequestModel} updateByKeycloakUsernameRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel: UpdateByKeycloakUsernameRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.employeeUpdateKeycloakDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BodyUserSelection} bodyUserSelection 
         * @param {string} [status] 
         * @param {string} [conditionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSelection(bodyUserSelection: BodyUserSelection, status?: string, conditionType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetUsersByCriteriaResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSelection(bodyUserSelection, status, conditionType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.userSelection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} inputFilePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeBulkload(inputFilePath: string, options?: any): AxiosPromise<CreateEmployeesBulkResponseModel> {
            return localVarFp.employeeBulkload(inputFilePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFind(searchString?: string, options?: any): AxiosPromise<Array<GetAllKeycloakUsersResponseModel>> {
            return localVarFp.employeeFind(searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFindall(options?: any): AxiosPromise<Array<GetAllUserResponseModel>> {
            return localVarFp.employeeFindall(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeGet(empId?: string, options?: any): AxiosPromise<Array<GetEmployeeResponseModel>> {
            return localVarFp.employeeGet(empId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeGetByKeycloak(userId: string, options?: any): AxiosPromise<GetEmployeeByKeycloakResponseModel> {
            return localVarFp.employeeGetByKeycloak(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeListAllEmployeeDetails(options?: any): AxiosPromise<ListAllEmployeeResponseModel> {
            return localVarFp.employeeListAllEmployeeDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [firstname] 
         * @param {string} [lastname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeSearch(firstname?: string, lastname?: string, options?: any): AxiosPromise<Array<GetEmployeeResponseModel>> {
            return localVarFp.employeeSearch(firstname, lastname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmployeeRequestModel} employeeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeUpdate(employeeRequestModel: EmployeeRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.employeeUpdate(employeeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateByKeycloakIdRequestModel} updateByKeycloakIdRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel: UpdateByKeycloakIdRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateByKeycloakUsernameRequestModel} updateByKeycloakUsernameRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel: UpdateByKeycloakUsernameRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BodyUserSelection} bodyUserSelection 
         * @param {string} [status] 
         * @param {string} [conditionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSelection(bodyUserSelection: BodyUserSelection, status?: string, conditionType?: string, options?: any): AxiosPromise<Array<GetUsersByCriteriaResponseModel>> {
            return localVarFp.userSelection(bodyUserSelection, status, conditionType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeApi - interface
 * @export
 * @interface EmployeeApi
 */
export interface EmployeeApiInterface {
    /**
     * 
     * @param {string} inputFilePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeBulkload(inputFilePath: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateEmployeesBulkResponseModel>;

    /**
     * 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeFind(searchString?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetAllKeycloakUsersResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeFindall(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetAllUserResponseModel>>;

    /**
     * 
     * @param {string} [empId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeGet(empId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetEmployeeResponseModel>>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeGetByKeycloak(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetEmployeeByKeycloakResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeListAllEmployeeDetails(options?: RawAxiosRequestConfig): AxiosPromise<ListAllEmployeeResponseModel>;

    /**
     * 
     * @param {string} [firstname] 
     * @param {string} [lastname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeSearch(firstname?: string, lastname?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetEmployeeResponseModel>>;

    /**
     * 
     * @param {EmployeeRequestModel} employeeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeUpdate(employeeRequestModel: EmployeeRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {UpdateByKeycloakIdRequestModel} updateByKeycloakIdRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel: UpdateByKeycloakIdRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {UpdateByKeycloakUsernameRequestModel} updateByKeycloakUsernameRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel: UpdateByKeycloakUsernameRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {BodyUserSelection} bodyUserSelection 
     * @param {string} [status] 
     * @param {string} [conditionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    userSelection(bodyUserSelection: BodyUserSelection, status?: string, conditionType?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetUsersByCriteriaResponseModel>>;

}

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI implements EmployeeApiInterface {
    /**
     * 
     * @param {string} inputFilePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeBulkload(inputFilePath: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeBulkload(inputFilePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeFind(searchString?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeFind(searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeFindall(options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeFindall(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [empId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeGet(empId?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeGet(empId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeGetByKeycloak(userId: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeGetByKeycloak(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeListAllEmployeeDetails(options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeListAllEmployeeDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [firstname] 
     * @param {string} [lastname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeSearch(firstname?: string, lastname?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeSearch(firstname, lastname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmployeeRequestModel} employeeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeUpdate(employeeRequestModel: EmployeeRequestModel, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeUpdate(employeeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateByKeycloakIdRequestModel} updateByKeycloakIdRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel: UpdateByKeycloakIdRequestModel, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeUpdateByKeycloakId(updateByKeycloakIdRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateByKeycloakUsernameRequestModel} updateByKeycloakUsernameRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel: UpdateByKeycloakUsernameRequestModel, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).employeeUpdateKeycloakDetails(updateByKeycloakUsernameRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BodyUserSelection} bodyUserSelection 
     * @param {string} [status] 
     * @param {string} [conditionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public userSelection(bodyUserSelection: BodyUserSelection, status?: string, conditionType?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).userSelection(bodyUserSelection, status, conditionType, options).then((request) => request(this.axios, this.basePath));
    }
}

