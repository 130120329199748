/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Editable List Item
 */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Input, List, Popover, Space } from 'antd';
import { Color } from 'antd/es/color-picker';

import HalfCircleFilled from 'components/icons/HalfCircleFilled';
import { StageList, useManageQueueActions, useManageQueueValues } from 'store/manageQueue';
import { defaultIconColor } from './QueuesIndexingStages';

const colorOptions = ['#40A9FF', '#FF4D4F', '#FF7A45', '#FFC53D', '#73D13D', '#36CFC9', '#F759AB', '#9254DE'];

interface IProps {
	index: number;
	stage: StageList;
	color: string | Color;
	stageName: string;
	onColorChange: React.Dispatch<React.SetStateAction<string | Color>>;
	onStageNameChange: React.Dispatch<React.SetStateAction<string>>;
}

const EditableListItem: React.FC<IProps> = (props) => {
	const { index, stage, color, stageName, onColorChange, onStageNameChange } = props;

	const { stageList } = useManageQueueValues();
	const { removeStageAtIndex, cancelStageEditing, saveStage } = useManageQueueActions();
	const [popoverOpen, setPopoverOpen] = useState(false);

	const iconBgColor = useMemo<string>(() => (typeof color === 'string' ? color : color.toHexString()), [color]);

	const resetStageStates = () => {
		onColorChange(defaultIconColor);
		onStageNameChange('');
	};

	const handleCancel = (indexToCancel: number) => {
		if (stageList[indexToCancel].fromAddButton) {
			removeStageAtIndex(indexToCancel);
		} else {
			cancelStageEditing(indexToCancel);
		}
		resetStageStates();
	};

	const handleSaveStage = () => {
		saveStage(stageName, iconBgColor);
		resetStageStates();
	};

	return (
		<List.Item style={{ height: 54, display: 'flex', gap: 8 }}>
			<Popover
				placement='bottomLeft'
				open={popoverOpen}
				trigger={['click']}
				content={
					<Space size={16}>
						{_.map(colorOptions, (colorHex) => (
							<Button
								key={colorHex}
								size='small'
								shape='circle'
								type='text'
								style={{ background: colorHex }}
								onClick={() => {
									onColorChange(colorHex);
									setPopoverOpen(false);
								}}
							/>
						))}
					</Space>
				}
				onOpenChange={(open) => setPopoverOpen(open)}
			>
				<Button icon={<HalfCircleFilled style={{ color: iconBgColor }} />} />
			</Popover>
			<Input defaultValue={stage.name} style={{ flex: 1 }} onChange={(e) => onStageNameChange(e.target.value)} />
			<Button style={{ minWidth: 0 }} onClick={() => handleCancel(index)}>
				Cancel
			</Button>
			<Button
				type='primary'
				style={{ minWidth: 0 }}
				disabled={_.isEqual(stageName, '')}
				onClick={handleSaveStage}
			>
				Save
			</Button>
		</List.Item>
	);
};

export default EditableListItem;
