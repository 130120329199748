/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Auth helper
 */

import { ehrHelperInstance } from './EHRHelper';
import { standaloneHelperInstance } from './StandaloneHelper';

class AuthHelper {
	async getToken(minValidity = 5) {
		if (ehrHelperInstance.isLaunchedFromEhr) {
			return ehrHelperInstance.getToken();
		}
		return await standaloneHelperInstance.getToken(minValidity);
	}
}

const authHelperInstance = new AuthHelper();

export { authHelperInstance };
