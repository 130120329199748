/**
 * @author Lakkanna Walikar <lakkanna.walikar@314ecorp.com>
 * @description Novu Notifications styles hook
 */

import { theme } from 'antd';

const useNovuNotificationTheme = (): any => {
	const { token } = theme.useToken();
	const primaryColor = token.colorPrimary;
	const primaryTextColor = token.colorText;

	return {
		loader: {
			root: {
				stroke: primaryColor,
			},
		},
		notifications: {
			listItem: {
				unread: {
					'::before': { background: primaryColor },
					'font-weight': 600,
					'font-size': '14px',
				},
			},
		},
		layout: {
			root: {
				'*::selection': {
					background: '#e1f1ff',
				},
			},
		},
		header: {
			title: { color: primaryTextColor, fontWeight: 500 },
		},
	};
};

export default useNovuNotificationTheme;
