/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Scanner Configurations
 */


import { Form, Checkbox, Space } from 'antd';

const Configurations: React.FC = () => {
	return (
		<Space>
			<Form.Item name='adf' valuePropName='checked' initialValue={false}>
				<Checkbox>ADF</Checkbox>
			</Form.Item>
			<Form.Item name='twoSidedScan' valuePropName='checked' initialValue={false}>
				<Checkbox>2-sided Scan</Checkbox>
			</Form.Item>
		</Space>
	);
};

export default Configurations;
