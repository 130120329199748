/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ResourceEnum = {
    Documenttype: 'documenttype',
    Patient: 'patient',
    Encounter: 'encounter',
    User: 'user'
} as const;

export type ResourceEnum = typeof ResourceEnum[keyof typeof ResourceEnum];



