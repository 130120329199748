/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description common utils
 */

import _ from 'lodash';

import env from '@dexit/common/utils/Environment';
import { DEFAULT_SERVER_URL } from '@dexit/common/constants/url';

const getTenantName = (): string => {
	const isLocalhost = env.isLocalhost();

	if (isLocalhost) {
		return _.split(_.first(_.split(DEFAULT_SERVER_URL, '.')), '//')[1];
	}
	return _.split(_.get(window, 'location.hostname'), '.')[0];
};

export { getTenantName };
