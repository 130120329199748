const SETTINGS = {
	root: {
		url: '/settings/*',
	},
	default: {
		key: 'notifications',
		url: '/settings/notifications',
	},
	manageDocumentTypes: {
		url: '/settings/manage-document-types',
	},
	documentType: {
		url: '/settings/manage-document-types/document-type',
		children: {
			add: {
				url: '/settings/manage-document-types/create',
			},
			edit: {
				url: '/settings/manage-document-types/:id',
			},
		},
	},
	manageAttributes: {
		url: '/settings/manage-attributes',
		children: {
			add: {
				url: '/settings/manage-attributes/create',
			},
			edit: {
				url: '/settings/manage-attributes/:id',
			},
		},
	},
	manageList: {
		url: '/settings/manage-lists',
	},
	users: {
		url: '/settings/users',
		children: {
			add: {
				url: '/settings/users/create',
			},
			edit: {
				url: '/settings/users/:id',
			},
		},
	},
	manageQueues: {
		url: '/settings/manage-queues',
		children: {
			add: {
				url: '/settings/manage-queues/create',
			},
			edit: {
				url: '/settings/manage-queues/:id',
			},
		},
	},
};

export default SETTINGS;
