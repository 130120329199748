/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Universal Search
 */

import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Button, Input, InputRef, Popover, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Utils, ImmutableTree, JsonTree } from '@react-awesome-query-builder/antd';
import { useLocation, useNavigate } from 'react-router-dom';

import AdvancedSearchModal from 'components/advance-search/AdvancedSearchModal';
import RetrieveSearchOutlined from 'components/icons/RetrieveSearchOutlined';
import SliderStep from 'components/icons/SliderStepOutlined';
import UniversalSearchPopup from './UniversalSearchPopup';
import matomoConfig, { dimensions } from 'configs/matomo.config';
import useDebounce from 'hooks/useDebounce';
import useEntitySearch from 'hooks/useEntitySearch';
import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import useSavedSearch from 'hooks/useSavedSearch';
import { DEFAULT_PAGE_SIZE } from 'components/Grid/Grid';
import { ENTITY } from 'constants/search';
import { ResourceEnum } from '@dexit/common/openapi';
import { RetrieveContext } from 'components/page/RetrieveContext';
import { URL_CONFIG } from 'configs/menu-config';
import {
	addSelectedEntityToText,
	getEntity,
	defaultRAQBQuery,
	getStringBeforeCursor,
	filterQuery,
} from 'components/header/utils';
import { defaultQuery } from 'hooks/useSearchState';
import { getConfig } from 'components/query-builder/config';
import { useAppValues } from 'store/app';

const defaultTree = Utils.loadTree(defaultRAQBQuery);

interface IProps {
	faxSearch?: boolean;
	onSubmit?: (tree?: JsonTree, searchString?: string) => void;
}

const UniversalSearch: React.FC<IProps> = (props) => {
	const { faxSearch, onSubmit } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const { trackEvent } = useMatomo();

	const [itemSelected, setItemSelected] = useState(false);
	const [open, setOpen] = useState(false);
	const [queryTree, setQueryTree] = useState<ImmutableTree>(Utils.loadTree(defaultRAQBQuery));
	const [resource, setResource] = useState<ResourceEnum | undefined>(undefined);
	const [typedEntityText, setTypedEntityText] = useState('');
	const [typedText, setTypedText] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const [visible, setVisible] = useState(false);
	const { dateFormat, timeFormat } = useAppValues();

	const { query, setQuery, setPaginationAndSort } = useContext(RetrieveContext);
	let timeoutId: NodeJS.Timeout | null = null;
	const inputRef = useRef<InputRef>(null);

	const config = useMemo(() => getConfig(dateFormat, timeFormat, faxSearch), [dateFormat, timeFormat, faxSearch]);

	const { data: savedSearchData, isFetching: fetchingSavedSearch } = useSavedSearch();

	const [debounceSearchQuery] = useDebounce(_.trimStart(_.isEmpty(typedEntityText) ? typedText : typedEntityText), 3);
	const { data, isFetching } = useEntitySearch(
		{ search_string: debounceSearchQuery, resource },
		{ enabled: !!debounceSearchQuery },
	);

	const handleTypedTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputString = e.target.value;
		const cursorPosition = e.target.selectionStart;
		const entity = getEntity(inputString, cursorPosition);
		setResource(entity);
		if (_.isUndefined(entity)) {
			setTypedEntityText('');
		} else {
			const entityText = getStringBeforeCursor(inputString, cursorPosition);
			if (!_.isEmpty(entityText)) {
				setTypedEntityText(entityText);
			}
		}
		setTypedText(inputString);
		if (!open) {
			setOpen(true);
		}
	};

	const handleClear = () => {
		setQuery(defaultQuery);
		setTypedText('');
		setQueryTree(Utils.loadTree(defaultRAQBQuery));
	};
	const handleOpenChange = () => {
		setOpen(false);
		setVisible(!visible);
	};
	const handleSubmit = (tree?: ImmutableTree, searchString?: string) => {
		if (tree) {
			const jsonTree = Utils.getTree(tree);
			setQueryTree(tree);
			if (onSubmit) {
				onSubmit(jsonTree);
			} else {
				setQuery({ ...query, query_filter: jsonTree, search_string: undefined });
			}
		} else {
			setQueryTree(defaultTree);
			if (onSubmit) {
				onSubmit(undefined, searchString);
			} else {
				setQuery({ ...query, query_filter: undefined, search_string: searchString });
			}
		}
		if (!onSubmit) {
			setPaginationAndSort({ current: 1, pageSize: DEFAULT_PAGE_SIZE }, {});
		}
		setItemSelected(false);
		if (!_.isEqual(location.pathname, URL_CONFIG.retrieval) && !onSubmit) {
			navigate(URL_CONFIG.retrieval);
		}
		setOpen(false);
	};
	const handleEnter = (newTypedText: string, fromSelect?: boolean) => {
		const processTree = (defaultSpelFormat: string) => {
			const [tree, spelErrors] = Utils.loadFromSpel(defaultSpelFormat, config);
			if (_.isEmpty(spelErrors) && tree && !_.isEmpty(filterQuery(Utils.getTree(tree)))) {
				handleSubmit(tree);
			} else {
				handleSubmit(undefined, typedText);
			}
		};
		if (fromSelect || !itemSelected) {
			if (_.isEmpty(newTypedText)) {
				handleSubmit(undefined, '');
			} else {
				const [tree, spelErrors] = Utils.loadFromSpel(newTypedText, config);
				if (_.isEmpty(spelErrors) && tree && !_.isEmpty(filterQuery(Utils.getTree(tree)))) {
					handleSubmit(tree);
				} else {
					const defaultSpelFormat = `text.contains('${newTypedText}')`;
					processTree(defaultSpelFormat);
				}
			}
		}
		setTypedText(newTypedText);
		setItemSelected(false);
	};
	const handleOnSelect = (item: any) => {
		setItemSelected(true);
		if (_.isUndefined(item.type)) {
			const queryTree = Utils.loadTree(item.data);
			const [spel, spelErrors] = Utils._spelFormat(queryTree, config);
			if (spel && spelErrors.length === 0) {
				setTypedText(spel);
			}
			handleSubmit(queryTree);
		} else if (_.isEqual(item.type, ENTITY.TEXT)) {
			if (_.isEmpty(item.text)) {
				handleEnter(item.text, true);
			} else {
				const newTypedText = `text.contains('${item.text}')`;
				handleEnter(newTypedText, true);
			}
		} else {
			const newTypedText = addSelectedEntityToText(typedText, item, inputRef.current?.input?.selectionStart ?? 0);
			handleEnter(newTypedText, true);
		}
		if (timeoutId) {
			clearTimeout(timeoutId);
			timeoutId = null;
		}
	};
	const handleSearch = (queryTree: ImmutableTree) => {
		const [spel, spelErrors] = Utils._spelFormat(queryTree, config);
		if (spel && spelErrors.length === 0) {
			setTypedText(spel);
		} else {
			setTypedText('');
		}
		handleSubmit(queryTree);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
			e.preventDefault();
		}
	};

	useEffect(() => {
		if (query?.query_filter) {
			const queryTree = Utils.loadTree(query.query_filter as JsonTree);
			const [spel, spelErrors] = Utils._spelFormat(queryTree, config);
			if (spel && spelErrors.length === 0) {
				setTypedText(spel);
				setQueryTree(queryTree);
			}
		}
	}, []);

	useEffect(() => {
		let queryString: string = '';
		if (!_.isEmpty(query?.search_string) && !_.isNil(query?.search_string)) {
			queryString = query.search_string;
		} else {
			const queryTree = Utils.loadTree((query.query_filter as JsonTree) ?? defaultTree);
			const [spel, spelErrors] = Utils._spelFormat(queryTree, config);
			if (spel && spelErrors.length === 0) {
				queryString = spel;
			}
		}
		const customDimensionValue = globalThis.btoa(JSON.stringify({ query: queryString }));
		trackEvent({
			category: 'Search',
			action: matomoConfig.actions.CLICK,
			name: 'Search',
			customDimensions: [{ id: dimensions.visitDetail, value: customDimensionValue }],
		});
	}, [query]);

	const renderContent = () => {
		return (
			<UniversalSearchPopup
				data={data}
				loading={isFetching}
				typedText={typedText}
				visible={open}
				onSelect={handleOnSelect}
				savedSearchData={savedSearchData}
				fetchingSavedSearch={fetchingSavedSearch}
				config={config}
			/>
		);
	};

	return (
		<>
			<Popover
				open={open}
				arrow={false}
				trigger={['click']}
				placement='bottomLeft'
				overlayClassName='universal-search'
				destroyTooltipOnHide
				content={renderContent}
				onOpenChange={setOpen}
			>
				<Input
					className={classnames('universal-search-input', {
						'universal-search-applied': !_.isEmpty(typedText),
						'universal-search-active': isFocused,
					})}
					value={typedText}
					ref={inputRef}
					placeholder='Search Documents'
					onChange={handleTypedTextChange}
					onClick={() => setOpen(true)}
					onPressEnter={() => {
						timeoutId = setTimeout(() => {
							handleEnter(typedText);
						}, 200);
					}}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onKeyDown={handleKeyDown}
					prefix={<RetrieveSearchOutlined />}
					suffix={
						<>
							<Tooltip title='Clear Search'>
								<Button
									size='small'
									type='text'
									icon={<CloseOutlined style={{ color: '#00000073' }} />}
									hidden={_.isEmpty(typedText)}
									onClick={handleClear}
								/>
							</Tooltip>
							<Button
								style={{ marginRight: '0' }}
								type='text'
								onClick={handleOpenChange}
								icon={<SliderStep />}
							/>
						</>
					}
				/>
			</Popover>
			{visible && (
				<AdvancedSearchModal
					open={visible}
					queryTree={queryTree}
					faxSearch={faxSearch}
					onChangeQueryTree={setQueryTree}
					onOpenChange={handleOpenChange}
					onSearch={handleSearch}
				/>
			)}
		</>
	);
};

export default UniversalSearch;
