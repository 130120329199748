/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description User Profile
 */

import React, { useMemo } from 'react';
import _ from 'lodash';
import { Avatar, Dropdown, MenuProps, Modal, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import matomoConfig from 'configs/matomo.config';
import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import { standaloneHelperInstance } from 'utils/StandaloneHelper';
import { useUserValues } from 'store/user';

const UserProfile: React.FC = () => {
	const { trackEvent } = useMatomo();
	const [modal, contextHolder] = Modal.useModal();

	const { user } = useUserValues();

	const userInitial = useMemo(() => {
		if (user?.firstName && user?.lastName) {
			return `${_.first(_.capitalize(user?.firstName))}${_.first(_.capitalize(user?.lastName))}`;
		}
		return '';
	}, [user?.firstName, user?.lastName]);

	const logout = async () => {
		await standaloneHelperInstance.logout();
		trackEvent({ category: 'User Profile', action: matomoConfig.actions.CLICK, name: 'Logout' });
	};

	const signOut = () => {
		void modal.confirm({
			title: 'Are you sure you want to sign out ?',
			okText: 'Sign Out',
			onOk: async () => {
				localStorage.clear();
				sessionStorage.clear();
				await logout();
			},
		});
	};

	const items: MenuProps['items'] = [
		{
			key: 'sign-out',
			icon: <LogoutOutlined />,
			title: '',
			label: 'Logout',
			onClick: signOut,
		},
	];

	const getDropdownRender = (menu: React.ReactNode) => {
		return (
			<div className='user-profile-menu'>
				<div className='user-profile-avatar'>
					<Avatar style={{ background: '#F76948', cursor: 'default' }} className='flex-center'>
						{userInitial}
					</Avatar>
					<Typography.Text style={{ color: '#000000D9', fontWeight: 600 }}>
						{_.join(_.compact([user?.firstName, user?.lastName]), ' ')}
					</Typography.Text>
					<Typography.Text style={{ color: '#000000D9' }}>{user?.email}</Typography.Text>
				</div>
				<div className='user-profile-submenu'>{menu}</div>
			</div>
		);
	};

	return (
		<>
			{contextHolder}
			<Dropdown
				menu={{ items, style: { padding: '12px 6px' } }}
				placement={'bottomLeft'}
				trigger={['click']}
				dropdownRender={(menu) => getDropdownRender(menu)}
			>
				<Avatar style={{ background: '#F76948', cursor: 'pointer' }}>{userInitial}</Avatar>
			</Dropdown>
		</>
	);
};

export default UserProfile;
