/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Client
 */

import _ from 'lodash';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import log from 'loglevel';
import { H } from 'highlight.run';
import { LogLevel, faro } from '@grafana/faro-react';

import config from 'configs/client.config';
import { AuditHistoryApi } from '@dexit/common/openapi/Apis/audit-history-api';
import { BatchScanningApi } from '@dexit/common/openapi/Apis/batch-scanning-api';
import { BulkImportApi } from '@dexit/common/openapi/Apis/bulk-import-api';
import { CustomAttributesApi } from '@dexit/common/openapi/Apis/custom-attributes-api';
import { CustomerSettingsApi } from '@dexit/common/openapi/Apis/customer-settings-api';
import { DashboardApi } from '@dexit/common/openapi/Apis/dashboard-api';
import { DeficiencyApi } from '@dexit/common/openapi/Apis/deficiency-api';
import { DocumentApi } from '@dexit/common/openapi/Apis/document-api';
import { DocumentReviewApi } from '@dexit/common/openapi/Apis/document-review-api';
import { DocumentTypeApi } from '@dexit/common/openapi/Apis/document-type-api';
import { EPICIntegrationApi } from '@dexit/common/openapi/Apis/epicintegration-api';
import { EmployeeApi } from '@dexit/common/openapi/Apis/employee-api';
import { EventSubscriptionApi } from '@dexit/common/openapi/Apis/event-subscription-api';
import { EventTypeApi } from '@dexit/common/openapi/Apis/event-type-api';
import { FaxApi } from '@dexit/common/openapi/Apis/fax-api';
import { ListOfValuesApi } from '@dexit/common/openapi/Apis/list-of-values-api';
import { MachineLearningApi } from '@dexit/common/openapi/Apis/machine-learning-api';
import { MatomoReportsApi } from '@dexit/common/openapi/Apis/matomo-reports-api';
import { NovuIntegrationApi } from '@dexit/common/openapi/Apis/novu-integration-api';
import { OrganizationApi } from '@dexit/common/openapi/Apis/organization-api';
import { QueueApi } from '@dexit/common/openapi/Apis/queue-api';
import { ReleaseOfInformationApi } from '@dexit/common/openapi/Apis/release-of-information-api';
import { ReportsApi } from '@dexit/common/openapi/Apis/reports-api';
import { ReportsCsvApi } from '@dexit/common/openapi/Apis/reports-csv-api';
import { ResourceApi } from '@dexit/common/openapi/Apis/resource-api';
import { SavedSearchApi } from '@dexit/common/openapi/Apis/saved-search-api';
import { SearchApi } from '@dexit/common/openapi/Apis/search-api';
import { StampApi } from '@dexit/common/openapi/Apis/stamp-api';
import { SubscriptionApi } from '@dexit/common/openapi/Apis/subscription-api';
import { TemplateApi } from '@dexit/common/openapi/Apis/template-api';
import { UserApi } from '@dexit/common/openapi/Apis/user-api';
import { WorkflowApi } from '@dexit/common/openapi/Apis/workflow-api';
import { authHelperInstance } from 'utils/AuthHelper';
import { standaloneHelperInstance } from 'utils/StandaloneHelper';

const MESSAGE = '[AxiosInterceptor]';

class AxiosClient {
	minValidity!: number;
	AuditHistoryApi!: AuditHistoryApi;
	BatchScanningApi!: BatchScanningApi;
	BulkImportApi!: BulkImportApi;
	CustomAttributesApi!: CustomAttributesApi;
	CustomerSettingsApi!: CustomerSettingsApi;
	DashboardApi!: DashboardApi;
	DeficiencyApi!: DeficiencyApi;
	DocumentApi!: DocumentApi;
	DocumentReviewApi!: DocumentReviewApi;
	DocumentTypeApi!: DocumentTypeApi;
	EPICIntegrationApi!: EPICIntegrationApi;
	EmployeeApi!: EmployeeApi;
	EventSubscriptionApi!: EventSubscriptionApi;
	EventTypeApi!: EventTypeApi;
	FaxApi!: FaxApi;
	ListOfValuesApi!: ListOfValuesApi;
	MachineLearningApi!: MachineLearningApi;
	MatomoReportsApi!: MatomoReportsApi;
	NovuIntegrationApi!: NovuIntegrationApi;
	OrganizationApi!: OrganizationApi;
	QueueApi!: QueueApi;
	ReleaseOfInformationApi!: ReleaseOfInformationApi;
	ReportsApi!: ReportsApi;
	ReportsCsvApi!: ReportsCsvApi;
	ResourceApi!: ResourceApi;
	SavedSearchApi!: SavedSearchApi;
	SearchApi!: SearchApi;
	StampApi!: StampApi;
	SubscriptionApi!: SubscriptionApi;
	TemplateApi!: TemplateApi;
	UserApi!: UserApi;
	WorkflowApi!: WorkflowApi;

	init = (minValidity = 5): void => {
		this.minValidity = minValidity;
		const basePath = config.baseURL;
		const client = axios.create(config);
		client.interceptors.request.use(this.requestInterceptor, this.rejectInterceptor);
		client.interceptors.response.use(this.responseInterceptor, this.rejectInterceptor);

		this.AuditHistoryApi = new AuditHistoryApi(undefined, basePath, client);
		this.BatchScanningApi = new BatchScanningApi(undefined, basePath, client);
		this.BulkImportApi = new BulkImportApi(undefined, basePath, client);
		this.CustomAttributesApi = new CustomAttributesApi(undefined, basePath, client);
		this.CustomerSettingsApi = new CustomerSettingsApi(undefined, basePath, client);
		this.DashboardApi = new DashboardApi(undefined, basePath, client);
		this.DeficiencyApi = new DeficiencyApi(undefined, basePath, client);
		this.DocumentApi = new DocumentApi(undefined, basePath, client);
		this.DocumentReviewApi = new DocumentReviewApi(undefined, basePath, client);
		this.DocumentTypeApi = new DocumentTypeApi(undefined, basePath, client);
		this.EPICIntegrationApi = new EPICIntegrationApi(undefined, basePath, client);
		this.EmployeeApi = new EmployeeApi(undefined, basePath, client);
		this.EventSubscriptionApi = new EventSubscriptionApi(undefined, basePath, client);
		this.EventTypeApi = new EventTypeApi(undefined, basePath, client);
		this.FaxApi = new FaxApi(undefined, basePath, client);
		this.ListOfValuesApi = new ListOfValuesApi(undefined, basePath, client);
		this.MachineLearningApi = new MachineLearningApi(undefined, basePath, client);
		this.MatomoReportsApi = new MatomoReportsApi(undefined, basePath, client);
		this.NovuIntegrationApi = new NovuIntegrationApi(undefined, basePath, client);
		this.OrganizationApi = new OrganizationApi(undefined, basePath, client);
		this.QueueApi = new QueueApi(undefined, basePath, client);
		this.ReleaseOfInformationApi = new ReleaseOfInformationApi(undefined, basePath, client);
		this.ReportsApi = new ReportsApi(undefined, basePath, client);
		this.ReportsCsvApi = new ReportsCsvApi(undefined, basePath, client);
		this.ResourceApi = new ResourceApi(undefined, basePath, client);
		this.SavedSearchApi = new SavedSearchApi(undefined, basePath, client);
		this.SearchApi = new SearchApi(undefined, basePath, client);
		this.StampApi = new StampApi(undefined, basePath, client);
		this.SubscriptionApi = new SubscriptionApi(undefined, basePath, client);
		this.TemplateApi = new TemplateApi(undefined, basePath, client);
		this.UserApi = new UserApi(undefined, basePath, client);
		this.WorkflowApi = new WorkflowApi(undefined, basePath, client);
	};

	requestInterceptor = async (
		requestConfig: InternalAxiosRequestConfig<any>,
	): Promise<InternalAxiosRequestConfig<any>> => {
		try {
			const token = await authHelperInstance.getToken(this.minValidity);
			if (token) {
				_.setWith(requestConfig, 'headers.Authorization', `Bearer ${token}`, Object);
			}

			return requestConfig;
		} catch (ex) {
			log.error(MESSAGE, ex);
			faro.api.pushLog([MESSAGE, ex], { level: LogLevel.ERROR });
			standaloneHelperInstance.clearToken();
			return Promise.reject(requestConfig);
		}
	};

	responseInterceptor = (
		response: AxiosResponse<any, any>,
	): Promise<AxiosResponse<any, any>> | AxiosResponse<any, any> => {
		const { data, headers } = response;

		if (!_.isNil(data)) {
			return {
				...response,
				data,
			};
		}

		if (headers['content-type'] !== 'application/json') {
			return response;
		}

		log.error('[AxiosInterceptor] Not following response standard', response);
		return Promise.reject({ ...response, data: _.get(data, 'error') });
	};

	rejectInterceptor = (error: AxiosError): Promise<never> => {
		H.consumeError(error, JSON.stringify(error));
		if (error.response) {
			log.error(MESSAGE, error.response.status);
			faro.api.pushLog([MESSAGE, error.response.status], { level: LogLevel.ERROR });
			switch (error.response.status) {
				case 400:
					log.error('[AxiosInterceptor] Bad Request');
					faro.api.pushLog(['[AxiosInterceptor] Bad Request'], {
						level: LogLevel.ERROR,
					});
					break;
				case 401:
					log.error('[AxiosInterceptor] UnAuthorized');
					faro.api.pushLog(['[AxiosInterceptor] UnAuthorized'], {
						level: LogLevel.ERROR,
					});
					standaloneHelperInstance.logout();
					break;
				case 403:
					log.error('[AxiosInterceptor] Forbidden');
					faro.api.pushLog(['[AxiosInterceptor] Forbidden'], {
						level: LogLevel.ERROR,
					});
					break;
				case 404:
					log.error('[AxiosInterceptor] Not Found');
					faro.api.pushLog(['[AxiosInterceptor] Not Found'], {
						level: LogLevel.ERROR,
					});
					break;
			}
		}
		return Promise.reject(error);
	};
}

export default AxiosClient;
