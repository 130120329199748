/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Save Search Form
 */

import React, { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { JsonTree } from '@react-awesome-query-builder/antd';

import useCreateSavedSearch from 'hooks/mutations/useCreateSavedSearch';
import { SavedSearchRequestModel, SavedSearchResponseModel } from '@dexit/common/openapi';

const { TextArea } = Input;

interface IProps {
	open: boolean;
	query: JsonTree;
	isNew: boolean;
	savedSearchRecord: SavedSearchResponseModel | null;
	onCancel: () => void;
}

interface IForm {
	name: string;
	comments: string;
}

const SaveSearchForm: React.FC<IProps> = (props) => {
	const { open, query, savedSearchRecord, isNew, onCancel } = props;
	const [form] = Form.useForm();

	const [disabled, setDisabled] = useState(isNew);
	const savedSearch = useCreateSavedSearch();

	const handleCancel = () => {
		onCancel();
		form.resetFields();
		setDisabled(true);
	};

	useEffect(() => {
		if (!isNew) {
			form.setFieldsValue({ name: savedSearchRecord?.name });
		}
	}, [isNew, open]);

	const handleFinish = (val: IForm) => {
		const { name, comments } = val;
		const payload: SavedSearchRequestModel = {
			name,
			comments,
			data: query,
		};
		if (!isNew) {
			payload.id = savedSearchRecord?.id;
		}

		savedSearch.mutate(payload, {
			onSuccess: () => {
				handleCancel();
				setDisabled(true);
			},
		});
	};

	const handleOk = () => {
		form.submit();
	};

	return (
		<Modal
			open={open}
			title={isNew ? 'Save Search' : 'Update Search'}
			okText={isNew ? 'Save' : 'Update'}
			okButtonProps={{ loading: savedSearch.isLoading, disabled }}
			cancelButtonProps={{ style: { display: 'none' } }}
			onCancel={handleCancel}
			onOk={handleOk}
		>
			<Form
				name='SaveSearchForm'
				layout='vertical'
				form={form}
				onFinish={handleFinish}
				onFieldsChange={() => setDisabled(false)}
			>
				<Form.Item
					name='name'
					label='Title'
					rules={[{ required: true, message: 'Enter the name for saved search' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item name='comments'>
					<TextArea rows={4} placeholder='Add description (Optional)' />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default SaveSearchForm;
