/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description hook to detect key press
 */

import { useEffect, useState } from 'react';

const useKeyPress = (targetKey: string): boolean => {
	const [keyPressed, setKeyPressed] = useState(false);
	useEffect(() => {
		const downHandler = ({ key }: { key: string }) => {
			if (key === targetKey) {
				setKeyPressed(true);
			}
		};
		const upHandler = ({ key }: { key: string }) => {
			if (key === targetKey) {
				setKeyPressed(false);
			}
		};

		globalThis.addEventListener('keydown', downHandler);
		globalThis.addEventListener('keyup', upHandler);
		return () => {
			globalThis.removeEventListener('keydown', downHandler);
			globalThis.removeEventListener('keyup', upHandler);
		};
	}, [targetKey]);
	return keyPressed;
};

export default useKeyPress;
