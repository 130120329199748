/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Review Outlined
 */


import classnames from 'classnames';

const NotesOutlined: React.FC<any> = (props) => {
	const { className, isFilled, width, height, ...restProps } = props;
	const cn = classnames('anticon anticon-notes-Outlined', className);
	return (
		<span role='img' aria-label='Notes-Outlined' className={cn} {...restProps}>
			<svg
				aria-label='Notes-Outlined'
				width={width ?? '24'}
				height={height ?? '24'}
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M4 3C4 2.44771 4.44771 2 5 2H15L20 7V21C20 21.5523 19.5523 22 19 22H5C4.44771 22 4 21.5523 4 21V3Z'
					fill={isFilled ? '#8C8C8C' : 'white'}
					stroke='#8C8C8C'
					strokeWidth='2'
					strokeLinejoin='round'
				/>
				<path
					d='M8 10H16'
					stroke={isFilled ? 'white' : '#8C8C8C'}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M8 14H16'
					stroke={isFilled ? 'white' : '#8C8C8C'}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</span>
	);
};

export default NotesOutlined;
