/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Session storage state
 */

import log from 'loglevel';
import { useEffect, useState } from 'react';

const useSessionStorageState = <T>(defaultValue: T, key: string): [T, React.Dispatch<React.SetStateAction<T>>] => {
	const [value, setValue] = useState(() => {
		const stickyValue = globalThis.sessionStorage.getItem(key);
		if (stickyValue === null) {
			return defaultValue;
		} else {
			try {
				return JSON.parse(stickyValue);
			} catch {
				log.error('[useSessionStorageState] Unable to parse session storage key item');
			}
		}
	});

	useEffect(() => {
		globalThis.sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};

export default useSessionStorageState;
