/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Query Builder
 */

import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { ItemType } from 'antd/es/menu/interface';
import { Query, Builder, Config, ImmutableTree, BuilderProps, Utils } from '@react-awesome-query-builder/antd';

import './raqb.css';
import getFieldItem from './getAttributeField';
import useAllAttributes from 'hooks/useAllAttributes';
import { EntityDefinitionResponseModel } from '@dexit/common/openapi';
import { getConfig } from './config';
import { useAppValues } from 'store/app';

interface IProps {
	tree: ImmutableTree;
	faxSearch?: boolean;
	workflowItems?: ItemType[];
	onChange: (tree: ImmutableTree) => void;
}

const generateConfig = (
	dateFormat: string,
	timeFormat: string,
	data?: EntityDefinitionResponseModel[],
	faxSearch?: boolean,
	workflowItems?: ItemType[],
): Config => {
	if (workflowItems) {
		return getConfig(dateFormat, timeFormat, faxSearch, workflowItems);
	}
	const initConfig: Config = getConfig(dateFormat, timeFormat, faxSearch);
	if (!data) {
		return initConfig;
	}
	const filteredData = _.filter(data, (item) => !item.issystem || item.field === 'name');
	const subfieldsWithDef = _.reduce(
		filteredData,
		(acc: any, item) => {
			acc[item.field] = getFieldItem(item);
			return acc;
		},
		{},
	);
	_.set(initConfig.fields, 'attributes', { type: '!group', label: 'Attributes', subfields: subfieldsWithDef });
	return initConfig;
};

const QueryBuilder: React.FC<IProps> = (props) => {
	const { tree, faxSearch, workflowItems, onChange } = props;
	const { dateFormat, timeFormat } = useAppValues();
	const { data } = useAllAttributes('document', false, undefined, { enabled: !workflowItems });

	const config = useMemo(
		() => generateConfig(dateFormat, timeFormat, data, faxSearch, workflowItems),
		[data, dateFormat, timeFormat, faxSearch, workflowItems],
	);

	const [state, setState] = useState({
		tree: Utils.checkTree(tree, config),
		config,
	});

	useEffect(() => {
		if (!_.isEqual(tree, state.tree)) {
			setState((prevState) => ({
				...prevState,
				tree: Utils.checkTree(tree, config),
			}));
		}
	}, [tree, config]);

	const handleChange = (immutableTree: ImmutableTree, configuration: Config): void => {
		setState({
			tree: immutableTree,
			config: configuration,
		});
		onChange?.(immutableTree);
	};
	const renderBuilder = (builderProps: BuilderProps) => {
		return (
			<div className='query-builder-container' style={{ padding: '16px' }}>
				<div className='query-builder qb-lite'>
					<Builder {...builderProps} />
				</div>
			</div>
		);
	};
	return <Query {...config} value={state.tree} onChange={handleChange} renderBuilder={renderBuilder} />;
};

export default QueryBuilder;
