/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Preview Unavailable Outlined
 */


import classnames from 'classnames';

const PreviewUnavailableOutlined: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-previewunavailableoutlined', className);
	return (
		<span role='img' aria-label='Previewunavailableoutlined' className={cn} {...restProps}>
			<svg
				width='152'
				height='152'
				viewBox='0 0 152 152'
				fill='none'
				aria-label='Previewunavailableoutlined'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='76' cy='76' r='76' fill='#D8E0E5' />
				<mask
					id='mask0_16565_110764'
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='153'
					height='152'
				>
					<circle cx='76.0039' cy='76' r='76' fill='url(#paint0_linear_16565_110764)' />
				</mask>
				<g mask='url(#mask0_16565_110764)'>
					<rect x='22.75' y='39.9922' width='107.568' height='127.543' rx='4.66361' fill='white' />
					<rect x='40' y='58.5781' width='36.7745' height='7.77572' fill='#E2E6E9' />
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M51.7588 117.455C51.7588 110.399 57.4784 104.68 64.5338 104.68C71.5892 104.68 77.3088 110.399 77.3088 117.455C77.3088 124.51 71.5892 130.23 64.5338 130.23C57.4784 130.23 51.7588 124.51 51.7588 117.455ZM64.5338 111.98C61.5101 111.98 59.0588 114.431 59.0588 117.455C59.0588 120.478 61.5101 122.93 64.5338 122.93C67.5575 122.93 70.0088 120.478 70.0088 117.455C70.0088 114.431 67.5575 111.98 64.5338 111.98Z'
						fill='#F4F7FA'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M85.8179 129.359C87.2112 128.105 89.3278 128.11 90.7148 129.371L110.79 147.621C112.281 148.977 112.391 151.286 111.035 152.777C109.679 154.269 107.371 154.379 105.879 153.023L88.2473 136.994L72.4513 151.21C71.0882 152.437 69.0265 152.462 67.6342 151.268L57.0822 142.224L44.8246 151.417C43.2119 152.626 40.9241 152.3 39.7146 150.687C38.5051 149.074 38.8319 146.786 40.4446 145.577L55.0446 134.627C56.4132 133.6 58.3111 133.662 59.61 134.776L69.9508 143.639L85.8179 129.359Z'
						fill='#F4F7FA'
					/>
					<path d='M40 89H113V97H40V89Z' fill='#F4F7FA' />
				</g>
				<defs>
					<linearGradient
						id='paint0_linear_16565_110764'
						x1='76.0039'
						y1='0'
						x2='76.0039'
						y2='182.501'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0.174908' stopColor='#C4D1D5' />
						<stop offset='0.788009' stopColor='#4C6168' />
					</linearGradient>
				</defs>
			</svg>
		</span>
	);
};

export default PreviewUnavailableOutlined;
