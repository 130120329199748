/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description common
 */

import { getBasePath } from '@dexit/common/utils/url';
import _ from 'lodash';

const selectedMenuKey = (
	param: Record<string, string | undefined>,
	menuItems: MenuItem[],
	defaultKey: string,
): string => {
	const url = _.first(_.filter(_.split(_.get(param, '*'), '/'), (item) => item !== getBasePath()));
	const menuObj = _.findKey(menuItems, (value: MenuItem) => value.key === url);

	return menuObj ? _.get(menuItems, [menuObj, 'key']) : defaultKey;
};

export { selectedMenuKey };
