/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Slider Step Icon
 */


import classnames from 'classnames';

const SliderStep: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-sliderstep', className);
	return (
		<span role='img' aria-label='Sliderstep' className={cn} {...restProps}>
			<svg
				aria-label='Sliderstep'
				width='14'
				height='15'
				viewBox='0 0 14 15'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M10.6608 4.04874C10.9555 4.04874 11.2502 4.04874 11.5463 4.04874C12.0137 4.04874 12.4811 4.04874 12.95 4.04874C13.0588 4.04874 13.1662 4.04874 13.2751 4.04874C13.4609 4.04874 13.6569 3.96726 13.789 3.83631C13.9124 3.70973 14.0082 3.50312 13.9994 3.32124C13.9907 3.13355 13.9298 2.93858 13.7875 2.80618C13.6453 2.67523 13.4711 2.59375 13.2737 2.59375C12.979 2.59375 12.6843 2.59375 12.3882 2.59375C11.9208 2.59375 11.4534 2.59375 10.9845 2.59375C10.8757 2.59375 10.7682 2.59375 10.6594 2.59375C10.4736 2.59375 10.2776 2.67523 10.1455 2.80618C10.0192 2.93276 9.92488 3.13937 9.93359 3.32124C9.9423 3.50894 10.0033 3.70391 10.1455 3.83631C10.2892 3.9658 10.4649 4.04874 10.6608 4.04874Z'
					fill='#323837'
				/>
				<path
					d='M0.742157 4.05068C0.997634 4.05068 1.25311 4.05068 1.50859 4.05068C2.1168 4.05068 2.72501 4.05068 3.33177 4.05068C4.07207 4.05068 4.81237 4.05068 5.55122 4.05068C6.18846 4.05068 6.8257 4.05068 7.4644 4.05068C7.77358 4.05068 8.08422 4.05504 8.3934 4.05068C8.39776 4.05068 8.40211 4.05068 8.40647 4.05068C8.59227 4.05068 8.78823 3.9692 8.92033 3.83825C9.04661 3.71167 9.14096 3.50506 9.13225 3.32318C9.12355 3.13549 9.06258 2.94052 8.92033 2.80812C8.77807 2.67717 8.60388 2.59569 8.40647 2.59569C8.15099 2.59569 7.89551 2.59569 7.64004 2.59569C7.03183 2.59569 6.42362 2.59569 5.81686 2.59569C5.07656 2.59569 4.33625 2.59569 3.5974 2.59569C2.96016 2.59569 2.32292 2.59569 1.68423 2.59569C1.37504 2.59569 1.06441 2.59132 0.755221 2.59569C0.750867 2.59569 0.746511 2.59569 0.742157 2.59569C0.556355 2.59569 0.360393 2.67717 0.228299 2.80812C0.102013 2.9347 0.00766084 3.14131 0.0163698 3.32318C0.0250797 3.51088 0.0860452 3.70585 0.228299 3.83825C0.370554 3.96774 0.546194 4.05068 0.742157 4.05068Z'
					fill='#323837'
				/>
				<path
					d='M9.93905 1.71986C9.93905 2.07906 9.93905 2.43826 9.93905 2.79746C9.93905 3.36752 9.93905 3.93758 9.93905 4.5091C9.93905 4.64144 9.93905 4.77232 9.93905 4.90465C9.93905 5.0908 10.0203 5.28712 10.151 5.41946C10.2773 5.54598 10.4834 5.6405 10.6648 5.63178C10.8521 5.62305 11.0466 5.56197 11.1787 5.41946C11.3093 5.27694 11.3906 5.10243 11.3906 4.90465C11.3906 4.54546 11.3906 4.18626 11.3906 3.82706C11.3906 3.257 11.3906 2.68693 11.3906 2.11541C11.3906 1.98308 11.3906 1.8522 11.3906 1.71986C11.3906 1.53372 11.3093 1.33739 11.1787 1.20506C11.0524 1.07999 10.8463 0.984013 10.6648 0.992738C10.4776 1.00146 10.2831 1.06254 10.151 1.20506C10.0218 1.34757 9.93905 1.52354 9.93905 1.71986Z'
					fill='#323837'
				/>
				<path
					d='M4.30624 6.36831C4.30624 6.7275 4.30624 7.0867 4.30624 7.4459C4.30624 8.01596 4.30624 8.58603 4.30624 9.15755C4.30624 9.28988 4.30624 9.42076 4.30624 9.5531C4.30624 9.73924 4.38753 9.93557 4.51817 10.0679C4.64445 10.1944 4.85058 10.2889 5.03203 10.2802C5.21928 10.2715 5.41379 10.2104 5.54588 10.0679C5.67652 9.92539 5.75781 9.75088 5.75781 9.5531C5.75781 9.1939 5.75781 8.8347 5.75781 8.47551C5.75781 7.90544 5.75781 7.33538 5.75781 6.76386C5.75781 6.63152 5.75781 6.50064 5.75781 6.36831C5.75781 6.18216 5.67652 5.98584 5.54588 5.8535C5.4196 5.72698 5.21347 5.63246 5.03203 5.64118C4.84477 5.64991 4.65026 5.71099 4.51817 5.8535C4.38898 5.99602 4.30624 6.17198 4.30624 6.36831Z'
					fill='#323837'
				/>
				<path
					d='M5.02872 8.70693C5.30307 8.70693 5.57596 8.70693 5.85031 8.70693C6.50787 8.70693 7.16544 8.70693 7.823 8.70693C8.61556 8.70693 9.40812 8.70693 10.2007 8.70693C10.8858 8.70693 11.571 8.70693 12.2561 8.70693C12.59 8.70693 12.9253 8.71129 13.2591 8.70693C13.2635 8.70693 13.2679 8.70693 13.2737 8.70693C13.4595 8.70693 13.6554 8.62545 13.7875 8.4945C13.9124 8.36792 14.0082 8.16131 13.9995 7.97943C13.9907 7.79174 13.9298 7.59677 13.7875 7.46437C13.6453 7.33342 13.4711 7.25194 13.2737 7.25194C12.9993 7.25194 12.7264 7.25194 12.4521 7.25194C11.7945 7.25194 11.1369 7.25194 10.4794 7.25194C9.68682 7.25194 8.89426 7.25194 8.1017 7.25194C7.41656 7.25194 6.73142 7.25194 6.04627 7.25194C5.71241 7.25194 5.3771 7.24757 5.04324 7.25194C5.03888 7.25194 5.03453 7.25194 5.02872 7.25194C4.84292 7.25194 4.64696 7.33342 4.51486 7.46437C4.38858 7.59095 4.29422 7.79756 4.30293 7.97943C4.31164 8.16713 4.37261 8.3621 4.51486 8.4945C4.65712 8.62399 4.83276 8.70693 5.02872 8.70693Z'
					fill='#323837'
				/>
				<path
					d='M0.733639 8.70499C1.32733 8.70499 1.92248 8.70499 2.51617 8.70499C2.59891 8.70499 2.6831 8.70499 2.76584 8.70499C2.95164 8.70499 3.14761 8.62351 3.2797 8.49256C3.40599 8.36598 3.50034 8.15937 3.49163 7.97749C3.48292 7.7898 3.42195 7.59483 3.2797 7.46243C3.13745 7.33148 2.96326 7.25 2.76584 7.25C2.17215 7.25 1.577 7.25 0.98331 7.25C0.90057 7.25 0.816379 7.25 0.733639 7.25C0.547838 7.25 0.351875 7.33148 0.219782 7.46243C0.0934946 7.58901 -0.000857139 7.79562 0.00785228 7.97749C0.0165617 8.16519 0.0775275 8.36016 0.219782 8.49256C0.362036 8.62205 0.537677 8.70499 0.733639 8.70499Z'
					fill='#323837'
				/>
				<path
					d='M8.06251 13.4172C8.23525 13.4172 8.40654 13.4172 8.57927 13.4172C8.99442 13.4172 9.41102 13.4172 9.82617 13.4172C10.327 13.4172 10.8278 13.4172 11.3286 13.4172C11.7626 13.4172 12.1951 13.4172 12.6292 13.4172C12.8411 13.4172 13.053 13.4201 13.265 13.4172C13.2679 13.4172 13.2708 13.4172 13.2737 13.4172C13.4595 13.4172 13.6554 13.3357 13.7875 13.2048C13.9124 13.0782 14.0082 12.8716 13.9995 12.6897C13.9907 12.502 13.9298 12.3071 13.7875 12.1747C13.6453 12.0437 13.4711 11.9622 13.2737 11.9622C13.1009 11.9622 12.9296 11.9622 12.7569 11.9622C12.3418 11.9622 11.9252 11.9622 11.51 11.9622C11.0092 11.9622 10.5084 11.9622 10.0076 11.9622C9.5736 11.9622 9.14103 11.9622 8.70701 11.9622C8.49508 11.9622 8.28315 11.9593 8.07122 11.9622C8.06832 11.9622 8.06542 11.9622 8.06251 11.9622C7.87671 11.9622 7.68075 12.0437 7.54866 12.1747C7.42237 12.3012 7.32802 12.5079 7.33673 12.6897C7.34543 12.8774 7.4064 13.0724 7.54865 13.2048C7.69091 13.3343 7.86655 13.4172 8.06251 13.4172Z'
					fill='#323837'
				/>
				<path
					d='M0.7311 13.4172C0.898031 13.4172 1.06496 13.4172 1.23189 13.4172C1.63543 13.4172 2.03897 13.4172 2.44396 13.4172C2.93023 13.4172 3.41651 13.4172 3.90424 13.4172C4.32665 13.4172 4.75051 13.4172 5.17292 13.4172C5.37759 13.4172 5.58371 13.4201 5.78838 13.4172C5.79129 13.4172 5.79419 13.4172 5.79709 13.4172C5.98289 13.4172 6.17886 13.3357 6.31095 13.2048C6.43724 13.0782 6.53159 12.8716 6.52288 12.6897C6.51417 12.502 6.4532 12.3071 6.31095 12.1747C6.1687 12.0437 5.99451 11.9622 5.79709 11.9622C5.63016 11.9622 5.46323 11.9622 5.2963 11.9622C4.89276 11.9622 4.48922 11.9622 4.08424 11.9622C3.59796 11.9622 3.11168 11.9622 2.62395 11.9622C2.20154 11.9622 1.77768 11.9622 1.35528 11.9622C1.1506 11.9622 0.944481 11.9593 0.739809 11.9622C0.736906 11.9622 0.734003 11.9622 0.7311 11.9622C0.545298 11.9622 0.349335 12.0437 0.217242 12.1747C0.0909552 12.3012 -0.00339701 12.5079 0.00531241 12.6897C0.0140218 12.8774 0.0749878 13.0724 0.217242 13.2048C0.359496 13.3343 0.535137 13.4172 0.7311 13.4172Z'
					fill='#323837'
				/>
				<path
					d='M7.3453 11.0871C7.3453 11.4463 7.3453 11.8055 7.3453 12.1646C7.3453 12.7347 7.3453 13.3048 7.3453 13.8763C7.3453 14.0086 7.3453 14.1395 7.3453 14.2718C7.3453 14.458 7.42659 14.6543 7.55723 14.7867C7.68352 14.9132 7.88964 15.0077 8.07109 14.999C8.25834 14.9902 8.45285 14.9292 8.58494 14.7867C8.71559 14.6441 8.79687 14.4696 8.79687 14.2718C8.79687 13.9127 8.79687 13.5535 8.79687 13.1943C8.79687 12.6242 8.79687 12.0541 8.79687 11.4826C8.79687 11.3503 8.79687 11.2194 8.79687 11.0871C8.79687 10.9009 8.71559 10.7046 8.58495 10.5723C8.45866 10.4457 8.25253 10.3512 8.07109 10.3599C7.88383 10.3687 7.68932 10.4297 7.55723 10.5723C7.42804 10.7148 7.3453 10.8907 7.3453 11.0871Z'
					fill='#323837'
				/>
			</svg>
		</span>
	);
};

export default SliderStep;
