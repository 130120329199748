/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description RAQB delete Outlined
 */


import classnames from 'classnames';

const QueryDeleteOutlined: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-queryDelete-Outlined', className);
	return (
		<span role='img' aria-label='QueryDelete-Outlined' className={cn} {...restProps}>
			<svg
				aria-label='QueryDelete-Outlined'
				width='18'
				height='18'
				viewBox='0 0 18 18'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.23338 0.550781C6.95948 0.550781 6.70736 0.70009 6.5757 0.940268L5.55583 2.80078H3.375H1.5C1.08579 2.80078 0.75 3.13657 0.75 3.55078C0.75 3.96499 1.08579 4.30078 1.5 4.30078H2.625V16.3008C2.625 16.715 2.96079 17.0508 3.375 17.0508H14.625C15.0392 17.0508 15.375 16.715 15.375 16.3008V4.30078H16.5C16.9142 4.30078 17.25 3.96499 17.25 3.55078C17.25 3.13657 16.9142 2.80078 16.5 2.80078H14.625H12.4485L11.4521 0.945878C11.3214 0.702572 11.0676 0.550781 10.7914 0.550781H7.23338ZM10.7458 2.80078L10.3429 2.05078H7.67754L7.26642 2.80078H10.7458ZM6 4.30078H4.125V15.5508H13.875V4.30078H12H6ZM7.5 6.55078C7.91421 6.55078 8.25 6.88657 8.25 7.30078V12.1758C8.25 12.59 7.91421 12.9258 7.5 12.9258C7.08579 12.9258 6.75 12.59 6.75 12.1758V7.30078C6.75 6.88657 7.08579 6.55078 7.5 6.55078ZM11.25 7.30078C11.25 6.88657 10.9142 6.55078 10.5 6.55078C10.0858 6.55078 9.75 6.88657 9.75 7.30078V12.1758C9.75 12.59 10.0858 12.9258 10.5 12.9258C10.9142 12.9258 11.25 12.59 11.25 12.1758V7.30078Z'
					fill='#333333'
				/>
			</svg>
		</span>
	);
};

export default QueryDeleteOutlined;
