/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Keycloak App
 */

import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import markerSDK from '@marker.io/browser';
import moment from 'moment-timezone';
import { H } from 'highlight.run';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { faro } from '@grafana/faro-react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import InsufficientPermission from 'components/exceptions/InsufficientPermission';
import LandingPage from './LandingPage';
import Settings from 'pages/Settings';
import api from 'client/index';
import bootstrap from 'utils/bootstrap';
import config from 'configs/index';
import env from '@dexit/common/utils/Environment';
import { ServerDownResult, ServerExceptionResult } from 'components/exceptions';
import { Spin } from 'antd';
import { dimensions } from 'configs/matomo.config';
import { getBasePath } from '@dexit/common/utils/url';
import { getTenantName } from 'utils/matomoHelper';
import { standaloneHelperInstance } from 'utils/StandaloneHelper';
import { useAppValues } from 'store/app';

const settingsPath = '/settings/*';
const rootPath = '/*';

interface IProps {
	isAuthenticated: boolean;
	onStatusChange?: (message: string) => void;
}

const KeycloakApp: React.FC<IProps> = (props) => {
	const { trackEvent, pushInstruction } = useMatomo();

	const { status, timezone, dateAndTimeFormat, webItems } = useAppValues();

	useEffect(() => {
		moment.tz.setDefault(timezone);
	}, [timezone]);

	useEffect(() => {
		moment.defaultFormat = dateAndTimeFormat;
	}, [dateAndTimeFormat]);

	useEffect(() => {
		props.onStatusChange?.(status.message);
		if (!status.isLoading) {
			document.getElementById('splash-screen')?.remove();
		}
	}, [status]);

	const appInit = () => {
		api.init();

		const { tokenParsed } = standaloneHelperInstance.keycloakInstance;
		const user: User = {
			id: _.get(tokenParsed, 'sub') as string,
			email: _.get(tokenParsed, 'email'),
			firstName: `${_.get(tokenParsed, 'given_name')}`,
			lastName: `${_.get(tokenParsed, 'family_name')}`,
			roles: _.get(tokenParsed, ['resource_access', _.toLower(config.keycloak.config.clientId), 'roles'], []),
		};
		bootstrap(user);

		pushInstruction('setUserId', user.id);
		trackEvent({
			category: 'Login',
			action: 'Login',
			name: 'Login',
			customDimensions: [
				{
					id: dimensions.tenant,
					value: getTenantName(),
				},
				{
					id: dimensions.userName,
					value: user.email,
				},
				{
					id: dimensions.launchSource,
					value: 'Standalone',
				},
				{
					id: dimensions.USERFULLNAME,
					value: _.join(_.compact([user.firstName, user.lastName]), ' '),
				},
			],
		});

		H.identify(user.email);
		if (env.isProduction()) {
			markerSDK
				.loadWidget({
					project: config.marker.projectID,
				})
				.then((res) => {
					res.setReporter({
						fullName: _.join(_.compact([user.firstName, user.lastName]), ' '),
						email: user.email,
					});
				})
				.catch((err) => log.error(err));
		}
		faro.api.setUser({
			id: user?.id ?? 'id not present',
			email: user.email,
		});
	};

	useEffect(() => {
		if (props.isAuthenticated) {
			appInit();
		}
	}, [props.isAuthenticated]);

	const basename = getBasePath();
	const router = useMemo(
		() =>
			createBrowserRouter(
				createRoutesFromElements(
					<>
						<Route path={settingsPath} element={<Settings />} />
						<Route path={rootPath} element={<LandingPage />} />
					</>,
				),
				{ basename: basename ? `/${basename}` : undefined },
			),
		[webItems],
	);

	if (status.isLoading) {
		return <Spin />;
	}

	if (status.isError) {
		switch (status.status) {
			case 403:
				return <InsufficientPermission />;
			case 503:
				return <ServerDownResult />;
			default:
				return <ServerExceptionResult />;
		}
	}

	return <RouterProvider router={router} />;
};

export default KeycloakApp;
