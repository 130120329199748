/**
 * @author Harsh Raj <harsh.raj@314ecorp.com>
 * @description React Exception Result
 */


import { Button, Result, Space, Typography } from 'antd';

interface IProps {
	reload?: () => void;
}

const ReactExceptionResult: React.FC<IProps> = (props) => {
	const reload = () => {
		location.reload();
	};

	return (
		<Result
			title='This page is corrupted!'
			subTitle={
				<Space direction='vertical'>
					<Typography.Text>Uh-oh! Looks like our developer was sleepy while coding this one.</Typography.Text>
				</Space>
			}
			extra={
				<Button key='refresh' onClick={reload}>
					Reload
				</Button>
			}
			{...props}
		/>
	);
};

export default ReactExceptionResult;
