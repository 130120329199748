/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ChannelEnum } from '../Models';
// @ts-ignore
import { GetNovuSubscriberData200Response } from '../Models';
// @ts-ignore
import { IntegrationProviderDetails } from '../Models';
// @ts-ignore
import { NovuResponseModel } from '../Models';
// @ts-ignore
import { NovuWorkflowGroupResponseModel } from '../Models';
// @ts-ignore
import { NovuWorkflowIn } from '../Models';
// @ts-ignore
import { NovuWorkflowModel } from '../Models';
// @ts-ignore
import { NovuWorkflowResponseModel } from '../Models';
// @ts-ignore
import { ProviderIdEnum } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { SubscriberDetails } from '../Models';
/**
 * NovuIntegrationApi - axios parameter creator
 * @export
 */
export const NovuIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIntegrationProvider: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/novuIntegration/getAllIntegrationProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNovuWorkflows: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/novuIntegration/getAllNovuWorkflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNovuSubscriberData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/novuIntegration/getNovuSubscriberData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNovuWorkflowById: async (workflowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getNovuWorkflowById', 'workflowId', workflowId)
            const localVarPath = `/api/v1/novuIntegration/getNovuWorkflowById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (workflowId !== undefined) {
                localVarQueryParameter['workflow_id'] = workflowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNovuWorkflowsGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/novuIntegration/getNovuWorkflowsGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberID: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/novuIntegration/getSubscriberID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/novuIntegration/removeSubscriber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ProviderIdEnum} provider 
         * @param {ChannelEnum} channel 
         * @param {boolean} active 
         * @param {{ [key: string]: any; }} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationProvider: async (id: string, provider: ProviderIdEnum, channel: ChannelEnum, active: boolean, credentials?: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIntegrationProvider', 'id', id)
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('updateIntegrationProvider', 'provider', provider)
            // verify required parameter 'channel' is not null or undefined
            assertParamExists('updateIntegrationProvider', 'channel', channel)
            // verify required parameter 'active' is not null or undefined
            assertParamExists('updateIntegrationProvider', 'active', active)
            const localVarPath = `/api/v1/novuIntegration/updateIntegrationProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (credentials !== undefined) {
                for (const [key, value] of Object.entries(credentials)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {NovuWorkflowIn} novuWorkflowIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNovuWorkflow: async (workflowId: string, novuWorkflowIn: NovuWorkflowIn, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateNovuWorkflow', 'workflowId', workflowId)
            // verify required parameter 'novuWorkflowIn' is not null or undefined
            assertParamExists('updateNovuWorkflow', 'novuWorkflowIn', novuWorkflowIn)
            const localVarPath = `/api/v1/novuIntegration/updateNovuWorkflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (workflowId !== undefined) {
                localVarQueryParameter['workflow_id'] = workflowId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(novuWorkflowIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NovuIntegrationApi - functional programming interface
 * @export
 */
export const NovuIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NovuIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllIntegrationProvider(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationProviderDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllIntegrationProvider(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.getAllIntegrationProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNovuWorkflows(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NovuWorkflowModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNovuWorkflows(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.getAllNovuWorkflows']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNovuSubscriberData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNovuSubscriberData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNovuSubscriberData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.getNovuSubscriberData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNovuWorkflowById(workflowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NovuWorkflowResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNovuWorkflowById(workflowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.getNovuWorkflowById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNovuWorkflowsGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NovuWorkflowGroupResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNovuWorkflowsGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.getNovuWorkflowsGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberID(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberID(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.getSubscriberID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSubscriber(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSubscriber(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.removeSubscriber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ProviderIdEnum} provider 
         * @param {ChannelEnum} channel 
         * @param {boolean} active 
         * @param {{ [key: string]: any; }} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIntegrationProvider(id: string, provider: ProviderIdEnum, channel: ChannelEnum, active: boolean, credentials?: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NovuResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIntegrationProvider(id, provider, channel, active, credentials, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.updateIntegrationProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {NovuWorkflowIn} novuWorkflowIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNovuWorkflow(workflowId: string, novuWorkflowIn: NovuWorkflowIn, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NovuWorkflowResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNovuWorkflow(workflowId, novuWorkflowIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NovuIntegrationApi.updateNovuWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NovuIntegrationApi - factory interface
 * @export
 */
export const NovuIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NovuIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIntegrationProvider(options?: any): AxiosPromise<Array<IntegrationProviderDetails>> {
            return localVarFp.getAllIntegrationProvider(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNovuWorkflows(options?: any): AxiosPromise<Array<NovuWorkflowModel>> {
            return localVarFp.getAllNovuWorkflows(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNovuSubscriberData(options?: any): AxiosPromise<GetNovuSubscriberData200Response> {
            return localVarFp.getNovuSubscriberData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNovuWorkflowById(workflowId: string, options?: any): AxiosPromise<NovuWorkflowResponseModel> {
            return localVarFp.getNovuWorkflowById(workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNovuWorkflowsGroups(options?: any): AxiosPromise<Array<NovuWorkflowGroupResponseModel>> {
            return localVarFp.getNovuWorkflowsGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberID(options?: any): AxiosPromise<SubscriberDetails> {
            return localVarFp.getSubscriberID(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.removeSubscriber(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ProviderIdEnum} provider 
         * @param {ChannelEnum} channel 
         * @param {boolean} active 
         * @param {{ [key: string]: any; }} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationProvider(id: string, provider: ProviderIdEnum, channel: ChannelEnum, active: boolean, credentials?: { [key: string]: any; }, options?: any): AxiosPromise<NovuResponseModel> {
            return localVarFp.updateIntegrationProvider(id, provider, channel, active, credentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {NovuWorkflowIn} novuWorkflowIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNovuWorkflow(workflowId: string, novuWorkflowIn: NovuWorkflowIn, options?: any): AxiosPromise<NovuWorkflowResponseModel> {
            return localVarFp.updateNovuWorkflow(workflowId, novuWorkflowIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NovuIntegrationApi - interface
 * @export
 * @interface NovuIntegrationApi
 */
export interface NovuIntegrationApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    getAllIntegrationProvider(options?: RawAxiosRequestConfig): AxiosPromise<Array<IntegrationProviderDetails>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    getAllNovuWorkflows(options?: RawAxiosRequestConfig): AxiosPromise<Array<NovuWorkflowModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    getNovuSubscriberData(options?: RawAxiosRequestConfig): AxiosPromise<GetNovuSubscriberData200Response>;

    /**
     * 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    getNovuWorkflowById(workflowId: string, options?: RawAxiosRequestConfig): AxiosPromise<NovuWorkflowResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    getNovuWorkflowsGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<NovuWorkflowGroupResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    getSubscriberID(options?: RawAxiosRequestConfig): AxiosPromise<SubscriberDetails>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    removeSubscriber(options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {ProviderIdEnum} provider 
     * @param {ChannelEnum} channel 
     * @param {boolean} active 
     * @param {{ [key: string]: any; }} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    updateIntegrationProvider(id: string, provider: ProviderIdEnum, channel: ChannelEnum, active: boolean, credentials?: { [key: string]: any; }, options?: RawAxiosRequestConfig): AxiosPromise<NovuResponseModel>;

    /**
     * 
     * @param {string} workflowId 
     * @param {NovuWorkflowIn} novuWorkflowIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApiInterface
     */
    updateNovuWorkflow(workflowId: string, novuWorkflowIn: NovuWorkflowIn, options?: RawAxiosRequestConfig): AxiosPromise<NovuWorkflowResponseModel>;

}

/**
 * NovuIntegrationApi - object-oriented interface
 * @export
 * @class NovuIntegrationApi
 * @extends {BaseAPI}
 */
export class NovuIntegrationApi extends BaseAPI implements NovuIntegrationApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public getAllIntegrationProvider(options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).getAllIntegrationProvider(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public getAllNovuWorkflows(options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).getAllNovuWorkflows(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public getNovuSubscriberData(options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).getNovuSubscriberData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public getNovuWorkflowById(workflowId: string, options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).getNovuWorkflowById(workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public getNovuWorkflowsGroups(options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).getNovuWorkflowsGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public getSubscriberID(options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).getSubscriberID(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public removeSubscriber(options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).removeSubscriber(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ProviderIdEnum} provider 
     * @param {ChannelEnum} channel 
     * @param {boolean} active 
     * @param {{ [key: string]: any; }} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public updateIntegrationProvider(id: string, provider: ProviderIdEnum, channel: ChannelEnum, active: boolean, credentials?: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).updateIntegrationProvider(id, provider, channel, active, credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowId 
     * @param {NovuWorkflowIn} novuWorkflowIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovuIntegrationApi
     */
    public updateNovuWorkflow(workflowId: string, novuWorkflowIn: NovuWorkflowIn, options?: RawAxiosRequestConfig) {
        return NovuIntegrationApiFp(this.configuration).updateNovuWorkflow(workflowId, novuWorkflowIn, options).then((request) => request(this.axios, this.basePath));
    }
}

