/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Get URL and fields to upload
 */

import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import api from 'client/index';

const QUERY_UPLOAD_FILE = 'query-uploadfile';

export interface UploadFile {
	fileNames?: string[];
	blob?: Blob[];
	setProgress?: (progress: number) => void;
}

const useUploadFile = (): UseMutationResult<AxiosResponse<any, any>[], unknown, UploadFile, string> => {
	return useMutation({
		mutationKey: [QUERY_UPLOAD_FILE],
		mutationFn: async ({ fileNames, blob, setProgress }) => {
			if (!fileNames || !blob) {
				throw new Error(fileNames ? 'Blob Not Found' : 'fileName not found');
			}

			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent: any) => {
					const percent = _.round((progressEvent.loaded / progressEvent.total) * 100);
					if (setProgress) {
						setProgress(percent);
					}
				},
			};

			const { data } = await api.DocumentApi.presignedURLupload({ file_names: fileNames });
			return await axios.all(_.map(data, (file, index) => axios.put(file.url, blob[index], config)));
		},
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useUploadFile;
