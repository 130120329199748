/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Image Viewer
 */

import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Space, Tooltip } from 'antd';
import { ZoomIn, ZoomOut } from '@icon-park/react';

interface IProps {
	src?: string;
}

const ImageViewer: React.FC<IProps> = (props) => {
	const { src } = props;

	const [scale, setScale] = useState(1);

	const zoomIn = () => {
		setScale((prevScale) => _.round(prevScale + 0.5, 1));
	};

	const zoomOut = () => {
		setScale((prevScale) => Math.max(_.round(prevScale - 0.5, 1), 0.5));
	};

	return (
		<div className='flex-column full-width-height'>
			<Space className='flex-end' style={{ backgroundColor: '#F9F9FA' }}>
				<Tooltip title='Zoomin' placement='bottom'>
					<Button
						className='grey-color'
						shape='circle'
						type='text'
						icon={<ZoomIn size='16' />}
						onClick={zoomIn}
					/>
				</Tooltip>
				<Tooltip title='Zoomout' placement='bottom'>
					<Button
						className='grey-color'
						shape='circle'
						type='text'
						icon={<ZoomOut size='16' />}
						onClick={zoomOut}
					/>
				</Tooltip>
			</Space>
			<div className='flex-center' style={{ backgroundColor: '#EBEEF0', flex: 1, overflow: 'auto' }}>
				<img alt='' src={src} style={{ transform: `scale(${scale})`, transition: 'transform 0.3s' }} />
			</div>
		</div>
	);
};

export default ImageViewer;
