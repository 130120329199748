/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Create a new Queue or update the existing Queue
 */

import log from 'loglevel';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import EventBus, { Events } from '@dexit/common/utils/EventBus';
import api from 'client/index';
import { QUERY_QUEUE } from 'hooks/useQueue';
import { ResponseModel, QueueRequestModel } from '@dexit/common/openapi';

const useCreateQueue = (): UseMutationResult<ResponseModel, unknown, QueueRequestModel, unknown> => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (payload) => {
			const { data } = await api.QueueApi.createQueue(payload);
			return data;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries([QUERY_QUEUE]).catch((err) => log.error(err));
			EventBus.publish(Events.M_Success, data.message);
		},
		onError: (error) => {
			EventBus.publish(Events.M_Error, error);
		},
	});
};

export default useCreateQueue;
