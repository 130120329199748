/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Draggable Stages
 */

import React, { useState } from 'react';
import _ from 'lodash';
import { Color } from 'antd/es/color-picker';
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { List } from 'antd';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import EditableListItem from './EditableListItem';
import StageListItem from './StageListItem';
import { defaultIconColor } from './QueuesIndexingStages';
import { useManageQueueActions, useManageQueueValues } from 'store/manageQueue';

const DynamicStages: React.FC = () => {
	const { stageList } = useManageQueueValues();
	const { setStageList, setDisabled, addFieldChanged } = useManageQueueActions();
	const [stageName, setStageName] = useState('');
	const [color, setColor] = useState<Color | string>(defaultIconColor);

	const handleOnDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (active.id === over?.id) {
			return;
		}
		const oldIndex = _.findIndex(stageList, (stage) => stage.id === active.id);
		const newIndex = _.findIndex(stageList, (stage) => stage.id === over?.id);
		const newStageList = arrayMove(stageList, oldIndex, newIndex);
		setStageList(newStageList);
		setDisabled(false);
		addFieldChanged('indexingStages');
	};

	return (
		<DndContext collisionDetection={closestCenter} onDragEnd={handleOnDragEnd}>
			<SortableContext items={stageList} strategy={verticalListSortingStrategy}>
				<List
					dataSource={stageList}
					renderItem={(stage, index) =>
						stage.isEditing ? (
							<EditableListItem
								key={index}
								index={index}
								stage={stage}
								color={color}
								stageName={stageName}
								onColorChange={setColor}
								onStageNameChange={setStageName}
							/>
						) : (
							<StageListItem
								key={index}
								stage={stage}
								index={index}
								onColorChange={setColor}
								onStageNameChange={setStageName}
							/>
						)
					}
				/>
			</SortableContext>
		</DndContext>
	);
};

export default DynamicStages;
