/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Word Outlined
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
}

const WordOutlined: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-wordoutlined', className);
	return (
		<span role='img' aria-label='Wordoutlined' className={cn} {...restProps}>
			<svg
				width='14'
				height='15'
				viewBox='0 0 14 15'
				fill='none'
				aria-label='Wordoutlined'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M2.91667 1.00781C2.27233 1.00781 1.75 1.53015 1.75 2.17448V12.6745C1.75 13.3188 2.27234 13.8411 2.91667 13.8411H11.0833C11.7277 13.8411 12.25 13.3188 12.25 12.6745V4.50781C12.25 4.3531 12.1885 4.20473 12.0791 4.09533L9.16248 1.17867C9.05308 1.06927 8.90471 1.00781 8.75 1.00781H2.91667ZM2.91667 2.17448H8.50838L11.0833 4.74944V12.6745H2.91667V2.17448ZM5.23947 6.13559C5.17197 5.82057 4.86188 5.61992 4.54686 5.68743C4.23185 5.75493 4.0312 6.06502 4.0987 6.38004L4.9737 10.4634C5.02561 10.7056 5.22486 10.8886 5.47062 10.9198C5.71638 10.951 5.95505 10.8236 6.06584 10.602L7.00242 8.72885L7.939 10.602C8.0498 10.8236 8.28846 10.951 8.53422 10.9198C8.77998 10.8886 8.97923 10.7056 9.03114 10.4634L9.90614 6.38004C9.97364 6.06502 9.77299 5.75493 9.45798 5.68743C9.14296 5.61992 8.83287 5.82057 8.76537 6.13559L8.23881 8.59288L7.52417 7.1636C7.42536 6.96598 7.22337 6.84115 7.00242 6.84115C6.78147 6.84115 6.57948 6.96598 6.48067 7.1636L5.76604 8.59288L5.23947 6.13559Z'
					fill='#2F54EB'
				/>
			</svg>
		</span>
	);
};

export default WordOutlined;
