/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description BulkImport Icon
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
}

const BulkImportOutlined: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-bulkImport', className);
	return (
		<span role='img' aria-label='BulkImport' className={cn} {...restProps}>
			<svg
				aria-label='BulkImport'
				width='14'
				height='14'
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M2.93372 0.333984C2.63916 0.333984 2.40039 0.572766 2.40039 0.867318C2.40039 1.16188 2.63916 1.40065 2.93372 1.40065H13.0671C13.3616 1.40065 13.6004 1.16188 13.6004 0.867318C13.6004 0.572766 13.3616 0.333984 13.0671 0.333984H2.93372Z'
					fill='#9098A7'
				/>
				<path
					d='M1.86634 3C1.57179 3 1.33301 3.23877 1.33301 3.53333C1.33301 3.82789 1.57179 4.06667 1.86634 4.06667H14.133C14.4276 4.06667 14.6663 3.82789 14.6663 3.53333C14.6663 3.23877 14.4276 3 14.133 3H1.86634Z'
					fill='#9098A7'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0.5 8.33464C0.5 6.86187 1.39543 5.66797 2.5 5.66797H13.5C14.6046 5.66797 15.5 6.86187 15.5 8.33464V13.0013C15.5 14.4741 14.6046 15.668 13.5 15.668H2.5C1.39543 15.668 0.5 14.4741 0.5 13.0013V8.33464ZM9.75 10.668C10.5784 10.668 11.25 9.77254 11.25 8.66797C11.25 8.29977 11.0261 8.0013 10.75 8.0013C10.4739 8.0013 10.25 8.29977 10.25 8.66797C10.25 9.03617 10.0261 9.33464 9.75 9.33464H6.25C5.97385 9.33464 5.75 9.03617 5.75 8.66797C5.75 8.29977 5.52615 8.0013 5.25 8.0013C4.97385 8.0013 4.75 8.29977 4.75 8.66797C4.75 9.77254 5.42158 10.668 6.25 10.668H9.75Z'
					fill='#9098A7'
				/>
			</svg>
		</span>
	);
};

export default BulkImportOutlined;
