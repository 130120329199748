/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Image Outlined
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
}

const ImageOutlined: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-imageoutlined', className);
	return (
		<span role='img' aria-label='Imageoutlined' className={cn} {...restProps}>
			<svg
				width='14'
				height='15'
				viewBox='0 0 14 15'
				fill='none'
				aria-label='Imageoutlined'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1.16703 3.04427C1.16703 2.23886 1.81995 1.58594 2.62536 1.58594H11.3754C12.1808 1.58594 12.8337 2.23886 12.8337 3.04427V10.9172C12.8337 10.9187 12.8337 10.9202 12.8337 10.9217V11.7943C12.8337 12.5997 12.1808 13.2526 11.3754 13.2526H2.62536C1.81995 13.2526 1.16703 12.5997 1.16703 11.7943V10.6344C1.16698 10.6302 1.16698 10.6259 1.16703 10.6217V3.04427ZM2.33369 10.9193V11.7943C2.33369 11.9554 2.46427 12.0859 2.62536 12.0859H11.3754C11.5365 12.0859 11.667 11.9554 11.667 11.7943V11.1773L9.04006 8.78917L6.51559 11.0612C6.29775 11.2573 5.96825 11.2612 5.74573 11.0705L4.05935 9.62503L2.33369 10.9193ZM11.667 9.60062L9.43442 7.57097C9.21275 7.36946 8.87447 7.36861 8.6518 7.56902L6.11597 9.85126L4.46332 8.4347C4.25573 8.25677 3.95242 8.24689 3.73369 8.41094L2.33369 9.46094V3.04427C2.33369 2.88319 2.46428 2.7526 2.62536 2.7526H11.3754C11.5364 2.7526 11.667 2.88318 11.667 3.04427V9.60062ZM3.20869 5.66927C3.20869 4.5417 4.12279 3.6276 5.25036 3.6276C6.37793 3.6276 7.29203 4.5417 7.29203 5.66927C7.29203 6.79685 6.37793 7.71094 5.25036 7.71094C4.12279 7.71094 3.20869 6.79685 3.20869 5.66927ZM5.25036 4.79427C4.76712 4.79427 4.37536 5.18603 4.37536 5.66927C4.37536 6.15251 4.76712 6.54427 5.25036 6.54427C5.7336 6.54427 6.12536 6.15251 6.12536 5.66927C6.12536 5.18603 5.7336 4.79427 5.25036 4.79427Z'
					fill='#F759AB'
				/>
			</svg>
		</span>
	);
};

export default ImageOutlined;
