/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Indexing stages
 */

import _ from 'lodash';
import { Button, Card, List, Space, Tooltip, Typography } from 'antd';
import { CheckCircleFilled, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import DynamicStages from './DynamicStages';
import TodoCircleOutlined from 'components/icons/TodoCircleOutlined';
import { QueueResponseModel } from '@dexit/common/openapi';
import { useManageQueueActions, useManageQueueValues } from 'store/manageQueue';

export const defaultIconColor = '#FFA940';
export const initialStageName = 'Initial Stage';
export const commitStageName = 'Commit';

interface IProps {
	loading: boolean;
	queueData?: QueueResponseModel;
}

const QueuesIndexingStages: React.FC<IProps> = ({ loading, queueData }) => {
	const { stageList } = useManageQueueValues();
	const { setDisabled, addStage, addFieldChanged } = useManageQueueActions();

	const handleAddStage = () => {
		addStage({
			name: '',
			id: uuidv4(),
			icon_color: defaultIconColor,
			issystem: false,
			iscommit: false,
			isEditing: true,
			fromAddButton: true,
		});
		setDisabled(false);
		addFieldChanged('indexingStages');
	};

	return (
		<Card
			loading={loading}
			styles={{ body: { padding: 0, borderTop: '1px solid #E8EFEF' } }}
			title={
				<Space>
					Work Stages
					<Tooltip title='Stages define the type and order of statuses that documents go through from start to completion. Here you can customize & re-order the available stages. '>
						<InfoCircleOutlined />
					</Tooltip>
				</Space>
			}
		>
			<List.Item
				key='todo'
				style={{
					height: 54,
					padding: '8px 16px',
					borderBottom: '1px solid #e8efef',
					backgroundColor: '#FAFAFA',
				}}
				className='flex-space-between'
			>
				<Space size={16} style={{ marginLeft: 'calc(1em + 16px)' }}>
					<TodoCircleOutlined />
					{_.find(queueData?.stages, { sort_index: 1 })?.name ?? initialStageName}
				</Space>
				<Typography.Text style={{ fontWeight: 400, color: '#00000073' }}>Initial Stage</Typography.Text>
			</List.Item>
			{!_.isEmpty(stageList) && <DynamicStages />}
			<List.Item
				key='add-stage'
				className='flex-center'
				style={{
					height: 54,
					padding: '8px 16px',
					borderTop: _.isEmpty(stageList) ? undefined : '1px solid #e8efef',
				}}
			>
				<Button
					style={{ color: '#323837' }}
					icon={<PlusOutlined />}
					onClick={handleAddStage}
					disabled={_.some(stageList, 'isEditing')}
				>
					Add Stage
				</Button>
			</List.Item>
			<List.Item
				key='committed'
				style={{
					height: 54,
					padding: '8px 16px',
					display: 'flex',
					backgroundColor: '#FAFAFA',
					borderTop: '1px solid #e8efef',
					borderRadius: '0px 0px 10px 10px',
				}}
			>
				<Space size={16} style={{ marginLeft: 'calc(1em + 16px)' }}>
					<CheckCircleFilled style={{ color: '#1890FF' }} />
					{_.find(queueData?.stages, { iscommit: true })?.name ?? commitStageName}
				</Space>
			</List.Item>
		</Card>
	);
};

export default QueuesIndexingStages;
