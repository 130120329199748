/**
 * sessionKeys for app
 */

const SESSION_KEYS = {
	DO_NOT_ASK_FOR_PUBLISH_UNPUBLISH_ASSET: 'DO_NOT_ASK_FOR_PUBLISH_UNPUBLISH_ASSET',
	AUTH_CLIENT: 'AUTH_CLIENT',
} as const;

export default SESSION_KEYS;
