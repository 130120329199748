/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Video Outlined
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
}

const VideoOutlined: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-videooutlined', className);
	return (
		<span role='img' aria-label='Videooutlined' className={cn} {...restProps}>
			<svg
				width='14'
				height='15'
				viewBox='0 0 14 15'
				fill='none'
				aria-label='Videooutlined'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6.13436 1.586H9.61736H9.63436H11.3753C12.1808 1.586 12.8337 2.23892 12.8337 3.04433V4.79433V11.7943C12.8337 12.5998 12.1808 13.2527 11.3753 13.2527H2.62533C1.81992 13.2527 1.16699 12.5998 1.16699 11.7943V4.79433V3.04433C1.16699 2.23892 1.81991 1.586 2.62533 1.586H6.11736C6.12302 1.58592 6.12869 1.58592 6.13436 1.586ZM5.46529 4.211L6.43752 2.75267H8.53536L7.56314 4.211H5.46529ZM4.3663 5.37767C4.37196 5.37775 4.37762 5.37775 4.38328 5.37767H7.8663H7.88328H11.667V11.7943C11.667 11.9554 11.5364 12.086 11.3753 12.086H2.62533C2.46424 12.086 2.33366 11.9554 2.33366 11.7943V5.37767H4.3663ZM4.06314 4.211H2.33366V3.04433C2.33366 2.88325 2.46424 2.75267 2.62533 2.75267H5.03536L4.06314 4.211ZM9.93752 2.75267L8.96529 4.211H11.667V3.04433C11.667 2.88324 11.5364 2.75267 11.3753 2.75267H9.93752ZM5.68783 6.31268C5.86831 6.20847 6.09068 6.20847 6.27116 6.31268L9.33366 8.08082C9.51414 8.18502 9.62533 8.37759 9.62533 8.586C9.62533 8.7944 9.51414 8.98698 9.33366 9.09118L6.27116 10.8593C6.09068 10.9635 5.86831 10.9635 5.68783 10.8593C5.50734 10.7551 5.39616 10.5625 5.39616 10.3541V6.81786C5.39616 6.60945 5.50734 6.41688 5.68783 6.31268ZM6.56283 9.34378L7.87533 8.586L6.56283 7.82822V9.34378Z'
					fill='#FA8C16'
				/>
			</svg>
		</span>
	);
};

export default VideoOutlined;
