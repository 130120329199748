/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
/**
 * EPICIntegrationApi - axios parameter creator
 * @export
 */
export const EPICIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {string} grantType 
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        epicToken: async (code: string, grantType: string, redirectUri: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('epicToken', 'code', code)
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('epicToken', 'grantType', grantType)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('epicToken', 'redirectUri', redirectUri)
            const localVarPath = `/public/api/v1/epicIntegration/epicToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (grantType !== undefined) {
                localVarQueryParameter['grant_type'] = grantType;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        epicintegrationLinkEpicKeycloakUser: async (redirectUri: string, requestBody: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('epicintegrationLinkEpicKeycloakUser', 'redirectUri', redirectUri)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('epicintegrationLinkEpicKeycloakUser', 'requestBody', requestBody)
            const localVarPath = `/public/api/v1/epicIntegration/linkEpicKeycloakUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EPICIntegrationApi - functional programming interface
 * @export
 */
export const EPICIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EPICIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {string} grantType 
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async epicToken(code: string, grantType: string, redirectUri: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.epicToken(code, grantType, redirectUri, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EPICIntegrationApi.epicToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async epicintegrationLinkEpicKeycloakUser(redirectUri: string, requestBody: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.epicintegrationLinkEpicKeycloakUser(redirectUri, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EPICIntegrationApi.epicintegrationLinkEpicKeycloakUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EPICIntegrationApi - factory interface
 * @export
 */
export const EPICIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EPICIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {string} grantType 
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        epicToken(code: string, grantType: string, redirectUri: string, options?: any): AxiosPromise<any> {
            return localVarFp.epicToken(code, grantType, redirectUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        epicintegrationLinkEpicKeycloakUser(redirectUri: string, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<any> {
            return localVarFp.epicintegrationLinkEpicKeycloakUser(redirectUri, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EPICIntegrationApi - interface
 * @export
 * @interface EPICIntegrationApi
 */
export interface EPICIntegrationApiInterface {
    /**
     * 
     * @param {string} code 
     * @param {string} grantType 
     * @param {string} redirectUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EPICIntegrationApiInterface
     */
    epicToken(code: string, grantType: string, redirectUri: string, options?: RawAxiosRequestConfig): AxiosPromise<any>;

    /**
     * 
     * @param {string} redirectUri 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EPICIntegrationApiInterface
     */
    epicintegrationLinkEpicKeycloakUser(redirectUri: string, requestBody: { [key: string]: any; }, options?: RawAxiosRequestConfig): AxiosPromise<any>;

}

/**
 * EPICIntegrationApi - object-oriented interface
 * @export
 * @class EPICIntegrationApi
 * @extends {BaseAPI}
 */
export class EPICIntegrationApi extends BaseAPI implements EPICIntegrationApiInterface {
    /**
     * 
     * @param {string} code 
     * @param {string} grantType 
     * @param {string} redirectUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EPICIntegrationApi
     */
    public epicToken(code: string, grantType: string, redirectUri: string, options?: RawAxiosRequestConfig) {
        return EPICIntegrationApiFp(this.configuration).epicToken(code, grantType, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} redirectUri 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EPICIntegrationApi
     */
    public epicintegrationLinkEpicKeycloakUser(redirectUri: string, requestBody: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return EPICIntegrationApiFp(this.configuration).epicintegrationLinkEpicKeycloakUser(redirectUri, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}

