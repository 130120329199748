/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BatchRequestModel } from '../Models';
// @ts-ignore
import { CreateDraftsResponseModel } from '../Models';
// @ts-ignore
import { DraftsRequestModel } from '../Models';
// @ts-ignore
import { ExtractDraftTextRequestModel } from '../Models';
// @ts-ignore
import { GetBatchesResponseModel } from '../Models';
// @ts-ignore
import { GetDraftsResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
/**
 * BatchScanningApi - axios parameter creator
 * @export
 */
export const BatchScanningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BatchRequestModel} batchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatch: async (batchRequestModel: BatchRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchRequestModel' is not null or undefined
            assertParamExists('createBatch', 'batchRequestModel', batchRequestModel)
            const localVarPath = `/api/v1/batchScanning/createBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DraftsRequestModel} draftsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createdrafts: async (draftsRequestModel: DraftsRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'draftsRequestModel' is not null or undefined
            assertParamExists('createdrafts', 'draftsRequestModel', draftsRequestModel)
            const localVarPath = `/api/v1/batchScanning/createDrafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(draftsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch: async (batchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('deleteBatch', 'batchId', batchId)
            const localVarPath = `/api/v1/batchScanning/deleteBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batch_id'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrafts: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDrafts', 'id', id)
            const localVarPath = `/api/v1/batchScanning/deleteDrafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteFiles', 'requestBody', requestBody)
            const localVarPath = `/api/v1/batchScanning/deleteFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExtractDraftTextRequestModel} extractDraftTextRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractDraftText: async (extractDraftTextRequestModel: ExtractDraftTextRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extractDraftTextRequestModel' is not null or undefined
            assertParamExists('extractDraftText', 'extractDraftTextRequestModel', extractDraftTextRequestModel)
            const localVarPath = `/api/v1/batchScanning/extractDraftText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extractDraftTextRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} batchId 
         * @param {string} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileProcessing: async (batchId: string, operation?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('fileProcessing', 'batchId', batchId)
            const localVarPath = `/api/v1/batchScanning/fileProcessing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batch_id'] = batchId;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [batchIds] 
         * @param {string} [queueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatches: async (batchIds?: Array<string>, queueId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batchScanning/getBatches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (batchIds) {
                localVarQueryParameter['batch_ids'] = batchIds;
            }

            if (queueId !== undefined) {
                localVarQueryParameter['queue_id'] = queueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [batchId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts: async (id?: string, batchId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batchScanning/getDrafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batch_id'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isLock] 
         * @param {string} [batchId] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockBatch: async (isLock?: boolean, batchId?: string, all?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batchScanning/lockBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (isLock !== undefined) {
                localVarQueryParameter['is_lock'] = isLock;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batch_id'] = batchId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all_'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchScanningApi - functional programming interface
 * @export
 */
export const BatchScanningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchScanningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BatchRequestModel} batchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatch(batchRequestModel: BatchRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatch(batchRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.createBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DraftsRequestModel} draftsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createdrafts(draftsRequestModel: DraftsRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDraftsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createdrafts(draftsRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.createdrafts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatch(batchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatch(batchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.deleteBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDrafts(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDrafts(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.deleteDrafts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFiles(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFiles(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.deleteFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ExtractDraftTextRequestModel} extractDraftTextRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extractDraftText(extractDraftTextRequestModel: ExtractDraftTextRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extractDraftText(extractDraftTextRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.extractDraftText']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} batchId 
         * @param {string} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileProcessing(batchId: string, operation?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileProcessing(batchId, operation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.fileProcessing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [batchIds] 
         * @param {string} [queueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatches(batchIds?: Array<string>, queueId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetBatchesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatches(batchIds, queueId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.getBatches']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [batchId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrafts(id?: string, batchId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDraftsResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrafts(id, batchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.getDrafts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isLock] 
         * @param {string} [batchId] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockBatch(isLock?: boolean, batchId?: string, all?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockBatch(isLock, batchId, all, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BatchScanningApi.lockBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BatchScanningApi - factory interface
 * @export
 */
export const BatchScanningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchScanningApiFp(configuration)
    return {
        /**
         * 
         * @param {BatchRequestModel} batchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatch(batchRequestModel: BatchRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createBatch(batchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DraftsRequestModel} draftsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createdrafts(draftsRequestModel: DraftsRequestModel, options?: any): AxiosPromise<CreateDraftsResponseModel> {
            return localVarFp.createdrafts(draftsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch(batchId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteBatch(batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrafts(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDrafts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFiles(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExtractDraftTextRequestModel} extractDraftTextRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractDraftText(extractDraftTextRequestModel: ExtractDraftTextRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.extractDraftText(extractDraftTextRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} batchId 
         * @param {string} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileProcessing(batchId: string, operation?: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.fileProcessing(batchId, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [batchIds] 
         * @param {string} [queueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatches(batchIds?: Array<string>, queueId?: string, options?: any): AxiosPromise<Array<GetBatchesResponseModel>> {
            return localVarFp.getBatches(batchIds, queueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [batchId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrafts(id?: string, batchId?: string, options?: any): AxiosPromise<Array<GetDraftsResponseModel>> {
            return localVarFp.getDrafts(id, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isLock] 
         * @param {string} [batchId] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockBatch(isLock?: boolean, batchId?: string, all?: boolean, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.lockBatch(isLock, batchId, all, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchScanningApi - interface
 * @export
 * @interface BatchScanningApi
 */
export interface BatchScanningApiInterface {
    /**
     * 
     * @param {BatchRequestModel} batchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    createBatch(batchRequestModel: BatchRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {DraftsRequestModel} draftsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    createdrafts(draftsRequestModel: DraftsRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<CreateDraftsResponseModel>;

    /**
     * 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    deleteBatch(batchId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    deleteDrafts(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    deleteFiles(requestBody: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {ExtractDraftTextRequestModel} extractDraftTextRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    extractDraftText(extractDraftTextRequestModel: ExtractDraftTextRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} batchId 
     * @param {string} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    fileProcessing(batchId: string, operation?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {Array<string>} [batchIds] 
     * @param {string} [queueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    getBatches(batchIds?: Array<string>, queueId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetBatchesResponseModel>>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [batchId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    getDrafts(id?: string, batchId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetDraftsResponseModel>>;

    /**
     * 
     * @param {boolean} [isLock] 
     * @param {string} [batchId] 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApiInterface
     */
    lockBatch(isLock?: boolean, batchId?: string, all?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

}

/**
 * BatchScanningApi - object-oriented interface
 * @export
 * @class BatchScanningApi
 * @extends {BaseAPI}
 */
export class BatchScanningApi extends BaseAPI implements BatchScanningApiInterface {
    /**
     * 
     * @param {BatchRequestModel} batchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public createBatch(batchRequestModel: BatchRequestModel, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).createBatch(batchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DraftsRequestModel} draftsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public createdrafts(draftsRequestModel: DraftsRequestModel, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).createdrafts(draftsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public deleteBatch(batchId: string, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).deleteBatch(batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public deleteDrafts(id: string, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).deleteDrafts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public deleteFiles(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).deleteFiles(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtractDraftTextRequestModel} extractDraftTextRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public extractDraftText(extractDraftTextRequestModel: ExtractDraftTextRequestModel, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).extractDraftText(extractDraftTextRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} batchId 
     * @param {string} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public fileProcessing(batchId: string, operation?: string, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).fileProcessing(batchId, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [batchIds] 
     * @param {string} [queueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public getBatches(batchIds?: Array<string>, queueId?: string, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).getBatches(batchIds, queueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [batchId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public getDrafts(id?: string, batchId?: string, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).getDrafts(id, batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isLock] 
     * @param {string} [batchId] 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchScanningApi
     */
    public lockBatch(isLock?: boolean, batchId?: string, all?: boolean, options?: RawAxiosRequestConfig) {
        return BatchScanningApiFp(this.configuration).lockBatch(isLock, batchId, all, options).then((request) => request(this.axios, this.basePath));
    }
}

