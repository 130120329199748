/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description api to get search data by entity
 */

import _ from 'lodash';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import api from 'client/index';
import {
	AllUserResponseModel,
	DocumentTypeSearchResponseModel,
	EncounterSearchResponseModel,
	EntitySearchRequestModel,
	PatientSearchResponseModel,
} from '@dexit/common/openapi';

const QUERY_ENTITY_SEARCH = 'query-entity-search';

type AllUserResponseModelWithType = AllUserResponseModel & { type: string };
type EncounterSearchResponseModelWithType = EncounterSearchResponseModel & { type: string };
type DocumentTypeSearchResponseModelWithType = DocumentTypeSearchResponseModel & { type: string };
type PatientSearchResponseModelWithType = PatientSearchResponseModel & { type: string };

export interface EntitySearchResponseModel {
	documenttype: DocumentTypeSearchResponseModelWithType[];
	patient: PatientSearchResponseModelWithType[];
	encounter: EncounterSearchResponseModelWithType[];
	user: AllUserResponseModelWithType[];
}

const useEntitySearch = (
	payload: EntitySearchRequestModel,
	options?: UseQueryOptions<Promise<EntitySearchResponseModel>, unknown, EntitySearchResponseModel>,
): UseQueryResult<EntitySearchResponseModel, unknown> => {
	return useQuery({
		queryKey: [QUERY_ENTITY_SEARCH, payload],
		queryFn: async () => {
			const { data } = await api.SearchApi.entitySearch(payload);
			return _.reduce(
				data,
				(acc: any, records, type) => {
					acc[type] = _.map(
						records,
						(
							item:
								| DocumentTypeSearchResponseModel
								| PatientSearchResponseModel
								| EncounterSearchResponseModel
								| AllUserResponseModel,
						) => ({ ...item, type }),
					);
					return acc;
				},
				{},
			);
		},
		...options,
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useEntitySearch;
export { QUERY_ENTITY_SEARCH };
