/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description advance search utils
 */

import _, { Many } from 'lodash';
import { JsonGroup, JsonItem, JsonTree, Utils } from '@react-awesome-query-builder/antd';

import { ENTITY } from 'constants/search';
import { ResourceEnum } from '@dexit/common/openapi';

/* Advance Search constants */
enum FIELDS {
	DOCUMENT_TYPE = 'documentType',
	PATIENT = 'patient',
	ENCOUNTER = 'encounter',
	USER = 'uploadedBy',
	DOCUMENT_ID = 'documentId',
	UPLOADED_ON = 'uploadedOn',
}

/* Advance Search Utils */
const getField = (item: any): string => {
	switch (item.type) {
		case ENTITY.DOCUMENT_TYPES:
			return 'documentType';
		case ENTITY.PATIENTS:
			return 'patient.mrn';
		case ENTITY.ENCOUNTERS:
			return 'encounter.id';
		case ENTITY.USERS:
			return 'username';
		default:
			return '';
	}
};
const getResource = (field: string): ResourceEnum | undefined => {
	switch (field as FIELDS) {
		case FIELDS.DOCUMENT_TYPE:
			return ResourceEnum.Documenttype;
		case FIELDS.PATIENT:
			return ResourceEnum.Patient;
		case FIELDS.ENCOUNTER:
			return ResourceEnum.Encounter;
		case FIELDS.USER:
			return ResourceEnum.User;
		default:
			return undefined;
	}
};

const getStringBeforeCursor = (inputString: string, cursorPosition: number | null): string => {
	if (!cursorPosition || inputString.length === 0) {
		return '';
	}
	const nextQuoteIndex = inputString.indexOf("'", cursorPosition);
	const previousQuoteIndex = inputString.lastIndexOf("'", cursorPosition - 1);
	const endQuoteIndex = nextQuoteIndex === -1 ? inputString.length : nextQuoteIndex;
	if (previousQuoteIndex === -1) {
		return '';
	}
	return inputString.substring(previousQuoteIndex + 1, endQuoteIndex);
};

const getEntityText = (item: any): string => {
	switch (item.type) {
		case ENTITY.TEXT:
			return item.text;
		case ENTITY.DOCUMENT_TYPES:
			return item.name;
		case ENTITY.PATIENTS:
			return item.mrn;
		case ENTITY.ENCOUNTERS:
			return item.encounter_number;
		case ENTITY.USERS:
			return item.username;
		default:
			return '';
	}
};

const getEntity = (inputString: string, cursorPosition: number | null): ResourceEnum | undefined => {
	if (!cursorPosition || cursorPosition > inputString.length) {
		return undefined;
	}
	const str = inputString.substring(0, cursorPosition + 1);
	const operators = ['==', '<', '>', '!=', '<=', '>='];
	const words = _.values(FIELDS) as string[];

	const splitStr = str.match(/'[^']+[^\s]|[^\s]+/g) ?? [];
	const lastIndex = splitStr.length - 1;

	if (splitStr[lastIndex].startsWith("'") && lastIndex >= 2) {
		const operatorIndex = lastIndex - 1;
		const wordIndex = operatorIndex - 1;
		const foundWord = words.find((word) => splitStr[wordIndex].startsWith(word));
		if (operators.includes(splitStr[operatorIndex]) && foundWord) {
			return getResource(foundWord);
		}
	}
	return undefined;
};

const addSelectedEntityToText = (inputString: string, item: any, cursorPosition: number): string => {
	if (!cursorPosition || inputString.length === 0) {
		return inputString;
	}

	const nextQuoteIndex = inputString.indexOf("'", cursorPosition);
	const endQuoteIndex = nextQuoteIndex === -1 ? inputString.length : nextQuoteIndex;

	const entity = getEntity(inputString, cursorPosition);

	if (_.isUndefined(entity)) {
		return `${getField(item)} == '${getEntityText(item)}' `;
	}

	const str = inputString.substring(0, endQuoteIndex);
	const splitStr = str.match(/'[^']+[^\s]|[^\s]+/g) ?? [];
	const endsWithQuote = splitStr[splitStr.length - 1].endsWith("'");

	if (splitStr.length >= 3) {
		splitStr.splice(-3, 3, getField(item), '==', `'${getEntityText(item)}'${endsWithQuote ? '' : ' '}`);
	} else {
		splitStr.splice(0, 3, getField(item), '==', `'${getEntityText(item)}' `);
	}

	return splitStr.join(' ');
};

/* RAQB Utils*/
const filterQuery = (query: JsonTree): Record<string, any> => {
	let result: JsonGroup['children1'] = [];

	const children1 = _.get(query, 'children1') as any;
	_.forEach(children1, (values) => {
		if (_.has(values, 'children1')) {
			const childResult = filterQuery(values);
			if (!_.isEmpty(childResult)) {
				result = _.concat(result, childResult);
			}
			return;
		}

		const { field, value, operator, valueSrc } = _.get(values, 'properties');
		if (field !== null && !_.isNil(operator) && _.size(_.without(value, undefined, null)) === _.size(valueSrc)) {
			result = _.concat(result as Many<JsonItem>, values);
		}
	});

	if (_.isEmpty(result)) {
		return {};
	}

	return {
		...query,
		children1: result,
	};
};

const defaultRAQBQuery: JsonTree = {
	id: Utils.uuid(),
	type: 'group',
	children1: [
		{
			type: 'rule',
			id: Utils.uuid(),
			properties: {
				field: null,
				operator: null,
				value: [],
				valueSrc: [],
				valueError: [],
			},
		},
	],
};
export {
	defaultRAQBQuery,
	filterQuery,
	getStringBeforeCursor,
	getEntityText,
	getField,
	addSelectedEntityToText,
	getEntity,
};
