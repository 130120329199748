/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Retrieve Context
 */

import { createContext } from 'react';
import { PaginationConfig } from 'antd/es/pagination';
import { ColumnStateParams } from 'ag-grid-community';

import { AdvancedSearchRequestModel } from '@dexit/common/openapi';
import { defaultQuery } from 'hooks/useSearchState';

export interface SearchStateContextType {
	query: AdvancedSearchRequestModel;
	setQuery: React.Dispatch<React.SetStateAction<AdvancedSearchRequestModel>>;
	setPaginationAndSort: (
		pagination?: PaginationConfig,
		sorter?: { field?: string; order?: ColumnStateParams['sort'] },
	) => void;
}

export const RetrieveContext = createContext<SearchStateContextType>({
	query: defaultQuery,
	setQuery: () => {},
	setPaginationAndSort: () => {},
});
