/**
 * @author Goutam Shetty <goutam.shetty@314ecorp.com>
 * @description Access Forbidden
 */


import classnames from 'classnames';

const AccessForbiddenResult: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-access-forbidden', className);
	return (
		<span role='img' aria-label='Acess-forbidden' className={cn} {...restProps}>
			<svg
				aria-label='Access-forbidden'
				width='251'
				height='136'
				viewBox='0 0 251 136'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<mask
					id='mask0_8227_147045'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='4'
					width='251'
					height='121'
				>
					<path d='M250.002 4.80641H0.998003V124.17H250.002V4.80641Z' fill='white' />
				</mask>
				<g mask='url(#mask0_8227_147045)'>
					<path d='M147.59 102.635L249.096 83.5141V5.67477H147.59V102.635Z' fill='#E6EBF6' />
					<path
						d='M147.59 103.504C147.391 103.504 147.173 103.434 147.01 103.313C146.793 103.156 146.684 102.896 146.684 102.635V5.67477C146.684 5.18849 147.083 4.80641 147.59 4.80641H249.096C249.603 4.80641 250.002 5.18849 250.002 5.67477V83.5141C250.002 83.9309 249.694 84.2783 249.277 84.3651L147.772 103.486C147.717 103.486 147.645 103.504 147.59 103.504ZM148.497 6.54312V101.576L248.189 82.8021V6.54312H148.497Z'
						fill='#535D7D'
					/>
					<path
						d='M119.128 115.435H174.422L182.163 113.003L187.239 96.4179H235.644L222.192 64.0282C222.192 55.692 215.14 48.9362 206.438 48.9362H119.128V115.435Z'
						fill='#E6EBF6'
					/>
					<path
						d='M174.422 116.303H119.128C118.62 116.303 118.221 115.921 118.221 115.435V48.9362C118.221 48.4499 118.62 48.0679 119.128 48.0679H206.438C215.557 48.0679 222.99 55.1363 223.08 63.8546L236.478 96.0879C236.586 96.3484 236.55 96.661 236.387 96.9041C236.224 97.1473 235.934 97.2862 235.644 97.2862H187.91L183.033 113.247C182.942 113.524 182.725 113.733 182.453 113.82L174.712 116.251C174.603 116.286 174.512 116.303 174.422 116.303ZM120.034 114.566H174.276L181.419 112.326L186.369 96.1747C186.477 95.81 186.84 95.5495 187.239 95.5495H234.302L221.34 64.3582C221.304 64.254 221.267 64.1498 221.267 64.0456C221.267 56.213 214.614 49.8219 206.419 49.8219H120.034V114.566Z'
						fill='#535D7D'
					/>
					<path
						d='M191.173 48.9362H105.132C95.741 48.9362 88.1267 56.2304 88.1267 65.2265V123.285H109.773L116.807 115.435H174.422'
						fill='#E6EBF6'
					/>
					<path
						d='M109.773 124.153H88.1267C87.6191 124.153 87.2203 123.771 87.2203 123.285V65.2439C87.2203 55.7788 95.2515 48.0852 105.132 48.0852H191.173C191.68 48.0852 192.079 48.4673 192.079 48.9536C192.079 49.4399 191.68 49.8219 191.173 49.8219H105.132C96.2486 49.8219 89.0332 56.734 89.0332 65.2439V122.434H109.356L116.118 114.879C116.281 114.688 116.535 114.584 116.807 114.584H174.422C174.929 114.584 175.328 114.966 175.328 115.452C175.328 115.938 174.929 116.321 174.422 116.321H117.206L110.462 123.858C110.281 124.049 110.027 124.153 109.773 124.153Z'
						fill='#535D7D'
					/>
					<path
						d='M191.173 100.534C190.665 100.534 190.266 100.152 190.266 99.6655V63.7851C190.266 55.1189 197.627 48.0679 206.673 48.0679C215.72 48.0679 223.08 55.1189 223.08 63.7851V86.8312C223.08 87.3175 222.681 87.6996 222.174 87.6996C221.666 87.6996 221.267 87.3175 221.267 86.8312V63.7851C221.267 56.0741 214.723 49.8046 206.673 49.8046C198.624 49.8046 192.079 56.0741 192.079 63.7851V99.6655C192.079 100.152 191.68 100.534 191.173 100.534Z'
						fill='#535D7D'
					/>
					<path
						d='M249.096 43.7435V83.5141C249.096 90.6346 243.077 96.4005 235.644 96.4005C228.211 96.4005 222.192 90.6346 222.192 83.5141V63.4378'
						fill='#E6EBF6'
					/>
					<path
						d='M235.644 97.2862C227.721 97.2862 221.285 91.1209 221.285 83.5315V63.4378C221.285 62.9515 221.684 62.5694 222.192 62.5694C222.7 62.5694 223.098 62.9515 223.098 63.4378V83.5141C223.098 90.1484 228.737 95.5322 235.644 95.5322C242.551 95.5322 248.189 90.131 248.189 83.5141V43.7435C248.189 43.2572 248.588 42.8751 249.096 42.8751C249.603 42.8751 250.002 43.2572 250.002 43.7435V83.5141C250.002 91.1035 243.566 97.2862 235.644 97.2862Z'
						fill='#535D7D'
					/>
					<path d='M249.096 5.67477H147.59V32.8022H249.096V5.67477Z' fill='#535D7D' />
					<path
						d='M249.096 33.6705H147.59C147.083 33.6705 146.684 33.2885 146.684 32.8022V5.67477C146.684 5.18849 147.083 4.80641 147.59 4.80641H249.096C249.603 4.80641 250.002 5.18849 250.002 5.67477V32.8022C250.002 33.2885 249.603 33.6705 249.096 33.6705ZM148.497 31.9338H248.189V6.54312H148.497V31.9338Z'
						fill='#535D7D'
					/>
					<path
						d='M42.2418 100.221C42.2418 112.969 31.455 123.285 18.1663 123.285H1.92259H110.552C110.552 123.285 120.161 121.982 123.388 115.435C126.615 108.887 141.898 75.9421 141.898 75.9421H61.1505C50.7081 75.9421 42.2418 84.0525 42.2418 94.056V100.221Z'
						fill='#E6EBF6'
					/>
					<path
						d='M110.534 124.153H1.90446C1.39684 124.153 0.998003 123.771 0.998003 123.285C0.998003 122.799 1.39684 122.416 1.90446 122.416H18.1482C30.9292 122.416 41.3173 112.465 41.3173 100.221V94.056C41.3173 83.5836 50.2005 75.0737 61.1324 75.0737H141.862C142.17 75.0737 142.46 75.23 142.623 75.4732C142.786 75.7163 142.822 76.0289 142.696 76.3068C142.55 76.6368 127.376 109.339 124.186 115.817C120.777 122.712 111.078 124.101 110.661 124.171C110.625 124.153 110.589 124.153 110.534 124.153ZM27.5028 122.416H110.462C111.241 122.295 119.69 120.853 122.554 115.053C125.382 109.304 137.692 82.8195 140.484 76.7931H61.1506C51.2158 76.7931 43.1483 84.5388 43.1483 94.0386V100.204C43.1483 110.259 36.6581 118.874 27.5028 122.416Z'
						fill='#535D7D'
					/>
					<path
						d='M191.173 70.0025V99.3703C191.173 108.245 183.667 115.435 174.403 115.435C165.139 115.435 157.634 108.245 157.634 99.3703V90.9646C157.634 82.6631 150.618 75.9421 141.952 75.9421C133.286 75.9421 126.27 82.6631 126.27 90.9646V106.039C126.27 115.556 118.203 123.285 108.268 123.285C108.268 123.285 119.381 122.851 123.37 115.435H174.403'
						fill='#E6EBF6'
					/>
					<path
						d='M108.268 124.153C107.779 124.153 107.38 123.771 107.362 123.302C107.344 122.833 107.742 122.434 108.232 122.416C108.304 122.416 112.8 122.191 116.952 120.141C121.992 117.293 125.364 112.031 125.364 106.039V90.9646C125.364 82.1942 132.815 75.0737 141.952 75.0737C151.107 75.0737 158.54 82.2116 158.54 90.9646V99.3703C158.54 107.741 165.647 114.567 174.403 114.567C183.142 114.567 190.266 107.759 190.266 99.3703V70.0025C190.266 69.5163 190.665 69.1342 191.173 69.1342C191.68 69.1342 192.079 69.5163 192.079 70.0025V99.3703C192.079 108.696 184.157 116.303 174.403 116.303H123.914C122.572 118.544 120.469 120.384 117.714 121.739C114.922 123.285 111.713 124.153 108.268 124.153ZM124.947 114.567H166.608C160.77 111.805 156.727 106.039 156.727 99.3703V90.9646C156.727 83.1494 150.092 76.8104 141.952 76.8104C133.794 76.8104 127.177 83.1668 127.177 90.9646V106.039C127.177 109.131 126.379 112.031 124.947 114.567Z'
						fill='#535D7D'
					/>
					<path
						d='M159.229 21.7567C161.262 21.7567 162.909 20.1783 162.909 18.2312C162.909 16.2841 161.262 14.7057 159.229 14.7057C157.197 14.7057 155.549 16.2841 155.549 18.2312C155.549 20.1783 157.197 21.7567 159.229 21.7567Z'
						fill='#E06A63'
					/>
					<path
						d='M170.216 21.7567C172.248 21.7567 173.896 20.1783 173.896 18.2312C173.896 16.2841 172.248 14.7057 170.216 14.7057C168.183 14.7057 166.535 16.2841 166.535 18.2312C166.535 20.1783 168.183 21.7567 170.216 21.7567Z'
						fill='#F6E049'
					/>
					<path
						d='M181.22 21.7567C183.252 21.7567 184.9 20.1783 184.9 18.2312C184.9 16.2841 183.252 14.7057 181.22 14.7057C179.187 14.7057 177.54 16.2841 177.54 18.2312C177.54 20.1783 179.187 21.7567 181.22 21.7567Z'
						fill='#7FD6C2'
					/>
					<path
						d='M161.967 113.559C162.467 113.559 162.873 113.17 162.873 112.691C162.873 112.211 162.467 111.822 161.967 111.822C161.466 111.822 161.06 112.211 161.06 112.691C161.06 113.17 161.466 113.559 161.967 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M157.489 104.997C157.99 104.997 158.395 104.608 158.395 104.129C158.395 103.649 157.99 103.261 157.489 103.261C156.988 103.261 156.582 103.649 156.582 104.129C156.582 104.608 156.988 104.997 157.489 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M157.489 109.287C157.99 109.287 158.395 108.898 158.395 108.419C158.395 107.939 157.99 107.55 157.489 107.55C156.988 107.55 156.582 107.939 156.582 108.419C156.582 108.898 156.988 109.287 157.489 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M157.489 113.559C157.99 113.559 158.395 113.17 158.395 112.691C158.395 112.211 157.99 111.822 157.489 111.822C156.988 111.822 156.582 112.211 156.582 112.691C156.582 113.17 156.988 113.559 157.489 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 83.5836C153.512 83.5836 153.917 83.1948 153.917 82.7152C153.917 82.2357 153.512 81.8469 153.011 81.8469C152.51 81.8469 152.105 82.2357 152.105 82.7152C152.105 83.1948 152.51 83.5836 153.011 83.5836Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 87.8559C153.512 87.8559 153.917 87.4671 153.917 86.9876C153.917 86.508 153.512 86.1192 153.011 86.1192C152.51 86.1192 152.105 86.508 152.105 86.9876C152.105 87.4671 152.51 87.8559 153.011 87.8559Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 92.1456C153.512 92.1456 153.917 91.7568 153.917 91.2772C153.917 90.7976 153.512 90.4089 153.011 90.4089C152.51 90.4089 152.105 90.7976 152.105 91.2772C152.105 91.7568 152.51 92.1456 153.011 92.1456Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 96.4352C153.512 96.4352 153.917 96.0465 153.917 95.5669C153.917 95.0873 153.512 94.6985 153.011 94.6985C152.51 94.6985 152.105 95.0873 152.105 95.5669C152.105 96.0465 152.51 96.4352 153.011 96.4352Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 100.708C153.512 100.708 153.917 100.319 153.917 99.8392C153.917 99.3596 153.512 98.9708 153.011 98.9708C152.51 98.9708 152.105 99.3596 152.105 99.8392C152.105 100.319 152.51 100.708 153.011 100.708Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 104.997C153.512 104.997 153.917 104.608 153.917 104.129C153.917 103.649 153.512 103.261 153.011 103.261C152.51 103.261 152.105 103.649 152.105 104.129C152.105 104.608 152.51 104.997 153.011 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 109.287C153.512 109.287 153.917 108.898 153.917 108.419C153.917 107.939 153.512 107.55 153.011 107.55C152.51 107.55 152.105 107.939 152.105 108.419C152.105 108.898 152.51 109.287 153.011 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M153.011 113.559C153.512 113.559 153.917 113.17 153.917 112.691C153.917 112.211 153.512 111.822 153.011 111.822C152.51 111.822 152.105 112.211 152.105 112.691C152.105 113.17 152.51 113.559 153.011 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 79.2939C149.052 79.2939 149.458 78.9051 149.458 78.4256C149.458 77.946 149.052 77.5572 148.551 77.5572C148.051 77.5572 147.645 77.946 147.645 78.4256C147.645 78.9051 148.051 79.2939 148.551 79.2939Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 83.5836C149.052 83.5836 149.458 83.1948 149.458 82.7152C149.458 82.2357 149.052 81.8469 148.551 81.8469C148.051 81.8469 147.645 82.2357 147.645 82.7152C147.645 83.1948 148.051 83.5836 148.551 83.5836Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 87.8559C149.052 87.8559 149.458 87.4671 149.458 86.9876C149.458 86.508 149.052 86.1192 148.551 86.1192C148.051 86.1192 147.645 86.508 147.645 86.9876C147.645 87.4671 148.051 87.8559 148.551 87.8559Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 92.1456C149.052 92.1456 149.458 91.7568 149.458 91.2772C149.458 90.7976 149.052 90.4089 148.551 90.4089C148.051 90.4089 147.645 90.7976 147.645 91.2772C147.645 91.7568 148.051 92.1456 148.551 92.1456Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 96.4352C149.052 96.4352 149.458 96.0465 149.458 95.5669C149.458 95.0873 149.052 94.6985 148.551 94.6985C148.051 94.6985 147.645 95.0873 147.645 95.5669C147.645 96.0465 148.051 96.4352 148.551 96.4352Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 100.708C149.052 100.708 149.458 100.319 149.458 99.8392C149.458 99.3596 149.052 98.9708 148.551 98.9708C148.051 98.9708 147.645 99.3596 147.645 99.8392C147.645 100.319 148.051 100.708 148.551 100.708Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 104.997C149.052 104.997 149.458 104.608 149.458 104.129C149.458 103.649 149.052 103.261 148.551 103.261C148.051 103.261 147.645 103.649 147.645 104.129C147.645 104.608 148.051 104.997 148.551 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 109.287C149.052 109.287 149.458 108.898 149.458 108.419C149.458 107.939 149.052 107.55 148.551 107.55C148.051 107.55 147.645 107.939 147.645 108.419C147.645 108.898 148.051 109.287 148.551 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M148.551 113.559C149.052 113.559 149.458 113.17 149.458 112.691C149.458 112.211 149.052 111.822 148.551 111.822C148.051 111.822 147.645 112.211 147.645 112.691C147.645 113.17 148.051 113.559 148.551 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 79.2939C144.574 79.2939 144.98 78.9051 144.98 78.4256C144.98 77.946 144.574 77.5572 144.073 77.5572C143.573 77.5572 143.167 77.946 143.167 78.4256C143.167 78.9051 143.573 79.2939 144.073 79.2939Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 83.5836C144.574 83.5836 144.98 83.1948 144.98 82.7152C144.98 82.2357 144.574 81.8469 144.073 81.8469C143.573 81.8469 143.167 82.2357 143.167 82.7152C143.167 83.1948 143.573 83.5836 144.073 83.5836Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 87.8559C144.574 87.8559 144.98 87.4671 144.98 86.9876C144.98 86.508 144.574 86.1192 144.073 86.1192C143.573 86.1192 143.167 86.508 143.167 86.9876C143.167 87.4671 143.573 87.8559 144.073 87.8559Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 92.1456C144.574 92.1456 144.98 91.7568 144.98 91.2772C144.98 90.7976 144.574 90.4089 144.073 90.4089C143.573 90.4089 143.167 90.7976 143.167 91.2772C143.167 91.7568 143.573 92.1456 144.073 92.1456Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 96.4352C144.574 96.4352 144.98 96.0465 144.98 95.5669C144.98 95.0873 144.574 94.6985 144.073 94.6985C143.573 94.6985 143.167 95.0873 143.167 95.5669C143.167 96.0465 143.573 96.4352 144.073 96.4352Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 100.708C144.574 100.708 144.98 100.319 144.98 99.8392C144.98 99.3596 144.574 98.9708 144.073 98.9708C143.573 98.9708 143.167 99.3596 143.167 99.8392C143.167 100.319 143.573 100.708 144.073 100.708Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 104.997C144.574 104.997 144.98 104.608 144.98 104.129C144.98 103.649 144.574 103.261 144.073 103.261C143.573 103.261 143.167 103.649 143.167 104.129C143.167 104.608 143.573 104.997 144.073 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 109.287C144.574 109.287 144.98 108.898 144.98 108.419C144.98 107.939 144.574 107.55 144.073 107.55C143.573 107.55 143.167 107.939 143.167 108.419C143.167 108.898 143.573 109.287 144.073 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M144.073 113.559C144.574 113.559 144.98 113.17 144.98 112.691C144.98 112.211 144.574 111.822 144.073 111.822C143.573 111.822 143.167 112.211 143.167 112.691C143.167 113.17 143.573 113.559 144.073 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 79.2939C140.096 79.2939 140.502 78.9051 140.502 78.4256C140.502 77.946 140.096 77.5572 139.595 77.5572C139.095 77.5572 138.689 77.946 138.689 78.4256C138.689 78.9051 139.095 79.2939 139.595 79.2939Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 83.5836C140.096 83.5836 140.502 83.1948 140.502 82.7152C140.502 82.2357 140.096 81.8469 139.595 81.8469C139.095 81.8469 138.689 82.2357 138.689 82.7152C138.689 83.1948 139.095 83.5836 139.595 83.5836Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 87.8559C140.096 87.8559 140.502 87.4671 140.502 86.9876C140.502 86.508 140.096 86.1192 139.595 86.1192C139.095 86.1192 138.689 86.508 138.689 86.9876C138.689 87.4671 139.095 87.8559 139.595 87.8559Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 92.1456C140.096 92.1456 140.502 91.7568 140.502 91.2772C140.502 90.7976 140.096 90.4089 139.595 90.4089C139.095 90.4089 138.689 90.7976 138.689 91.2772C138.689 91.7568 139.095 92.1456 139.595 92.1456Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 96.4352C140.096 96.4352 140.502 96.0465 140.502 95.5669C140.502 95.0873 140.096 94.6985 139.595 94.6985C139.095 94.6985 138.689 95.0873 138.689 95.5669C138.689 96.0465 139.095 96.4352 139.595 96.4352Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 100.708C140.096 100.708 140.502 100.319 140.502 99.8392C140.502 99.3596 140.096 98.9708 139.595 98.9708C139.095 98.9708 138.689 99.3596 138.689 99.8392C138.689 100.319 139.095 100.708 139.595 100.708Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 104.997C140.096 104.997 140.502 104.608 140.502 104.129C140.502 103.649 140.096 103.261 139.595 103.261C139.095 103.261 138.689 103.649 138.689 104.129C138.689 104.608 139.095 104.997 139.595 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 109.287C140.096 109.287 140.502 108.898 140.502 108.419C140.502 107.939 140.096 107.55 139.595 107.55C139.095 107.55 138.689 107.939 138.689 108.419C138.689 108.898 139.095 109.287 139.595 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M139.595 113.559C140.096 113.559 140.502 113.17 140.502 112.691C140.502 112.211 140.096 111.822 139.595 111.822C139.095 111.822 138.689 112.211 138.689 112.691C138.689 113.17 139.095 113.559 139.595 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 79.2939C135.636 79.2939 136.042 78.9051 136.042 78.4256C136.042 77.946 135.636 77.5572 135.136 77.5572C134.635 77.5572 134.229 77.946 134.229 78.4256C134.229 78.9051 134.635 79.2939 135.136 79.2939Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 83.5836C135.636 83.5836 136.042 83.1948 136.042 82.7152C136.042 82.2357 135.636 81.8469 135.136 81.8469C134.635 81.8469 134.229 82.2357 134.229 82.7152C134.229 83.1948 134.635 83.5836 135.136 83.5836Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 87.8559C135.636 87.8559 136.042 87.4671 136.042 86.9876C136.042 86.508 135.636 86.1192 135.136 86.1192C134.635 86.1192 134.229 86.508 134.229 86.9876C134.229 87.4671 134.635 87.8559 135.136 87.8559Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 92.1456C135.636 92.1456 136.042 91.7568 136.042 91.2772C136.042 90.7976 135.636 90.4089 135.136 90.4089C134.635 90.4089 134.229 90.7976 134.229 91.2772C134.229 91.7568 134.635 92.1456 135.136 92.1456Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 96.4352C135.636 96.4352 136.042 96.0465 136.042 95.5669C136.042 95.0873 135.636 94.6985 135.136 94.6985C134.635 94.6985 134.229 95.0873 134.229 95.5669C134.229 96.0465 134.635 96.4352 135.136 96.4352Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 100.708C135.636 100.708 136.042 100.319 136.042 99.8392C136.042 99.3596 135.636 98.9708 135.136 98.9708C134.635 98.9708 134.229 99.3596 134.229 99.8392C134.229 100.319 134.635 100.708 135.136 100.708Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 104.997C135.636 104.997 136.042 104.608 136.042 104.129C136.042 103.649 135.636 103.261 135.136 103.261C134.635 103.261 134.229 103.649 134.229 104.129C134.229 104.608 134.635 104.997 135.136 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 109.287C135.636 109.287 136.042 108.898 136.042 108.419C136.042 107.939 135.636 107.55 135.136 107.55C134.635 107.55 134.229 107.939 134.229 108.419C134.229 108.898 134.635 109.287 135.136 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M135.136 113.559C135.636 113.559 136.042 113.17 136.042 112.691C136.042 112.211 135.636 111.822 135.136 111.822C134.635 111.822 134.229 112.211 134.229 112.691C134.229 113.17 134.635 113.559 135.136 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 83.5836C131.158 83.5836 131.564 83.1948 131.564 82.7152C131.564 82.2357 131.158 81.8469 130.658 81.8469C130.157 81.8469 129.751 82.2357 129.751 82.7152C129.751 83.1948 130.157 83.5836 130.658 83.5836Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 87.8559C131.158 87.8559 131.564 87.4671 131.564 86.9876C131.564 86.508 131.158 86.1192 130.658 86.1192C130.157 86.1192 129.751 86.508 129.751 86.9876C129.751 87.4671 130.157 87.8559 130.658 87.8559Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 92.1456C131.158 92.1456 131.564 91.7568 131.564 91.2772C131.564 90.7976 131.158 90.4089 130.658 90.4089C130.157 90.4089 129.751 90.7976 129.751 91.2772C129.751 91.7568 130.157 92.1456 130.658 92.1456Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 96.4352C131.158 96.4352 131.564 96.0465 131.564 95.5669C131.564 95.0873 131.158 94.6985 130.658 94.6985C130.157 94.6985 129.751 95.0873 129.751 95.5669C129.751 96.0465 130.157 96.4352 130.658 96.4352Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 100.708C131.158 100.708 131.564 100.319 131.564 99.8392C131.564 99.3596 131.158 98.9708 130.658 98.9708C130.157 98.9708 129.751 99.3596 129.751 99.8392C129.751 100.319 130.157 100.708 130.658 100.708Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 104.997C131.158 104.997 131.564 104.608 131.564 104.129C131.564 103.649 131.158 103.261 130.658 103.261C130.157 103.261 129.751 103.649 129.751 104.129C129.751 104.608 130.157 104.997 130.658 104.997Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 109.287C131.158 109.287 131.564 108.898 131.564 108.419C131.564 107.939 131.158 107.55 130.658 107.55C130.157 107.55 129.751 107.939 129.751 108.419C129.751 108.898 130.157 109.287 130.658 109.287Z'
						fill='#535D7D'
					/>
					<path
						d='M130.658 113.559C131.158 113.559 131.564 113.17 131.564 112.691C131.564 112.211 131.158 111.822 130.658 111.822C130.157 111.822 129.751 112.211 129.751 112.691C129.751 113.17 130.157 113.559 130.658 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M126.18 113.559C126.68 113.559 127.086 113.17 127.086 112.691C127.086 112.211 126.68 111.822 126.18 111.822C125.679 111.822 125.273 112.211 125.273 112.691C125.273 113.17 125.679 113.559 126.18 113.559Z'
						fill='#535D7D'
					/>
					<path
						d='M225.292 94.6811C225.793 94.6811 226.198 94.2924 226.198 93.8128C226.198 93.3332 225.793 92.9444 225.292 92.9444C224.791 92.9444 224.385 93.3332 224.385 93.8128C224.385 94.2924 224.791 94.6811 225.292 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 60.4159C221.333 60.4159 221.739 60.0271 221.739 59.5475C221.739 59.0679 221.333 58.6791 220.832 58.6791C220.332 58.6791 219.926 59.0679 219.926 59.5475C219.926 60.0271 220.332 60.4159 220.832 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 64.7055C221.333 64.7055 221.739 64.3168 221.739 63.8372C221.739 63.3576 221.333 62.9688 220.832 62.9688C220.332 62.9688 219.926 63.3576 219.926 63.8372C219.926 64.3168 220.332 64.7055 220.832 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 68.9778C221.333 68.9778 221.739 68.5891 221.739 68.1095C221.739 67.6299 221.333 67.2411 220.832 67.2411C220.332 67.2411 219.926 67.6299 219.926 68.1095C219.926 68.5891 220.332 68.9778 220.832 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 73.2675C221.333 73.2675 221.739 72.8787 221.739 72.3992C221.739 71.9196 221.333 71.5308 220.832 71.5308C220.332 71.5308 219.926 71.9196 219.926 72.3992C219.926 72.8787 220.332 73.2675 220.832 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 77.5572C221.333 77.5572 221.739 77.1684 221.739 76.6888C221.739 76.2092 221.333 75.8205 220.832 75.8205C220.332 75.8205 219.926 76.2092 219.926 76.6888C219.926 77.1684 220.332 77.5572 220.832 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 81.8295C221.333 81.8295 221.739 81.4407 221.739 80.9611C221.739 80.4816 221.333 80.0928 220.832 80.0928C220.332 80.0928 219.926 80.4816 219.926 80.9611C219.926 81.4407 220.332 81.8295 220.832 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 86.1192C221.333 86.1192 221.739 85.7304 221.739 85.2508C221.739 84.7712 221.333 84.3825 220.832 84.3825C220.332 84.3825 219.926 84.7712 219.926 85.2508C219.926 85.7304 220.332 86.1192 220.832 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 90.4089C221.333 90.4089 221.739 90.0201 221.739 89.5405C221.739 89.0609 221.333 88.6722 220.832 88.6722C220.332 88.6722 219.926 89.0609 219.926 89.5405C219.926 90.0201 220.332 90.4089 220.832 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M220.832 94.6811C221.333 94.6811 221.739 94.2924 221.739 93.8128C221.739 93.3332 221.333 92.9444 220.832 92.9444C220.332 92.9444 219.926 93.3332 219.926 93.8128C219.926 94.2924 220.332 94.6811 220.832 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 56.1262C216.855 56.1262 217.261 55.7374 217.261 55.2578C217.261 54.7782 216.855 54.3895 216.354 54.3895C215.854 54.3895 215.448 54.7782 215.448 55.2578C215.448 55.7374 215.854 56.1262 216.354 56.1262Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 60.4159C216.855 60.4159 217.261 60.0271 217.261 59.5475C217.261 59.0679 216.855 58.6791 216.354 58.6791C215.854 58.6791 215.448 59.0679 215.448 59.5475C215.448 60.0271 215.854 60.4159 216.354 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 64.7055C216.855 64.7055 217.261 64.3168 217.261 63.8372C217.261 63.3576 216.855 62.9688 216.354 62.9688C215.854 62.9688 215.448 63.3576 215.448 63.8372C215.448 64.3168 215.854 64.7055 216.354 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 68.9778C216.855 68.9778 217.261 68.5891 217.261 68.1095C217.261 67.6299 216.855 67.2411 216.354 67.2411C215.854 67.2411 215.448 67.6299 215.448 68.1095C215.448 68.5891 215.854 68.9778 216.354 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 73.2675C216.855 73.2675 217.261 72.8787 217.261 72.3992C217.261 71.9196 216.855 71.5308 216.354 71.5308C215.854 71.5308 215.448 71.9196 215.448 72.3992C215.448 72.8787 215.854 73.2675 216.354 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 77.5572C216.855 77.5572 217.261 77.1684 217.261 76.6888C217.261 76.2092 216.855 75.8205 216.354 75.8205C215.854 75.8205 215.448 76.2092 215.448 76.6888C215.448 77.1684 215.854 77.5572 216.354 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 81.8295C216.855 81.8295 217.261 81.4407 217.261 80.9611C217.261 80.4816 216.855 80.0928 216.354 80.0928C215.854 80.0928 215.448 80.4816 215.448 80.9611C215.448 81.4407 215.854 81.8295 216.354 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 86.1192C216.855 86.1192 217.261 85.7304 217.261 85.2508C217.261 84.7712 216.855 84.3825 216.354 84.3825C215.854 84.3825 215.448 84.7712 215.448 85.2508C215.448 85.7304 215.854 86.1192 216.354 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 90.4089C216.855 90.4089 217.261 90.0201 217.261 89.5405C217.261 89.0609 216.855 88.6722 216.354 88.6722C215.854 88.6722 215.448 89.0609 215.448 89.5405C215.448 90.0201 215.854 90.4089 216.354 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M216.354 94.6811C216.855 94.6811 217.261 94.2924 217.261 93.8128C217.261 93.3332 216.855 92.9444 216.354 92.9444C215.854 92.9444 215.448 93.3332 215.448 93.8128C215.448 94.2924 215.854 94.6811 216.354 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 51.8539C212.377 51.8539 212.783 51.4651 212.783 50.9855C212.783 50.5059 212.377 50.1172 211.876 50.1172C211.376 50.1172 210.97 50.5059 210.97 50.9855C210.97 51.4651 211.376 51.8539 211.876 51.8539Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 56.1262C212.377 56.1262 212.783 55.7374 212.783 55.2578C212.783 54.7782 212.377 54.3895 211.876 54.3895C211.376 54.3895 210.97 54.7782 210.97 55.2578C210.97 55.7374 211.376 56.1262 211.876 56.1262Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 60.4159C212.377 60.4159 212.783 60.0271 212.783 59.5475C212.783 59.0679 212.377 58.6791 211.876 58.6791C211.376 58.6791 210.97 59.0679 210.97 59.5475C210.97 60.0271 211.376 60.4159 211.876 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 64.7055C212.377 64.7055 212.783 64.3168 212.783 63.8372C212.783 63.3576 212.377 62.9688 211.876 62.9688C211.376 62.9688 210.97 63.3576 210.97 63.8372C210.97 64.3168 211.376 64.7055 211.876 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 68.9778C212.377 68.9778 212.783 68.5891 212.783 68.1095C212.783 67.6299 212.377 67.2411 211.876 67.2411C211.376 67.2411 210.97 67.6299 210.97 68.1095C210.97 68.5891 211.376 68.9778 211.876 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 73.2675C212.377 73.2675 212.783 72.8787 212.783 72.3992C212.783 71.9196 212.377 71.5308 211.876 71.5308C211.376 71.5308 210.97 71.9196 210.97 72.3992C210.97 72.8787 211.376 73.2675 211.876 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 77.5572C212.377 77.5572 212.783 77.1684 212.783 76.6888C212.783 76.2092 212.377 75.8205 211.876 75.8205C211.376 75.8205 210.97 76.2092 210.97 76.6888C210.97 77.1684 211.376 77.5572 211.876 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 81.8295C212.377 81.8295 212.783 81.4407 212.783 80.9611C212.783 80.4816 212.377 80.0928 211.876 80.0928C211.376 80.0928 210.97 80.4816 210.97 80.9611C210.97 81.4407 211.376 81.8295 211.876 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 86.1192C212.377 86.1192 212.783 85.7304 212.783 85.2508C212.783 84.7712 212.377 84.3825 211.876 84.3825C211.376 84.3825 210.97 84.7712 210.97 85.2508C210.97 85.7304 211.376 86.1192 211.876 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 90.4089C212.377 90.4089 212.783 90.0201 212.783 89.5405C212.783 89.0609 212.377 88.6722 211.876 88.6722C211.376 88.6722 210.97 89.0609 210.97 89.5405C210.97 90.0201 211.376 90.4089 211.876 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M211.876 94.6811C212.377 94.6811 212.783 94.2924 212.783 93.8128C212.783 93.3332 212.377 92.9444 211.876 92.9444C211.376 92.9444 210.97 93.3332 210.97 93.8128C210.97 94.2924 211.376 94.6811 211.876 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 51.8539C207.917 51.8539 208.323 51.4651 208.323 50.9855C208.323 50.5059 207.917 50.1172 207.417 50.1172C206.916 50.1172 206.51 50.5059 206.51 50.9855C206.51 51.4651 206.916 51.8539 207.417 51.8539Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 56.1262C207.917 56.1262 208.323 55.7374 208.323 55.2578C208.323 54.7782 207.917 54.3895 207.417 54.3895C206.916 54.3895 206.51 54.7782 206.51 55.2578C206.51 55.7374 206.916 56.1262 207.417 56.1262Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 60.4159C207.917 60.4159 208.323 60.0271 208.323 59.5475C208.323 59.0679 207.917 58.6791 207.417 58.6791C206.916 58.6791 206.51 59.0679 206.51 59.5475C206.51 60.0271 206.916 60.4159 207.417 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 64.7055C207.917 64.7055 208.323 64.3168 208.323 63.8372C208.323 63.3576 207.917 62.9688 207.417 62.9688C206.916 62.9688 206.51 63.3576 206.51 63.8372C206.51 64.3168 206.916 64.7055 207.417 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 68.9778C207.917 68.9778 208.323 68.5891 208.323 68.1095C208.323 67.6299 207.917 67.2411 207.417 67.2411C206.916 67.2411 206.51 67.6299 206.51 68.1095C206.51 68.5891 206.916 68.9778 207.417 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 73.2675C207.917 73.2675 208.323 72.8787 208.323 72.3992C208.323 71.9196 207.917 71.5308 207.417 71.5308C206.916 71.5308 206.51 71.9196 206.51 72.3992C206.51 72.8787 206.916 73.2675 207.417 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 77.5572C207.917 77.5572 208.323 77.1684 208.323 76.6888C208.323 76.2092 207.917 75.8205 207.417 75.8205C206.916 75.8205 206.51 76.2092 206.51 76.6888C206.51 77.1684 206.916 77.5572 207.417 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 81.8295C207.917 81.8295 208.323 81.4407 208.323 80.9611C208.323 80.4816 207.917 80.0928 207.417 80.0928C206.916 80.0928 206.51 80.4816 206.51 80.9611C206.51 81.4407 206.916 81.8295 207.417 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 86.1192C207.917 86.1192 208.323 85.7304 208.323 85.2508C208.323 84.7712 207.917 84.3825 207.417 84.3825C206.916 84.3825 206.51 84.7712 206.51 85.2508C206.51 85.7304 206.916 86.1192 207.417 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 90.4089C207.917 90.4089 208.323 90.0201 208.323 89.5405C208.323 89.0609 207.917 88.6722 207.417 88.6722C206.916 88.6722 206.51 89.0609 206.51 89.5405C206.51 90.0201 206.916 90.4089 207.417 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M207.417 94.6811C207.917 94.6811 208.323 94.2924 208.323 93.8128C208.323 93.3332 207.917 92.9444 207.417 92.9444C206.916 92.9444 206.51 93.3332 206.51 93.8128C206.51 94.2924 206.916 94.6811 207.417 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 51.8539C203.439 51.8539 203.845 51.4651 203.845 50.9855C203.845 50.5059 203.439 50.1172 202.939 50.1172C202.438 50.1172 202.032 50.5059 202.032 50.9855C202.032 51.4651 202.438 51.8539 202.939 51.8539Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 56.1262C203.439 56.1262 203.845 55.7374 203.845 55.2578C203.845 54.7782 203.439 54.3895 202.939 54.3895C202.438 54.3895 202.032 54.7782 202.032 55.2578C202.032 55.7374 202.438 56.1262 202.939 56.1262Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 60.4159C203.439 60.4159 203.845 60.0271 203.845 59.5475C203.845 59.0679 203.439 58.6791 202.939 58.6791C202.438 58.6791 202.032 59.0679 202.032 59.5475C202.032 60.0271 202.438 60.4159 202.939 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 64.7055C203.439 64.7055 203.845 64.3168 203.845 63.8372C203.845 63.3576 203.439 62.9688 202.939 62.9688C202.438 62.9688 202.032 63.3576 202.032 63.8372C202.032 64.3168 202.438 64.7055 202.939 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 68.9778C203.439 68.9778 203.845 68.5891 203.845 68.1095C203.845 67.6299 203.439 67.2411 202.939 67.2411C202.438 67.2411 202.032 67.6299 202.032 68.1095C202.032 68.5891 202.438 68.9778 202.939 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 73.2675C203.439 73.2675 203.845 72.8787 203.845 72.3992C203.845 71.9196 203.439 71.5308 202.939 71.5308C202.438 71.5308 202.032 71.9196 202.032 72.3992C202.032 72.8787 202.438 73.2675 202.939 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 77.5572C203.439 77.5572 203.845 77.1684 203.845 76.6888C203.845 76.2092 203.439 75.8205 202.939 75.8205C202.438 75.8205 202.032 76.2092 202.032 76.6888C202.032 77.1684 202.438 77.5572 202.939 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 81.8295C203.439 81.8295 203.845 81.4407 203.845 80.9611C203.845 80.4816 203.439 80.0928 202.939 80.0928C202.438 80.0928 202.032 80.4816 202.032 80.9611C202.032 81.4407 202.438 81.8295 202.939 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 86.1192C203.439 86.1192 203.845 85.7304 203.845 85.2508C203.845 84.7712 203.439 84.3825 202.939 84.3825C202.438 84.3825 202.032 84.7712 202.032 85.2508C202.032 85.7304 202.438 86.1192 202.939 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 90.4089C203.439 90.4089 203.845 90.0201 203.845 89.5405C203.845 89.0609 203.439 88.6722 202.939 88.6722C202.438 88.6722 202.032 89.0609 202.032 89.5405C202.032 90.0201 202.438 90.4089 202.939 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M202.939 94.6811C203.439 94.6811 203.845 94.2924 203.845 93.8128C203.845 93.3332 203.439 92.9444 202.939 92.9444C202.438 92.9444 202.032 93.3332 202.032 93.8128C202.032 94.2924 202.438 94.6811 202.939 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 56.1262C198.961 56.1262 199.367 55.7374 199.367 55.2578C199.367 54.7782 198.961 54.3895 198.461 54.3895C197.96 54.3895 197.554 54.7782 197.554 55.2578C197.554 55.7374 197.96 56.1262 198.461 56.1262Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 60.4159C198.961 60.4159 199.367 60.0271 199.367 59.5475C199.367 59.0679 198.961 58.6791 198.461 58.6791C197.96 58.6791 197.554 59.0679 197.554 59.5475C197.554 60.0271 197.96 60.4159 198.461 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 64.7055C198.961 64.7055 199.367 64.3168 199.367 63.8372C199.367 63.3576 198.961 62.9688 198.461 62.9688C197.96 62.9688 197.554 63.3576 197.554 63.8372C197.554 64.3168 197.96 64.7055 198.461 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 68.9778C198.961 68.9778 199.367 68.5891 199.367 68.1095C199.367 67.6299 198.961 67.2411 198.461 67.2411C197.96 67.2411 197.554 67.6299 197.554 68.1095C197.554 68.5891 197.96 68.9778 198.461 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 73.2675C198.961 73.2675 199.367 72.8787 199.367 72.3992C199.367 71.9196 198.961 71.5308 198.461 71.5308C197.96 71.5308 197.554 71.9196 197.554 72.3992C197.554 72.8787 197.96 73.2675 198.461 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 77.5572C198.961 77.5572 199.367 77.1684 199.367 76.6888C199.367 76.2092 198.961 75.8205 198.461 75.8205C197.96 75.8205 197.554 76.2092 197.554 76.6888C197.554 77.1684 197.96 77.5572 198.461 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 81.8295C198.961 81.8295 199.367 81.4407 199.367 80.9611C199.367 80.4816 198.961 80.0928 198.461 80.0928C197.96 80.0928 197.554 80.4816 197.554 80.9611C197.554 81.4407 197.96 81.8295 198.461 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 86.1192C198.961 86.1192 199.367 85.7304 199.367 85.2508C199.367 84.7712 198.961 84.3825 198.461 84.3825C197.96 84.3825 197.554 84.7712 197.554 85.2508C197.554 85.7304 197.96 86.1192 198.461 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 90.4089C198.961 90.4089 199.367 90.0201 199.367 89.5405C199.367 89.0609 198.961 88.6722 198.461 88.6722C197.96 88.6722 197.554 89.0609 197.554 89.5405C197.554 90.0201 197.96 90.4089 198.461 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M198.461 94.6811C198.961 94.6811 199.367 94.2924 199.367 93.8128C199.367 93.3332 198.961 92.9444 198.461 92.9444C197.96 92.9444 197.554 93.3332 197.554 93.8128C197.554 94.2924 197.96 94.6811 198.461 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 60.4159C194.502 60.4159 194.907 60.0271 194.907 59.5475C194.907 59.0679 194.502 58.6792 194.001 58.6792C193.5 58.6792 193.095 59.0679 193.095 59.5475C193.095 60.0271 193.5 60.4159 194.001 60.4159Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 64.7055C194.502 64.7055 194.907 64.3168 194.907 63.8372C194.907 63.3576 194.502 62.9688 194.001 62.9688C193.5 62.9688 193.095 63.3576 193.095 63.8372C193.095 64.3168 193.5 64.7055 194.001 64.7055Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 68.9778C194.502 68.9778 194.907 68.5891 194.907 68.1095C194.907 67.6299 194.502 67.2411 194.001 67.2411C193.5 67.2411 193.095 67.6299 193.095 68.1095C193.095 68.5891 193.5 68.9778 194.001 68.9778Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 73.2675C194.502 73.2675 194.907 72.8787 194.907 72.3992C194.907 71.9196 194.502 71.5308 194.001 71.5308C193.5 71.5308 193.095 71.9196 193.095 72.3992C193.095 72.8787 193.5 73.2675 194.001 73.2675Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 77.5572C194.502 77.5572 194.907 77.1684 194.907 76.6888C194.907 76.2092 194.502 75.8205 194.001 75.8205C193.5 75.8205 193.095 76.2092 193.095 76.6888C193.095 77.1684 193.5 77.5572 194.001 77.5572Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 81.8295C194.502 81.8295 194.907 81.4407 194.907 80.9611C194.907 80.4816 194.502 80.0928 194.001 80.0928C193.5 80.0928 193.095 80.4816 193.095 80.9611C193.095 81.4407 193.5 81.8295 194.001 81.8295Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 86.1192C194.502 86.1192 194.907 85.7304 194.907 85.2508C194.907 84.7712 194.502 84.3825 194.001 84.3825C193.5 84.3825 193.095 84.7712 193.095 85.2508C193.095 85.7304 193.5 86.1192 194.001 86.1192Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 90.4089C194.502 90.4089 194.907 90.0201 194.907 89.5405C194.907 89.0609 194.502 88.6722 194.001 88.6722C193.5 88.6722 193.095 89.0609 193.095 89.5405C193.095 90.0201 193.5 90.4089 194.001 90.4089Z'
						fill='#535D7D'
					/>
					<path
						d='M194.001 94.6811C194.502 94.6811 194.907 94.2924 194.907 93.8128C194.907 93.3332 194.502 92.9444 194.001 92.9444C193.5 92.9444 193.095 93.3332 193.095 93.8128C193.095 94.2924 193.5 94.6811 194.001 94.6811Z'
						fill='#535D7D'
					/>
					<path
						d='M108.812 75.2995L88.9969 61.319C88.725 61.1279 88.3624 61.0932 88.0542 61.2495C87.746 61.4058 87.5647 61.701 87.5647 62.031V75.9594C87.5647 76.4457 87.9636 76.8278 88.4712 76.8278L108.286 76.8799C108.685 76.8799 109.03 76.6367 109.138 76.2894C109.247 75.9073 109.12 75.5252 108.812 75.2995Z'
						fill='#535D7D'
					/>
				</g>
				<path
					d='M89.7638 22.7259C89.7368 22.2651 90.7012 21.8094 92.1644 21.5079C92.9027 21.3554 93.4574 20.7628 93.5322 20.0435C93.6807 18.6188 94.0454 17.6506 94.5271 17.6247C95.0089 17.5989 95.4852 18.5215 95.8003 19.9212C95.9597 20.6284 96.5792 21.1582 97.332 21.2297C98.8222 21.371 99.837 21.7198 99.864 22.1815C99.891 22.645 98.9249 23.1015 97.4617 23.4013C96.7215 23.5529 96.1668 24.1464 96.0921 24.8665C95.9444 26.293 95.5797 27.2612 95.0971 27.287C94.6154 27.3129 94.139 26.3903 93.8239 24.9888C93.6645 24.2816 93.0432 23.751 92.2904 23.6804C90.802 23.5399 89.7908 23.1911 89.7638 22.7268V22.7259Z'
					fill='#3C7EFF'
				/>
				<path
					d='M223.639 115.2C223.612 114.739 224.577 114.284 226.04 113.982C226.778 113.83 227.333 113.237 227.408 112.518C227.556 111.093 227.921 110.125 228.403 110.099C228.884 110.073 229.361 110.996 229.676 112.395C229.835 113.103 230.455 113.632 231.207 113.704C232.698 113.845 233.712 114.194 233.739 114.656C233.766 115.119 232.8 115.576 231.337 115.875C230.597 116.027 230.042 116.621 229.968 117.341C229.82 118.767 229.455 119.735 228.973 119.761C228.491 119.787 228.014 118.864 227.699 117.463C227.54 116.756 226.919 116.225 226.166 116.155C224.677 116.014 223.666 115.665 223.639 115.201V115.2Z'
					fill='#3C7EFF'
				/>
				<path
					d='M44.325 50.636C44.3115 50.4068 44.7912 50.1801 45.519 50.0302C45.8863 49.9543 46.1622 49.6595 46.1994 49.3018C46.2733 48.5931 46.4547 48.1115 46.6943 48.0987C46.9339 48.0858 47.1708 48.5447 47.3276 49.2409C47.4069 49.5927 47.715 49.8562 48.0894 49.8918C48.8307 49.962 49.3354 50.1356 49.3489 50.3652C49.3623 50.5957 48.8817 50.8228 48.1539 50.9719C47.7858 51.0473 47.5099 51.3426 47.4727 51.7007C47.3992 52.4103 47.2178 52.8919 46.9778 52.9047C46.7382 52.9176 46.5012 52.4587 46.3445 51.7616C46.2652 51.4098 45.9562 51.1459 45.5817 51.1108C44.8414 51.0409 44.3384 50.8674 44.325 50.6364V50.636Z'
					fill='#3C7EFF'
				/>
				<path
					d='M169.823 131.636C169.81 131.407 170.289 131.18 171.017 131.03C171.384 130.954 171.66 130.66 171.697 130.302C171.771 129.593 171.953 129.112 172.192 129.099C172.432 129.086 172.669 129.545 172.826 130.241C172.905 130.593 173.213 130.856 173.587 130.892C174.329 130.962 174.833 131.136 174.847 131.365C174.86 131.596 174.38 131.823 173.652 131.972C173.284 132.047 173.008 132.343 172.971 132.701C172.897 133.41 172.716 133.892 172.476 133.905C172.236 133.918 171.999 133.459 171.842 132.762C171.763 132.41 171.454 132.146 171.08 132.111C170.339 132.041 169.836 131.867 169.823 131.636V131.636Z'
					fill='#3C7EFF'
				/>
				<path
					d='M120.761 3.771C121.439 2.98574 121.323 1.82329 120.502 1.17459C119.681 0.525886 118.466 0.636581 117.788 1.42184C117.11 2.2071 117.226 3.36957 118.047 4.01827C118.867 4.66697 120.083 4.55626 120.761 3.771Z'
					fill='#3C7EFF'
				/>
				<path
					d='M80.017 48.9239C80.0692 49.445 79.6694 49.9085 79.1247 49.9576C78.5799 50.0067 78.0955 49.6251 78.0441 49.1039C77.9919 48.5828 78.3917 48.1193 78.9365 48.0702C79.4812 48.0203 79.9657 48.4027 80.017 48.9239Z'
					fill='#3C7EFF'
				/>
				<path
					d='M196.701 118.956C196.753 119.478 196.353 119.941 195.808 119.99C195.263 120.039 194.779 119.658 194.728 119.136C194.675 118.615 195.075 118.152 195.62 118.103C196.165 118.053 196.649 118.435 196.701 118.956Z'
					fill='#3C7EFF'
				/>
				<path
					d='M25.3858 20.9564C25.438 21.4776 25.0382 21.941 24.4935 21.9901C23.9487 22.0392 23.4643 21.6576 23.413 21.1365C23.3607 20.6153 23.7605 20.1519 24.3053 20.1028C24.85 20.0528 25.3345 20.4353 25.3858 20.9564Z'
					fill='#3C7EFF'
				/>
				<path
					d='M116.863 34.5041C116.915 35.0252 116.515 35.4887 115.971 35.5378C115.426 35.5869 114.941 35.2053 114.89 34.6841C114.838 34.163 115.238 33.6995 115.782 33.6504C116.327 33.6005 116.812 33.9829 116.863 34.5041Z'
					fill='#3C7EFF'
				/>
				<path
					d='M243.513 128.956C243.566 129.478 243.166 129.941 242.621 129.99C242.076 130.039 241.592 129.658 241.54 129.136C241.488 128.615 241.888 128.152 242.433 128.103C242.978 128.053 243.462 128.435 243.513 128.956Z'
					fill='#3C7EFF'
				/>
				<path
					d='M57.2087 22.7869C58.2734 22.7869 59.1365 21.9612 59.1365 20.9426C59.1365 19.9241 58.2734 19.0984 57.2087 19.0984C56.144 19.0984 55.2809 19.9241 55.2809 20.9426C55.2809 21.9612 56.144 22.7869 57.2087 22.7869Z'
					fill='#3C7EFF'
				/>
				<path
					d='M213.583 135.787C214.648 135.787 215.511 134.961 215.511 133.943C215.511 132.924 214.648 132.098 213.583 132.098C212.519 132.098 211.655 132.924 211.655 133.943C211.655 134.961 212.519 135.787 213.583 135.787Z'
					fill='#3C7EFF'
				/>
				<path
					d='M31.3977 78.992C32.4624 78.992 33.3256 78.1663 33.3256 77.1477C33.3256 76.1292 32.4624 75.3035 31.3977 75.3035C30.333 75.3035 29.4699 76.1292 29.4699 77.1477C29.4699 78.1663 30.333 78.992 31.3977 78.992Z'
					fill='#3C7EFF'
				/>
			</svg>
		</span>
	);
};

export default AccessForbiddenResult;
