/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Notification Center
 */

import { NovuProvider, PopoverNotificationCenter, IMessage } from '@novu/notification-center';
import { useNavigate } from 'react-router-dom';

import NotificationBell from './NotificationBell';
import config from 'configs/index';
import useNovuThemeStyle from './useNovuThemeStyle';
import { useAppValues } from 'store/app';

const NotificationCenter: React.FC = () => {
	const navigate = useNavigate();

	const { novuConfig } = useAppValues();
	const styles = useNovuThemeStyle();
	const handleNotificationClick = (message: IMessage) => {
		if (message?.cta?.data?.url) {
			navigate(message.cta.data.url);
		}
	};

	const getFooter = () => <></>;

	return (
		<NovuProvider
			subscriberId={novuConfig?.subscriberId}
			backendUrl={config.novu.backendUrl}
			socketUrl={config.novu.socketUrl}
			applicationIdentifier={novuConfig?.environmentIdentifier ?? ''}
			styles={styles}
		>
			<PopoverNotificationCenter
				colorScheme='light'
				showUserPreferences={false}
				footer={getFooter}
				onNotificationClick={handleNotificationClick}
			>
				{({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
			</PopoverNotificationCenter>
		</NovuProvider>
	);
};

export default NotificationCenter;
