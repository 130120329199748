/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description hook to delete saved search
 */

import log from 'loglevel';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import api from 'client/index';
import { QUERY_SAVED_SEARCH } from 'hooks/useSavedSearch';
import { ResponseModel } from '@dexit/common/openapi';

const useDeleteSavedSearch = (): UseMutationResult<ResponseModel, unknown, string, unknown> => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (payload) => {
			if (!payload) {
				throw new Error('Saved Search Id not found');
			}
			const { data } = await api.SavedSearchApi.deleteSavedSearchById(payload);
			return data;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries([QUERY_SAVED_SEARCH]).catch((err) => log.error(err));
			EventBus.publish(Events.M_Success, data.message);
		},
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default useDeleteSavedSearch;
