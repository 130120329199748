/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Half Circle Filled
 */


import classnames from 'classnames';

const HalfCircleFilled: React.FC<any> = (props) => {
	const { className, style, ...restProps } = props;
	const fillColor = style?.color ?? '#FFA940';
	const cn = classnames('anticon anticon-half-circle-filled', className);
	return (
		<span role='img' aria-label='Half-circle-filled' className={cn} {...restProps}>
			<svg
				aria-label='Half-circle-filled'
				width='14'
				height='14'
				viewBox='0 0 16 16'
				fill={fillColor}
				xmlns='http://www.w3.org/2000/svg'
			>
				<g clipPath='url(#clip0_8094_148858)'>
					<path
						d='M8 0C5.87824 0 3.84336 0.84288 2.3432 2.3432C0.842944 3.84352 0 5.8784 0 8C0 10.1216 0.84288 12.1566 2.3432 13.6568C3.84352 15.1571 5.8784 16 8 16C10.1216 16 12.1566 15.1571 13.6568 13.6568C15.1571 12.1565 16 10.1216 16 8C16 6.59568 15.6304 5.21616 14.9282 4C14.2261 2.78387 13.2162 1.77392 12 1.07184C10.7839 0.369648 9.40435 3.19824e-05 8.00003 3.19824e-05L8 0ZM8 14.4C6.30256 14.4 4.67472 13.7257 3.47456 12.5254C2.27437 11.3252 1.6 9.69728 1.6 8C1.6 6.30272 2.27432 4.67472 3.47456 3.47456C4.67482 2.27437 6.30272 1.6 8 1.6C9.69728 1.6 11.3253 2.27432 12.5254 3.47456C13.7256 4.67482 14.4 6.30272 14.4 8C14.4 9.12344 14.1043 10.227 13.5426 11.2C12.9809 12.1729 12.1729 12.9808 11.2 13.5426C10.2271 14.1042 9.12336 14.4 8 14.4Z'
						fill={fillColor}
					/>
					<path
						d='M3.59961 8.00161C3.59961 9.16855 4.06318 10.2877 4.88836 11.1128C5.71348 11.938 6.83268 12.4016 7.99956 12.4016V3.60156C6.83262 3.60156 5.71348 4.06513 4.88836 4.89031C4.06318 5.71543 3.59961 6.83463 3.59961 8.00151V8.00161Z'
						fill={fillColor}
					/>
				</g>
				<defs>
					<clipPath id='clip0_8094_148858'>
						<rect width='16' height='16' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</span>
	);
};

export default HalfCircleFilled;
