/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description RetrieveDoc store
 */

import _ from 'lodash';
import { Moment } from 'moment';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { DeficiencyModel, GetDeficiencyResponseModel } from '@dexit/common/openapi';

export enum SignatureStatus {
	CREATED = 'created',
	ASSIGNED = 'assigned',
	REQUESTED = 'requested',
	SIGNED = 'signed',
	TEMPSIGNED = 'temp-signed',
}

export interface SignatureRequests {
	id: string;
	assignee?: GetDeficiencyResponseModel['assignee'];
	date?: Moment;
	comment?: string;
	status?: SignatureStatus;
	xattrs?: { [key: string]: any };
	isFocused?: boolean;
}

type State = {
	pdfRenderScale: number;
	pageToView: { pageNum: number; scroll: boolean };
	isRequestSignatureMode: boolean;
	signatureRequests: SignatureRequests[];
	signRequestsFromApi: ((GetDeficiencyResponseModel | DeficiencyModel) & {
		isFocused?: boolean;
	})[];
	currentOpenPopoverId: string | null;
};

type Actions = {
	setPdfRenderScale: (scale: number) => { scale: number };
	setPageToView: (pageNum?: number, scroll?: boolean) => { pageNum: number; scroll: boolean };
	setRequestSignatureMode: (isRequestSignatureMode: boolean) => { isRequestSignatureMode: boolean };
	setSignatureRequests: (requests: SignatureRequests[]) => { requests: SignatureRequests[] };
	addSignatureRequest: (request: SignatureRequests) => { request: SignatureRequests };
	removeSignatureRequestById: (id: string) => { id: string };
	updateSignatureRequestById: (
		id: string,
		data: SignatureRequests,
	) => {
		id: string;
		data: SignatureRequests;
	};
	setSignRequestsFromApi: (
		signRequestsFromApi: ((GetDeficiencyResponseModel | DeficiencyModel) & {
			isFocused?: boolean;
		})[],
	) => {
		signRequestsFromApi: ((GetDeficiencyResponseModel | DeficiencyModel) & {
			isFocused?: boolean;
		})[];
	};
	addFocusInSignatureRequests: (id: string | null) => { id: string | null };
	addFocusInSignReqFromApi: (id: string | null) => { id: string | null };
	updateSignRequestFromApi: (
		id: string,
		data: Partial<
			(GetDeficiencyResponseModel | DeficiencyModel) & {
				isFocused?: boolean;
			}
		>,
	) => {
		id: string;
		data: Partial<
			(GetDeficiencyResponseModel | DeficiencyModel) & {
				isFocused?: boolean;
			}
		>;
	};
	setCurrentOpenPopoverId: (id: string | null) => { id: string | null };
};

const initialState: State = {
	pdfRenderScale: 1,
	pageToView: { pageNum: 0, scroll: false },
	isRequestSignatureMode: false,
	signatureRequests: [],
	signRequestsFromApi: [],
	currentOpenPopoverId: null,
};

const actions = (set: any): Actions => ({
	setPdfRenderScale: (pdfRenderScale) => set({ pdfRenderScale }),
	setPageToView: (pageNum, scroll) => set({ pageToView: { pageNum, scroll } }),
	setRequestSignatureMode: (isRequestSignatureMode) => set({ isRequestSignatureMode }),

	setSignatureRequests: (signatureRequests) => set({ signatureRequests }),
	addSignatureRequest: (request) =>
		set((state: State) => ({ signatureRequests: [...state.signatureRequests, request] })),
	updateSignatureRequestById: (id, data) =>
		set((state: State) => ({
			signatureRequests: _.map(state.signatureRequests, (request) =>
				request.id === id ? { ...request, ...data } : request,
			),
		})),
	addFocusInSignatureRequests: (id) =>
		set((state: State) => ({
			signatureRequests: _.map(state.signatureRequests, (request) =>
				request.id === id ? { ...request, isFocused: true } : { ...request, isFocused: false },
			),
		})),
	removeSignatureRequestById: (id) =>
		set((state: State) => ({
			signatureRequests: _.filter(state.signatureRequests, (request) => request.id !== id),
		})),

	setSignRequestsFromApi: (signRequestsFromApi) => set({ signRequestsFromApi }),
	addFocusInSignReqFromApi: (id) =>
		set((state: State) => ({
			signRequestsFromApi: _.map(state.signRequestsFromApi, (request) =>
				request.id === id ? { ...request, isFocused: true } : { ...request, isFocused: false },
			),
		})),
	updateSignRequestFromApi: (id, data) =>
		set((state: State) => ({
			signRequestsFromApi: _.map(state.signRequestsFromApi, (request) =>
				request.id === id ? { ...request, ...data } : request,
			),
		})),

	setCurrentOpenPopoverId: (currentOpenPopoverId) => set({ currentOpenPopoverId }),
});

const useRetrieveDocValues = (): State =>
	useRetrieveDocStore((state) => ({
		pdfRenderScale: state.pdfRenderScale,
		pageToView: state.pageToView,
		isRequestSignatureMode: state.isRequestSignatureMode,
		signatureRequests: state.signatureRequests,
		signRequestsFromApi: state.signRequestsFromApi,
		currentOpenPopoverId: state.currentOpenPopoverId,
	}));

const useRetrieveDocActions = (): Actions =>
	useRetrieveDocStore((state) => ({
		setPdfRenderScale: state.setPdfRenderScale,
		setPageToView: state.setPageToView,
		setRequestSignatureMode: state.setRequestSignatureMode,
		setSignatureRequests: state.setSignatureRequests,
		addSignatureRequest: state.addSignatureRequest,
		removeSignatureRequestById: state.removeSignatureRequestById,
		updateSignatureRequestById: state.updateSignatureRequestById,
		addFocusInSignatureRequests: state.addFocusInSignatureRequests,
		setSignRequestsFromApi: state.setSignRequestsFromApi,
		addFocusInSignReqFromApi: state.addFocusInSignReqFromApi,
		updateSignRequestFromApi: state.updateSignRequestFromApi,
		setCurrentOpenPopoverId: state.setCurrentOpenPopoverId,
	}));

const useRetrieveDocStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			...actions(set),
		}),
		{ name: 'RetrieveDoc Store' },
	),
);

export default useRetrieveDocStore;
export { useRetrieveDocValues, useRetrieveDocActions };
