/* eslint-disable no-bitwise */
/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Modal to Scan Custom Files
 */

import Dynamsoft from 'dwt';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import { Col, Divider, Form, Modal, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { DeviceConfiguration, Device } from 'dwt/dist/types/WebTwain.Acquire';
import { RemoteScanObject } from 'dwt/dist/types/RemoteScan';
import { WebTwain } from 'dwt/dist/types/WebTwain';

import BatchForm from 'components/batch-scan/BatchForm';
import Configurations from 'components/scan/Configurations';
import EventBus, { Events } from '@dexit/common/utils/EventBus';
import PixelType from 'components/scan/PixelType';
import Resolution from 'components/scan/Resolution';
import config from 'configs/config.integration';
import matomoconfig from 'configs/matomo.config';
import useCreateBatch from 'hooks/mutations/useCreateBatch';
import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import { isWebTwain } from './uploadHelper';

const remote = '-remote';

interface ScannerForm {
	service: number;
	scanner: string;
	adf: boolean;
	twoSidedScan: boolean;
	pixelType: 0 | 1 | 2;
	resolution: 100 | 200 | 300;
	name: string;
	queue: string;
}

interface IProps {
	batchId?: string;
	queueId?: string;
	localDwt: WebTwain;
	onLocalDwtChange: React.Dispatch<React.SetStateAction<WebTwain | undefined>>;
}

const Scan: React.FC<IProps> = (props) => {
	const { batchId, queueId, localDwt, onLocalDwtChange } = props;
	const { trackEvent } = useMatomo();
	const [form] = Form.useForm<ScannerForm>();

	const [loadingDevices, setLoadingDevices] = useState(false);
	const [remoteDwt, setRemoteDwt] = useState<RemoteScanObject>();
	const [deviceOptions, setDeviceOptions] = useState<Device[]>([]);

	const createBatch = useCreateBatch();

	const options: DefaultOptionType[] | undefined = useMemo(() => {
		if (localDwt) {
			return _.map(localDwt.GetSourceNames() as string[], (scanner) => ({
				label: scanner,
				value: scanner,
			}));
		} else {
			return [];
		}
	}, [localDwt]);

	const loadRemoteDevices = async () => {
		const deviceType =
			Dynamsoft.DWT.EnumDWT_DeviceType.TWAINSCANNER |
			Dynamsoft.DWT.EnumDWT_DeviceType.WIASCANNER |
			Dynamsoft.DWT.EnumDWT_DeviceType.TWAINX64SCANNER |
			Dynamsoft.DWT.EnumDWT_DeviceType.ICASCANNER |
			Dynamsoft.DWT.EnumDWT_DeviceType.SANESCANNER |
			Dynamsoft.DWT.EnumDWT_DeviceType.ESCLSCANNER |
			Dynamsoft.DWT.EnumDWT_DeviceType.WIFIDIRECTSCANNER;

		let remObj: RemoteScanObject;
		await Dynamsoft.DWT.CreateRemoteScanObjectAsync(config.dwt.serverUrl)
			.then((rsObject) => {
				remObj = rsObject;
				setRemoteDwt(rsObject);
				setLoadingDevices(true);
				return rsObject.getDynamsoftService();
			})
			.then((ser) => {
				if (ser.length > 0) {
					remObj
						.getDevices({ serviceInfo: ser[0], deviceType })
						.then((devices) => setDeviceOptions(devices))
						.catch((exp) => log.error(exp))
						.finally(() => setLoadingDevices(false));
				}
			})
			.catch((error) => {
				log.error(error);
				setLoadingDevices(false);
			});
	};

	useEffect(() => void loadRemoteDevices(), []);

	const closeModal = () => {
		form.resetFields();
		onLocalDwtChange(undefined);
		setRemoteDwt(undefined);
	};

	const handleScan = (values: ScannerForm) => {
		const { adf, scanner, twoSidedScan, pixelType, resolution, name, queue } = values;
		const DWObject = _.endsWith(scanner, remote) ? remoteDwt : localDwt;
		if (DWObject) {
			if (isWebTwain(DWObject)) {
				DWObject.IfShowProgressBar = false;
			} else {
				DWObject.showProgressBar = false;
			}
			const deviceConfiguration: DeviceConfiguration = {};
			if (isWebTwain(DWObject)) {
				deviceConfiguration.SelectSourceByIndex = _.findIndex(options, { value: scanner });
			}
			deviceConfiguration.IfFeederEnabled = adf;

			deviceConfiguration.IfDuplexEnabled = twoSidedScan;
			deviceConfiguration.PixelType = pixelType;
			deviceConfiguration.Resolution = resolution;
			deviceConfiguration.IfShowUI = false;

			const payload = { id: batchId, name, pages: 0, source: 'Scan', queue };
			createBatch.mutate(payload, {
				onSuccess: (data) => {
					EventBus.publish(Events.FILE_UPLOAD_REQ, {
						batchId: batchId ?? data.id,
						DWObject,
						deviceConfiguration,
						device: isWebTwain(DWObject)
							? undefined
							: deviceOptions[_.findIndex(deviceOptions, { name: _.replace(scanner, remote, '') })],
					});
					closeModal();
					trackEvent({
						category: 'Import - Scan',
						action: matomoconfig.actions.UPLOAD,
						name: 'Uploaded file',
					});
				},
			});
		}
	};

	return (
		<Modal
			destroyOnClose
			centered={true}
			title='Scan & add files'
			open={!_.isUndefined(remoteDwt) && !_.isUndefined(localDwt)}
			width={800}
			okText='Scan'
			okButtonProps={{ type: 'primary', loading: createBatch.isLoading }}
			cancelButtonProps={{ type: 'text' }}
			onOk={form.submit}
			onCancel={closeModal}
		>
			<Row>
				<Col lg={16}>
					<Form form={form} layout='vertical' className='ant-secondary-form-item' onFinish={handleScan}>
						<Form.Item
							name='scanner'
							label='Select Scanner'
							shouldUpdate={(prevValues, curValues) =>
								_.get(prevValues, 'service') !== _.get(curValues, 'service')
							}
						>
							<Select
								loading={loadingDevices}
								disabled={loadingDevices}
								placeholder='Choose a scanner'
								options={_.concat(
									options,
									_.map(deviceOptions, (device) => ({
										label: `${device.name} - Remote`,
										value: `${device.name}${remote}`,
									})),
								)}
								notFoundContent='No Scanner Found'
							/>
						</Form.Item>
						<Configurations />
						<PixelType />
						<Resolution />
						<Divider dashed />
						<BatchForm queueId={queueId} />
					</Form>
				</Col>
			</Row>
		</Modal>
	);
};

export default Scan;
