/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DeficiencyRequestModel } from '../Models';
// @ts-ignore
import { DocumentDeficiencyResponseModel } from '../Models';
// @ts-ignore
import { GetDeficiencyResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { SignDeficiencyRequestModel } from '../Models';
/**
 * DeficiencyApi - axios parameter creator
 * @export
 */
export const DeficiencyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentid 
         * @param {Array<DeficiencyRequestModel>} deficiencyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeficiency: async (documentid: string, deficiencyRequestModel: Array<DeficiencyRequestModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentid' is not null or undefined
            assertParamExists('createDeficiency', 'documentid', documentid)
            // verify required parameter 'deficiencyRequestModel' is not null or undefined
            assertParamExists('createDeficiency', 'deficiencyRequestModel', deficiencyRequestModel)
            const localVarPath = `/api/v1/deficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documentid !== undefined) {
                localVarQueryParameter['documentid'] = documentid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deficiencyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deficiencyid] 
         * @param {string} [documentid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeficiency: async (deficiencyid?: string, documentid?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/deficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (deficiencyid !== undefined) {
                localVarQueryParameter['deficiencyid'] = deficiencyid;
            }

            if (documentid !== undefined) {
                localVarQueryParameter['documentid'] = documentid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [documentid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeficiency: async (id?: string, documentid?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/deficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (documentid !== undefined) {
                localVarQueryParameter['documentid'] = documentid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [documentid] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDeficiencies: async (documentid?: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/deficiency/getProviderDeficiencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documentid !== undefined) {
                localVarQueryParameter['documentid'] = documentid;
            }

            if (type !== undefined) {
                localVarQueryParameter['type_'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignDeficiencyRequestModel} signDeficiencyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signDeficiency: async (signDeficiencyRequestModel: SignDeficiencyRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signDeficiencyRequestModel' is not null or undefined
            assertParamExists('signDeficiency', 'signDeficiencyRequestModel', signDeficiencyRequestModel)
            const localVarPath = `/api/v1/deficiency/signDeficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signDeficiencyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} assignee 
         * @param {string} [comment] 
         * @param {{ [key: string]: any; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeficiency: async (id: string, assignee: string, comment?: string, requestBody?: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeficiency', 'id', id)
            // verify required parameter 'assignee' is not null or undefined
            assertParamExists('updateDeficiency', 'assignee', assignee)
            const localVarPath = `/api/v1/deficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (assignee !== undefined) {
                localVarQueryParameter['assignee'] = assignee;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeficiencyApi - functional programming interface
 * @export
 */
export const DeficiencyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeficiencyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} documentid 
         * @param {Array<DeficiencyRequestModel>} deficiencyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeficiency(documentid: string, deficiencyRequestModel: Array<DeficiencyRequestModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeficiency(documentid, deficiencyRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeficiencyApi.createDeficiency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deficiencyid] 
         * @param {string} [documentid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeficiency(deficiencyid?: string, documentid?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeficiency(deficiencyid, documentid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeficiencyApi.deleteDeficiency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [documentid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeficiency(id?: string, documentid?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDeficiencyResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeficiency(id, documentid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeficiencyApi.getDeficiency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [documentid] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderDeficiencies(documentid?: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDeficiencyResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderDeficiencies(documentid, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeficiencyApi.getProviderDeficiencies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SignDeficiencyRequestModel} signDeficiencyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signDeficiency(signDeficiencyRequestModel: SignDeficiencyRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signDeficiency(signDeficiencyRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeficiencyApi.signDeficiency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} assignee 
         * @param {string} [comment] 
         * @param {{ [key: string]: any; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeficiency(id: string, assignee: string, comment?: string, requestBody?: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeficiency(id, assignee, comment, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeficiencyApi.updateDeficiency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeficiencyApi - factory interface
 * @export
 */
export const DeficiencyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeficiencyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} documentid 
         * @param {Array<DeficiencyRequestModel>} deficiencyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeficiency(documentid: string, deficiencyRequestModel: Array<DeficiencyRequestModel>, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createDeficiency(documentid, deficiencyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deficiencyid] 
         * @param {string} [documentid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeficiency(deficiencyid?: string, documentid?: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDeficiency(deficiencyid, documentid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [documentid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeficiency(id?: string, documentid?: string, options?: any): AxiosPromise<Array<GetDeficiencyResponseModel>> {
            return localVarFp.getDeficiency(id, documentid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [documentid] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDeficiencies(documentid?: string, type?: string, options?: any): AxiosPromise<Array<DocumentDeficiencyResponseModel>> {
            return localVarFp.getProviderDeficiencies(documentid, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignDeficiencyRequestModel} signDeficiencyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signDeficiency(signDeficiencyRequestModel: SignDeficiencyRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.signDeficiency(signDeficiencyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} assignee 
         * @param {string} [comment] 
         * @param {{ [key: string]: any; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeficiency(id: string, assignee: string, comment?: string, requestBody?: { [key: string]: any; }, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.updateDeficiency(id, assignee, comment, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeficiencyApi - interface
 * @export
 * @interface DeficiencyApi
 */
export interface DeficiencyApiInterface {
    /**
     * 
     * @param {string} documentid 
     * @param {Array<DeficiencyRequestModel>} deficiencyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApiInterface
     */
    createDeficiency(documentid: string, deficiencyRequestModel: Array<DeficiencyRequestModel>, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [deficiencyid] 
     * @param {string} [documentid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApiInterface
     */
    deleteDeficiency(deficiencyid?: string, documentid?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [documentid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApiInterface
     */
    getDeficiency(id?: string, documentid?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetDeficiencyResponseModel>>;

    /**
     * 
     * @param {string} [documentid] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApiInterface
     */
    getProviderDeficiencies(documentid?: string, type?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentDeficiencyResponseModel>>;

    /**
     * 
     * @param {SignDeficiencyRequestModel} signDeficiencyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApiInterface
     */
    signDeficiency(signDeficiencyRequestModel: SignDeficiencyRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {string} assignee 
     * @param {string} [comment] 
     * @param {{ [key: string]: any; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApiInterface
     */
    updateDeficiency(id: string, assignee: string, comment?: string, requestBody?: { [key: string]: any; }, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

}

/**
 * DeficiencyApi - object-oriented interface
 * @export
 * @class DeficiencyApi
 * @extends {BaseAPI}
 */
export class DeficiencyApi extends BaseAPI implements DeficiencyApiInterface {
    /**
     * 
     * @param {string} documentid 
     * @param {Array<DeficiencyRequestModel>} deficiencyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApi
     */
    public createDeficiency(documentid: string, deficiencyRequestModel: Array<DeficiencyRequestModel>, options?: RawAxiosRequestConfig) {
        return DeficiencyApiFp(this.configuration).createDeficiency(documentid, deficiencyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deficiencyid] 
     * @param {string} [documentid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApi
     */
    public deleteDeficiency(deficiencyid?: string, documentid?: string, options?: RawAxiosRequestConfig) {
        return DeficiencyApiFp(this.configuration).deleteDeficiency(deficiencyid, documentid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [documentid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApi
     */
    public getDeficiency(id?: string, documentid?: string, options?: RawAxiosRequestConfig) {
        return DeficiencyApiFp(this.configuration).getDeficiency(id, documentid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [documentid] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApi
     */
    public getProviderDeficiencies(documentid?: string, type?: string, options?: RawAxiosRequestConfig) {
        return DeficiencyApiFp(this.configuration).getProviderDeficiencies(documentid, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignDeficiencyRequestModel} signDeficiencyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApi
     */
    public signDeficiency(signDeficiencyRequestModel: SignDeficiencyRequestModel, options?: RawAxiosRequestConfig) {
        return DeficiencyApiFp(this.configuration).signDeficiency(signDeficiencyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} assignee 
     * @param {string} [comment] 
     * @param {{ [key: string]: any; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeficiencyApi
     */
    public updateDeficiency(id: string, assignee: string, comment?: string, requestBody?: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return DeficiencyApiFp(this.configuration).updateDeficiency(id, assignee, comment, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}

