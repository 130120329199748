/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Broom Icon
 */


import classnames from 'classnames';

const DexboxOutlined: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-Dexboxoutlined', className);
	return (
		<span role='img' aria-label='Dexboxoutlined' className={cn} {...restProps}>
			<svg
				width='14'
				height='14'
				viewBox='0 0 24 24'
				fill='none'
				aria-label='Dexboxoutlined'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M18 17.5C18 17.7652 17.8946 18.0196 17.7071 18.2071C17.5196 18.3946 17.2652 18.5 17 18.5H7C6.73478 18.5 6.48043 18.3946 6.29289 18.2071C6.10536 18.0196 6 17.7652 6 17.5C6 17.2348 6.10536 16.9804 6.29289 16.7929C6.48043 16.6054 6.73478 16.5 7 16.5H17C17.2652 16.5 17.5196 16.6054 17.7071 16.7929C17.8946 16.9804 18 17.2348 18 17.5ZM18 12.5H6C5.73478 12.5 5.48043 12.6054 5.29289 12.7929C5.10536 12.9804 5 13.2348 5 13.5C5 13.7652 5.10536 14.0196 5.29289 14.2071C5.48043 14.3946 5.73478 14.5 6 14.5H18C18.2652 14.5 18.5196 14.3946 18.7071 14.2071C18.8946 14.0196 19 13.7652 19 13.5C19 13.2348 18.8946 12.9804 18.7071 12.7929C18.5196 12.6054 18.2652 12.5 18 12.5ZM20 8C19.9992 8.6628 19.7356 9.29824 19.2669 9.76691C18.7982 10.2356 18.1628 10.4992 17.5 10.5H6.5C5.83696 10.5 5.20107 10.2366 4.73223 9.76777C4.26339 9.29893 4 8.66304 4 8C4 7.33696 4.26339 6.70107 4.73223 6.23223C5.20107 5.76339 5.83696 5.5 6.5 5.5H17.5C18.1628 5.50078 18.7982 5.76442 19.2669 6.23309C19.7356 6.70176 19.9992 7.3372 20 8ZM18 8C17.9999 7.86742 17.9472 7.74029 17.8535 7.64655C17.7597 7.5528 17.6326 7.50009 17.5 7.5H6.5C6.36739 7.5 6.24021 7.55268 6.14645 7.64645C6.05268 7.74021 6 7.86739 6 8C6 8.13261 6.05268 8.25979 6.14645 8.35355C6.24021 8.44732 6.36739 8.5 6.5 8.5H17.5C17.6326 8.49991 17.7597 8.4472 17.8535 8.35345C17.9472 8.25971 17.9999 8.13258 18 8Z'
					fill='#109888'
				/>
			</svg>
		</span>
	);
};

export default DexboxOutlined;
