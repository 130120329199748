/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Error Boundary component
 */
import React from 'react';
import log from 'loglevel';
import { faro } from '@grafana/faro-web-sdk';
import { useLocation, Location } from 'react-router-dom';

import { ReactExceptionResult } from 'components/exceptions';

interface IProps {
	location: Location;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends React.PureComponent<any, State> {
	// unlisten: UnregisterCallback | null = null;
	state = { hasError: false };

	static getDerivedStateFromError(): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		// You can also log the error to an error reporting service
		log.error(error, errorInfo);
		faro.api.pushError(error);
	}

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.location !== this.props.location && this.state.hasError) {
			this.setState({ hasError: false });
		}
	}

	reload = (): void => {
		this.setState({ hasError: false });
	};

	render(): React.ReactNode {
		if (this.state.hasError) {
			return <ReactExceptionResult reload={this.reload} />;
		}

		return this.props.children;
	}
}

const withLocation = (Component: React.ComponentClass) => {
	return (props: any) => {
		const location = useLocation();
		return <Component location={location} {...props} />;
	};
};

const ErrorBoundaryWithLocation = withLocation(ErrorBoundary);

export default ErrorBoundaryWithLocation;
