/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Branch One
 */


import classnames from 'classnames';

const BranchOne: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-branch-one-Outlined', className);
	return (
		<span role='img' aria-label='Branch-one' className={cn} {...restProps}>
			<svg
				width='14'
				height='14'
				viewBox='0 0 24 24'
				fill='none'
				aria-label='Branch-one'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12 6.5C12.5523 6.5 13 6.94772 13 7.5V16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V7.5C11 6.94772 11.4477 6.5 12 6.5Z'
					fill='#333333'
				/>
				<path d='M19 4.5H5V7.5H19V4.5Z' fill='#333333' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4 4.5C4 3.94772 4.44772 3.5 5 3.5H19C19.5523 3.5 20 3.94772 20 4.5V7.5C20 8.05228 19.5523 8.5 19 8.5H5C4.44772 8.5 4 8.05228 4 7.5V4.5ZM6 5.5V6.5H18V5.5H6Z'
					fill='#333333'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6.24074 11.8492C6.43073 11.6276 6.70808 11.5 7 11.5H16.9872C17.2783 11.5 17.5551 11.6269 17.745 11.8476L20.7579 15.3476C21.1182 15.7662 21.071 16.3976 20.6524 16.7579C20.2338 17.1182 19.6024 17.071 19.2421 16.6524L16.5285 13.5H7.45994L4.75926 16.6508C4.39983 17.0701 3.76853 17.1187 3.34921 16.7593C2.92988 16.3998 2.88132 15.7685 3.24074 15.3492L6.24074 11.8492Z'
					fill='#333333'
				/>
				<path d='M6 16.5H2V20.5H6V16.5Z' fill='#333333' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1 16.5C1 15.9477 1.44772 15.5 2 15.5H6C6.55228 15.5 7 15.9477 7 16.5V20.5C7 21.0523 6.55228 21.5 6 21.5H2C1.44772 21.5 1 21.0523 1 20.5V16.5ZM3 17.5V19.5H5V17.5H3Z'
					fill='#333333'
				/>
				<path d='M14 16.5H10V20.5H14V16.5Z' fill='#333333' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9 16.5C9 15.9477 9.44771 15.5 10 15.5H14C14.5523 15.5 15 15.9477 15 16.5V20.5C15 21.0523 14.5523 21.5 14 21.5H10C9.44771 21.5 9 21.0523 9 20.5V16.5ZM11 17.5V19.5H13V17.5H11Z'
					fill='#333333'
				/>
				<path d='M22 16.5H18V20.5H22V16.5Z' fill='#333333' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M17 16.5C17 15.9477 17.4477 15.5 18 15.5H22C22.5523 15.5 23 15.9477 23 16.5V20.5C23 21.0523 22.5523 21.5 22 21.5H18C17.4477 21.5 17 21.0523 17 20.5V16.5ZM19 17.5V19.5H21V17.5H19Z'
					fill='#333333'
				/>
			</svg>
		</span>
	);
};

export default BranchOne;
