/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Page util function
 */

import { ReactElement } from 'react';

const getRecursiveChildText: (reactNode?: ReactElement) => any = (reactNode?: ReactElement) => {
	const children = reactNode?.props?.children;
	if (Array.isArray(reactNode)) {
		// Multiple children
		const joinedNodes: any = [];
		reactNode.forEach((node) => {
			if (typeof node === 'object') {
				joinedNodes.push(getRecursiveChildText(node));
			} else if (typeof node === 'string') {
				joinedNodes.push(node);
			}
		});
		return joinedNodes.join(' ');
	}
	if (typeof children === 'object') {
		return getRecursiveChildText(children);
	}
	if (typeof children === 'string') {
		return reactNode?.props.children;
	}
};

export { getRecursiveChildText };
