/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { EntityDefinitionIn } from '../Models';
// @ts-ignore
import { EntityDefinitionResponseModel } from '../Models';
// @ts-ignore
import { EntityReorderRequestModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { ValidateResponseModel } from '../Models';
/**
 * CustomAttributesApi - axios parameter creator
 * @export
 */
export const CustomAttributesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [entityname] 
         * @param {boolean} [ishidden] 
         * @param {boolean} [issystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attributes: async (id?: string, entityname?: string, ishidden?: boolean, issystem?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (entityname !== undefined) {
                localVarQueryParameter['entityname'] = entityname;
            }

            if (ishidden !== undefined) {
                localVarQueryParameter['ishidden'] = ishidden;
            }

            if (issystem !== undefined) {
                localVarQueryParameter['issystem'] = issystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EntityDefinitionIn} entityDefinitionIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomAttribute: async (entityDefinitionIn: EntityDefinitionIn, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityDefinitionIn' is not null or undefined
            assertParamExists('createCustomAttribute', 'entityDefinitionIn', entityDefinitionIn)
            const localVarPath = `/api/v1/customAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityDefinitionIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomAttribute: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomAttribute', 'id', id)
            const localVarPath = `/api/v1/customAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EntityReorderRequestModel} entityReorderRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorder: async (entityReorderRequestModel: EntityReorderRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityReorderRequestModel' is not null or undefined
            assertParamExists('reorder', 'entityReorderRequestModel', entityReorderRequestModel)
            const localVarPath = `/api/v1/customAttributes/reorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityReorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityname 
         * @param {string} displayname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: async (entityname: string, displayname: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityname' is not null or undefined
            assertParamExists('validate', 'entityname', entityname)
            // verify required parameter 'displayname' is not null or undefined
            assertParamExists('validate', 'displayname', displayname)
            const localVarPath = `/api/v1/customAttributes/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (entityname !== undefined) {
                localVarQueryParameter['entityname'] = entityname;
            }

            if (displayname !== undefined) {
                localVarQueryParameter['displayname'] = displayname;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomAttributesApi - functional programming interface
 * @export
 */
export const CustomAttributesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomAttributesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [entityname] 
         * @param {boolean} [ishidden] 
         * @param {boolean} [issystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attributes(id?: string, entityname?: string, ishidden?: boolean, issystem?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityDefinitionResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attributes(id, entityname, ishidden, issystem, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomAttributesApi.attributes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EntityDefinitionIn} entityDefinitionIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomAttribute(entityDefinitionIn: EntityDefinitionIn, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomAttribute(entityDefinitionIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomAttributesApi.createCustomAttribute']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomAttribute(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomAttribute(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomAttributesApi.deleteCustomAttribute']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EntityReorderRequestModel} entityReorderRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorder(entityReorderRequestModel: EntityReorderRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorder(entityReorderRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomAttributesApi.reorder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} entityname 
         * @param {string} displayname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(entityname: string, displayname: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validate(entityname, displayname, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomAttributesApi.validate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomAttributesApi - factory interface
 * @export
 */
export const CustomAttributesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomAttributesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [entityname] 
         * @param {boolean} [ishidden] 
         * @param {boolean} [issystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attributes(id?: string, entityname?: string, ishidden?: boolean, issystem?: boolean, options?: any): AxiosPromise<Array<EntityDefinitionResponseModel>> {
            return localVarFp.attributes(id, entityname, ishidden, issystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EntityDefinitionIn} entityDefinitionIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomAttribute(entityDefinitionIn: EntityDefinitionIn, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createCustomAttribute(entityDefinitionIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomAttribute(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteCustomAttribute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EntityReorderRequestModel} entityReorderRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorder(entityReorderRequestModel: EntityReorderRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.reorder(entityReorderRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityname 
         * @param {string} displayname 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(entityname: string, displayname: string, options?: any): AxiosPromise<ValidateResponseModel> {
            return localVarFp.validate(entityname, displayname, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomAttributesApi - interface
 * @export
 * @interface CustomAttributesApi
 */
export interface CustomAttributesApiInterface {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [entityname] 
     * @param {boolean} [ishidden] 
     * @param {boolean} [issystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApiInterface
     */
    attributes(id?: string, entityname?: string, ishidden?: boolean, issystem?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityDefinitionResponseModel>>;

    /**
     * 
     * @param {EntityDefinitionIn} entityDefinitionIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApiInterface
     */
    createCustomAttribute(entityDefinitionIn: EntityDefinitionIn, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApiInterface
     */
    deleteCustomAttribute(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {EntityReorderRequestModel} entityReorderRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApiInterface
     */
    reorder(entityReorderRequestModel: EntityReorderRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} entityname 
     * @param {string} displayname 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApiInterface
     */
    validate(entityname: string, displayname: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateResponseModel>;

}

/**
 * CustomAttributesApi - object-oriented interface
 * @export
 * @class CustomAttributesApi
 * @extends {BaseAPI}
 */
export class CustomAttributesApi extends BaseAPI implements CustomAttributesApiInterface {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [entityname] 
     * @param {boolean} [ishidden] 
     * @param {boolean} [issystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApi
     */
    public attributes(id?: string, entityname?: string, ishidden?: boolean, issystem?: boolean, options?: RawAxiosRequestConfig) {
        return CustomAttributesApiFp(this.configuration).attributes(id, entityname, ishidden, issystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EntityDefinitionIn} entityDefinitionIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApi
     */
    public createCustomAttribute(entityDefinitionIn: EntityDefinitionIn, options?: RawAxiosRequestConfig) {
        return CustomAttributesApiFp(this.configuration).createCustomAttribute(entityDefinitionIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApi
     */
    public deleteCustomAttribute(id: string, options?: RawAxiosRequestConfig) {
        return CustomAttributesApiFp(this.configuration).deleteCustomAttribute(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EntityReorderRequestModel} entityReorderRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApi
     */
    public reorder(entityReorderRequestModel: EntityReorderRequestModel, options?: RawAxiosRequestConfig) {
        return CustomAttributesApiFp(this.configuration).reorder(entityReorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityname 
     * @param {string} displayname 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAttributesApi
     */
    public validate(entityname: string, displayname: string, options?: RawAxiosRequestConfig) {
        return CustomAttributesApiFp(this.configuration).validate(entityname, displayname, options).then((request) => request(this.axios, this.basePath));
    }
}

