/**
 * @author Vaibhav <Vaibhav.Mane@314ecorp.com>
 * @description Matomo config
 */

const VISIT_DIMENSIONS = {
	baseApplication: 1,
	tenant: 2,
	userName: 3,
	launchSource: 4,
	EPICUSERID: 5,
	LOGINDEPTID: 6,
	LOGINDEPTSPECIALTY: 7,
	LOGINSERVICEAREA: 8,
	LOGINSERVICEAREANAME: 9,
	USERPROVFHIRID: 10,
	SYSLOGIN: 11,
	IPADDR: 12,
	USERFULLNAME: 13,
};

const ACTION_DIMENSIONS = {
	visitDetail: 14,
	patientDetails: 15,
};

const config = {
	actions: {
		PAGEVIEW: 'Page View',
		CLICK: 'Click',
		UPLOAD: 'Upload',
	},
};

export const dimensions = {
	...ACTION_DIMENSIONS,
	...VISIT_DIMENSIONS,
};

export const ACTION_DIMENSIONS_RESET = [
	{ id: dimensions.visitDetail, value: '' },
	{ id: dimensions.patientDetails, value: '' },
];

export default config;
