/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BatchesCommittedResponseModel } from '../Models';
// @ts-ignore
import { InboundFaxesByQueueResponseModel } from '../Models';
// @ts-ignore
import { QueueDetailsResponseModel } from '../Models';
// @ts-ignore
import { TotalBatchesPerQueueModel } from '../Models';
// @ts-ignore
import { UserDetailsResponseModel } from '../Models';
// @ts-ignore
import { UserOperationPerformedModel } from '../Models';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeBatches: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/activeBatches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchesCommitted: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/batchesCommitted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficienciesAssignedByUser: async (limit: number, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('deficienciesAssignedByUser', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/deficienciesAssignedByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficienciesPending: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/deficienciesPending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficienciesPendingPerUser: async (limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('deficienciesPendingPerUser', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/deficienciesPendingPerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsInReview: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/documentsInReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsInReviewResolvedPerUser: async (limit: number, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('documentsInReviewResolvedPerUser', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/documentsInReviewResolvedPerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faxesSentPerUser: async (limit: number, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('faxesSentPerUser', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/faxesSentPerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundFaxesByQueue: async (limit: number, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('inboundFaxesByQueue', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/inboundFaxesByQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/queueDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roiCompleted: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/roiCompleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roiCompletedPerUser: async (limit: number, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('roiCompletedPerUser', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/roiCompletedPerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalBatchesPerQueue: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/totalBatchesPerQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalInboundFaxes: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/totalInboundFaxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalOutboundFaxes: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/totalOutboundFaxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails: async (limit: number, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userDetails', 'limit', limit)
            const localVarPath = `/api/v1/dashboard/userDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activeBatches(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activeBatches(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.activeBatches']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchesCommitted(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchesCommittedResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchesCommitted(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.batchesCommitted']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deficienciesAssignedByUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOperationPerformedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deficienciesAssignedByUser(limit, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.deficienciesAssignedByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deficienciesPending(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deficienciesPending(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.deficienciesPending']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deficienciesPendingPerUser(limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOperationPerformedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deficienciesPendingPerUser(limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.deficienciesPendingPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsInReview(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsInReview(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.documentsInReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsInReviewResolvedPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOperationPerformedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsInReviewResolvedPerUser(limit, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.documentsInReviewResolvedPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faxesSentPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOperationPerformedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faxesSentPerUser(limit, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.faxesSentPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboundFaxesByQueue(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboundFaxesByQueueResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inboundFaxesByQueue(limit, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.inboundFaxesByQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QueueDetailsResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.queueDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roiCompleted(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roiCompleted(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.roiCompleted']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roiCompletedPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOperationPerformedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roiCompletedPerUser(limit, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.roiCompletedPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalBatchesPerQueue(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TotalBatchesPerQueueModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totalBatchesPerQueue(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.totalBatchesPerQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalInboundFaxes(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totalInboundFaxes(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.totalInboundFaxes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalOutboundFaxes(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totalOutboundFaxes(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.totalOutboundFaxes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDetails(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDetailsResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDetails(limit, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.userDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activeBatches(options?: any): AxiosPromise<number> {
            return localVarFp.activeBatches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchesCommitted(fromDate?: string, toDate?: string, options?: any): AxiosPromise<BatchesCommittedResponseModel> {
            return localVarFp.batchesCommitted(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficienciesAssignedByUser(limit: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<UserOperationPerformedModel>> {
            return localVarFp.deficienciesAssignedByUser(limit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficienciesPending(options?: any): AxiosPromise<number> {
            return localVarFp.deficienciesPending(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deficienciesPendingPerUser(limit: number, options?: any): AxiosPromise<Array<UserOperationPerformedModel>> {
            return localVarFp.deficienciesPendingPerUser(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsInReview(options?: any): AxiosPromise<number> {
            return localVarFp.documentsInReview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsInReviewResolvedPerUser(limit: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<UserOperationPerformedModel>> {
            return localVarFp.documentsInReviewResolvedPerUser(limit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faxesSentPerUser(limit: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<UserOperationPerformedModel>> {
            return localVarFp.faxesSentPerUser(limit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundFaxesByQueue(limit: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<InboundFaxesByQueueResponseModel>> {
            return localVarFp.inboundFaxesByQueue(limit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueDetails(options?: any): AxiosPromise<Array<QueueDetailsResponseModel>> {
            return localVarFp.queueDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roiCompleted(fromDate?: string, toDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.roiCompleted(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roiCompletedPerUser(limit: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<UserOperationPerformedModel>> {
            return localVarFp.roiCompletedPerUser(limit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalBatchesPerQueue(fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<TotalBatchesPerQueueModel>> {
            return localVarFp.totalBatchesPerQueue(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalInboundFaxes(fromDate?: string, toDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.totalInboundFaxes(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalOutboundFaxes(fromDate?: string, toDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.totalOutboundFaxes(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails(limit: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<UserDetailsResponseModel>> {
            return localVarFp.userDetails(limit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - interface
 * @export
 * @interface DashboardApi
 */
export interface DashboardApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    activeBatches(options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    batchesCommitted(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<BatchesCommittedResponseModel>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    deficienciesAssignedByUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserOperationPerformedModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    deficienciesPending(options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    deficienciesPendingPerUser(limit: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserOperationPerformedModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    documentsInReview(options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    documentsInReviewResolvedPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserOperationPerformedModel>>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    faxesSentPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserOperationPerformedModel>>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    inboundFaxesByQueue(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<InboundFaxesByQueueResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    queueDetails(options?: RawAxiosRequestConfig): AxiosPromise<Array<QueueDetailsResponseModel>>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    roiCompleted(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    roiCompletedPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserOperationPerformedModel>>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    totalBatchesPerQueue(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TotalBatchesPerQueueModel>>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    totalInboundFaxes(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    totalOutboundFaxes(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    userDetails(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserDetailsResponseModel>>;

}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI implements DashboardApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public activeBatches(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).activeBatches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public batchesCommitted(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).batchesCommitted(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public deficienciesAssignedByUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).deficienciesAssignedByUser(limit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public deficienciesPending(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).deficienciesPending(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public deficienciesPendingPerUser(limit: number, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).deficienciesPendingPerUser(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public documentsInReview(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).documentsInReview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public documentsInReviewResolvedPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).documentsInReviewResolvedPerUser(limit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public faxesSentPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).faxesSentPerUser(limit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public inboundFaxesByQueue(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).inboundFaxesByQueue(limit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public queueDetails(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).queueDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public roiCompleted(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).roiCompleted(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public roiCompletedPerUser(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).roiCompletedPerUser(limit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public totalBatchesPerQueue(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).totalBatchesPerQueue(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public totalInboundFaxes(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).totalInboundFaxes(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public totalOutboundFaxes(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).totalOutboundFaxes(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public userDetails(limit: number, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).userDetails(limit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}

