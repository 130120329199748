/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description RenderButtons for RAQB
 */


import { Button } from 'antd';
import { ButtonProps } from '@react-awesome-query-builder/antd';
import { PlusOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import QueryDeleteOutlined from 'components/icons/QueryDeleteOutlined';

interface IProps extends Omit<ButtonProps, 'onClick' | 'type'> {
	type: ButtonProps['type'] | 'addRuleGroupExt';
	onClick: () => void;
}

const RenderButtons: React.FC<IProps> = (props) => {
	const { type, readonly, config, onClick } = props;

	const size = config?.settings.renderSize as SizeType;

	switch (type) {
		case 'addRule':
		case 'addGroup':
			return (
				<Button
					key={type}
					type='text'
					disabled={readonly}
					size={size}
					icon={<PlusOutlined />}
					onClick={onClick}
				>
					{type === 'addRule' ? 'Add Rule' : 'Add Group'}
				</Button>
			);
		case 'addRuleGroupExt':
			return (
				<Button key={type} disabled={readonly} size={size} icon={<PlusOutlined />} onClick={onClick}>
					Add Sub Rule
				</Button>
			);
		case 'delRule':
		case 'delGroup':
		case 'delRuleGroup':
			return (
				<Button
					className='raqb-delete-btn'
					disabled={readonly}
					icon={<QueryDeleteOutlined />}
					key={type}
					shape='circle'
					size={size}
					type='text'
					onClick={onClick}
				/>
			);
		case 'addRuleGroup':
			return (
				<Button
					key={type}
					type='text'
					disabled={readonly}
					size={size}
					icon={<PlusOutlined />}
					onClick={onClick}
				/>
			);
		default:
			return null;
	}
};

export default RenderButtons;
