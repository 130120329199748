/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description user store
 */

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
	user: User | null;
};

type Actions = {
	setUser: (user: User | null) => void;
	resetStore: () => void;
};

const initialState: State = {
	user: null,
};

const actions = (set: any): Actions => ({
	setUser: (user) => set({ user }),
	resetStore: () => set(initialState),
});

const useUserValues = (): State => {
	return useUserStore((state) => ({
		user: state.user,
	}));
};

const useUserActions = (): Actions => {
	return useUserStore((state) => ({
		setUser: state.setUser,
		resetStore: state.resetStore,
	}));
};

const useUserStore = create<State & Actions>()(
	devtools(
		(set) => {
			return {
				...initialState,
				...actions(set),
			};
		},
		{ name: 'User Store' },
	),
);

export default useUserStore;
export { useUserValues, useUserActions };
