/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DocumentTypeIn } from '../Models';
// @ts-ignore
import { DocumentTypeResponseModel } from '../Models';
// @ts-ignore
import { DocumentTypeUserModel } from '../Models';
// @ts-ignore
import { EntityDefinitionResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { ValidateResponseModel } from '../Models';
/**
 * DocumentTypeApi - axios parameter creator
 * @export
 */
export const DocumentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documenttype 
         * @param {boolean} [ishidden] 
         * @param {boolean} [issystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attributes: async (documenttype: string, ishidden?: boolean, issystem?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documenttype' is not null or undefined
            assertParamExists('attributes', 'documenttype', documenttype)
            const localVarPath = `/api/v1/documentType/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documenttype !== undefined) {
                localVarQueryParameter['documenttype'] = documenttype;
            }

            if (ishidden !== undefined) {
                localVarQueryParameter['ishidden'] = ishidden;
            }

            if (issystem !== undefined) {
                localVarQueryParameter['issystem'] = issystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentTypeIn} documentTypeIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentType: async (documentTypeIn: DocumentTypeIn, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentTypeIn' is not null or undefined
            assertParamExists('createDocumentType', 'documentTypeIn', documentTypeIn)
            const localVarPath = `/api/v1/documentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentTypeIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documenttypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentType: async (documenttypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documenttypeId' is not null or undefined
            assertParamExists('deleteDocumentType', 'documenttypeId', documenttypeId)
            const localVarPath = `/api/v1/documentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documenttypeId !== undefined) {
                localVarQueryParameter['documenttype_id'] = documenttypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isprotected] 
         * @param {boolean} [isactive] 
         * @param {boolean} [isall] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentType: async (id?: string, isprotected?: boolean, isactive?: boolean, isall?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (isprotected !== undefined) {
                localVarQueryParameter['isprotected'] = isprotected;
            }

            if (isactive !== undefined) {
                localVarQueryParameter['isactive'] = isactive;
            }

            if (isall !== undefined) {
                localVarQueryParameter['isall'] = isall;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documenttypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypeUser: async (documenttypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documenttypeId' is not null or undefined
            assertParamExists('getDocumentTypeUser', 'documenttypeId', documenttypeId)
            const localVarPath = `/api/v1/documentType/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documenttypeId !== undefined) {
                localVarQueryParameter['documenttype_id'] = documenttypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDocumentType: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('validateDocumentType', 'name', name)
            const localVarPath = `/api/v1/documentType/validateDocumentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTypeApi - functional programming interface
 * @export
 */
export const DocumentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} documenttype 
         * @param {boolean} [ishidden] 
         * @param {boolean} [issystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attributes(documenttype: string, ishidden?: boolean, issystem?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityDefinitionResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attributes(documenttype, ishidden, issystem, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentTypeApi.attributes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentTypeIn} documentTypeIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentType(documentTypeIn: DocumentTypeIn, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentType(documentTypeIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentTypeApi.createDocumentType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} documenttypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentType(documenttypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentType(documenttypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentTypeApi.deleteDocumentType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isprotected] 
         * @param {boolean} [isactive] 
         * @param {boolean} [isall] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentType(id?: string, isprotected?: boolean, isactive?: boolean, isall?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTypeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentType(id, isprotected, isactive, isall, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentTypeApi.getDocumentType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} documenttypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTypeUser(documenttypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTypeUserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTypeUser(documenttypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentTypeApi.getDocumentTypeUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateDocumentType(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateDocumentType(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentTypeApi.validateDocumentType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentTypeApi - factory interface
 * @export
 */
export const DocumentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} documenttype 
         * @param {boolean} [ishidden] 
         * @param {boolean} [issystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attributes(documenttype: string, ishidden?: boolean, issystem?: boolean, options?: any): AxiosPromise<Array<EntityDefinitionResponseModel>> {
            return localVarFp.attributes(documenttype, ishidden, issystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentTypeIn} documentTypeIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentType(documentTypeIn: DocumentTypeIn, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createDocumentType(documentTypeIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documenttypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentType(documenttypeId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDocumentType(documenttypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isprotected] 
         * @param {boolean} [isactive] 
         * @param {boolean} [isall] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentType(id?: string, isprotected?: boolean, isactive?: boolean, isall?: boolean, options?: any): AxiosPromise<Array<DocumentTypeResponseModel>> {
            return localVarFp.getDocumentType(id, isprotected, isactive, isall, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documenttypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypeUser(documenttypeId: string, options?: any): AxiosPromise<Array<DocumentTypeUserModel>> {
            return localVarFp.getDocumentTypeUser(documenttypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDocumentType(name: string, options?: any): AxiosPromise<ValidateResponseModel> {
            return localVarFp.validateDocumentType(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTypeApi - interface
 * @export
 * @interface DocumentTypeApi
 */
export interface DocumentTypeApiInterface {
    /**
     * 
     * @param {string} documenttype 
     * @param {boolean} [ishidden] 
     * @param {boolean} [issystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApiInterface
     */
    attributes(documenttype: string, ishidden?: boolean, issystem?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityDefinitionResponseModel>>;

    /**
     * 
     * @param {DocumentTypeIn} documentTypeIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApiInterface
     */
    createDocumentType(documentTypeIn: DocumentTypeIn, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} documenttypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApiInterface
     */
    deleteDocumentType(documenttypeId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isprotected] 
     * @param {boolean} [isactive] 
     * @param {boolean} [isall] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApiInterface
     */
    getDocumentType(id?: string, isprotected?: boolean, isactive?: boolean, isall?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentTypeResponseModel>>;

    /**
     * 
     * @param {string} documenttypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApiInterface
     */
    getDocumentTypeUser(documenttypeId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DocumentTypeUserModel>>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApiInterface
     */
    validateDocumentType(name: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateResponseModel>;

}

/**
 * DocumentTypeApi - object-oriented interface
 * @export
 * @class DocumentTypeApi
 * @extends {BaseAPI}
 */
export class DocumentTypeApi extends BaseAPI implements DocumentTypeApiInterface {
    /**
     * 
     * @param {string} documenttype 
     * @param {boolean} [ishidden] 
     * @param {boolean} [issystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApi
     */
    public attributes(documenttype: string, ishidden?: boolean, issystem?: boolean, options?: RawAxiosRequestConfig) {
        return DocumentTypeApiFp(this.configuration).attributes(documenttype, ishidden, issystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentTypeIn} documentTypeIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApi
     */
    public createDocumentType(documentTypeIn: DocumentTypeIn, options?: RawAxiosRequestConfig) {
        return DocumentTypeApiFp(this.configuration).createDocumentType(documentTypeIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documenttypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApi
     */
    public deleteDocumentType(documenttypeId: string, options?: RawAxiosRequestConfig) {
        return DocumentTypeApiFp(this.configuration).deleteDocumentType(documenttypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isprotected] 
     * @param {boolean} [isactive] 
     * @param {boolean} [isall] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApi
     */
    public getDocumentType(id?: string, isprotected?: boolean, isactive?: boolean, isall?: boolean, options?: RawAxiosRequestConfig) {
        return DocumentTypeApiFp(this.configuration).getDocumentType(id, isprotected, isactive, isall, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documenttypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApi
     */
    public getDocumentTypeUser(documenttypeId: string, options?: RawAxiosRequestConfig) {
        return DocumentTypeApiFp(this.configuration).getDocumentTypeUser(documenttypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypeApi
     */
    public validateDocumentType(name: string, options?: RawAxiosRequestConfig) {
        return DocumentTypeApiFp(this.configuration).validateDocumentType(name, options).then((request) => request(this.axios, this.basePath));
    }
}

