/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Auto Select
 */

import React, { useMemo } from 'react';
import _ from 'lodash';
import { Button, Divider, Select } from 'antd';
import { Hooks } from '@react-awesome-query-builder/ui';

const { useListValuesAutocomplete } = Hooks;

const SELECT_WIDTH_OFFSET_RIGHT = 48;
const DEFAULT_FONT_SIZE = '14px';
const DEFAULT_FONT_FAMILY = "'Helvetica Neue', Helvetica, Arial, sans-serif";
const Option = Select.Option;

const calcTextWidth = (str: string, fontFamily: string | null, fontSize: string) => {
	let div = document.createElement('div');
	div.innerHTML = str;
	div.style.position = 'absolute';
	div.style.float = 'left';
	div.style.position = 'absolute';
	div.style.visibility = 'hidden';
	div.style.whiteSpace = 'nowrap';
	div.style.fontFamily = fontSize;
	if (fontFamily) {
		div.style.fontSize = fontFamily;
	}
	div = document.body.appendChild(div);
	const w = div.offsetWidth;
	document.body.removeChild(div);
	return w;
};

const getChildrenTitle = (children: any): any => {
	if (_.isString(children)) {
		return children;
	}

	if (_.isArray(children)) {
		const first = _.get(children, [0]);
		if (_.isString(first)) {
			return first;
		}

		return getChildrenTitle(_.get(children, [0, 'props', 'children']));
	}
	if (_.isObject(children)) {
		return getChildrenTitle(_.get(children, ['props', 'children']));
	}
};

const getMode = (multiple: boolean, allowCustomValues: boolean) => {
	if (multiple) {
		return allowCustomValues ? 'tags' : 'multiple';
	}
};

const AutoSelect: React.FC<any> = (props) => {
	const {
		config,
		placeholder,
		allowCustomValues,
		customProps,
		value,
		readonly,
		multiple = false,
		useAsyncSearch,
		asyncListValues,
	} = props;

	const {
		open,
		onDropdownVisibleChange,
		onChange,
		isSpecialValue,
		options,
		isLoading,
		aPlaceholder,
		extendOptions,
		getOptionLabel,
		onInputChange,
	} = useListValuesAutocomplete(props, {
		debounceTimeout: 100,
		multiple,
	});

	const filteredOptions = extendOptions(options);

	const modifiedOptions = useMemo(() => {
		let defaultOptions = filteredOptions;

		if (asyncListValues) {
			const propsOption = _.map(asyncListValues, ({ value: val, children, title }) => ({
				title: children ? getChildrenTitle(children) : title,
				value: val,
			}));
			defaultOptions = _.concat(propsOption, defaultOptions);
		}

		const uniqOptions = _.uniqBy(defaultOptions, 'value');
		return _.filter(uniqOptions, (obj: any) => !_.isUndefined(obj.title) && !_.isUndefined(obj.value));
	}, [filteredOptions, asyncListValues]);

	const optionsMaxWidth = useMemo(() => {
		return modifiedOptions.reduce((max: any, option: any) => {
			return Math.max(max, calcTextWidth(option.title, null, DEFAULT_FONT_SIZE));
		}, 0);
	}, [options]);

	const { defaultSelectWidth, renderSize } = config.settings;
	const placeholderWidth = calcTextWidth(placeholder, DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE);
	const aValue = value && value.length ? value : undefined;
	const width = aValue ? null : placeholderWidth + SELECT_WIDTH_OFFSET_RIGHT;
	const dropdownWidth = optionsMaxWidth + SELECT_WIDTH_OFFSET_RIGHT;
	const minWidth = width || defaultSelectWidth;

	const style = {
		width: multiple ? undefined : minWidth,
		minWidth,
	};
	const dropdownStyle = {
		width: dropdownWidth,
	};

	const mode = getMode(multiple, allowCustomValues);

	const dynamicPlaceholder = !readonly ? aPlaceholder : '';

	const renderedOptions = modifiedOptions
		?.filter((option: any) => !option.specialValue)
		.map((option: any) => (
			<Option key={option.value} value={option.value}>
				{getOptionLabel(option)}
			</Option>
		));

	const handleClick = (specialValue: any) => () => {
		const option = modifiedOptions.find((opt: any) => opt.specialValue === specialValue);
		onChange(null, option);
	};

	const specialOptions = modifiedOptions
		?.filter((option: any) => !!option.specialValue)
		.map((option: any) => (
			<Button
				type='link'
				style={{ padding: '5px 10px', display: 'block', cursor: 'pointer' }}
				key={option.specialValue}
				onClick={() => handleClick(option.specialValue)}
			>
				{getOptionLabel(option)}
			</Button>
		));

	const handleSelect = async (label: any, option: any) => {
		if (isSpecialValue(option)) {
			await onChange(label, option);
		}
	};

	const handleChange = async (label: any, option: any) => {
		if (!isSpecialValue(option)) {
			await onChange(label, option);
		}
	};

	const dropdownRender = (menu: any) => (
		<div>
			{menu}
			{specialOptions.length > 0 && (
				<>
					<Divider style={{ margin: '0px' }} />
					<div style={{ display: 'flex', flexDirection: 'column' }}>{specialOptions}</div>
				</>
			)}
		</div>
	);

	const handleOnSearch = async (newInputValue: string) => {
		if (newInputValue === '' && !open) {
			return;
		}
		await onInputChange(null, newInputValue);
	};

	return (
		<Select
			filterOption={useAsyncSearch ? false : true}
			dropdownRender={dropdownRender}
			notFoundContent={isLoading ? 'Loading...' : null}
			disabled={readonly}
			mode={mode}
			style={customProps?.style || style}
			dropdownStyle={customProps?.dropdownStyle || dropdownStyle}
			key='widget-autocomplete'
			popupMatchSelectWidth={customProps?.dropdownMatchSelectWidth || customProps?.popupMatchSelectWidth || false}
			placeholder={customProps?.placeholder || dynamicPlaceholder}
			onDropdownVisibleChange={onDropdownVisibleChange}
			onSearch={(value) => void handleOnSearch(value)}
			showSearch
			size={renderSize}
			loading={isLoading}
			value={aValue}
			open={open}
			onChange={(value, option) => void handleChange(value, option)}
			onSelect={(value, option) => void handleSelect(value, option)}
			{..._.omit(customProps, 'dropdownMatchSelectWidth')}
			allowClear
		>
			{renderedOptions}
		</Select>
	);
};

export default AutoSelect;
