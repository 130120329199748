/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateListOfValuesRequestModel } from '../Models';
// @ts-ignore
import { GetListResponseModel } from '../Models';
// @ts-ignore
import { ListOfValuesResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { ValidateResponseModel } from '../Models';
/**
 * ListOfValuesApi - axios parameter creator
 * @export
 */
export const ListOfValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateListOfValuesRequestModel>} createListOfValuesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListOfValues: async (createListOfValuesRequestModel: Array<CreateListOfValuesRequestModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createListOfValuesRequestModel' is not null or undefined
            assertParamExists('createListOfValues', 'createListOfValuesRequestModel', createListOfValuesRequestModel)
            const localVarPath = `/api/v1/listOfValues/createListOfValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createListOfValuesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListOfValuesType: async (listofvaluestype: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listofvaluestype' is not null or undefined
            assertParamExists('deleteListOfValuesType', 'listofvaluestype', listofvaluestype)
            const localVarPath = `/api/v1/listOfValues/deleteListOfValuesType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (listofvaluestype !== undefined) {
                localVarQueryParameter['listofvaluestype'] = listofvaluestype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/listOfValues/getList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfValuesByName: async (listofvaluestype: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listofvaluestype' is not null or undefined
            assertParamExists('getListOfValuesByName', 'listofvaluestype', listofvaluestype)
            const localVarPath = `/api/v1/listOfValues/getListOfValuesByName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (listofvaluestype !== undefined) {
                localVarQueryParameter['listofvaluestype'] = listofvaluestype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateListOfValues: async (listofvaluestype: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listofvaluestype' is not null or undefined
            assertParamExists('validateListOfValues', 'listofvaluestype', listofvaluestype)
            const localVarPath = `/api/v1/listOfValues/validateListOfValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (listofvaluestype !== undefined) {
                localVarQueryParameter['listofvaluestype'] = listofvaluestype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListOfValuesApi - functional programming interface
 * @export
 */
export const ListOfValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListOfValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<CreateListOfValuesRequestModel>} createListOfValuesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createListOfValues(createListOfValuesRequestModel: Array<CreateListOfValuesRequestModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createListOfValues(createListOfValuesRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ListOfValuesApi.createListOfValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteListOfValuesType(listofvaluestype: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteListOfValuesType(listofvaluestype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ListOfValuesApi.deleteListOfValuesType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetListResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ListOfValuesApi.getList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListOfValuesByName(listofvaluestype: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListOfValuesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfValuesByName(listofvaluestype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ListOfValuesApi.getListOfValuesByName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateListOfValues(listofvaluestype: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateListOfValues(listofvaluestype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ListOfValuesApi.validateListOfValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ListOfValuesApi - factory interface
 * @export
 */
export const ListOfValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListOfValuesApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<CreateListOfValuesRequestModel>} createListOfValuesRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListOfValues(createListOfValuesRequestModel: Array<CreateListOfValuesRequestModel>, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createListOfValues(createListOfValuesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListOfValuesType(listofvaluestype: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteListOfValuesType(listofvaluestype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(options?: any): AxiosPromise<Array<GetListResponseModel>> {
            return localVarFp.getList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfValuesByName(listofvaluestype: string, options?: any): AxiosPromise<Array<ListOfValuesResponseModel>> {
            return localVarFp.getListOfValuesByName(listofvaluestype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} listofvaluestype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateListOfValues(listofvaluestype: string, options?: any): AxiosPromise<ValidateResponseModel> {
            return localVarFp.validateListOfValues(listofvaluestype, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListOfValuesApi - interface
 * @export
 * @interface ListOfValuesApi
 */
export interface ListOfValuesApiInterface {
    /**
     * 
     * @param {Array<CreateListOfValuesRequestModel>} createListOfValuesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApiInterface
     */
    createListOfValues(createListOfValuesRequestModel: Array<CreateListOfValuesRequestModel>, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} listofvaluestype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApiInterface
     */
    deleteListOfValuesType(listofvaluestype: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApiInterface
     */
    getList(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetListResponseModel>>;

    /**
     * 
     * @param {string} listofvaluestype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApiInterface
     */
    getListOfValuesByName(listofvaluestype: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ListOfValuesResponseModel>>;

    /**
     * 
     * @param {string} listofvaluestype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApiInterface
     */
    validateListOfValues(listofvaluestype: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateResponseModel>;

}

/**
 * ListOfValuesApi - object-oriented interface
 * @export
 * @class ListOfValuesApi
 * @extends {BaseAPI}
 */
export class ListOfValuesApi extends BaseAPI implements ListOfValuesApiInterface {
    /**
     * 
     * @param {Array<CreateListOfValuesRequestModel>} createListOfValuesRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApi
     */
    public createListOfValues(createListOfValuesRequestModel: Array<CreateListOfValuesRequestModel>, options?: RawAxiosRequestConfig) {
        return ListOfValuesApiFp(this.configuration).createListOfValues(createListOfValuesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} listofvaluestype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApi
     */
    public deleteListOfValuesType(listofvaluestype: string, options?: RawAxiosRequestConfig) {
        return ListOfValuesApiFp(this.configuration).deleteListOfValuesType(listofvaluestype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApi
     */
    public getList(options?: RawAxiosRequestConfig) {
        return ListOfValuesApiFp(this.configuration).getList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} listofvaluestype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApi
     */
    public getListOfValuesByName(listofvaluestype: string, options?: RawAxiosRequestConfig) {
        return ListOfValuesApiFp(this.configuration).getListOfValuesByName(listofvaluestype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} listofvaluestype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListOfValuesApi
     */
    public validateListOfValues(listofvaluestype: string, options?: RawAxiosRequestConfig) {
        return ListOfValuesApiFp(this.configuration).validateListOfValues(listofvaluestype, options).then((request) => request(this.axios, this.basePath));
    }
}

