/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Retrieve Search Outlined
 */


import classnames from 'classnames';

const RetrieveSearchOutlined: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-retrieve-search-Outlined', className);
	return (
		<span role='img' aria-label='Retrieve-search' className={cn} {...restProps}>
			<svg
				aria-label='Retrieve-search'
				width='15'
				height='15'
				viewBox='0 0 15 15'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0.585938 6.9987C0.585938 3.45488 3.45879 0.582031 7.0026 0.582031C10.5464 0.582031 13.4193 3.45488 13.4193 6.9987C13.4193 8.50036 12.9034 9.88154 12.0393 10.9747L14.4343 13.3697C14.7272 13.6626 14.7272 14.1375 14.4343 14.4304C14.1414 14.7233 13.6665 14.7233 13.3736 14.4304L10.9786 12.0354C9.88545 12.8995 8.50426 13.4154 7.0026 13.4154C3.45879 13.4154 0.585938 10.5425 0.585938 6.9987ZM7.0026 2.08203C4.28722 2.08203 2.08594 4.28331 2.08594 6.9987C2.08594 9.71408 4.28722 11.9154 7.0026 11.9154C9.71799 11.9154 11.9193 9.71408 11.9193 6.9987C11.9193 4.28331 9.71799 2.08203 7.0026 2.08203ZM4.58671 4.2494C5.20418 3.63193 6.05938 3.2487 7.00264 3.2487C7.94593 3.2487 8.80112 3.63192 9.4186 4.2494C9.71149 4.54229 9.71149 5.01717 9.4186 5.31006C9.12571 5.60295 8.65084 5.60295 8.35794 5.31006C8.01028 4.9624 7.53215 4.7487 7.00264 4.7487C6.47316 4.7487 5.99503 4.9624 5.64737 5.31006C5.35447 5.60295 4.8796 5.60295 4.58671 5.31006C4.29381 5.01717 4.29381 4.54229 4.58671 4.2494Z'
					fill='#595959'
				/>
			</svg>
		</span>
	);
};

export default RetrieveSearchOutlined;
