/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Entity Search List Item
 */


import _ from 'lodash';
import moment from 'moment';
import { List, Space, Typography, Divider, Tag } from 'antd';

import NotesOutlined from 'components/icons/NotesOutlined';
import { ENTITY } from 'constants/search';

const { Text } = Typography;
const className = 'ant-secondary-text';

interface IProps {
	data: any;
	activeKey: ENTITY;
}

const EntitySearchListItem: React.FC<IProps> = (props) => {
	const { data, activeKey } = props;

	const getDescription = () => {
		switch (data.type) {
			case ENTITY.TEXT:
				return <Text>{data.text}</Text>;
			case ENTITY.DOCUMENT_TYPES:
				return <Text>{data.name}</Text>;
			case ENTITY.USERS:
				return <Text>{_.join(_.compact([data.firstname, data.lastname]), ' ')}</Text>;
			case ENTITY.PATIENTS:
				return (
					<Space direction='vertical' style={{ width: '100%' }} size={4}>
						<Text>{_.join(_.compact([data.firstname, data.lastname]), ' ')}</Text>
						<Space split={<Divider type='vertical' />}>
							<Space>
								<Text className={className}>MRN</Text>
								<Text>{data?.mrn ?? '-'}</Text>
							</Space>
							<Space>
								<Text className={className}>SSN</Text>
								<Text>{data?.ssn ?? '-'}</Text>
							</Space>
							<Space>
								<Text className={className}>DOB</Text>
								<Text>{_.isNil(data?.birthdate) ? '-' : moment(data?.birthdate).format()}</Text>
							</Space>
						</Space>
					</Space>
				);
			case ENTITY.ENCOUNTERS:
				return (
					<Space direction='vertical' style={{ width: '100%' }} size={4}>
						<Space>
							<Text className={className}>CSN</Text>
							<Text>{data?.encounter_number ?? '-'}</Text>
						</Space>
						<Space split={<Divider type='vertical' />}>
							<Space>
								<Text className={className}>Type</Text>
								<Text>{data?.encounter_type ?? '-'}</Text>
							</Space>
							<Space>
								<Text className={className}>Date</Text>
								<Text>
									{moment(data.period?.start).format()} - {moment(data.period?.end).format()}
								</Text>
							</Space>
						</Space>
					</Space>
				);
			default:
				return (
					<Space direction='vertical' style={{ width: '100%' }} size={4}>
						<Space>
							<Text>{data?.name ?? '-'}</Text>
							<Text>{data.issystem && <Tag>System</Tag>}</Text>
						</Space>
						<Space split={<Divider type='vertical' />}>
							<Space>
								<Text className={className}>Created By</Text>
								<Text>{_.join(_.compact([data.user.firstname, data.user.lastname]), ' ')}</Text>
							</Space>
							<Space>
								<Text className={className}>Created Date</Text>
								<Text>{_.isNil(data?.creationdate) ? '-' : moment(data?.creationdate).format()}</Text>
							</Space>
						</Space>
					</Space>
				);
		}
	};

	const getTitle = () => {
		if (_.isEqual(activeKey, ENTITY.SAVED_SEARCH)) {
			return 'Saved Search';
		}
		switch (data.type) {
			case ENTITY.TEXT:
				return 'Text Contains';
			case ENTITY.DOCUMENT_TYPES:
				return 'Document Type';
			case ENTITY.USERS:
				return 'Uploaded By';
			case ENTITY.PATIENTS:
				return 'Patient';
			case ENTITY.ENCOUNTERS:
				return 'Encounter';
			default:
				return null;
		}
	};

	return (
		<List.Item.Meta
			avatar={<NotesOutlined isFilled />}
			title={<Text style={{ color: '#00000073' }}>{getTitle()}</Text>}
			description={getDescription()}
			className='entity-item-avatar'
		/>
	);
};

export default EntitySearchListItem;
