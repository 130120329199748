/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Add Files component for nav bar
 */

import Dynamsoft from 'dwt';
import React, { useState } from 'react';
import log from 'loglevel';
import { Button, ButtonProps, Dropdown, MenuProps } from 'antd';
import { PlusOutlined, ScanOutlined, UploadOutlined } from '@ant-design/icons';
import { WebTwain } from 'dwt/dist/types/WebTwain';

import ImportLocal from 'components/upload/ImportLocal';
import Scan from './Scan';
import config from 'configs/config.integration';
import { ROLES } from 'constants/roles';
import HasAccess from 'utils/HasAccess';

interface IProps {
	batchId?: string;
	queueId?: string;
	buttonText?: string;
	icon?: React.ReactNode;
}

const AddFiles: React.FC<IProps & ButtonProps> = (props) => {
	const { buttonText, batchId, queueId, icon, ...restProps } = props;

	const [importOpen, setImportOpen] = useState(false);
	const [localDwt, setLocalDwt] = useState<WebTwain>();

	const DWTLoad = () => {
		Dynamsoft.DWT.OnWebTwainPreExecute = () => log.info('pre execution scan');
		Dynamsoft.DWT.OnWebTwainPostExecute = () => log.info('post execution scan');

		Dynamsoft.DWT.ProductKey = config.dwt.productKey;
		Dynamsoft.DWT.ResourcesPath = config.dwt.resourcesPath;
		Dynamsoft.DWT.Containers = [{ WebTwainId: config.dwt.WebTwainId }];
	};

	const DWTLocalLoad = () => {
		DWTLoad();
		Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
			const DWObject = Dynamsoft.DWT.GetWebTwain(config.dwt.WebTwainId);
			setLocalDwt(DWObject);
		});
		Dynamsoft.DWT.Load().catch((err) => log.error(err));
	};

	const items: MenuProps['items'] = [
		{
			label: 'Scan',
			key: 'scan',
			icon: <ScanOutlined />,
			onClick: () => DWTLocalLoad(),
		},
		{
			label: 'Import',
			key: 'import',
			icon: <UploadOutlined />,
			onClick: () => setImportOpen(true),
		},
	];

	return (
		<HasAccess key='Upload' roles={[ROLES.documentIndexing]}>
			<Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ minWidth: '192px' }}>
				<Button
					icon={icon ?? <PlusOutlined />}
					style={{
						minWidth: 54,
						height: 36,
						borderRadius: 4,
						color: '#fff',
						backgroundColor: '#041427',
						border: '1px solid #D9D9D9',
						boxShadow: '0px 2px 0px 0px #0000000B',
					}}
					{...restProps}
				>
					{buttonText ?? ''}
				</Button>
			</Dropdown>
			{importOpen && (
				<ImportLocal batchID={batchId} queueId={queueId} open={importOpen} onModalOpen={setImportOpen} />
			)}
			{localDwt && (
				<Scan batchId={batchId} queueId={queueId} localDwt={localDwt} onLocalDwtChange={setLocalDwt} />
			)}
		</HasAccess>
	);
};

export default AddFiles;
