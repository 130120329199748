/**
 * Event Bus using pub/sub design pattern
 *
 * @author Karthik <karthik.x@314ecorp.com>
 */

import _ from 'lodash';
import log from 'loglevel';
import { faro, LogLevel } from '@grafana/faro-web-sdk';

/**
 * Events
 */
enum Events {
	FILE_UPLOAD_REQ = 'FILE_UPLOAD-REQUEST',

	M_Loading = 'M_LOADING',
	M_Warn = 'M_WARN',
	M_Info = 'M_INFO',
	M_Success = 'M_SUCCESS',
	M_Error = 'M_ERROR',

	N_Warn = 'N_WARN',
	N_Info = 'N_INFO',
	N_Success = 'N_SUCCESS',
	N_Error = 'N_ERROR',

	MO_Success = 'MO_SUCCESS',
	MO_Error = 'MO_ERROR',
	MO_Info = 'MO_INFO',
	MO_Warn = 'MO_WARN',

	Land = 'APP_LAND',
	Redirect = 'REDIRECT',
	AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
	AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE',
}

enum Messages {
	Success = 'Success.',
	Error = 'Something went wrong! Please try after sometime.',
	AuthorizationError = 'You do not have the authorization for this action. Please talk to your manager!',
}

/**
 * App Events
 *
 * @class
 */
class AppEvents {
	/**
	 * All Subscriptions
	 *
	 * @member subscribers
	 */
	subscribers: Record<string, any>;
	constructor() {
		this.subscribers = {};
	}

	/**
	 * publish an event
	 *
	 * @function publish
	 * @param {string} event event
	 * @param {...any} rest rest
	 */
	publish = (event: string, ...rest: any) => {
		log.info(`[EventBus] event: ${event}`, rest);
		if (_.has(this.subscribers, event)) {
			_.forOwn(_.get(this.subscribers, event), (callback: any) => {
				try {
					if (_.isFunction(callback)) {
						callback?.(...rest);
					} else {
						throw new Error(`${event} Not a Function`);
					}
				} catch (ex) {
					log.error(`[Eventbus] ${ex}`);
					faro.api.pushLog([`[Eventbus] ${ex}`], { level: LogLevel.ERROR });
				}
			});
		}
	};

	/**
	 * Subscribes an event
	 *
	 * @function subscribe
	 * @param {event} event event
	 * @param {Function} callback callback
	 */
	subscribe = (event: string, callback: any) => {
		if (!_.isFunction(callback)) {
			log.error(`[Eventbus] ${callback} is not a function`);
			faro.api.pushLog([`[Eventbus] ${callback} is not a function`], { level: LogLevel.ERROR });
			return;
		}

		let listeners = _.get(this.subscribers, event);
		listeners = _.isArray(listeners) ? [...listeners, callback] : [callback];
		_.setWith(this.subscribers, event, listeners, Object);
	};

	/**
	 * Un-subscribes an event
	 *
	 * @param {string} event event
	 */
	unsubscribe = (event: string) => {
		_.unset(this.subscribers, event);
	};
}

export default new AppEvents();

export { Events, Messages };
