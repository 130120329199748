/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Landing Page
 */

import React, { useMemo } from 'react';
import _ from 'lodash';
import { Layout } from 'antd';
import { matchPath, useLocation } from 'react-router-dom';

import Content from 'components/content';
import Header from 'components/header/Header';
import NavigationBar from 'components/navigationbar/NavigationBar';
import UploadCard from 'components/upload/UploadCard';
import useSearchState from 'hooks/useSearchState';
import { EXTRA_PATHS } from 'configs/menu-config';
import { RetrieveContext, SearchStateContextType } from 'components/page/RetrieveContext';

const LandingPage: React.FC = () => {
	const location = useLocation();
	const [query, setQuery, setPaginationAndSort] = useSearchState();

	const contextValue: SearchStateContextType = useMemo(
		() => ({
			query,
			setQuery,
			setPaginationAndSort,
		}),
		[query, setQuery, setPaginationAndSort],
	);

	const getContent = () => (
		<>
			<Content />
			<UploadCard />
		</>
	);

	const config = useMemo(
		() =>
			_.find(EXTRA_PATHS, (item) => {
				const match = matchPath(item.url ?? '', location.pathname);
				return match !== null;
			}),
		[location.pathname],
	);

	return (
		<Layout>
			<RetrieveContext.Provider value={contextValue}>
				<Layout>
					{!config?.fullscreen && (
						<Layout.Header>
							<Header />
						</Layout.Header>
					)}
					<Layout hasSider className='main-layout'>
						<NavigationBar />
						<Layout.Content>{getContent()}</Layout.Content>
					</Layout>
				</Layout>
			</RetrieveContext.Provider>
		</Layout>
	);
};

export default LandingPage;
