/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description navigation store
 */

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
	selectedKeys: string[];
};

type Actions = {
	setSelectedKeys: (selectedKeys: string[]) => { selectedKeys: string[] };
};

const initialState: State = {
	selectedKeys: ['queues'],
};

const actions = (set: any): Actions => ({
	setSelectedKeys: (selectedKeys: string[]) => set({ selectedKeys }),
});

const useNavigationValues = (): State =>
	useNavigationStore((state) => ({
		selectedKeys: state.selectedKeys,
	}));

const useNavigationActions = (): Actions =>
	useNavigationStore((state) => ({
		setSelectedKeys: state.setSelectedKeys,
	}));

const useNavigationStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			...actions(set),
		}),
		{ name: 'Navigation Store' },
	),
);

export default useNavigationStore;
export { useNavigationValues, useNavigationActions };
