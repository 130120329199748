/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description useOnScreen
 */

import { RefObject, useEffect, useMemo, useState } from 'react';

const useOnScreen = (
	ref: RefObject<HTMLElement>,
	threshold: number | number[] = 0.4,
): { isIntersecting: boolean; intersectingRatio: number } => {
	const [isIntersecting, setIntersecting] = useState(false);
	const [intersectingRatio, setIntersectingRatio] = useState(0);

	const observer = useMemo(
		() =>
			new IntersectionObserver(
				([entry]) => {
					setIntersecting(entry.isIntersecting);
					setIntersectingRatio(entry.intersectionRatio);
				},
				{ threshold },
			),
		[ref],
	);

	useEffect(() => {
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => observer.disconnect();
	}, []);

	return { isIntersecting, intersectingRatio };
};

export default useOnScreen;
