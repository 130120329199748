/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Excel Outlined
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
}

const ExcelOutlined: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-exceloutlined', className);
	return (
		<span role='img' aria-label='Exceloutlined' className={cn} {...restProps}>
			<svg
				width='14'
				height='15'
				viewBox='0 0 14 15'
				aria-label='Exceloutlined'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1.75 2.17448C1.75 1.53015 2.27233 1.00781 2.91667 1.00781H8.75C8.90471 1.00781 9.05308 1.06927 9.16248 1.17867L12.0791 4.09533C12.1885 4.20473 12.25 4.3531 12.25 4.50781V12.6745C12.25 13.3188 11.7277 13.8411 11.0833 13.8411H2.91667C2.27234 13.8411 1.75 13.3188 1.75 12.6745V2.17448ZM8.50838 2.17448H2.91667V12.6745H11.0833V4.74944L8.50838 2.17448ZM4.95833 5.67448C4.95833 5.35231 5.2195 5.09115 5.54167 5.09115H8.45833C8.7805 5.09115 9.04167 5.35231 9.04167 5.67448C9.04167 5.99664 8.7805 6.25781 8.45833 6.25781H6.125V7.42448H8.45833C8.7805 7.42448 9.04167 7.68565 9.04167 8.00781C9.04167 8.32998 8.7805 8.59115 8.45833 8.59115H6.125V9.75781H8.45833C8.7805 9.75781 9.04167 10.019 9.04167 10.3411C9.04167 10.6633 8.7805 10.9245 8.45833 10.9245H5.54167C5.2195 10.9245 4.95833 10.6633 4.95833 10.3411V8.00781V5.67448Z'
					fill='#00B58E'
				/>
			</svg>
		</span>
	);
};

export default ExcelOutlined;
