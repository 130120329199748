/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Dragger Icon
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
}

const Dragger: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-dragger', className);
	return (
		<span role='img' aria-label='Dragger' className={cn} {...restProps}>
			<svg
				width='17'
				height='16'
				viewBox='0 0 17 16'
				fill='none'
				aria-label='Dragger'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.15592 3.33333C7.15592 4.0697 6.55896 4.66667 5.82259 4.66667C5.08622 4.66667 4.48926 4.0697 4.48926 3.33333C4.48926 2.59695 5.08622 2 5.82259 2C6.55896 2 7.15592 2.59695 7.15592 3.33333ZM5.82259 9.33333C6.55896 9.33333 7.15592 8.73637 7.15592 8C7.15592 7.26363 6.55896 6.66667 5.82259 6.66667C5.08622 6.66667 4.48926 7.26363 4.48926 8C4.48926 8.73637 5.08622 9.33333 5.82259 9.33333ZM5.82259 14C6.55896 14 7.15592 13.403 7.15592 12.6667C7.15592 11.9303 6.55896 11.3333 5.82259 11.3333C5.08622 11.3333 4.48926 11.9303 4.48926 12.6667C4.48926 13.403 5.08622 14 5.82259 14Z'
					fill='#595959'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M13.1559 3.33333C13.1559 4.0697 12.559 4.66667 11.8226 4.66667C11.0862 4.66667 10.4893 4.0697 10.4893 3.33333C10.4893 2.59695 11.0862 2 11.8226 2C12.559 2 13.1559 2.59695 13.1559 3.33333ZM11.8226 9.33333C12.559 9.33333 13.1559 8.73637 13.1559 8C13.1559 7.26363 12.559 6.66667 11.8226 6.66667C11.0862 6.66667 10.4893 7.26363 10.4893 8C10.4893 8.73637 11.0862 9.33333 11.8226 9.33333ZM11.8226 14C12.559 14 13.1559 13.403 13.1559 12.6667C13.1559 11.9303 12.559 11.3333 11.8226 11.3333C11.0862 11.3333 10.4893 11.9303 10.4893 12.6667C10.4893 13.403 11.0862 14 11.8226 14Z'
					fill='#595959'
				/>
			</svg>
		</span>
	);
};

export default Dragger;
