/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Environment Utils
 */

class Environment {
	static INTEGRATION = 'integration';
	static PRODUCTION = 'production';
	static LOCALHOST = 'localhost';

	isLocalhost = () => {
		const hostname = globalThis.location.hostname;
		return hostname === Environment.LOCALHOST;
	};

	isIntegration = () => {
		const hostname = globalThis.location.hostname;
		return hostname === Environment.LOCALHOST || hostname.split('.').pop() === 'tech';
	};

	isProduction = () => {
		return !this.isIntegration();
	};

	getEnvironment = () => {
		return this.isIntegration() ? Environment.INTEGRATION : Environment.PRODUCTION;
	};

	getHighlightEnvironment = () => {
		if (this.isLocalhost()) {
			return 'development';
		}

		if (this.isIntegration()) {
			return 'staging';
		}

		return Environment.PRODUCTION;
	};
}

const env = new Environment();
export default env;
