/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description config
 */

import Environment from '@dexit/common/utils/Environment';
import integration from './config.integration';
import production from './config.production';

const config = Environment.isIntegration() ? integration : production;
export default config;
