/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AuditHistory } from '../Models';
// @ts-ignore
import { AuditHistoryRequestModel } from '../Models';
// @ts-ignore
import { AuditHistoryResponseModel } from '../Models';
// @ts-ignore
import { BatchReportResponseModel } from '../Models';
// @ts-ignore
import { QueueReportResponseModel } from '../Models';
// @ts-ignore
import { UserReportResponseModel } from '../Models';
/**
 * AuditHistoryApi - axios parameter creator
 * @export
 */
export const AuditHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [queueId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReport: async (queueId?: string, fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auditHistory/batchReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (queueId !== undefined) {
                localVarQueryParameter['queue_id'] = queueId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [auditId] 
         * @param {string} [entityId] 
         * @param {string} [entityName] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditHistory: async (auditId?: string, entityId?: string, entityName?: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auditHistory/getAuditHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['audit_id'] = auditId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (entityName !== undefined) {
                localVarQueryParameter['entity_name'] = entityName;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedAuditHistory: async (auditHistoryRequestModel: AuditHistoryRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditHistoryRequestModel' is not null or undefined
            assertParamExists('getDetailedAuditHistory', 'auditHistoryRequestModel', auditHistoryRequestModel)
            const localVarPath = `/api/v1/auditHistory/getDetailedAuditHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditHistoryRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [draftId] 
         * @param {string} [sortType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftAuditHistory: async (draftId?: string, sortType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auditHistory/getDraftAuditHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (draftId !== undefined) {
                localVarQueryParameter['draft_id'] = draftId;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['sort_type'] = sortType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueReport: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auditHistory/queueReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userReport: async (fromDate?: string, toDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auditHistory/userReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditHistoryApi - functional programming interface
 * @export
 */
export const AuditHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [queueId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReport(queueId?: string, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchReportResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReport(queueId, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditHistoryApi.batchReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [auditId] 
         * @param {string} [entityId] 
         * @param {string} [entityName] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditHistory(auditId?: string, entityId?: string, entityName?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditHistory(auditId, entityId, entityName, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditHistoryApi.getAuditHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditHistoryResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedAuditHistory(auditHistoryRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditHistoryApi.getDetailedAuditHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [draftId] 
         * @param {string} [sortType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftAuditHistory(draftId?: string, sortType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftAuditHistory(draftId, sortType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditHistoryApi.getDraftAuditHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueReport(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QueueReportResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueReport(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditHistoryApi.queueReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userReport(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserReportResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userReport(fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditHistoryApi.userReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuditHistoryApi - factory interface
 * @export
 */
export const AuditHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [queueId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReport(queueId?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<BatchReportResponseModel>> {
            return localVarFp.batchReport(queueId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [auditId] 
         * @param {string} [entityId] 
         * @param {string} [entityName] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditHistory(auditId?: string, entityId?: string, entityName?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<AuditHistory>> {
            return localVarFp.getAuditHistory(auditId, entityId, entityName, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: any): AxiosPromise<AuditHistoryResponseModel> {
            return localVarFp.getDetailedAuditHistory(auditHistoryRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [draftId] 
         * @param {string} [sortType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftAuditHistory(draftId?: string, sortType?: string, options?: any): AxiosPromise<Array<AuditHistory>> {
            return localVarFp.getDraftAuditHistory(draftId, sortType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueReport(fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<QueueReportResponseModel>> {
            return localVarFp.queueReport(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userReport(fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<UserReportResponseModel>> {
            return localVarFp.userReport(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditHistoryApi - interface
 * @export
 * @interface AuditHistoryApi
 */
export interface AuditHistoryApiInterface {
    /**
     * 
     * @param {string} [queueId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApiInterface
     */
    batchReport(queueId?: string, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BatchReportResponseModel>>;

    /**
     * 
     * @param {string} [auditId] 
     * @param {string} [entityId] 
     * @param {string} [entityName] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApiInterface
     */
    getAuditHistory(auditId?: string, entityId?: string, entityName?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AuditHistory>>;

    /**
     * 
     * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApiInterface
     */
    getDetailedAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<AuditHistoryResponseModel>;

    /**
     * 
     * @param {string} [draftId] 
     * @param {string} [sortType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApiInterface
     */
    getDraftAuditHistory(draftId?: string, sortType?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AuditHistory>>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApiInterface
     */
    queueReport(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<QueueReportResponseModel>>;

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApiInterface
     */
    userReport(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserReportResponseModel>>;

}

/**
 * AuditHistoryApi - object-oriented interface
 * @export
 * @class AuditHistoryApi
 * @extends {BaseAPI}
 */
export class AuditHistoryApi extends BaseAPI implements AuditHistoryApiInterface {
    /**
     * 
     * @param {string} [queueId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApi
     */
    public batchReport(queueId?: string, fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return AuditHistoryApiFp(this.configuration).batchReport(queueId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [auditId] 
     * @param {string} [entityId] 
     * @param {string} [entityName] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApi
     */
    public getAuditHistory(auditId?: string, entityId?: string, entityName?: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return AuditHistoryApiFp(this.configuration).getAuditHistory(auditId, entityId, entityName, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditHistoryRequestModel} auditHistoryRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApi
     */
    public getDetailedAuditHistory(auditHistoryRequestModel: AuditHistoryRequestModel, options?: RawAxiosRequestConfig) {
        return AuditHistoryApiFp(this.configuration).getDetailedAuditHistory(auditHistoryRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [draftId] 
     * @param {string} [sortType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApi
     */
    public getDraftAuditHistory(draftId?: string, sortType?: string, options?: RawAxiosRequestConfig) {
        return AuditHistoryApiFp(this.configuration).getDraftAuditHistory(draftId, sortType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApi
     */
    public queueReport(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return AuditHistoryApiFp(this.configuration).queueReport(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHistoryApi
     */
    public userReport(fromDate?: string, toDate?: string, options?: RawAxiosRequestConfig) {
        return AuditHistoryApiFp(this.configuration).userReport(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}

