/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Wrapper Function logic for rendering components on roles
 */

import React, { PropsWithChildren } from 'react';

import useIsAuthorized from './useIsAuthorized';
import { operators } from './isAuthorized';

interface IProps {
	roles: string[];
	operator?: operators;
}

const HasAccess: React.FC<PropsWithChildren<IProps>> = (props) => {
	const { roles, operator = operators.AND, children } = props;
	const authorized = useIsAuthorized(roles, operator);

	return authorized ? <>{children}</> : null;
};

export default HasAccess;
