/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description app store
 */

import _ from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { DEFAULT_DATE_FORMAT, DEFAULT_TIMEZONE, DEFAULT_TIME_FORMAT } from 'constants/defaults';

interface INovuConfig {
	subscriberId: string;
	environmentIdentifier: string;
	slackIntegrationUrl: string;
}

interface Status {
	isLoading: boolean;
	isError: boolean;
	status: number;
	message: string;
}

type State = {
	status: Status;
	webItems: MenuItem[];
	webConfig: MenuItem[];
	collapsed: boolean;
	dateFormat: string;
	timeFormat: string;
	dateAndTimeFormat: string;
	timezone: string;
	companyDomain: string;
	companyName: string;
	companyAddress: object;
	novuConfig: INovuConfig | null;
	defaultTemplate: { roi?: string; fax?: string };
};

type Actions = {
	setStatus: (status: Status) => {
		status: Status;
	};
	setWebConfig: (
		webConfig: MenuItem[],
		webItems: readonly MenuItem[],
	) => {
		webConfig: MenuItem[];
		webItems: MenuItem[];
	};
	setCollapsed: (collapsed: boolean) => { collapsed: boolean };
	setFormats: (
		dateFormat: string,
		timeFormat: string,
		timezone: string,
	) => {
		dateFormat: string;
		timeFormat: string;
		timezone: string;
	};
	setNovuConfig: (novuConfig: INovuConfig) => {
		novuConfig: INovuConfig;
	};
	setDefaultTemplate: (defaultTemplate: { roi: string; fax: string }) => {
		defaultTemplate: { roi: string; fax: string };
	};
};

const initialState: State = {
	status: {
		isLoading: true,
		isError: false,
		status: 200,
		message: 'Initializing ... ',
	},
	webItems: [],
	webConfig: [],
	collapsed: true,
	novuConfig: null,
	companyDomain: '',
	companyName: '',
	companyAddress: {},
	defaultTemplate: {},
	dateFormat: DEFAULT_DATE_FORMAT,
	timeFormat: DEFAULT_TIME_FORMAT,
	dateAndTimeFormat: DEFAULT_DATE_FORMAT,
	timezone: DEFAULT_TIMEZONE,
};

const actions = (set: any): Actions => ({
	setStatus: (status) =>
		set({
			status,
		}),
	setWebConfig: (webConfig, webItems) =>
		set({
			webConfig,
			webItems,
		}),
	setCollapsed: (collapsed) => set({ collapsed }),
	setFormats: (dateFormat, timeFormat, timezone) =>
		set({
			dateFormat,
			timeFormat,
			timezone,
			dateAndTimeFormat: _.join([dateFormat, timeFormat], ' '),
		}),
	setNovuConfig: (novuConfig) => set({ novuConfig }),
	setDefaultTemplate: (defaultTemplate) => set({ defaultTemplate }),
});

const useAppValues = (): State => {
	return useAppStore((state) => ({
		status: state.status,
		webItems: state.webItems,
		webConfig: state.webConfig,
		collapsed: state.collapsed,
		dateFormat: state.dateFormat,
		timeFormat: state.timeFormat,
		dateAndTimeFormat: state.dateAndTimeFormat,
		timezone: state.timezone,
		novuConfig: state.novuConfig,
		companyDomain: state.companyDomain,
		companyName: state.companyName,
		companyAddress: state.companyAddress,
		defaultTemplate: state.defaultTemplate,
	}));
};

const useAppActions = (): Actions => {
	return useAppStore((state) => ({
		setStatus: state.setStatus,
		setWebConfig: state.setWebConfig,
		setCollapsed: state.setCollapsed,
		setFormats: state.setFormats,
		setNovuConfig: state.setNovuConfig,
		setDefaultTemplate: state.setDefaultTemplate,
	}));
};

const useAppStore = create<State & Actions>()(
	devtools(
		(set) => {
			return {
				...initialState,
				...actions(set),
			};
		},
		{ name: 'App Store' },
	),
);

export default useAppStore;
export { useAppValues, useAppActions };
