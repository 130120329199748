/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description URL utils
 */
import _ from 'lodash';

const getBasePath = (): string | undefined => {
	let path = _.get(window, 'basePath') as unknown as string;
	path = _.isString(path) ? path.slice(1, -1) : '';
	return _.isEmpty(path) ? undefined : path;
};

const basePath = getBasePath();

export { getBasePath, basePath };
