/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Ohif Viewer
 */


import Environment from '@dexit/common/utils/Environment';
import { DEFAULT_SERVER_URL } from '@dexit/common/constants/url';
interface IProps {
	StudyInstanceUIDs?: string;
	SeriesInstanceUID?: string;
	initialSOPInstanceUID?: string;
}
const OHIFViewer: React.FC<IProps> = (props) => {
	const { StudyInstanceUIDs } = props;
	const hostname = Environment.isLocalhost() ? new URL(DEFAULT_SERVER_URL).hostname : globalThis.location.hostname;
	return (
		<iframe
			title='OHIF viewer'
			src={`https://${hostname}/dicomviewer/viewer/${StudyInstanceUIDs}/`}
			height='100%'
			width='100%'
		/>
	);
};

export default OHIFViewer;
