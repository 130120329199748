/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description EHR Helper
 */

import _ from 'lodash';
import log from 'loglevel';

interface IUser {
	userId: string;
	userName: string;
	patientId?: string;
	patientName?: string;
	email: string;
	roles: string[];
}

export type TokenDataType =
	| {
			state: string;
			code: string;
			launchedFromEpic: boolean;
			query: string;
	  }
	| {
			launchedFromEpic: boolean;
			query: string;
			state?: string;
			code?: string;
	  };

/**
 * isLaunchedFromEpic
 */
const isLaunchedFromEpic = (): TokenDataType => {
	const urlContent = new URL(location.href);
	const code = urlContent.searchParams.get('code');
	const state = urlContent.searchParams.get('state');
	const query = urlContent.searchParams.get('query');
	if (state && code) {
		return {
			state,
			code,
			launchedFromEpic: true,
			query: query ?? '',
		};
	}
	return {
		launchedFromEpic: false,
		query: query ?? '',
	};
};

class EHRHelper {
	isLaunchedFromEhr = false;
	query = '';
	accessToken = '';
	handshakeToken = '';
	epicToken = '';
	defaultQuery = '';
	tokenResponse: Record<string, any> = {};
	user: IUser = {
		userId: '',
		userName: '',
		patientId: undefined,
		patientName: undefined,
		email: '',
		roles: [],
	};

	constructor() {
		const { launchedFromEpic, query } = isLaunchedFromEpic();
		this.isLaunchedFromEhr = launchedFromEpic;
		this.query = query;
	}

	// Here need to set token after read token via ehr
	setAccessToken(token: string) {
		this.accessToken = token;
	}

	getToken() {
		return this.accessToken;
	}

	setHandshakeToken = (token: string) => {
		this.handshakeToken = token;
	};

	setEpicToken = (newToken: string) => {
		this.epicToken = newToken;
	};

	setTokenResponse = (tokenResp: any) => {
		this.defaultQuery = _.get(tokenResp, 'query', this.defaultQuery);
	};

	setUserDetails = (token: string) => {
		try {
			const subToken = _.get(_.split(token, '.'), [1]);
			const parsed = subToken ? JSON.parse(globalThis.atob(subToken)) : { sub: '' };
			this.user = {
				userId: parsed.sub,
				userName: parsed.name,
				patientId: undefined,
				patientName: undefined,
				email: parsed.email,
				roles: _.get(parsed, ['resource_access', _.toLower('dexit'), 'roles'], []),
			};
		} catch (e) {
			log.error('error while parsing token');
		}
	};
}

const ehrHelperInstance = new EHRHelper();

export { ehrHelperInstance };
