/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description constants
 */

const tempFolderPath = 'tmp';
const ROOT_URL = '/';

const defaultStampDims = { top: 0, left: 0, width: 200, height: 50 };
const defaultSignatureDims = { top: 0, left: 0, width: 170, height: 42 };

export { tempFolderPath, ROOT_URL, defaultStampDims, defaultSignatureDims };
