/**
 * @author Harsh Raj <harsh.raj@314ecorp.com>
 * @description Server Exception Result
 */


import { Button, Result } from 'antd';

interface IProps {
	reload?: () => void;
}

const ServerExceptionResult: React.FC<IProps> = () => {
	const reload = () => {
		location.reload();
	};
	return (
		<Result
			title='With non-stop actions, Dexit is taking a breather. Hit the Reload button to get back in action'
			extra={
				<Button key='refresh' onClick={reload}>
					Reload
				</Button>
			}
		/>
	);
};

export default ServerExceptionResult;
