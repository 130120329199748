/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Epic Entry
 */

import React, { ComponentType, useEffect, useState } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import { KeycloakTokenParsed } from 'keycloak-js';
import { Layout } from 'antd';
import { Routes } from 'react-router-dom';

import EpicDocViewer from 'pages/EpicDocViewer';
import api from 'client/index';
import bootstrap from 'utils/bootstrap';
import config from 'configs/index';
import { dimensions } from 'configs/matomo.config';
import { ehrHelperInstance } from 'utils/EHRHelper';

window.SHOW_LOGS = true;

const Listener = (event: any): void => {
	for (const type in event.data) {
		const payload = event.data[type];
		switch (type) {
			case 'token':
				ehrHelperInstance.setHandshakeToken(payload);
				log.info('found handshake token');
				break;
			case 'error':
				log.error('Handshake error');
				break;
			case 'actionExecuted':
				log.info('Launched');
				break;
			default:
				break;
		}
	}
};

const EpicApp: React.FC = () => {
	const { trackEvent, pushInstruction } = useMatomo();

	const [docIdData, setDocIdData] = useState({ isLoading: true, docId: '' });
	const [error, setError] = useState(false);

	useEffect(() => {
		(async (): Promise<void> => {
			api.init();
			const FHIR: any = _.get(window, 'FHIR');
			if (FHIR) {
				try {
					await FHIR.oauth2.init({});
					const client = await FHIR.oauth2.ready();
					const tokenResponse = _.get(client, 'state.tokenResponse');

					globalThis.addEventListener('message', Listener, false);

					globalThis.parent.postMessage(
						{
							action: 'Epic.Clinical.Informatics.Web.InitiateHandshake',
						},
						'*',
					);

					ehrHelperInstance.setEpicToken(_.get(tokenResponse, 'access_token'));
					ehrHelperInstance.setAccessToken(_.get(tokenResponse, 'id_token'));
					ehrHelperInstance.setTokenResponse(tokenResponse);

					setDocIdData((prevData) => ({ ...prevData, docId: _.get(tokenResponse, 'docid') }));
					const { data } = await api.EPICIntegrationApi.epicintegrationLinkEpicKeycloakUser(
						document.location.origin,
						tokenResponse,
					);

					const accessToken: string = _.get(data, 'access_token') ?? '';

					if (accessToken) {
						ehrHelperInstance.setAccessToken(accessToken);
						ehrHelperInstance.setUserDetails(accessToken);
						setDocIdData((prevData) => ({ ...prevData, isLoading: false }));
						const parsedToken: KeycloakTokenParsed = JSON.parse(globalThis.atob(accessToken.split('.')[1]));
						const resourceAccess = _.get(
							parsedToken,
							['resource_access', _.toLower(config.keycloak.config.clientId), 'roles'],
							[],
						);
						const user: User = {
							id: _.get(parsedToken, 'sub'),
							email: _.get(tokenResponse, 'USEREMAIL'),
							firstName: _.get(tokenResponse, 'USERFNAME'),
							lastName: _.get(tokenResponse, 'USERLNAME'),
							roles: resourceAccess,
						};
						bootstrap(user);

						pushInstruction('setUserId', _.get(parsedToken, 'sub'));
						trackEvent({
							category: 'Login',
							action: 'Login',
							name: 'Login',
							customDimensions: [
								{
									id: dimensions.userName,
									value: _.get(parsedToken, 'preferred_username', _.get(parsedToken, 'email')),
								},
								{
									id: dimensions.launchSource,
									value: 'Web UI',
								},
								{
									id: dimensions.baseApplication,
									value: 'Epic',
								},
								{
									id: dimensions.EPICUSERID,
									value: _.get(tokenResponse, 'EPICUSERID'),
								},
								{
									id: dimensions.LOGINDEPTID,
									value: _.get(tokenResponse, 'LOGINDEPTID'),
								},
								{
									id: dimensions.LOGINDEPTSPECIALTY,
									value: _.get(tokenResponse, 'LOGINDEPTSPECIALTY'),
								},
								{
									id: dimensions.LOGINSERVICEAREA,
									value: _.get(tokenResponse, 'LOGINSERVICEAREA'),
								},
								{
									id: dimensions.LOGINSERVICEAREANAME,
									value: _.get(tokenResponse, 'LOGINSERVICEAREANAME'),
								},
								{
									id: dimensions.USERPROVFHIRID,
									value: _.get(tokenResponse, 'USERPROVFHIRID'),
								},
								{
									id: dimensions.SYSLOGIN,
									value: _.get(tokenResponse, 'SYSLOGIN'),
								},
								{
									id: dimensions.IPADDR,
									value: _.get(tokenResponse, 'IPADDR'),
								},
								{
									id: dimensions.USERFULLNAME,
									value: _.join(_.compact([user.firstName, user.lastName]), ' '),
								},
							],
						});

						window.SHOW_LOGS = _.includes(resourceAccess, '_developer');
						const btn = document.getElementById('error-log-button');
						if (btn && window.SHOW_LOGS) {
							btn.style.display = 'block';
						}

						if (btn && !window.SHOW_LOGS) {
							btn.style.display = 'none';
						}

						globalThis.removeEventListener('message', Listener, false);
					} else {
						throw new Error('No access token found');
					}
				} catch (ex) {
					setDocIdData((prevData) => ({ ...prevData, isLoading: false }));
					setError(true);
					log.error(ex);
				}
			} else {
				setDocIdData((prevData) => ({ ...prevData, isLoading: false }));
				setError(true);
				log.error('FHIR is not defined');
			}
		})().catch((err) => log.error(err));
		document.getElementById('splash-screen')?.remove();
		return () => {
			globalThis.removeEventListener('message', Listener, false);
		};
	}, []);

	const getContent = () => {
		if (docIdData.isLoading) {
			return <div> loading... </div>;
		} else if (error) {
			return <div> Oops... something went wrong</div>;
		} else {
			return (
				<React.Suspense fallback={<h1>Taking longer than expected</h1>}>
					<Routes>{!_.isEmpty(docIdData.docId) && <EpicDocViewer id={docIdData.docId} />}</Routes>
				</React.Suspense>
			);
		}
	};
	return <Layout>{getContent()}</Layout>;
};

export default EpicApp as ComponentType<unknown>;
