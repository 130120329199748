/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Entry
 */

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useEffect, useState } from 'react';
import { message, notification, Modal } from 'antd';

import 'styles/index';
import EventBus, { Events } from '@dexit/common/utils/EventBus';
import withProviders from './withProviders';
import { ehrHelperInstance } from 'utils/EHRHelper';

import SecureApp from '../app/SecureApp';
import EpicApp from '../app/EpicApp';


const App: React.FC = () => {
	const [ready, setReady] = useState(false);
	const [isEhrLaunch, setIsEhrLaunch] = useState(false);

	const initializeEventBus = () => {
		message.config({ top: 50, duration: 2, maxCount: 1 });

		EventBus.subscribe(Events.M_Loading, message.loading);
		EventBus.subscribe(Events.M_Warn, message.warning);
		EventBus.subscribe(Events.M_Info, message.info);
		EventBus.subscribe(Events.M_Success, message.success);
		EventBus.subscribe(Events.M_Error, message.error);

		EventBus.subscribe(Events.N_Success, notification.success);
		EventBus.subscribe(Events.N_Error, notification.error);
		EventBus.subscribe(Events.N_Info, notification.info);
		EventBus.subscribe(Events.N_Warn, notification.warning);

		EventBus.subscribe(Events.MO_Success, Modal.success);
		EventBus.subscribe(Events.MO_Error, Modal.error);
		EventBus.subscribe(Events.MO_Info, Modal.info);
		EventBus.subscribe(Events.MO_Warn, Modal.warn);
	};

	useEffect(() => {
		if (ehrHelperInstance.isLaunchedFromEhr) {
			setIsEhrLaunch(true);
		}
		setReady(true);
		initializeEventBus();
	}, []);

	if (ready && isEhrLaunch) {
		return <EpicApp />;
	}

	if (ready && !isEhrLaunch) {
		return <SecureApp />;
	}
	return <div> Initializing....</div>;
};

const AppWithProviders = withProviders(App);

export default AppWithProviders;
