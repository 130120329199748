/**
 * @author Vaibhav Mane <vaibhav.mane@314ecorp.com>
 * @description Default constants
 */

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
const DEFAULT_TIME_FORMAT = 'hh:mm A';
const DEFAULT_TIMEZONE = 'America/Los_Angeles';
const DEFAULT_LOCALE = 'en-US';
const DEFAULT_CURRENCY = 'USD';
const DEFAULT_APP_NAME = 'Dexit';

export {
	DEFAULT_DATE_FORMAT,
	DEFAULT_TIME_FORMAT,
	DEFAULT_TIMEZONE,
	DEFAULT_LOCALE,
	DEFAULT_CURRENCY,
	DEFAULT_APP_NAME,
};
