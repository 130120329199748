/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description
 */

import _ from 'lodash';
const DEFAULT_TENANT_NAME = 'test5';

export const getTenantName: () => string = () => {
	const hostname = _.get(window, 'location.hostname');
	const isDev = _.includes(hostname, 'localhost');
	const tenantFromHostname = _.first(_.split(hostname, '.'));
	return isDev ? DEFAULT_TENANT_NAME : (tenantFromHostname ?? '');
};
