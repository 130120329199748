/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description string constants for roles
 */

const ENTITY = {
	unsignedCopy: {
		name: 'unsigned-copy' as const,
		title: 'Unsigned Copy',
		url: '/unsigned-copy/:id',
	},
	retrieve: {
		name: 'retrieve' as const,
		title: 'Retrieval',
		url: '/retrieval/:id',
	},
	bulkImport: {
		name: 'bulk-import' as const,
		title: 'bulk-import',
		url: '/bulk-import/:id',
	},
	faxes: {
		name: 'faxes' as const,
		title: 'faxes',
		url: '/faxes/:id',
	},
};

export { ENTITY };
