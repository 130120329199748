/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description custom attribute field config for RAQB
 */

import _ from 'lodash';
import log from 'loglevel';
import { FieldOrGroup } from '@react-awesome-query-builder/antd';

import api from 'client/index';
import { EntityDefinitionResponseModel } from '@dexit/common/openapi';

const getFieldItem = (field: EntityDefinitionResponseModel): FieldOrGroup => {
	switch (field.definition.widgettype) {
		case 'Number':
			return {
				label: field.displayname,
				type: 'number',
			};
		case 'Date':
			return {
				label: field.displayname,
				type: 'date',
			};
		case 'DateTime':
			return {
				label: field.displayname,
				type: 'datetime',
			};
		case 'Dropdown':
			return {
				label: field.displayname,
				type: _.get(field.definition.widget_props, 'dropdownType') === 'MultiSelect' ? 'multiselect' : 'select',
				fieldSettings: {
					useAsyncSearch: false,
					showSearch: true,
					asyncFetch: async () => {
						try {
							if (!field.definition.listofvaluestype) {
								throw new Error('List Of Value Name not found');
							}
							const { data } = await api.ListOfValuesApi.getListOfValuesByName(
								field.definition.listofvaluestype,
							);
							return {
								values: _.map(data, (item) => ({
									value: item.name,
									title: item.name,
								})),
								hasMore: false,
							};
						} catch (ex) {
							log.error(ex);
							return {
								values: [],
								hasMore: false,
							};
						}
					},
				},
			};
		case 'Text':
		case 'Currency':
		default:
			return {
				label: field.displayname,
				type: 'text',
			};
	}
};

export default getFieldItem;
