/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { QueueRequestModel } from '../Models';
// @ts-ignore
import { QueueResponseModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { UserModel } from '../Models';
// @ts-ignore
import { ValidateResponseModel } from '../Models';
/**
 * QueueApi - axios parameter creator
 * @export
 */
export const QueueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {QueueRequestModel} queueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQueue: async (queueRequestModel: QueueRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueRequestModel' is not null or undefined
            assertParamExists('createQueue', 'queueRequestModel', queueRequestModel)
            const localVarPath = `/api/v1/queue/createQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} queueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueUser: async (queueId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('getQueueUser', 'queueId', queueId)
            const localVarPath = `/api/v1/queue/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (queueId !== undefined) {
                localVarQueryParameter['queue_id'] = queueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues: async (id?: string, isActive?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/queue/getQueues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateQueue: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('validateQueue', 'name', name)
            const localVarPath = `/api/v1/queue/validateQueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueueApi - functional programming interface
 * @export
 */
export const QueueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {QueueRequestModel} queueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQueue(queueRequestModel: QueueRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQueue(queueRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueueApi.createQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} queueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueueUser(queueId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueueUser(queueId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueueApi.getQueueUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueues(id?: string, isActive?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QueueResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueues(id, isActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueueApi.getQueues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateQueue(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateQueue(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QueueApi.validateQueue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QueueApi - factory interface
 * @export
 */
export const QueueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueueApiFp(configuration)
    return {
        /**
         * 
         * @param {QueueRequestModel} queueRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQueue(queueRequestModel: QueueRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createQueue(queueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} queueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueUser(queueId: string, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.getQueueUser(queueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueues(id?: string, isActive?: boolean, options?: any): AxiosPromise<Array<QueueResponseModel>> {
            return localVarFp.getQueues(id, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateQueue(name: string, options?: any): AxiosPromise<ValidateResponseModel> {
            return localVarFp.validateQueue(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueueApi - interface
 * @export
 * @interface QueueApi
 */
export interface QueueApiInterface {
    /**
     * 
     * @param {QueueRequestModel} queueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApiInterface
     */
    createQueue(queueRequestModel: QueueRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} queueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApiInterface
     */
    getQueueUser(queueId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserModel>>;

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApiInterface
     */
    getQueues(id?: string, isActive?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<QueueResponseModel>>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApiInterface
     */
    validateQueue(name: string, options?: RawAxiosRequestConfig): AxiosPromise<ValidateResponseModel>;

}

/**
 * QueueApi - object-oriented interface
 * @export
 * @class QueueApi
 * @extends {BaseAPI}
 */
export class QueueApi extends BaseAPI implements QueueApiInterface {
    /**
     * 
     * @param {QueueRequestModel} queueRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public createQueue(queueRequestModel: QueueRequestModel, options?: RawAxiosRequestConfig) {
        return QueueApiFp(this.configuration).createQueue(queueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} queueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public getQueueUser(queueId: string, options?: RawAxiosRequestConfig) {
        return QueueApiFp(this.configuration).getQueueUser(queueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public getQueues(id?: string, isActive?: boolean, options?: RawAxiosRequestConfig) {
        return QueueApiFp(this.configuration).getQueues(id, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public validateQueue(name: string, options?: RawAxiosRequestConfig) {
        return QueueApiFp(this.configuration).validateQueue(name, options).then((request) => request(this.axios, this.basePath));
    }
}

