/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description Get all Attributes
 */

import _ from 'lodash';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import api from 'client/index';
import { EntityDefinitionResponseModel as CustomAttributes } from '@dexit/common/openapi/Models/entity-definition-response-model';

const QUERY_ALL_ATTRIBUTES_KEY = 'query-allattributes';

const useAllAttributes = (
	entityName = 'document',
	isHidden?: boolean,
	isSystem?: boolean,
	options?: UseQueryOptions<unknown, unknown, any, any>,
): UseQueryResult<CustomAttributes[], unknown> => {
	return useQuery({
		queryKey: [QUERY_ALL_ATTRIBUTES_KEY, entityName, isHidden, isSystem],
		queryFn: async () => {
			const { data } = await api.CustomAttributesApi.attributes(undefined, entityName, isHidden, isSystem);
			return _.sortBy(data, (entity) => entity.definition?.sort_order);
		},
		...options,
		onError: () => EventBus.publish(Events.M_Error, Messages.Error),
	});
};

export default useAllAttributes;
export { QUERY_ALL_ATTRIBUTES_KEY };
