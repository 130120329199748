/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Notification Bell
 */


import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';

interface IProps {
	unseenCount?: number;
}
const NotificationBell: React.FC<IProps> = (props) => {
	const { unseenCount } = props;

	return (
		<div className='notification-bell-container'>
			<Badge count={unseenCount} overflowCount={99} size='small' className='unseen-count-badge'>
				<BellOutlined style={{ fontSize: 16 }} />
			</Badge>
		</div>
	);
};
export default NotificationBell;
