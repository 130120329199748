/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Render Conjunctions for RAQB
 */


import _ from 'lodash';
import { Button } from 'antd';
import { ConjsProps } from '@react-awesome-query-builder/antd';

const ButtonGroup = Button.Group;

interface ConjButtonProps extends Partial<ConjsProps> {
	item: any;
}

const ConjsButton: React.FC<ConjButtonProps> = ({ disabled, item, setConjunction }) => {
	const onClick = () => {
		const conj = item.key;
		setConjunction?.(conj);
	};

	return (
		<Button disabled={disabled} type={item.checked ? 'primary' : undefined} onClick={onClick}>
			{item.label}
		</Button>
	);
};

const RenderConjunctions: React.FC<ConjsProps> = (props) => {
	const { disabled, readonly, not, conjunctionOptions, config, notLabel, showNot, setNot, setConjunction } = props;

	const onSetNot = () => {
		if (setNot) {
			setNot(!not);
		}
	};

	const conjsCount = Object.keys(conjunctionOptions ?? {}).length;
	const lessThenTwo = disabled;
	const { forceShowConj } = config?.settings || {};
	const showConj = (forceShowConj || conjsCount > 1) && !lessThenTwo;

	return (
		<ButtonGroup key='group-conjs-buttons' size={_.get(config?.settings, 'renderSize', 'middle')}>
			{showNot && (readonly ? not : true) && (
				<Button key={'group-not'} onClick={onSetNot} type={not ? 'primary' : undefined} disabled={readonly}>
					{notLabel}
				</Button>
			)}
			{showConj &&
				_.map(conjunctionOptions, (item) =>
					(readonly || disabled) && !item.checked ? null : (
						<ConjsButton
							key={item.id}
							item={item}
							disabled={disabled || readonly}
							setConjunction={setConjunction}
						/>
					),
				)}
		</ButtonGroup>
	);
};

export default RenderConjunctions;
