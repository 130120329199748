/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Universal Search Popup
 */

import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Config, Utils } from '@react-awesome-query-builder/antd';
import { List, Menu, Row, Col, Typography, Space, Badge } from 'antd';
import { MenuProps } from 'antd/lib';

import EntitySearchListItem from './EntitySearchListItem';
import useKeyPress from './useKeyPress';
import { ENTITY } from 'constants/search';
import { EntitySearchResponseModel, SavedSearchResponseModel } from '@dexit/common/openapi';
import { filterQuery } from './utils';

const MENU_ITEMS = [
	{ key: ENTITY.SAVED_SEARCH, label: 'Saved searches' },
	{ key: ENTITY.ALL, label: 'All' },
	{ key: ENTITY.DOCUMENT_TYPES, label: 'Document Types' },
	{ key: ENTITY.PATIENTS, label: 'Patients' },
	{ key: ENTITY.ENCOUNTERS, label: 'Encounters' },
	{ key: ENTITY.USERS, label: 'Users' },
];
interface IProps {
	data?: EntitySearchResponseModel;
	savedSearchData?: SavedSearchResponseModel[];
	loading: boolean;
	typedText: string;
	visible: boolean;
	fetchingSavedSearch: boolean;
	config: Config;
	onSelect: (item: any) => void;
}

const initialState = { selectedIndex: -1 };
const reducer = (state: any, action: any) => {
	const maxLen = action.maxLen;
	const currentIndex = state.selectedIndex;

	switch (action.type) {
		case 'arrowUp':
			if (_.isEqual(currentIndex, -1)) {
				return state;
			} else {
				const newIndex = _.isEqual(currentIndex, 0) ? maxLen - 1 : currentIndex - 1;
				return {
					selectedIndex: newIndex,
				};
			}
		case 'arrowDown': {
			const newIndex = _.isEqual(currentIndex, maxLen - 1) ? 0 : currentIndex + 1;
			return { selectedIndex: newIndex };
		}
		case 'select':
			return {
				selectedIndex: action.payload,
			};
		default:
			throw new Error();
	}
};

const UniversalSearchPopup: React.FC<IProps> = (props) => {
	const { data, typedText, visible, loading, savedSearchData, fetchingSavedSearch, config, onSelect } = props;

	const selectedRef = useRef(null);
	const [activeKey, setActiveKey] = useState<ENTITY>(ENTITY.SAVED_SEARCH);
	const [dataSource, setDataSource] = useState<any>(savedSearchData);
	const [state, dispatch] = useReducer(reducer, initialState);

	const arrowUpPressed = useKeyPress('ArrowUp');
	const arrowDownPressed = useKeyPress('ArrowDown');

	useEffect(() => {
		let dataSource: any;
		switch (activeKey) {
			case ENTITY.SAVED_SEARCH:
				dataSource = savedSearchData;
				break;
			case ENTITY.ALL:
				dataSource = _.flatMap(data);
				break;
			default:
				dataSource = data ? _.get(data, activeKey) : [];
				break;
		}
		if (!visible) {
			setActiveKey(ENTITY.SAVED_SEARCH);
			setDataSource(savedSearchData);
		} else if (_.isEmpty(typedText) || _.isEqual(activeKey, ENTITY.SAVED_SEARCH)) {
			setDataSource(dataSource);
		} else {
			const [tree, spelErrors] = Utils.loadFromSpel(typedText, config);
			if (_.gt(_.size(spelErrors), 0) || _.isUndefined(tree) || _.isEmpty(filterQuery(Utils.getTree(tree)))) {
				setDataSource([{ type: ENTITY.TEXT, text: typedText }, ...dataSource]);
			} else {
				setDataSource(dataSource);
			}
		}
	}, [data, activeKey, visible, savedSearchData, typedText]);

	const getCountByItem = (item: ENTITY) => {
		switch (item) {
			case ENTITY.SAVED_SEARCH:
				return _.size(savedSearchData);
			case ENTITY.ALL:
				return _.size(_.flatMap(data));
			default:
				return _.size(_.get(data, item));
		}
	};
	const { items } = useMemo(() => {
		const menuItems: MenuProps['items'] = _.map(MENU_ITEMS, (item) => ({
			key: item.key,
			label: (
				<Space>
					{item.label}
					<Badge
						// 51 is used to show 50+ in badge
						count={_.gte(getCountByItem(item.key), 50) ? 51 : getCountByItem(item.key)}
						className='badge-count'
						overflowCount={50}
					/>
				</Space>
			),
		}));
		return { items: menuItems };
	}, [data, savedSearchData]);

	const handleOnSelect = (item: any) => {
		onSelect(item);
	};

	const handleCategoryChange = (key: ENTITY) => {
		setActiveKey(key);
	};

	useEffect(() => {
		const size = _.size(typedText);
		if ((size === 0 && activeKey !== ENTITY.SAVED_SEARCH) || (size > 0 && activeKey === ENTITY.SAVED_SEARCH)) {
			handleCategoryChange(ENTITY.ALL);
		}
	}, [typedText]);

	useEffect(() => {
		if (arrowUpPressed) {
			dispatch({ type: 'arrowUp', maxLen: _.size(dataSource) });
		}
		if (arrowDownPressed) {
			dispatch({ type: 'arrowDown', maxLen: _.size(dataSource) });
		}
	}, [arrowUpPressed, arrowDownPressed]);

	useEffect(() => {
		const enterHandler = ({ key }: { key: string }) => {
			if (!_.isEqual(state.selectedIndex, -1) && _.isEqual(key, 'Enter')) {
				onSelect(dataSource[state.selectedIndex]);
			}
		};
		globalThis.addEventListener('keydown', enterHandler);
		return () => {
			globalThis.removeEventListener('keydown', enterHandler);
		};
	}, [dataSource, state.selectedIndex]);

	useEffect(() => {
		dispatch({ type: 'select', payload: -1 });
	}, [dataSource]);

	useEffect(() => {
		if (selectedRef.current) {
			const selectedElement = selectedRef.current as HTMLElement;
			if (selectedElement?.parentElement) {
				const elementRect = selectedElement.getBoundingClientRect();
				const parentRect = selectedElement.parentElement.getBoundingClientRect();

				if (elementRect.bottom > parentRect.bottom) {
					selectedElement.scrollIntoView(false);
				}
				if (elementRect.top < parentRect.top) {
					selectedElement.scrollIntoView(true);
				}
			}
		}
	}, [state.selectedIndex]);

	const header = (
		<Typography.Text className='title'>
			{activeKey === ENTITY.SAVED_SEARCH
				? 'View documents associated with'
				: 'View documents associated with search input'}
		</Typography.Text>
	);
	const isSelected = (index: number) => _.isEqual(index, state.selectedIndex);

	const renderListItem = (item: any, index: number) => (
		<List.Item
			key={index}
			className={classNames('universal-list-item', { 'universal-list-item-selected': isSelected(index) })}
			onClick={() => handleOnSelect(item)}
			ref={isSelected(index) ? selectedRef : null}
		>
			<EntitySearchListItem data={item} activeKey={activeKey} />
		</List.Item>
	);

	const renderTextItem = !_.isEmpty(dataSource) && _.isEqual(dataSource[0]?.type, ENTITY.TEXT);

	return (
		<Row className='universal-search-popup universal-search-content'>
			<Col flex='170px'>
				<Menu
					className='universal-search-menu'
					selectedKeys={_.castArray(activeKey)}
					style={{ height: '100%' }}
					items={items}
					onClick={({ key }) => handleCategoryChange(key as ENTITY)}
				/>
			</Col>
			<Col flex='auto'>
				{renderTextItem && (
					<List
						className={classNames('universal-search-list', {
							'universal-list-item-border-bottom': !_.isEmpty(_.slice(dataSource, 1)),
						})}
						header={header}
						loading={false}
						dataSource={[dataSource[0]]}
						renderItem={(item, index) => renderListItem(item, index)}
					/>
				)}
				<List
					className='universal-search-list'
					loading={_.isEqual(activeKey, ENTITY.SAVED_SEARCH) ? fetchingSavedSearch : loading}
					locale={{ emptyText: 'No data found' }}
					dataSource={renderTextItem ? _.slice(dataSource, 1) : dataSource}
					renderItem={(item: any, index) => {
						const adjustedIndex = renderTextItem ? index + 1 : index;
						return renderListItem(item, adjustedIndex);
					}}
				/>
			</Col>
		</Row>
	);
};

export default UniversalSearchPopup;
