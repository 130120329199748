/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Production config
 */

import Environment from '@dexit/common/utils/Environment';
import {
	AUTH_URL_PRODUCTION,
	DEFAULT_SERVER_URL,
	HIGHLIGHT_PROJECT_ID,
	MARKER_PROJECT_ID,
	MATOMO_URL_PRODUCTION,
} from '@dexit/common/constants/url';

const hostname = Environment.isLocalhost() ? new URL(DEFAULT_SERVER_URL).hostname : globalThis.location.hostname;
const realm = hostname.split('.')[0];

const config: EnvConfig = {
	keycloak: {
		config: {
			realm,
			url: AUTH_URL_PRODUCTION,
			clientId: 'dexit',
		},
		initOptions: {
			onLoad: 'login-required',
			checkLoginIframe: false,
			token: '',
		},
	},
	marker: {
		projectID: MARKER_PROJECT_ID,
	},
	analytics: {
		matomo: {
			urlBase: MATOMO_URL_PRODUCTION,
			siteId: 14,
			disabled: Environment.isLocalhost(),
		},
	},
	highlight: {
		projectID: HIGHLIGHT_PROJECT_ID,
		debug: {
			environment: Environment.getHighlightEnvironment(),
			networkRecording: { recordHeadersAndBody: true },
		},
	},
	dwt: {
		WebTwainId: 'dwtObject',
		productKey:
			't0181nwUAACbkZfBK9ivWCr10E2Z4rb3Wmid5n+a6JrBp/xRWYPBZDNWy+aYAhIygcuNGfsTgKCk7v5lJbaBtBHF8v0N+CqepueE+3I60tNGqiVbFRAsbLc9qRR55OJX7d3nrsocJeGZAtweyAaiBEs4X8NI1PPQA9gDtANqLAT3g5yna/C/DJq3yb/7l0EkDp17vrCvlGicMnDzljAUyxqJ4x9POBUD9cnYAe4A2wBTggyc/+vgZxQ==',
		resourcesPath: 'assets/dwt-resources',
		serverUrl: 'http://dexit.scannerproxy.com:18602',
		chunkSize: 5,
	},
	novu: {
		backendUrl: 'https://alerting.314ecorp.com',
		socketUrl: 'https://ws-alerting.314ecorp.com',
	},
	faro: {
		url: 'https://collector.314ecorp.com/collect',
		apiKey: 'reC!6G!K',
	},
};

export default config;
