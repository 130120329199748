/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Pdf Outlined
 */


import classnames from 'classnames';

interface IProps {
	className?: string;
}

const PdfOutlined: React.FC<IProps> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-pdfoutlined', className);
	return (
		<span role='img' aria-label='Pdfoutlined' className={cn} {...restProps}>
			<svg
				width='14'
				height='15'
				viewBox='0 0 14 15'
				fill='none'
				aria-label='Pdfoutlined'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M2.04199 2.17448C2.04199 1.53015 2.56433 1.00781 3.20866 1.00781H11.3753C12.0197 1.00781 12.542 1.53015 12.542 2.17448V12.6745C12.542 13.3188 12.0197 13.8411 11.3753 13.8411H3.20866C2.56433 13.8411 2.04199 13.3188 2.04199 12.6745V2.17448ZM11.3753 2.17448H3.20866V12.6745H11.3753V2.17448Z'
					fill='#40A9FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.375 9.16927C4.375 8.8471 4.63617 8.58594 4.95833 8.58594H9.04167C9.36383 8.58594 9.625 8.8471 9.625 9.16927C9.625 9.49144 9.36383 9.7526 9.04167 9.7526H4.95833C4.63617 9.7526 4.375 9.49144 4.375 9.16927Z'
					fill='#40A9FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.375 10.9193C4.375 10.5971 4.63617 10.3359 4.95833 10.3359H7C7.32217 10.3359 7.58333 10.5971 7.58333 10.9193C7.58333 11.2414 7.32217 11.5026 7 11.5026H4.95833C4.63617 11.5026 4.375 11.2414 4.375 10.9193Z'
					fill='#40A9FF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.375 3.91927C4.375 3.5971 4.63617 3.33594 4.95833 3.33594H9.04167C9.36383 3.33594 9.625 3.5971 9.625 3.91927V6.83594C9.625 7.1581 9.36383 7.41927 9.04167 7.41927H4.95833C4.63617 7.41927 4.375 7.1581 4.375 6.83594V3.91927ZM5.54167 4.5026V6.2526H8.45833V4.5026H5.54167Z'
					fill='#40A9FF'
				/>
			</svg>
		</span>
	);
};

export default PdfOutlined;
