/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Secure app
 */

import React, { useEffect, useState } from 'react';
import log from 'loglevel';

import EventBus, { Events } from '@dexit/common/utils/EventBus';
import SplashPortal from './SplashPortal';
import { standaloneHelperInstance } from 'utils/StandaloneHelper';

import AuthenticatedApp from './AuthenticatedApp';

const SecureApp: React.FC = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [status, setStatus] = useState<React.ReactNode>('Signing in...');

	const changeStatus = (message: string) => {
		setStatus(message);
		log.info(message);
	};

	useEffect(() => {
		const handleAuthSuccess = () => {
			changeStatus('Signed in');
			setIsAuthenticated(true);
		};

		const handleAuthError = () => {
			changeStatus('Failed to Sign in. Please refresh and try again');
		};

		standaloneHelperInstance.init();
		EventBus.subscribe(Events.AUTHENTICATION_FAILURE, handleAuthError);
		EventBus.subscribe(Events.AUTHENTICATION_SUCCESS, handleAuthSuccess);

		return () => {
			EventBus.unsubscribe(Events.AUTHENTICATION_FAILURE);
			EventBus.unsubscribe(Events.AUTHENTICATION_SUCCESS);
		};
	}, []);

	return (
		<>
			<React.Suspense fallback={<h1>Taking longer than expected</h1>}>
				<AuthenticatedApp isAuthenticated={isAuthenticated} onStatusChange={changeStatus} />
			</React.Suspense>
			<SplashPortal status={status} />
		</>
	);
};

export default SecureApp;
