/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Upload files and generate thumbnails
 */

import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import api from 'client/index';
import { Status } from 'store/upload';

const QUERY_UPLOAD_FILE = 'query-uploadfile';

interface UploadFile {
	fileNames: string[];
	blob: (string | ArrayBuffer | Blob)[];
	batchId: string;
	source: 'Upload' | 'Scan';
	setProgress?: (progress: number, index: number, batchId: string) => void;
	changeUploadStatus: (batchId: string, status: Status) => void;
}

const useUploadAndProcess = (): UseMutationResult<
	{ upload: AxiosResponse<any, any>[] },
	unknown,
	UploadFile,
	string
> => {
	return useMutation({
		mutationKey: [QUERY_UPLOAD_FILE],
		mutationFn: async ({ fileNames, blob, batchId, setProgress }) => {
			const { data } = await api.DocumentApi.presignedURLupload({ file_names: fileNames });
			const requests = _.map(data, (file, index) => {
				const config = {
					headers: {
						'content-type': 'multipart/form-data',
					},
					onUploadProgress: (progressEvent: any) => {
						const percent = _.round((progressEvent.loaded / progressEvent.total) * 100);
						if (setProgress) {
							setProgress(percent, index, batchId);
						}
					},
				};
				return axios.put(file.url, new Blob([blob[index]]), config);
			});
			const responses = await axios.all(requests);
			const fileProcess = await api.BatchScanningApi.fileProcessing(batchId, 'create');
			return { upload: responses, fileProcess };
		},
		onSuccess: (response, { changeUploadStatus }) => {
			if (response.fileProcess.data.id) {
				changeUploadStatus(response.fileProcess.data.id, Status.inProgress);
			}
		},
		onError: (__, { batchId, changeUploadStatus }) => {
			changeUploadStatus(batchId, Status.error);
		},
	});
};

export default useUploadAndProcess;
