/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Get client ID
 */
import _ from 'lodash';

import SESSION_KEYS from './sessionKeys';

const DEFAULT_APP_NAME = 'dexit';
const getClientID: () => string = () => {
	return sessionStorage.getItem(SESSION_KEYS.AUTH_CLIENT) ?? _.toLower(DEFAULT_APP_NAME);
};
export { getClientID };
