/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BulkImportCreateRequestModel } from '../Models';
// @ts-ignore
import { BulkImportDocumentsResponseModel } from '../Models';
// @ts-ignore
import { BulkImportResponseModel } from '../Models';
// @ts-ignore
import { BulkImportUpdateRequestModel } from '../Models';
// @ts-ignore
import { ResponseModel } from '../Models';
/**
 * BulkImportApi - axios parameter creator
 * @export
 */
export const BulkImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkImportCreateRequestModel} bulkImportCreateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkImport: async (bulkImportCreateRequestModel: BulkImportCreateRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkImportCreateRequestModel' is not null or undefined
            assertParamExists('createBulkImport', 'bulkImportCreateRequestModel', bulkImportCreateRequestModel)
            const localVarPath = `/api/v1/bulkImport/createBulkImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkImportCreateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bulkimportprocessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentByProcessId: async (bulkimportprocessId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkimportprocessId' is not null or undefined
            assertParamExists('deleteDocumentByProcessId', 'bulkimportprocessId', bulkimportprocessId)
            const localVarPath = `/api/v1/bulkImport/deleteDocumentByProcessId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (bulkimportprocessId !== undefined) {
                localVarQueryParameter['bulkimportprocess_id'] = bulkimportprocessId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkImport: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bulkImport/getBulkImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id_'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pagesize 
         * @param {number} pagenumber 
         * @param {string} bulkimportprocessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByBulkImportId: async (pagesize: number, pagenumber: number, bulkimportprocessId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagesize' is not null or undefined
            assertParamExists('getDocumentsByBulkImportId', 'pagesize', pagesize)
            // verify required parameter 'pagenumber' is not null or undefined
            assertParamExists('getDocumentsByBulkImportId', 'pagenumber', pagenumber)
            // verify required parameter 'bulkimportprocessId' is not null or undefined
            assertParamExists('getDocumentsByBulkImportId', 'bulkimportprocessId', bulkimportprocessId)
            const localVarPath = `/api/v1/bulkImport/getDocumentsByBulkImportId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }

            if (pagenumber !== undefined) {
                localVarQueryParameter['pagenumber'] = pagenumber;
            }

            if (bulkimportprocessId !== undefined) {
                localVarQueryParameter['bulkimportprocess_id'] = bulkimportprocessId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filePath 
         * @param {string} entityName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntityFromCsv: async (filePath: string, entityName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('importEntityFromCsv', 'filePath', filePath)
            // verify required parameter 'entityName' is not null or undefined
            assertParamExists('importEntityFromCsv', 'entityName', entityName)
            const localVarPath = `/api/v1/bulkImport/importEntityFromCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (filePath !== undefined) {
                localVarQueryParameter['file_path'] = filePath;
            }

            if (entityName !== undefined) {
                localVarQueryParameter['entity_name'] = entityName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} processId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBulkImportService: async (processId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processId' is not null or undefined
            assertParamExists('startBulkImportService', 'processId', processId)
            const localVarPath = `/api/v1/bulkImport/startBulkImportService`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (processId !== undefined) {
                localVarQueryParameter['process_id'] = processId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bulkImportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateProcess: async (bulkImportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkImportId' is not null or undefined
            assertParamExists('terminateProcess', 'bulkImportId', bulkImportId)
            const localVarPath = `/api/v1/bulkImport/terminateProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (bulkImportId !== undefined) {
                localVarQueryParameter['bulk_import_id'] = bulkImportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkImportUpdateRequestModel} bulkImportUpdateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBulkImport: async (bulkImportUpdateRequestModel: BulkImportUpdateRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkImportUpdateRequestModel' is not null or undefined
            assertParamExists('updateBulkImport', 'bulkImportUpdateRequestModel', bulkImportUpdateRequestModel)
            const localVarPath = `/api/v1/bulkImport/updateBulkImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkImportUpdateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkImportApi - functional programming interface
 * @export
 */
export const BulkImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulkImportCreateRequestModel} bulkImportCreateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBulkImport(bulkImportCreateRequestModel: BulkImportCreateRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBulkImport(bulkImportCreateRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.createBulkImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bulkimportprocessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentByProcessId(bulkimportprocessId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentByProcessId(bulkimportprocessId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.deleteDocumentByProcessId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBulkImport(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkImportResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBulkImport(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.getBulkImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} pagesize 
         * @param {number} pagenumber 
         * @param {string} bulkimportprocessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsByBulkImportId(pagesize: number, pagenumber: number, bulkimportprocessId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkImportDocumentsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentsByBulkImportId(pagesize, pagenumber, bulkimportprocessId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.getDocumentsByBulkImportId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} filePath 
         * @param {string} entityName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEntityFromCsv(filePath: string, entityName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEntityFromCsv(filePath, entityName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.importEntityFromCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} processId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startBulkImportService(processId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startBulkImportService(processId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.startBulkImportService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bulkImportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminateProcess(bulkImportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminateProcess(bulkImportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.terminateProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BulkImportUpdateRequestModel} bulkImportUpdateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBulkImport(bulkImportUpdateRequestModel: BulkImportUpdateRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBulkImport(bulkImportUpdateRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BulkImportApi.updateBulkImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BulkImportApi - factory interface
 * @export
 */
export const BulkImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkImportApiFp(configuration)
    return {
        /**
         * 
         * @param {BulkImportCreateRequestModel} bulkImportCreateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkImport(bulkImportCreateRequestModel: BulkImportCreateRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createBulkImport(bulkImportCreateRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bulkimportprocessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentByProcessId(bulkimportprocessId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteDocumentByProcessId(bulkimportprocessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkImport(id?: string, options?: any): AxiosPromise<Array<BulkImportResponseModel>> {
            return localVarFp.getBulkImport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pagesize 
         * @param {number} pagenumber 
         * @param {string} bulkimportprocessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsByBulkImportId(pagesize: number, pagenumber: number, bulkimportprocessId: string, options?: any): AxiosPromise<BulkImportDocumentsResponseModel> {
            return localVarFp.getDocumentsByBulkImportId(pagesize, pagenumber, bulkimportprocessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filePath 
         * @param {string} entityName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntityFromCsv(filePath: string, entityName: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.importEntityFromCsv(filePath, entityName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} processId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBulkImportService(processId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.startBulkImportService(processId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bulkImportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateProcess(bulkImportId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.terminateProcess(bulkImportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkImportUpdateRequestModel} bulkImportUpdateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBulkImport(bulkImportUpdateRequestModel: BulkImportUpdateRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.updateBulkImport(bulkImportUpdateRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkImportApi - interface
 * @export
 * @interface BulkImportApi
 */
export interface BulkImportApiInterface {
    /**
     * 
     * @param {BulkImportCreateRequestModel} bulkImportCreateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    createBulkImport(bulkImportCreateRequestModel: BulkImportCreateRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} bulkimportprocessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    deleteDocumentByProcessId(bulkimportprocessId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    getBulkImport(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BulkImportResponseModel>>;

    /**
     * 
     * @param {number} pagesize 
     * @param {number} pagenumber 
     * @param {string} bulkimportprocessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    getDocumentsByBulkImportId(pagesize: number, pagenumber: number, bulkimportprocessId: string, options?: RawAxiosRequestConfig): AxiosPromise<BulkImportDocumentsResponseModel>;

    /**
     * 
     * @param {string} filePath 
     * @param {string} entityName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    importEntityFromCsv(filePath: string, entityName: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} processId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    startBulkImportService(processId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} bulkImportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    terminateProcess(bulkImportId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {BulkImportUpdateRequestModel} bulkImportUpdateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApiInterface
     */
    updateBulkImport(bulkImportUpdateRequestModel: BulkImportUpdateRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

}

/**
 * BulkImportApi - object-oriented interface
 * @export
 * @class BulkImportApi
 * @extends {BaseAPI}
 */
export class BulkImportApi extends BaseAPI implements BulkImportApiInterface {
    /**
     * 
     * @param {BulkImportCreateRequestModel} bulkImportCreateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public createBulkImport(bulkImportCreateRequestModel: BulkImportCreateRequestModel, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).createBulkImport(bulkImportCreateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bulkimportprocessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public deleteDocumentByProcessId(bulkimportprocessId: string, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).deleteDocumentByProcessId(bulkimportprocessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public getBulkImport(id?: string, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).getBulkImport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pagesize 
     * @param {number} pagenumber 
     * @param {string} bulkimportprocessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public getDocumentsByBulkImportId(pagesize: number, pagenumber: number, bulkimportprocessId: string, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).getDocumentsByBulkImportId(pagesize, pagenumber, bulkimportprocessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filePath 
     * @param {string} entityName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public importEntityFromCsv(filePath: string, entityName: string, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).importEntityFromCsv(filePath, entityName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} processId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public startBulkImportService(processId: string, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).startBulkImportService(processId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bulkImportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public terminateProcess(bulkImportId: string, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).terminateProcess(bulkImportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkImportUpdateRequestModel} bulkImportUpdateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkImportApi
     */
    public updateBulkImport(bulkImportUpdateRequestModel: BulkImportUpdateRequestModel, options?: RawAxiosRequestConfig) {
        return BulkImportApiFp(this.configuration).updateBulkImport(bulkImportUpdateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

