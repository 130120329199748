/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description ResourceAccess
 */

import _ from 'lodash';
import isAuthorized from 'utils/isAuthorized';

interface IVisible {
	type: string;
	value: string;
}
export interface MenuItem extends Record<'key' | 'title' | 'url', string> {
	component?: string;
	visible?: string[] | IVisible;
	icon?: React.JSX.Element;
	roles?: Record<string, any>;
	landing?: boolean;
	external?: boolean;
	exact?: boolean;
	routeUrl?: string;
}

export interface MenuSection extends Pick<MenuItem, 'key' | 'title'> {
	children: (MenuSection | MenuItem)[];
}

export type Menu = (MenuItem | MenuSection)[];

class ResourceAccess {
	private filterMenuConfigRecursively = (config: Menu, roles?: string[], variables?: Record<string, any>): Menu => {
		if (_.isUndefined(roles)) {
			return [];
		}

		return _.filter(config, (value: MenuSection | MenuItem) => {
			let truthy = false;
			if ('visible' in value) {
				const { visible } = value;
				if (visible) {
					truthy = _.isArray(visible) ? isAuthorized(roles, visible) : variables?.[visible.value];
				}
			} else if ('children' in value) {
				const existingChildren = value.children || [];
				const newChildren = this.filterMenuConfigRecursively(existingChildren, roles, variables);
				truthy = !_.isEmpty(newChildren);
				if (truthy) {
					_.setWith(value, 'children', newChildren, Object);
				}
			}
			return truthy;
		});
	};

	private flattenMenuItemsRecursively = (result: MenuItem[], config: Menu) => {
		_.forEach(config, (item: MenuItem | MenuSection) => {
			if ('children' in item) {
				this.flattenMenuItemsRecursively(result, item.children);
			} else {
				result.push(item);
			}
		});
	};

	filterMenuConfig = (menuConfig: Menu, roles?: string[], variables?: Record<string, any>): Menu => {
		return this.filterMenuConfigRecursively(_.cloneDeep(menuConfig), roles, variables);
	};

	getMenuItems = (filteredMenuItem: Menu): MenuItem[] => {
		const menuItems: MenuItem[] = [];
		this.flattenMenuItemsRecursively(menuItems, filteredMenuItem);
		return menuItems;
	};

	filterExtraItems = (menuConfig: Menu, roles?: string[]): MenuItem[] => {
		return this.getMenuItems(this.filterMenuConfig(menuConfig, roles));
	};
}

export default ResourceAccess;
