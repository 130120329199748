/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Upload Card to show process
 */

import Dynamsoft from 'dwt';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import { Button, Collapse, CollapseProps, List, Progress, Space, Typography, UploadFile } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { DeviceConfiguration, Device } from 'dwt/dist/types/WebTwain.Acquire';
import { OutputInfo } from 'dwt/dist/types/WebTwain.Util';
import { RemoteScanObject } from 'dwt/dist/types/RemoteScan';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import EventBus, { Events } from '@dexit/common/utils/EventBus';
import config from 'configs/config.integration';
import useBatches, { QUERY_BATCHES_KEY } from 'hooks/useBatches';
import useFileProcessing from 'hooks/mutations/useFileProcessing';
import useQueue, { QUERY_QUEUE } from 'hooks/useQueue';
import useUploadAndProcess from 'hooks/mutations/useUploadAndProcess';
import useUploadFile from 'hooks/mutations/useUploadFile';
import { Status, Uploads, useUploadActions, useUploadValues } from 'store/upload';
import { URL_CONFIG } from 'configs/menu-config';
import { getFileIcon } from 'components/document/utils';
import { getPageStartScan, isWebTwain, itemExpandIcon, publishError } from './uploadHelper';

const chunkSize = config.dwt.chunkSize;

const UploadCard: React.FC = () => {
	const navigate = useNavigate();

	const [showUploadAssetPanel, setShowUploadAssetPanel] = useState(false);
	const { uploads } = useUploadValues();
	const { setUploads, addToUploads, setProgress, changeUploadStatus, addFileToBatch, changeFileName } =
		useUploadActions();
	const [batchIds, setBatchIds] = useState<string[]>([]);

	const { data: queueInfo } = useQueue(true);

	const queryClient = useQueryClient();
	const processFile = useFileProcessing();
	const uploadFiles = useUploadFile();
	const uploadAndProcess = useUploadAndProcess();

	const { data, refetch } = useBatches(batchIds, undefined, {
		enabled: _.some(uploads, { status: Status.inProgress }),
		refetchInterval: 5000,
		retry: 3,
		onSuccess: (batchData) => {
			_.forEach(batchData, (batch) => {
				const uploadStatus = _.find(uploads, { batchId: batch.id })?.status;
				if (
					uploadStatus &&
					_.some(uploads, { batchId: batch.id }) &&
					![Status.uploading, Status.scanning, Status.error].includes(uploadStatus)
				) {
					changeUploadStatus(batch.id, batch.status as Status.inProgress | Status.done);
					if (batch.status === Status.done && uploadStatus !== Status.done) {
						queryClient.invalidateQueries([QUERY_BATCHES_KEY]).catch((err) => log.error(err));
						queryClient.invalidateQueries([QUERY_QUEUE]).catch((err) => log.error(err));
					}
				}
			});
		},
	});

	const convertToPDFandUpload = (scannedBlob: Blob, batchId: string, name: string) => {
		return new Promise((resolve, reject) => {
			const fileName = `${name ?? uuidv4()}.pdf`;
			const scannedFile = new File([scannedBlob], fileName);
			uploadFiles.mutate(
				{
					fileNames: [`batches/${batchId}/${fileName}`],
					blob: [scannedFile as Blob],
				},
				{ onSuccess: resolve, onError: reject },
			);
		});
	};

	const scanDocuments = async (
		DWObject: RemoteScanObject | WebTwain,
		deviceConfiguration: DeviceConfiguration,
		batchId: string,
		device?: Device,
	) => {
		return new Promise((resolve, reject) => {
			let uploadChunk = false;
			if (isWebTwain(DWObject)) {
				DWObject.RegisterEvent('OnPostTransferAsync', (outputInfo: OutputInfo) => {
					if (outputInfo.imageId) {
						const imageIndex = DWObject.ImageIDToIndex(outputInfo.imageId);
						changeFileName(`Scanning page ${DWObject.HowManyImagesInBuffer}...`, batchId);
						if ((imageIndex + 1) % chunkSize === 0) {
							DWObject.ConvertToBlob(
								_.range(imageIndex - (chunkSize - 1), imageIndex + 1),
								Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
								(scannedBlob) => {
									convertToPDFandUpload(
										scannedBlob,
										batchId,
										`${getPageStartScan(imageIndex)} - ${imageIndex + 1}`,
									)
										.then((success) => {
											if (DWObject.HowManyImagesInBuffer % chunkSize === 0 && uploadChunk) {
												resolve(success);
											}
										})
										.catch(reject);
								},
								(__, error) => {
									publishError(error);
									reject(error);
								},
							);
						}
					}
				});

				DWObject.AcquireImageAsync(deviceConfiguration)
					.then((fulfilled) => {
						if (fulfilled) {
							const totalPages = DWObject.HowManyImagesInBuffer;
							if (totalPages % chunkSize !== 0) {
								DWObject.ConvertToBlob(
									_.range(_.floor(totalPages / chunkSize) * chunkSize, totalPages),
									Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
									(scannedBlob) => {
										convertToPDFandUpload(
											scannedBlob,
											batchId,
											`${_.floor(totalPages / chunkSize) * chunkSize + 1} - ${totalPages}`,
										)
											.then(resolve)
											.catch(reject);
									},
									(__, error) => {
										publishError(error);
										reject(error);
									},
								);
							} else {
								uploadChunk = true;
							}
						}
					})
					.catch((error) => {
						publishError(error.message);
						reject(error);
					})
					.finally(() => DWObject.UnregisterEvent('OnPostTransferAsync'));
			} else {
				DWObject.registerEvent('OnPostTransferAsync', (outputInfo: OutputInfo) => {
					if (outputInfo.imageId) {
						const imageIndex = DWObject.imageIDToIndex(outputInfo.imageId);
						changeFileName(`Scanning page ${DWObject.howManyImagesInBuffer}...`, batchId);
						if ((imageIndex + 1) % chunkSize === 0) {
							DWObject.getImages(
								_.range(imageIndex - (chunkSize - 1), imageIndex + 1),
								Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
								Dynamsoft.DWT.EnumDWT_ImageFormatType.Blob,
							)
								.then((scannedBlob) => {
									convertToPDFandUpload(
										scannedBlob as Blob,
										batchId,
										`${getPageStartScan(imageIndex)} - ${imageIndex + 1}`,
									)
										.then((success) => {
											if (DWObject.howManyImagesInBuffer % chunkSize === 0 && uploadChunk) {
												resolve(success);
											}
										})
										.catch(reject);
								})
								.catch((error) => {
									publishError(error);
									reject(error);
									log.error(error);
								});
						}
					}
				});
				if (device) {
					DWObject.acquireImage(device, deviceConfiguration)
						.then(() => {
							const totalPages = DWObject.howManyImagesInBuffer;
							if (totalPages % chunkSize !== 0) {
								DWObject.getImages(
									_.range(_.floor(totalPages / chunkSize) * chunkSize, totalPages),
									Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
									Dynamsoft.DWT.EnumDWT_ImageFormatType.Blob,
								)
									.then((scannedBlob) => {
										convertToPDFandUpload(
											scannedBlob as Blob,
											batchId,
											`${_.floor(totalPages / chunkSize) * chunkSize + 1} - ${totalPages}`,
										)
											.then(resolve)
											.catch(reject);
									})
									.catch((error) => {
										publishError(error);
										reject(error);
									});
							} else {
								uploadChunk = true;
							}
						})
						.catch((error) => {
							publishError(error.message);
							reject(error);
							log.error(error);
						})
						.finally(() => DWObject.unregisterEvent('OnPostTransferAsync'));
				} else {
					publishError('Device not found');
				}
			}
		});
	};

	const handleOnClose = () => {
		setShowUploadAssetPanel(false);
		setUploads([]);
	};

	const uploadFile = async (param: {
		batchId: string;
		filesParam?: UploadFile[];
		DWObject?: RemoteScanObject | WebTwain;
		deviceConfiguration?: DeviceConfiguration;
		device?: Device;
	}) => {
		const { batchId, filesParam, DWObject, deviceConfiguration, device } = param;
		const initialStatus: Status.scanning | Status.uploading =
			!filesParam && DWObject && deviceConfiguration ? Status.scanning : Status.uploading;
		setShowUploadAssetPanel(true);
		setBatchIds((prevBatchIds) => [...prevBatchIds, batchId]);
		addToUploads({
			files: _.map(filesParam, (file) => ({ file, progress: 0 })) || [],
			batchId,
			status: initialStatus,
		});

		if (initialStatus === Status.scanning && DWObject && deviceConfiguration) {
			changeUploadStatus(batchId, Status.scanning);
			const fileName = 'Starting Scan...';
			const scannedFile = new File([], fileName);
			addFileToBatch(
				{
					file: {
						uid: uuidv4(),
						name: scannedFile.name,
						status: 'done',
						size: scannedFile.size,
						type: scannedFile.type,
					},
					progress: 100,
				},
				batchId,
			);
			await scanDocuments(DWObject, deviceConfiguration, batchId, device).catch(() => {
				changeUploadStatus(batchId, Status.error);
				changeFileName('Error Scanning Pages', batchId);
			});
			processFile.mutate({ batchId, source: 'Scan', changeUploadStatus });
			changeFileName('Scanned File.pdf', batchId);
		} else if (initialStatus === Status.uploading) {
			uploadAndProcess.mutate({
				fileNames: _.map(filesParam, (file) => `batches/${batchId}/${file.name}`),
				blob: _.map(filesParam, (file) => file.originFileObj as Blob),
				batchId,
				source: !filesParam && DWObject && deviceConfiguration ? 'Scan' : 'Upload',
				setProgress,
				changeUploadStatus,
			});
		}
	};

	useEffect(() => {
		if (!_.isEmpty(batchIds)) {
			refetch();
		}
	}, [batchIds]);

	useEffect(() => {
		const inProgressUploads = _.filter(uploads, (upload) =>
			[Status.inProgress, Status.uploading, Status.scanning].includes(upload.status),
		);
		globalThis.onbeforeunload = _.isEmpty(inProgressUploads) ? null : () => true;
		return () => {
			globalThis.onbeforeunload = null;
		};
	}, [uploads]);

	useEffect(() => {
		EventBus.subscribe(Events.FILE_UPLOAD_REQ, uploadFile);
		return () => {
			EventBus.unsubscribe(Events.FILE_UPLOAD_REQ);
		};
	}, []);

	const handleViewBatch = (batchId: string) => {
		navigate(generatePath(`${URL_CONFIG.queues}/${_.find(data, { id: batchId })?.queue}/${batchId}`));
	};

	const getBatchCollapseItemStatus = (item: Uploads) => {
		switch (item.status) {
			case 'error':
				return 'Error';
			case Status.inProgress:
				return 'Processing..';
			case Status.scanning:
				return 'Scanning Pages..';
			case Status.uploading:
				return 'Uploading Files';
			case Status.done:
				return (
					<Button type='text' onClick={() => handleViewBatch(item.batchId)}>
						View Batch
					</Button>
				);
			default:
				return '';
		}
	};

	const getStatus = (status: Uploads['status']) => {
		if (status === Status.error) {
			return 'exception';
		}
		return status === Status.done ? 'success' : 'active';
	};
	const getBatchCollapseItems: (item: Uploads) => CollapseProps['items'] = (item) => [
		{
			key: item.batchId,
			label: (
				<Space className='flex-space-between'>
					<div className='flex-column'>
						<Typography.Text strong>{_.find(data, { id: item.batchId })?.name}</Typography.Text>
						<Typography.Text>
							{_.find(queueInfo, { id: _.find(data, { id: item.batchId })?.queue })?.name ?? ''}
						</Typography.Text>
					</div>
					<Typography.Text type='secondary' style={{ fontWeight: 400 }}>
						{getBatchCollapseItemStatus(item)}
					</Typography.Text>
				</Space>
			),
			children: (
				<List
					dataSource={item.files}
					renderItem={(files) => (
						<List.Item key={files.file.uid}>
							<div className='flex-column full-width'>
								<div className='flex-space-between'>
									<Space>
										{getFileIcon(files.file.name, true)}
										{files.file.name}
									</Space>
									{item.status === Status.inProgress && (
										<LoadingOutlined style={{ color: '#00866C' }} />
									)}
									{item.status === Status.error && (
										<Typography.Text type='secondary'>Error</Typography.Text>
									)}
								</div>
								{_.includes([Status.uploading, Status.scanning], item.status) && (
									<Progress
										strokeColor='#00866C'
										percent={files.progress}
										status={getStatus(item.status)}
									/>
								)}
							</div>
						</List.Item>
					)}
				/>
			),
		},
	];

	const getItemLabel = () => {
		if (uploadAndProcess.isError) {
			return 'Error Uploading files...';
		} else if (uploadAndProcess.isLoading) {
			return `Uploading ${uploads.length} ${uploads.length === 1 ? 'Batch' : 'Batches'}`;
		} else if (_.some(uploads, { status: Status.scanning })) {
			return 'Scanning...';
		} else if (_.some(uploads, { status: Status.inProgress })) {
			return `Processing ${uploads.length === 1 ? 'Batch' : 'Batches'}`;
		} else if (_.every(uploads, { status: Status.done })) {
			return `${uploads.length} ${uploads.length === 1 ? 'batch' : 'batches'} Uploaded`;
		} else {
			return 'Unknown status';
		}
	};
	const items: CollapseProps['items'] = [
		{
			key: 'panelHeader',
			label: <>{getItemLabel()}</>,
			extra: (
				<Button
					type='text'
					icon={<CloseOutlined style={{ color: '#fff' }} />}
					size='small'
					hidden={_.some(uploads, ({ status }) => _.includes([Status.uploading, Status.inProgress], status))}
					onClick={handleOnClose}
				/>
			),
			children: (
				<List
					dataSource={uploads}
					renderItem={(item) => (
						<List.Item key={item.batchId}>
							<Collapse
								ghost
								bordered={false}
								style={{ fontWeight: 500 }}
								className='collapse-header-without-sub full-width'
								defaultActiveKey={item.batchId}
								items={getBatchCollapseItems(item)}
								expandIcon={({ isActive }) => itemExpandIcon(isActive)}
							/>
						</List.Item>
					)}
				/>
			),
		},
	];

	return showUploadAssetPanel ? (
		<Collapse
			className='upload-panel'
			defaultActiveKey='panelHeader'
			expandIconPosition='end'
			items={items}
			expandIcon={({ isActive }) => itemExpandIcon(isActive)}
		/>
	) : null;
};

export default UploadCard;
