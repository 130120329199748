/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Splash Screen Portal
 */

import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
	status: React.ReactNode;
}
const dom = document.getElementById('status') as HTMLElement;

const SplashPortal: React.FC<IProps> = (props) => {
	const { status } = props;
	const [innerHtmlEmptied, setInnerHtmlEmptied] = React.useState(false);

	React.useEffect(() => {
		if (!innerHtmlEmptied) {
			dom.innerHTML = '';
			setInnerHtmlEmptied(true);
		}
	}, [innerHtmlEmptied]);

	if (innerHtmlEmptied && dom) {
		return ReactDOM.createPortal(<>{status}</>, dom);
	}
	return null;
};
export default SplashPortal;
