/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description query state
 */

import _ from 'lodash';
import { ColumnStateParams } from 'ag-grid-community';
import { PaginationConfig } from 'antd/es/pagination';

import useSessionStorageState from './useSessionStorageState';
import { AdvancedSearchRequestModel } from '@dexit/common/openapi';
import { ENTITY } from 'constants/entity';
import { defaultRAQBQuery } from 'components/header/utils';
import { DEFAULT_PAGE_SIZE } from 'components/Grid/Grid';

export const defaultQuery = {
	query_filter: defaultRAQBQuery,
	search_string: '',
	pagenumber: 1,
	pagesize: DEFAULT_PAGE_SIZE,
};

const useSearchState = (): [
	AdvancedSearchRequestModel,
	React.Dispatch<React.SetStateAction<AdvancedSearchRequestModel>>,
	(pagination?: PaginationConfig, sorter?: { field?: string; order?: ColumnStateParams['sort'] }) => void,
] => {
	const [query, setQuery] = useSessionStorageState<AdvancedSearchRequestModel>(defaultQuery, ENTITY.retrieve.name);

	const setPaginationAndSort = (
		pagination?: PaginationConfig,
		sorter?: { field?: string; order?: ColumnStateParams['sort'] },
	) =>
		setQuery((prevQuery) => ({
			...prevQuery,
			pagenumber: (pagination ? pagination.current : (prevQuery.pagenumber ?? 1)) ?? 1,
			pagesize:
				(pagination ? pagination.pageSize : (prevQuery.pagesize ?? DEFAULT_PAGE_SIZE)) ?? DEFAULT_PAGE_SIZE,
			sort_fields:
				_.isEmpty(sorter) || _.isUndefined(sorter.order)
					? prevQuery?.sort_fields
					: [
							{
								field_name: _.isArray(sorter.field)
									? _.first(_.get(sorter, 'column.esKey', sorter.field))
									: _.get(sorter, 'column.esKey', sorter.field),
								order: sorter.order ?? undefined,
							},
						],
		}));

	return [query, setQuery, setPaginationAndSort];
};

export default useSearchState;
