/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Pixel Type for Scanner
 */


import { Form, Radio } from 'antd';

const PixelType: React.FC = () => {
	return (
		<Form.Item name='pixelType' label='Pixel Type:' initialValue={0} className='inline-item-in-vertical-form'>
			<Radio.Group>
				<Radio value={0}>B&W</Radio>
				<Radio value={1}>Gray</Radio>
				<Radio value={2}>Color</Radio>
			</Radio.Group>
		</Form.Item>
	);
};

export default PixelType;
