/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AttributeRawResponseModelAI } from '../Models';
// @ts-ignore
import { AttributeRequestModelAI } from '../Models';
// @ts-ignore
import { AttributeResponseModelAI } from '../Models';
// @ts-ignore
import { AvailableModels } from '../Models';
// @ts-ignore
import { BoundingBoxResponseModel } from '../Models';
// @ts-ignore
import { DocumentTypeResponseModelAI } from '../Models';
// @ts-ignore
import { HfInferenceEndpointResumeRequestModel } from '../Models';
// @ts-ignore
import { PatientRawResponseModelAI } from '../Models';
// @ts-ignore
import { PatientRequestModelAI } from '../Models';
// @ts-ignore
import { PatientResponseModelAI } from '../Models';
/**
 * MachineLearningApi - axios parameter creator
 * @export
 */
export const MachineLearningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyDocument: async (documentPath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentPath' is not null or undefined
            assertParamExists('classifyDocument', 'documentPath', documentPath)
            const localVarPath = `/api/v1/ml/classifyDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (documentPath !== undefined) {
                localVarQueryParameter['document_path'] = documentPath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {AttributeRequestModelAI} attributeRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeInfo: async (selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectedModel' is not null or undefined
            assertParamExists('getAttributeInfo', 'selectedModel', selectedModel)
            // verify required parameter 'attributeRequestModelAI' is not null or undefined
            assertParamExists('getAttributeInfo', 'attributeRequestModelAI', attributeRequestModelAI)
            const localVarPath = `/api/v1/ml/getAttributeInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (selectedModel !== undefined) {
                localVarQueryParameter['selected_model'] = selectedModel;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attributeRequestModelAI, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {AttributeRequestModelAI} attributeRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeInfoRawResponse: async (selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectedModel' is not null or undefined
            assertParamExists('getAttributeInfoRawResponse', 'selectedModel', selectedModel)
            // verify required parameter 'attributeRequestModelAI' is not null or undefined
            assertParamExists('getAttributeInfoRawResponse', 'attributeRequestModelAI', attributeRequestModelAI)
            const localVarPath = `/api/v1/ml/getAttributeInfoRawResponse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (selectedModel !== undefined) {
                localVarQueryParameter['selected_model'] = selectedModel;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attributeRequestModelAI, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} endpointName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHfInferenceEndpointInfo: async (endpointName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endpointName' is not null or undefined
            assertParamExists('getHfInferenceEndpointInfo', 'endpointName', endpointName)
            const localVarPath = `/api/v1/ml/getHfInferenceEndpointInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (endpointName !== undefined) {
                localVarQueryParameter['endpoint_name'] = endpointName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {PatientRequestModelAI} patientRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientInfo: async (selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectedModel' is not null or undefined
            assertParamExists('getPatientInfo', 'selectedModel', selectedModel)
            // verify required parameter 'patientRequestModelAI' is not null or undefined
            assertParamExists('getPatientInfo', 'patientRequestModelAI', patientRequestModelAI)
            const localVarPath = `/api/v1/ml/getPatientInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (selectedModel !== undefined) {
                localVarQueryParameter['selected_model'] = selectedModel;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequestModelAI, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {PatientRequestModelAI} patientRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientInfoRawResponse: async (selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectedModel' is not null or undefined
            assertParamExists('getPatientInfoRawResponse', 'selectedModel', selectedModel)
            // verify required parameter 'patientRequestModelAI' is not null or undefined
            assertParamExists('getPatientInfoRawResponse', 'patientRequestModelAI', patientRequestModelAI)
            const localVarPath = `/api/v1/ml/getPatientInfoRawResponse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (selectedModel !== undefined) {
                localVarQueryParameter['selected_model'] = selectedModel;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequestModelAI, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUSDLBoundingBox: async (imagePath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagePath' is not null or undefined
            assertParamExists('getUSDLBoundingBox', 'imagePath', imagePath)
            const localVarPath = `/api/v1/ml/getUSDLBoundingBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (imagePath !== undefined) {
                localVarQueryParameter['image_path'] = imagePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HfInferenceEndpointResumeRequestModel} hfInferenceEndpointResumeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeHfInferenceEndpoints: async (hfInferenceEndpointResumeRequestModel: HfInferenceEndpointResumeRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hfInferenceEndpointResumeRequestModel' is not null or undefined
            assertParamExists('resumeHfInferenceEndpoints', 'hfInferenceEndpointResumeRequestModel', hfInferenceEndpointResumeRequestModel)
            const localVarPath = `/api/v1/ml/resumeHfInferenceEndpoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hfInferenceEndpointResumeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} endpointName 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHfInferenceEndpoint: async (endpointName: string, requestBody: { [key: string]: any; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endpointName' is not null or undefined
            assertParamExists('updateHfInferenceEndpoint', 'endpointName', endpointName)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateHfInferenceEndpoint', 'requestBody', requestBody)
            const localVarPath = `/api/v1/ml/updateHfInferenceEndpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (endpointName !== undefined) {
                localVarQueryParameter['endpoint_name'] = endpointName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MachineLearningApi - functional programming interface
 * @export
 */
export const MachineLearningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MachineLearningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} documentPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyDocument(documentPath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeResponseModelAI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyDocument(documentPath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.classifyDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {AttributeRequestModelAI} attributeRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributeInfo(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttributeResponseModelAI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributeInfo(selectedModel, attributeRequestModelAI, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.getAttributeInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {AttributeRequestModelAI} attributeRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributeInfoRawResponse(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttributeRawResponseModelAI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributeInfoRawResponse(selectedModel, attributeRequestModelAI, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.getAttributeInfoRawResponse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} endpointName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHfInferenceEndpointInfo(endpointName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHfInferenceEndpointInfo(endpointName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.getHfInferenceEndpointInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {PatientRequestModelAI} patientRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientInfo(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponseModelAI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientInfo(selectedModel, patientRequestModelAI, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.getPatientInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {PatientRequestModelAI} patientRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientInfoRawResponse(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRawResponseModelAI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientInfoRawResponse(selectedModel, patientRequestModelAI, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.getPatientInfoRawResponse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUSDLBoundingBox(imagePath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoundingBoxResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUSDLBoundingBox(imagePath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.getUSDLBoundingBox']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HfInferenceEndpointResumeRequestModel} hfInferenceEndpointResumeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel: HfInferenceEndpointResumeRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.resumeHfInferenceEndpoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} endpointName 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHfInferenceEndpoint(endpointName: string, requestBody: { [key: string]: any; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHfInferenceEndpoint(endpointName, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachineLearningApi.updateHfInferenceEndpoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MachineLearningApi - factory interface
 * @export
 */
export const MachineLearningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MachineLearningApiFp(configuration)
    return {
        /**
         * 
         * @param {string} documentPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyDocument(documentPath: string, options?: any): AxiosPromise<DocumentTypeResponseModelAI> {
            return localVarFp.classifyDocument(documentPath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {AttributeRequestModelAI} attributeRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeInfo(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: any): AxiosPromise<AttributeResponseModelAI> {
            return localVarFp.getAttributeInfo(selectedModel, attributeRequestModelAI, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {AttributeRequestModelAI} attributeRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeInfoRawResponse(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: any): AxiosPromise<AttributeRawResponseModelAI> {
            return localVarFp.getAttributeInfoRawResponse(selectedModel, attributeRequestModelAI, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} endpointName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHfInferenceEndpointInfo(endpointName: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getHfInferenceEndpointInfo(endpointName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {PatientRequestModelAI} patientRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientInfo(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: any): AxiosPromise<PatientResponseModelAI> {
            return localVarFp.getPatientInfo(selectedModel, patientRequestModelAI, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailableModels} selectedModel 
         * @param {PatientRequestModelAI} patientRequestModelAI 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientInfoRawResponse(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: any): AxiosPromise<PatientRawResponseModelAI> {
            return localVarFp.getPatientInfoRawResponse(selectedModel, patientRequestModelAI, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUSDLBoundingBox(imagePath: string, options?: any): AxiosPromise<BoundingBoxResponseModel> {
            return localVarFp.getUSDLBoundingBox(imagePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HfInferenceEndpointResumeRequestModel} hfInferenceEndpointResumeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel: HfInferenceEndpointResumeRequestModel, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} endpointName 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHfInferenceEndpoint(endpointName: string, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.updateHfInferenceEndpoint(endpointName, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MachineLearningApi - interface
 * @export
 * @interface MachineLearningApi
 */
export interface MachineLearningApiInterface {
    /**
     * 
     * @param {string} documentPath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    classifyDocument(documentPath: string, options?: RawAxiosRequestConfig): AxiosPromise<DocumentTypeResponseModelAI>;

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {AttributeRequestModelAI} attributeRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    getAttributeInfo(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: RawAxiosRequestConfig): AxiosPromise<AttributeResponseModelAI>;

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {AttributeRequestModelAI} attributeRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    getAttributeInfoRawResponse(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: RawAxiosRequestConfig): AxiosPromise<AttributeRawResponseModelAI>;

    /**
     * 
     * @param {string} endpointName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    getHfInferenceEndpointInfo(endpointName: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }>;

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {PatientRequestModelAI} patientRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    getPatientInfo(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: RawAxiosRequestConfig): AxiosPromise<PatientResponseModelAI>;

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {PatientRequestModelAI} patientRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    getPatientInfoRawResponse(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: RawAxiosRequestConfig): AxiosPromise<PatientRawResponseModelAI>;

    /**
     * 
     * @param {string} imagePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    getUSDLBoundingBox(imagePath: string, options?: RawAxiosRequestConfig): AxiosPromise<BoundingBoxResponseModel>;

    /**
     * 
     * @param {HfInferenceEndpointResumeRequestModel} hfInferenceEndpointResumeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel: HfInferenceEndpointResumeRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }>;

    /**
     * 
     * @param {string} endpointName 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApiInterface
     */
    updateHfInferenceEndpoint(endpointName: string, requestBody: { [key: string]: any; }, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }>;

}

/**
 * MachineLearningApi - object-oriented interface
 * @export
 * @class MachineLearningApi
 * @extends {BaseAPI}
 */
export class MachineLearningApi extends BaseAPI implements MachineLearningApiInterface {
    /**
     * 
     * @param {string} documentPath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public classifyDocument(documentPath: string, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).classifyDocument(documentPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {AttributeRequestModelAI} attributeRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public getAttributeInfo(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).getAttributeInfo(selectedModel, attributeRequestModelAI, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {AttributeRequestModelAI} attributeRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public getAttributeInfoRawResponse(selectedModel: AvailableModels, attributeRequestModelAI: AttributeRequestModelAI, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).getAttributeInfoRawResponse(selectedModel, attributeRequestModelAI, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} endpointName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public getHfInferenceEndpointInfo(endpointName: string, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).getHfInferenceEndpointInfo(endpointName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {PatientRequestModelAI} patientRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public getPatientInfo(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).getPatientInfo(selectedModel, patientRequestModelAI, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailableModels} selectedModel 
     * @param {PatientRequestModelAI} patientRequestModelAI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public getPatientInfoRawResponse(selectedModel: AvailableModels, patientRequestModelAI: PatientRequestModelAI, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).getPatientInfoRawResponse(selectedModel, patientRequestModelAI, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} imagePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public getUSDLBoundingBox(imagePath: string, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).getUSDLBoundingBox(imagePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HfInferenceEndpointResumeRequestModel} hfInferenceEndpointResumeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel: HfInferenceEndpointResumeRequestModel, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).resumeHfInferenceEndpoints(hfInferenceEndpointResumeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} endpointName 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningApi
     */
    public updateHfInferenceEndpoint(endpointName: string, requestBody: { [key: string]: any; }, options?: RawAxiosRequestConfig) {
        return MachineLearningApiFp(this.configuration).updateHfInferenceEndpoint(endpointName, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}

