/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Page context
 */

import React from 'react';

export interface IContext {
	config: MenuItem | null;
}

export default React.createContext<IContext>({
	config: null,
});
