/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Environment helper
 */

import { getClientID } from './getClientID';
import { getEnvironment } from './getEnvironment';
import { getTenantName } from './getTenantName';

class EnvironmentHelper {
	private environment = '';
	private domainName = '';
	private tenantName = '';
	private authClientId = '';

	constructor() {
		const { env, domain } = getEnvironment();
		this.environment = env;
		this.domainName = domain;
		this.tenantName = getTenantName();
		this.authClientId = getClientID();
	}
	get tenant() {
		return this.tenantName;
	}
	get domain() {
		return this.domainName;
	}
	get env() {
		return this.environment;
	}
	get clientId() {
		return this.authClientId;
	}
	get baseURL() {
		return `https://${this.tenant}.${this.domain}.${this.env}`;
	}
}

const environmentHelperInstance = new EnvironmentHelper();
export { environmentHelperInstance };
