/**
 * @author Vaibhav <vaibhab.mane@314ecorp.com>
 * @description ROI Outlined Icon
 */


import classnames from 'classnames';

const ROIOutlined: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-roi-Outlined', className);
	return (
		<span role='img' aria-label='Roi-Outlined' className={cn} {...restProps}>
			<svg
				aria-label='Roi-Outlined'
				width='14'
				height='14'
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M7.33322 10.1333C7.24481 10.1333 7.16002 10.0982 7.09751 10.0356C7.03499 9.97313 6.99988 9.88835 6.99988 9.79994V4.29983H4.52649C4.46371 4.29979 4.40221 4.28202 4.34908 4.24857C4.29595 4.21511 4.25335 4.16734 4.22619 4.11073C4.19902 4.05413 4.18839 3.99101 4.19552 3.92863C4.20265 3.86626 4.22725 3.80716 4.26649 3.75815L7.73989 0.124743C7.77112 0.0858106 7.8107 0.0543898 7.85571 0.0327994C7.90071 0.0112089 7.94998 0 7.9999 0C8.04981 0 8.09908 0.0112089 8.14409 0.0327994C8.18909 0.0543898 8.22867 0.0858106 8.2599 0.124743L11.7333 3.75815C11.7725 3.80716 11.7971 3.86626 11.8043 3.92863C11.8114 3.99101 11.8008 4.05413 11.7736 4.11073C11.7464 4.16734 11.7038 4.21511 11.6507 4.24857C11.5976 4.28202 11.5361 4.29979 11.4733 4.29983H8.99992V9.79994C8.99992 9.88835 8.9648 9.97313 8.90228 10.0356C8.83977 10.0982 8.75498 10.1333 8.66658 10.1333H7.33322Z'
					fill='#9098A7'
				/>
				<path
					d='M14.0003 8H11.3336C11.2452 8 11.1604 8.03512 11.0979 8.09763C11.0353 8.16015 11.0002 8.24493 11.0002 8.33334V9.6667C11.0002 9.75511 11.0353 9.83989 11.0979 9.90241C11.1604 9.96492 11.2452 10 11.3336 10H13.6669C13.7554 10 13.8401 10.0352 13.9027 10.0977C13.9652 10.1602 14.0003 10.245 14.0003 10.3334V11.6667C14.0003 11.7551 13.9652 11.8399 13.9027 11.9024C13.8401 11.965 13.7554 12.0001 13.6669 12.0001H2.33338C2.24497 12.0001 2.16019 11.965 2.09767 11.9024C2.03516 11.8399 2.00004 11.7551 2.00004 11.6667V10.3334C2.00004 10.245 2.03516 10.1602 2.09767 10.0977C2.16019 10.0352 2.24497 10 2.33338 10H4.66676C4.75517 10 4.83996 9.96492 4.90247 9.90241C4.96498 9.83989 5.0001 9.75511 5.0001 9.6667V8.33334C5.0001 8.24493 4.96498 8.16015 4.90247 8.09763C4.83996 8.03512 4.75517 8 4.66676 8H2.00004C1.4696 8 0.960879 8.21072 0.585798 8.5858C0.210718 8.96088 0 9.4696 0 10V14.0001C0 14.5306 0.210718 15.0393 0.585798 15.4144C0.960879 15.7894 1.4696 16.0002 2.00004 16.0002H14.0003C14.5307 16.0002 15.0394 15.7894 15.4145 15.4144C15.7896 15.0393 16.0003 14.5306 16.0003 14.0001V10C16.0003 9.4696 15.7896 8.96088 15.4145 8.5858C15.0394 8.21072 14.5307 8 14.0003 8Z'
					fill='#9098A7'
				/>
			</svg>
		</span>
	);
};

export default ROIOutlined;
