/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description custom Matomo hook with action dimension reset
 */

import { useMatomo as useOriginalMatomo } from '@jonkoops/matomo-tracker-react';
import { TrackEventParams, TrackLinkParams, TrackSiteSearchParams } from '@jonkoops/matomo-tracker-react/lib/types';
import { ACTION_DIMENSIONS_RESET } from 'configs/matomo.config';

const useMatomo: () => {
	trackEvent: (params: TrackEventParams) => void;
	trackEvents: () => void | undefined;
	trackPageView: (params?: any) => void | undefined;
	trackSiteSearch: (params: TrackSiteSearchParams) => void | undefined;
	trackLink: (params: TrackLinkParams) => void | undefined;
	enableLinkTracking: () => void;
	pushInstruction: (name: string, ...args: any[]) => void;
} = () => {
	const { trackEvent, ...matomo } = useOriginalMatomo();

	const customTrackEvent = (params: TrackEventParams) => {
		const customDimensions = params.customDimensions;
		trackEvent({
			...params,
			customDimensions:
				customDimensions && typeof customDimensions !== 'boolean'
					? [...ACTION_DIMENSIONS_RESET, ...customDimensions]
					: [...ACTION_DIMENSIONS_RESET],
		});
	};

	return {
		...matomo,
		trackEvent: customTrackEvent,
	};
};

export default useMatomo;
