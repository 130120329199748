/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Hook for page config
 */

import { useContext } from 'react';

import Context from './Context';

const usePageConfig = (): any => {
	return useContext(Context).config;
};

export default usePageConfig;
