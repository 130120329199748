/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Insufficient Permission
 */


import { Layout, Result, ResultProps } from 'antd';

import AccessForbiddenResult from 'components/icons/AccessForbidden';

const InsufficientPermission: React.FC<ResultProps> = (props) => {
	return (
		<Layout.Content className='main-layout-content'>
			<Result
				icon={<AccessForbiddenResult />}
				title=" It seems a bit quiet for now, but don't worry. ✨"
				subTitle='Reach out to the administrator and unlock a world of tools tailored just for you.'
				{...props}
			/>
		</Layout.Content>
	);
};

export default InsufficientPermission;
