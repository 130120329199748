/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Video Player
 */


import ReactPlayer from 'react-player';

interface IProps {
	url?: string;
	style?: React.CSSProperties;
}

const VideoPlayer: React.FC<IProps> = (props) => {
	const { url, style } = props;
	return <ReactPlayer controls url={url} width='100%' height='100%' style={style} />;
};

export default VideoPlayer;
