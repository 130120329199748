/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Preview Unavailable Component
 */


import { Result } from 'antd';

import PreviewUnavailableOutlined from 'components/icons/PreviewUnavailableOutlined';

const PreviewUnavailable: React.FC = () => {
	return (
		<Result
			title='Preview unavailable for this file type'
			subTitle='Please contact your system administrator for assistance.'
			icon={<PreviewUnavailableOutlined />}
			style={{ backgroundColor: '#EBEEF0' }}
			className='full-width-height flex-center flex-column'
		/>
	);
};

export default PreviewUnavailable;
