/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description To-do Circle Outlined
 */


import classnames from 'classnames';

const TodoCircleOutlined: React.FC<any> = (props) => {
	const { className, ...restProps } = props;
	const cn = classnames('anticon anticon-half-circle-filled', className);
	return (
		<span role='img' aria-label='Half-circle-filled' className={cn} {...restProps}>
			<svg
				aria-label='Half-circle-filled'
				width='14'
				height='14'
				viewBox='0 0 14 14'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M7 0C5.14346 0 3.36294 0.73752 2.0503 2.0503C0.737576 3.36308 0 5.1436 0 7C0 8.8564 0.73752 10.6371 2.0503 11.9497C3.36308 13.2624 5.1436 14 7 14C8.8564 14 10.6371 13.2625 11.9497 11.9497C13.2624 10.6369 14 8.8564 14 7C14 5.77122 13.6766 4.56414 13.0622 3.5C12.4478 2.43589 11.5642 1.55218 10.5 0.93786C9.43592 0.323442 8.22881 2.79846e-05 7.00003 2.79846e-05L7 0ZM7 12.6C5.51474 12.6 4.09038 12.01 3.04024 10.9598C1.99007 9.90954 1.4 8.48512 1.4 7C1.4 5.51488 1.99003 4.09038 3.04024 3.04024C4.09046 1.99007 5.51488 1.4 7 1.4C8.48512 1.4 9.90962 1.99003 10.9598 3.04024C12.0099 4.09046 12.6 5.51488 12.6 7C12.6 7.98301 12.3412 8.94866 11.8497 9.8C11.3583 10.6513 10.6513 11.3582 9.8 11.8497C8.94867 12.3412 7.98294 12.6 7 12.6Z'
					fill='#BFBFBF'
				/>
			</svg>
		</span>
	);
};

export default TodoCircleOutlined;
