/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description Faro initialization
 */

import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import {
	initializeFaro as coreInit,
	getWebInstrumentations,
	FetchTransport,
	browserMeta,
	pageMeta,
	TransportItem,
	Faro,
} from '@grafana/faro-react';

import config from 'configs/index';
import env from '@dexit/common/utils/Environment';

const isLocalhost = env.isLocalhost();
const Env = env.getEnvironment();

export const initializeFaro = (): Faro => {
	return coreInit({
		transports: [
			new FetchTransport({
				url: config.faro.url,
				apiKey: config.faro.apiKey,
				bufferSize: 1000,
				concurrency: 5,
				defaultRateLimitBackoffMs: 1000,
			}),
		],

		app: {
			name: 'Dexit-Web',
			version: '1.0.0',
			environment: Env,
		},
		instrumentations: [
			...getWebInstrumentations({
				captureConsole: true,
				captureConsoleDisabledLevels: [],
			}),
			new TracingInstrumentation(),
		],
		metas: [pageMeta, browserMeta],
		beforeSend: (item: TransportItem) => {
			if (isLocalhost) {
				return null;
			}
			delete item.meta.sdk;
			return item;
		},
	});
};
