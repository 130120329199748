/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description ManageQueue store
 */

import _ from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { AllUserResponseModel, Stages } from '@dexit/common/openapi';

export interface StageList extends Stages {
	isEditing: boolean;
	fromAddButton: boolean;
}
type State = {
	selectedUsers: AllUserResponseModel[];
	disabled: boolean;
	stageList: StageList[];
	fieldChanged: string[];
};

type Actions = {
	setSelectedUsers: (selectedUsers: AllUserResponseModel[]) => {
		selectedUsers: AllUserResponseModel[];
	};
	filterSelectedUsers: (records: AllUserResponseModel[]) => { records: AllUserResponseModel[] };
	setStageList: (stageList: StageList[]) => { stageList: StageList[] };
	addStage: (stage: Omit<StageList, 'sort_index'>) => { stage: Omit<StageList, 'sort_index'> };
	removeStageAtIndex: (indexToRemove: number) => { indexToRemove: number };
	cancelStageEditing: (indexToCancel: number) => { indexToCancel: number };
	saveStage: (
		stageName: string,
		iconBgColor: string,
	) => {
		stageName: string;
		iconBgColor: string;
	};
	editStage: (indexToEdit: number) => { indexToEdit: number };
	setDisabled: (disabled: boolean) => { disabled: boolean };
	addFieldChanged: (fieldChanged: string) => { fieldChanged: string };
};

const initialState: State = {
	selectedUsers: [],
	stageList: [],
	disabled: true,
	fieldChanged: [],
};

const actions = (set: any): Actions => ({
	setSelectedUsers: (selectedUsers) => set({ selectedUsers }),
	filterSelectedUsers: (records) =>
		set((state: State) => ({
			selectedUsers: state.selectedUsers.filter((selectedUsers) => !records.includes(selectedUsers)),
		})),
	setStageList: (stageList) => set({ stageList }),
	addStage: (stage) =>
		set((state: State) => ({ stageList: [...state.stageList, { ...stage, sort_index: state.stageList.length }] })),
	removeStageAtIndex: (indexToRemove) =>
		set((state: State) => ({
			stageList: _.filter(state.stageList, (_, index) => index !== indexToRemove),
		})),

	cancelStageEditing: (indexToCancel) =>
		set((state: State) => ({
			stageList: _.map(state.stageList, (prevStage, stageIndex) =>
				stageIndex === indexToCancel ? { ...prevStage, isEditing: false } : prevStage,
			),
		})),
	editStage: (indexToEdit) =>
		set((state: State) => ({
			stageList: _.map(
				_.filter(state.stageList, (prevStage) => !prevStage.fromAddButton),
				(filteredStage, filteredIndex) =>
					filteredIndex === indexToEdit
						? { ...filteredStage, isEditing: true }
						: { ...filteredStage, isEditing: false },
			),
		})),
	saveStage: (stageName, iconBgColor) =>
		set((state: State) => ({
			stageList: _.map(state.stageList, (prevStage) =>
				prevStage.isEditing
					? {
							...prevStage,
							name: stageName,
							icon_color: iconBgColor,
							isEditing: false,
							fromAddButton: false,
						}
					: prevStage,
			),
		})),
	setDisabled: (disabled) => set({ disabled }),
	addFieldChanged: (fieldChanged) =>
		set((state: State) => ({
			fieldChanged: _.includes(state.fieldChanged, fieldChanged)
				? state.fieldChanged
				: [...state.fieldChanged, fieldChanged],
		})),
});

const useManageQueueValues = (): State =>
	useManageQueueStore((state) => ({
		selectedUsers: state.selectedUsers,
		disabled: state.disabled,
		stageList: state.stageList,
		fieldChanged: state.fieldChanged,
	}));

const useManageQueueActions = (): Actions =>
	useManageQueueStore((state) => ({
		setSelectedUsers: state.setSelectedUsers,
		filterSelectedUsers: state.filterSelectedUsers,
		setStageList: state.setStageList,
		addStage: state.addStage,
		removeStageAtIndex: state.removeStageAtIndex,
		cancelStageEditing: state.cancelStageEditing,
		saveStage: state.saveStage,
		editStage: state.editStage,
		setDisabled: state.setDisabled,
		addFieldChanged: state.addFieldChanged,
	}));

const useManageQueueStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			...actions(set),
		}),
		{ name: 'ManageQueue Store' },
	),
);

export default useManageQueueStore;
export { useManageQueueValues, useManageQueueActions };
