/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description string constants for roles
 */

const ROLES = {
	manageDocumentType: '_manage-document-type',
	manageUsers: '_manage-users',
	manageOrganisation: '_manage-organisation',
	documentDelete: '_delete-document',
	manageQueues: '_manage-queues',
	manageBulkImport: '_manage-bulk-import',
	manageFaxes: '_manage-faxes',
	documentIndexing: '_document-indexing',
	standaloneLaunch: '_standalone-launch',
	documentCommit: '_document-commit',
	releaseOfInformation: '_roi',
	reports: '_reports',
	documentReview: '_document-review',
	manageDeficiency: '_manage-deficiency',
};

export { ROLES };
